import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";
import {colorList} from "../../../config/const";

const TagModal = (props) => {

    let list = [];
    for (let i = 0; i < 11; i++) {
        list.push(colorList[i]);
    }
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const shortList = list

    const {show, close, tagIdx, toggle, onChange, value, onConfirm, curColor, onChangeColor} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='tag-modal'>
            <ModalBody className="position-relative">
                <div className='d-flex'>
                    <div className='f-s-20 f-w-600'>{tagIdx < 0 ? strings.add_tag : strings.edit_tag}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className='tag-input d-flex'>
                    <input
                        className='form-control'
                        style={{
                            border: 'none',
                            outline: 'none',
                            fontSize: 15,
                            height: "100%",
                            boxShadow: "none",
                            flex: 1
                        }}
                        onChange={(e) => {
                            onChange(e.target.value)
                        }}
                        maxLength={50}
                        value={value}/>
                    <div className='d-flex'
                         onClick={() => setShowColorPicker(!showColorPicker)}
                         style={{cursor: 'pointer'}}>
                        <div className='color-item' style={{backgroundColor: curColor}}/>
                        <img className="dropdown-img"
                             src={!showColorPicker ? "/assets/image/icon_triangle_down.png" : "/assets/image/icon_triangle_up.png"}
                             alt=""/>
                    </div>
                </div>
                <button className={("tag-create-btn-disable ") + (value != "" ? "tag-create-btn" : "")} onClick={() => onConfirm()}>
                    {tagIdx < 0 ? strings.create : strings.modify}
                </button>
                {
                    showColorPicker &&
                    <div className='color-picker row'>
                        {
                            showMore &&
                            colorList.map((color, idx) =>
                                <div className='col-md-2 d-center' key={idx}>
                                    <div
                                        onClick={() => {
                                            setShowColorPicker(false);
                                            setShowMore(false);
                                            onChangeColor(color);
                                        }}
                                        style={{backgroundColor: color}}
                                        className={color == curColor ? 'color-item color-item-border' : 'color-item'}/>
                                </div>
                            )
                        }
                        {
                            !showMore &&
                            shortList.map((color, idx) =>
                                <div className='col-md-2 d-center' key={idx}>
                                    <div
                                        onClick={() => {
                                            setShowColorPicker(false);
                                            setShowMore(false);
                                            onChangeColor(color);
                                        }}
                                        style={{backgroundColor: color}}
                                        className={color == curColor ? 'color-item color-item-border' : 'color-item'}/>
                                </div>
                            )
                        }
                        {
                            !showMore &&
                            <div className='col-md-2 d-center'>
                                <div className='color-more-btn d-center'
                                     onClick={() => setShowMore(true)}>
                                    <img src={"/assets/image/icon_plus_white.png"} alt=""
                                         style={{width: 10, height: 10}}/>
                                </div>
                            </div>

                        }
                    </div>
                }
            </ModalBody>
        </Modal>
    );
}

export default TagModal;

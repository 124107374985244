import React from 'react';
import MissionItem from "../../components/control/item/mission_item";
import PerfectScrollbar from "react-perfect-scrollbar";

const MissionComponent = (props) => {

    const {theme, title, onClick, missionList} = props;

    return (
        <div className="mission-list box-shadow">
            <div className={"room-mission mission-" + theme} >
                <div className="mission-title">
                    <div>{title}</div>
                </div>
                <PerfectScrollbar className='scrollbar-custom' options={{suppressScrollX: true}}>
                {
                    missionList.map((item, idx) => {
                            return (
                                <MissionItem
                                    key={idx}
                                    item={item}
                                    detail={item.title}
                                    theme={theme}
                                    onClick={() => onClick(item)}
                                />
                            )
                        }
                    )
                }
            </PerfectScrollbar>
            </div>
        </div>
    );
}

export default MissionComponent;

import React from "react";

export default function CarouselButton(props) {
    return (
        <button
            className={props.more ? "carousel-button-black" : "carousel-button-grey"}
            onClick={props.onClick}>
            <img src={props.more? "/assets/image/icon_carousel_"+props.direction+"_white.png":"/assets/image/icon_carousel_"+props.direction+"_gray.png"} alt=""/>
        </button>
    );
}

import React from 'react';
import AddItemButton from "../../components/control/button/add_item_button";
import strings from "../../lang/strings";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToDoItem from "../../components/control/item/todo_item";
import { colorList } from '../../config/const';

const TodoList = (props) => {
    const {
        onCheck,
        onAddTarget,
        onChangeTodo,
        tagList,
        onMoveSlide,
        tagColor,
        tagTitle,
        todo,
        inputStatus,
        inputRef
    } = props;

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onAddTarget()
        }
    };

    return (
        <div className="col-lg-4">
            <div className="todo-status">
                <div className="to-do-list">
                    <div className="f-w-600">{strings.to_do_list}</div>
                    {
                        tagList.length != 0 &&
                        <div className="daily" style={{background: tagColor}}>{tagTitle}</div>
                    }
                </div>
                <Carousel
                    emulateTouch={true}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={tagList.length !== 0}
                    onChange={(e) => {
                        onMoveSlide(e)
                    }}
                >
                    {
                        tagList.map((item, idx) =>
                            <PerfectScrollbar className="to-do" options={{suppressScrollX: true}} key={idx}>
                                {
                                    item.todo_list.map((it, index) =>
                                        <ToDoItem
                                            key={index}
                                            item={it}
                                            color={colorList.indexOf(item.color)}
                                            onClick={() => onCheck(index)}
                                        />
                                    )
                                }

                            </PerfectScrollbar>
                        )
                    }
                </Carousel>
                {
                    tagList.length == 0 &&
                    <div className="text-center f-s-16">
                        <img src={"assets/image/img_empty_todo list.png"} className="no-plan" alt=""/>
                        <div className="write-today m-t-30 m-b-10">{strings.write_today}</div>
                    </div>
                }
                <div className="add-item">
                    {
                        inputStatus != 0 &&
                        <div className="border-bottom-full">
                            <img src={"/assets/image/icon_todo_check_normal.png"} alt=""/>
                            <input className="input-transparent" type={"text"} value={todo} ref={inputRef}
                                   onChange={(v) => onChangeTodo(v.target.value)}
                                   disabled={tagList.length == 0}
                                   onKeyDown={handleEnter}/>
                        </div>
                    }
                    <AddItemButton
                        onClick={() => onAddTarget()}
                        inputStatus={inputStatus}
                    />
                </div>
            </div>
        </div>
    );
}

export default TodoList;

import React, {useState} from "react";
import strings from "../../../lang/strings";
import StudyBottomButton from "../../../components/control/button/study_bottom_button";
import StudyStartButton from "../../../components/control/button/study_start_button";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {reactionList} from "../../../config/const";
import PerfectScrollbar from "react-perfect-scrollbar";
import ParticipantItem from "../../../components/control/item/participant_item";

const BottomContainer = (props) => {

    const [showReaction, setShowReaction] = useState(false);
    const [showParticipant, setShowParticipant] = useState(false);

    const onReaction = () => {
        setShowReaction(true)
    }

    const toggleParticipant = () => {
        setShowParticipant(!showParticipant)
    }

    const toggleReaction = () => {
        setShowReaction(!showReaction);
    }
    return (
        <div className="study-room-bottom-container">
            <div>
                <div className="room-title">{props.title}</div>
                <div className="owner-name">
                    <div className="name text-ellipsis">{props.owner}</div>
                    {
                        props.roomInfo != "" && props.roomInfo.user.premium == 1 &&
                        <img src={"/assets/image/icon_premium.png"} className="m-l-5" alt=""/>
                    }
                </div>
            </div>
            <div className="flex-grow-1"/>
            <StudyBottomButton
                image={"/assets/image/icon_mentor_question.png"}
                value={strings.mentor_question}
                onClick={() => props.onMentor()}
            />
            <StudyBottomButton
                image={"/assets/image/icon_item_search.png"}
                value={strings.item_search}
                onClick={() => props.onSearch()}
            />
            <StudyBottomButton
                image={"/assets/image/icon_study_material.png"}
                value={strings.study_material}
                onClick={() => props.onMaterial()}
            />
            <div className="position-relative">
                <Dropdown isOpen={showReaction} toggle={() => toggleReaction()}
                >
                    <DropdownToggle className="display-none"/>
                    <DropdownMenu className="media-list dropdown-menu reaction-container" tag="ul">
                        {
                            reactionList.map((item, idx) => {
                                return (
                                    <button className="common-button reaction-button" key={idx} onClick={() => {
                                        props.onClickReaction(item);
                                        setShowReaction(false);
                                    }}>
                                        <img src={item.icon} alt=""/>
                                    </button>
                                )
                            })
                        }

                    </DropdownMenu>
                </Dropdown>
                <StudyBottomButton
                    image={"/assets/image/icon_reaction.png"}
                    value={strings.reaction}
                    onClick={() => onReaction()}
                />
            </div>
            <div className="position-relative">
                <Dropdown isOpen={showParticipant} toggle={() => toggleParticipant()}
                >
                    <DropdownToggle className="display-none"/>
                    <DropdownMenu className="media-list dropdown-menu participant-container" tag="ul">
                        <PerfectScrollbar className="participant-scroll" options={{suppressScrollX: true}}>
                            <button className="invite" onClick={() => props.onInvite()}>
                                <img src={"/assets/image/icon_person_add_white.png"} alt=""/>
                                <div className="f-s-14 m-l-5">{strings.invite}</div>
                            </button>
                            {
                                props.memberList.map((item, idx) => {
                                    if (item.status != 1) {
                                        return (
                                            <ParticipantItem
                                                key={idx}
                                                item={item}
                                                mine={props.mine}
                                                onKick={() => props.onKick(item)}
                                                onInfo={() => props.onInfo(item)}
                                            />
                                        )
                                    }
                                })
                            }
                        </PerfectScrollbar>
                    </DropdownMenu>
                </Dropdown>
                <StudyBottomButton
                    image={"/assets/image/icon_user_white.png"}
                    value={strings.participants}
                    badge={props.participants}
                    onClick={() => toggleParticipant()}
                />
            </div>
            <StudyBottomButton
                image={"/assets/image/icon_settings.png"}
                value={strings.settings}
                onClick={() => props.onSetting()}
            />
            <StudyBottomButton
                image={"/assets/image/icon_logout.png"}
                value={strings.leave}
                onClick={() => props.onOut()}
            />
            <div className="flex-grow-1"/>
            <StudyStartButton
                start={!props.startPressed}
                onClick={() => props.onStart()}
            />
        </div>
    );
}

export default BottomContainer;

import $ from "jquery";
import { API_URL, FORMAT, GENDER, PENDING_STATUS, USER_STATUS } from "../config/const";
import strings from "../lang/strings";
import React from "react";

const moment = require('moment');

Array.prototype.equal = function (array) {
  const a = JSON.parse(JSON.stringify(this)).sort();
  const b = JSON.parse(JSON.stringify(array)).sort();

  return a.join(',') == b.join(',');
};

String.prototype.currencyFormat = function () {
  return this.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

String.prototype.dateFormat = function (format = FORMAT.DATE_TIME) {
  return moment(this).isValid() ? moment(this).format(format) : '';
};

String.prototype.customDateFormat = function () {
  if (!this) {
    return '';
  }

  let days = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
  let colors = ['text-danger', '', '', '', '', '', 'text-blue'];

  return this + '<span class="' + colors[moment(this).weekday()] + '"> (' + days[moment(this).weekday()] + ')</span>';
};

// for reactjs
String.prototype.nl2br = function () {
  return this.split('\n').map((line, i) => (
    <p key={i} className='mb-0'>{line}</p>));
};

// for jQuery
String.prototype.$nl2br = function () {
  return this.replace(/\n/g, '<br/>');
};

Number.prototype.currencyFormat = function () {
  return this.toString().currencyFormat();
};

String.prototype.getThumbnailUrl = function () {
  // TODO: uncomment
  // const ext = this.substr(this.lastIndexOf('.'));
  // return this.replace(ext, `_thumb${ext}`);
  return this;
};

String.prototype.getFullUrl = function () {
  if (this === '') {
    return '';
  }

  return this.startsWith('http') ? this : `${API_URL + '/' + this}`;
}

Number.prototype.status2String = function () {
  switch (this) {
    case USER_STATUS.LEAVE:
      return `<span style='color: gray'>${strings.member.leave}</span>`;
    case USER_STATUS.BLOCK:
      return `<span style='color: black'>${strings.member.block}</span>`;
    case USER_STATUS.HOLD:
      return `<span style='color: green'>${strings.member.hold}</span>`;
    case USER_STATUS.PENDING:
      return `<span style='color: red'>${strings.member.pending}</span>`;
    case USER_STATUS.NORMAL:
      return `<span style='color: blue'>${strings.member.normal}</span>`;
    default:
      return "unknown";
  }
};

Number.prototype.pendingStatusStr = function () {
  switch (this) {
    case PENDING_STATUS.PENDING:
    case PENDING_STATUS.EDIT_NEED:
      return `<span style='color: red'>${strings.member.pending}</span>`;
    case PENDING_STATUS.REJECT:
      return `<span style='color: gray'>${strings.common.rejected}</span>`;
    case PENDING_STATUS.ADD_FAVOR:
      return `<span style='color: green'>${strings.member.favor}</span>`;
    case PENDING_STATUS.COMPLETE:
      return `<span style='color: blue'>${strings.common.accepted}</span>`;
    default:
      return "unknown";
  }
};

String.prototype.genderStr = function () {
  return this === GENDER.FEMALE ? strings.common.female : (this === GENDER.MALE ? strings.common.male : strings.common.unknown);
};

$.fn.dateFormat = (date, format = FORMAT.DATE) => {
  return date.toString().dateFormat(format);
};

$.fn.currencyFormat = cost => {
  return cost.toString().currencyFormat();
};

$.fn.manage_select = function (callback) {
  return this
    .html('')
    .append('<select class="form-control manage-select"></select>')
    .find('select')
    .append('<option value="0">Normal</option>')
    .append('<option value="6">Nickname</option>')
    .append('<option value="7">P-Approve</option>')
    .append('<option value="8">P-Delete</option>')
    .append('<option value="1">Leave</option>')
    .append('<option value="2">Block</option>')
    .append('<option value="10">1-Day</option>')
    .append('<option value="11">3-Day</option>')
    .append('<option value="12">7-Day</option>')
    .append('<option value="13">30-Day</option>')
    .append('<option value="14">180-Day</option>')
    .append('<option value="4">Video Limit</option>')
    .append('<option value="5">DB Recycle</option>')
    .bind('change', function () {
      callback?.($(this).val());
    });
};

$.fn.nickname_label = function (nickname, gender, callback) {
  let clsName = gender === GENDER.MALE ? 'male' : 'female';
  let label = `<a href="javascript:void(0);" class="${clsName}">${nickname}</a>`;

  return this
    .html(label)
    .find('a')
    .bind('click', () => callback?.());
};

$.fn.image_label = function (images, callback) {
  if (images && images.length > 0) {
    this.html(`<div></div>`);
    images.split('#').forEach(image => {
      const full_url = image.getFullUrl();
      this.append(`<a><img src="${full_url.getThumbnailUrl()}" style="width: auto; height: 50px; margin: 0 1px;" /></a>`);
    });
    this.find('a')
      .bind('click', () => callback?.());
  } else {
    this.html('<img style="width: 50px; height: 50px; border: 1px #e2e7eb;"/>');
  }
};

$.fn.image_lightbox = function (images, callback) {
  if (images && images.length > 0) {
    const image_list = images.split('#')
    if (image_list.length > 0) {
      const full_url = image_list[0].getFullUrl();

      return this
        .html(`<a href="javascript:;"><img src="${full_url.getThumbnailUrl()}" style="width: auto; height: 50px; margin: 0 1px;" /></a>`)
        .find('a')
        .bind('click', () => callback?.());
    }
  }

  this.html('<img style="width: 50px; height: 50px; border: 1px #e2e7eb;"/>');
};

$.fn.video_label = function (videos, callback) {
  if (videos && videos.length > 0) {
    const video_list = videos.split('#')
    if (video_list.length > 0) {
      const ext = video_list[0].split('.').pop();
      const full_url = video_list[0].replace(ext, 'jpg').getFullUrl();

      return this
        .html(`<div style="display: inline-block; position: relative;width: 100px; height: 60px; margin: 0 1px; background-image: url(${full_url}); background-position: center; background-size: cover">
                    <span style="position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 30px;
                        height: 30px;
                        margin: -20% 0 0 -20%;
                        border-radius: 50%;
                        background: rgba(0,0,0,.75);
                        color: #fff;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;">
                        <i class="fa fa-play"></i>
                    </span>
                </div>`)
        .find('div')
        .bind('click', () => callback?.())
    }
  }

  this.html('<img style="width: 50px; height: 50px; border: 1px #e2e7eb;"/>');
};

import React, {useContext, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import strings from "../../../lang/strings";
import {Page_settings} from "../../../config/page_settings";

const SearchVideoItem = (props) => {
    const context = useContext(Page_settings);
    const {item, onClick} = props

    return (
        <div className="video-item">
            <button className="video-button" onClick={() => onClick()}>
                <img className="video large-video" src={context.loadImage(item.url.split(".")[0] + ".jpg")} alt=""/>
                <div className="video-length">{item.length}</div>
            </button>
            <div>
                <div className="title m-t-10 text-ellipsis">{item.title}</div>
                <div className="producer m-t-10 m-b-10">{(item.subject != null ? item.subject: "") + " | " + (item.speaker != null ? item.speaker : "")}</div>
                <div className="producer m-b-10">{item.company}</div>
                <div className="code-video">{item.code}</div>
            </div>
        </div>
    );
}

export default SearchVideoItem;

import React from 'react';
import strings from "../../lang/strings";

const ClinicTab = (props) => {

    return (
        <div className="col-lg-4">
            <button className="clinic-tab text-left" onClick={() => props.onClick()}>
                <div className="clinic-title">
                    <img src={"assets/image/icon_menu_clinic_active.png"} alt=""/>
                    <div>{strings.menu.clinic}</div>
                </div>
                <div className="advertise">
                    {strings.advertise1}
                </div>
                <img src={"assets/image/img_bot.png"} className="bot" alt=""/>
                <div className="advertise-detail">
                    {strings.advertise2}
                </div>
            </button>
        </div>
    );
}

export default ClinicTab;

import React from "react";
import { checkIconList } from "../../../config/const";

const ToDoItem = (props) => {
    const {item, color, onClick} = props
    return (
        <button className="to-do-item" onClick={() => onClick()}>
            <img
                src={item.status == "Y" ? checkIconList[color] : "/assets/image/icon_todo_check_normal.png"}
                alt=""/>
            <div className="to-do-info">{item.title}</div>
        </button>
    );
}

export default ToDoItem;

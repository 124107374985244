import React, { useContext, useState, useRef, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import strings from '../../../lang/strings';

const CoachingAnswerModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, answers, onConfirm } = props;

    const [userAnswersFirst, setUserAnswersFirst] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [userAnswersSecond, setUserAnswersSecond] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [enableConfirm, setEnableConfirm] = useState(false);

    useEffect(() => {
        checkAvailable();
    }, [userAnswersFirst, userAnswersSecond]);

    const setDefaultAnswers = () => {

    }

    const selectCorrectAnswer = (index, type) => {
        if (type == 1) {
            let answers = [...userAnswersFirst];
            answers[index] = 1;
            setUserAnswersFirst(answers);
        } else {
            let answers = [...userAnswersSecond];
            answers[index] = 1;
            setUserAnswersSecond(answers);
        }
    }

    const selectWrongAnswer = (index, type) => {
        if (type == 1) {
            let answers = [...userAnswersFirst];
            answers[index] = -1;
            setUserAnswersFirst(answers);
        } else {
            let answers = [...userAnswersSecond];
            answers[index] = -1;
            setUserAnswersSecond(answers);
        }
    }

    const checkAvailable = () => {
        let unchecked = 0;
        const userAnswer = [...userAnswersFirst, ...userAnswersSecond];
        userAnswer.map((value) => {
            if (value == 0) {
                unchecked++
            }
        });
        setEnableConfirm(unchecked == 0)
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="cloud-view-modal coaching-answer-modal" onOpened={() => setDefaultAnswers()}>
            <ModalBody>
                <div className="d-flex align-items-center p-l-30 p-r-30" style={{ borderBottom: '1px solid #E6E9F0' }}>
                    <div className='d-flex align-items-center flex-1 p-t-20 p-b-20' style={{ borderRight: '1px solid #E6E9F0' }}>
                        <div className="f-s-22 f-w-700">{strings.answer_sheet}</div>
                    </div>
                    <div className='d-flex align-items-center flex-1 p-t-20 p-b-20'>
                        <div className="f-s-22 f-w-700 m-l-30">{strings.correct_answer_check}</div>
                        <button className='modal-close-btn m-l-auto' onClick={() => toggle()}>
                            <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                        </button>
                    </div>
                </div>
                <div className='d-flex' style={{ borderBottom: '1px solid #E6E9F0' }}>
                    <div className='d-flex' style={{ borderRight: '1px solid #E6E9F0', padding: 22, width: '50%' }}>
                        <div className='d-flex flex-column flex-1'>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                    <div key={index} className='d-flex align-items-center' style={{ marginBottom: 8 }}>
                                        <div style={{ width: 35, color: '#222222', fontSize: 22, fontWeight: 700, lineHeight: '20px', letterSpacing: -0.44, textAlign: 'center' }}>{item}</div>
                                        <div className='d-flex align-items-center flex-1' style={{ borderRadius: '4px', background: '#F7F9FC', height: 42, justifyContent: 'center' }}>
                                            <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid #222', color: '#222', fontSize: 18, lineHeight: '26px', letterSpacing: -0.36, textAlign: 'center' }}>{answers[item - 1]}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='d-flex flex-column flex-1'>
                            {
                                [11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item, index) => (
                                    <div key={index} className='d-flex align-items-center' style={{ marginBottom: 8 }}>
                                        <div style={{ width: 35, color: '#222222', fontSize: 22, fontWeight: 700, lineHeight: '20px', letterSpacing: -0.44, textAlign: 'center' }}>{item}</div>
                                        <div className='d-flex align-items-center flex-1' style={{ borderRadius: '4px', background: '#F7F9FC', height: 42, justifyContent: 'center' }}>
                                            <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid #222', color: '#222', fontSize: 18, lineHeight: '26px', letterSpacing: -0.36, textAlign: 'center' }}>{answers[item - 1]}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='d-flex' style={{ padding: 22, width: '50%' }}>
                        <div className='d-flex flex-column flex-1'>
                            {
                                userAnswersFirst.map((item, index) => (
                                    <div key={index} className='d-flex align-items-center' style={{ marginBottom: 8 }}>
                                        <div style={{ width: 35, color: '#222222', fontSize: 22, fontWeight: 700, lineHeight: '20px', letterSpacing: -0.44, textAlign: 'center' }}>{index + 1}</div>
                                        <div className='d-flex align-items-center flex-1' style={{ borderRadius: '4px', background: '#F7F9FC', height: 42, justifyContent: 'center' }}>
                                            <button className='d-flex align-items-center justify-content-center' style={{ width: '50%', border: 'none', background: 'transparent' }} onClick={() => selectCorrectAnswer(index, 1)}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: item == 1 ? '#0F84F4' : '#D9DDE8', background: item == 1 ? '#0F84F4' : '#fff' }} />
                                                <div style={{ color: item == 1 ? '#0F84F4' : '#222', fontSize: 22, fontWeight: 500, letterSpacing: -0.44, marginLeft: 12 }}>O</div>
                                            </button>
                                            <button className='d-flex align-items-center justify-content-center' style={{ width: '50%', border: 'none', background: 'transparent' }} onClick={() => selectWrongAnswer(index, 1)}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: item == -1 ? '#EE4C69' : '#D9DDE8', background: item == -1 ? '#EE4C69' : '#fff' }} />
                                                <div style={{ color: item == -1 ? '#EE4C69' : '#222', fontSize: 22, fontWeight: 500, letterSpacing: -0.44, marginLeft: 12 }}>X</div>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='d-flex flex-column flex-1'>
                            {
                                userAnswersSecond.map((item, index) => (
                                    <div key={index} className='d-flex align-items-center' style={{ marginBottom: 8 }}>
                                        <div style={{ width: 35, color: '#222222', fontSize: 22, fontWeight: 700, lineHeight: '20px', letterSpacing: -0.44, textAlign: 'center' }}>{index + 11}</div>
                                        <div className='d-flex align-items-center flex-1' style={{ borderRadius: '4px', background: '#F7F9FC', height: 42, justifyContent: 'center' }}>
                                            <button className='d-flex align-items-center justify-content-center' style={{ width: '50%', border: 'none', background: 'transparent' }} onClick={() => selectCorrectAnswer(index, 2)}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: item == 1 ? '#0F84F4' : '#D9DDE8', background: item == 1 ? '#0F84F4' : '#fff' }} />
                                                <div style={{ color: item == 1 ? '#0F84F4' : '#222', fontSize: 22, fontWeight: 500, letterSpacing: -0.44, marginLeft: 12 }}>O</div>
                                            </button>
                                            <button className='d-flex align-items-center justify-content-center' style={{ width: '50%', border: 'none', background: 'transparent' }} onClick={() => selectWrongAnswer(index, 2)}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: item == -1 ? '#EE4C69' : '#D9DDE8', background: item == -1 ? '#EE4C69' : '#fff' }} />
                                                <div style={{ color: item == -1 ? '#EE4C69' : '#222', fontSize: 22, fontWeight: 500, letterSpacing: -0.44, marginLeft: 12 }}>X</div>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="p-l-35 p-r-25 p-t-15 p-b-15 width-100-percent d-flex align-items-center">
                    <div className='caution'>{strings.submit_caution}</div>
                    <button className={"submit-button m-l-auto" + (enableConfirm ? "" : " disabled")} onClick={() => {
                        if (enableConfirm) {
                            let wrongAnswers = [];
                            const userAnswer = [...userAnswersFirst, ...userAnswersSecond];
                            userAnswer.map((value, index) => {
                                if (value == -1) {
                                    wrongAnswers.push(index + 1);
                                }
                            });
                            onConfirm(wrongAnswers);
                        }
                    }}>
                        {strings.confirm}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default CoachingAnswerModal;

import strings from "../../lang/strings";
import {withRouter} from "react-router-dom";
import {formatNumber, getAgeFromBirthday} from "../../helper/common";
import {useContext, useEffect, useState} from "react";
import {Page_settings} from "../../config/page_settings";
import moment from "moment";
import {rootStore} from "../../mobx/store";

const ClinicExpert = (props) => {

    const {userName, profile, score, title, department, review} = props;
    const context = useContext(Page_settings);
    const [reservationList, setReservationList] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        getMemberList();
        getProfile();
    }, [])

    const getProfile = () => {
        context.get(
            'member/getProfile',
            {
                id: 0
            },
            response => {
                rootStore.setProfile(response.user_info);
            }
        );
    }


    const onReview = () => {

    }

    const getMemberList = () => {
        context.get(
            "clinic/getMemberList",
            {
                page_num: pageNum,
            },
            response => {
                setReservationList(response.list);
            }
        );
    }


    return (
        <div className="main-content">
            <button
                className={"consultant-tab consultant-selected"}
                onClick={() => window.location.reload()}>
                {strings.reservation_history}
            </button>
            <button
                className={"consultant-tab"}
                onClick={() => props.history.push("/clinic/consult_history/-1")}>
                {strings.consultation_history}
            </button>
            <div className="expert-item clinic-expert">
                <div className="flex-grow-1">
                    <div className="d-flex align-item-center">
                        <div className="name f-w-600">{userName + " " + strings.expert}</div>
                    </div>
                    <div className="org">{department}</div>
                    <div className="title">{title}</div>
                    <div className="d-flex align-item-center m-b-10">
                        <button className="review"
                                onClick={() => onReview()}>{strings.review + " " + formatNumber(review)}</button>
                        <img className="rating-star" src={"/assets/image/icon_fav_active.png"} alt=""/>
                        <div className="f-s-16">{score}</div>
                    </div>
                </div>
                <img className="expert-profile"
                     src={profile != null ? context.loadImage(profile) : "/assets/image/logo_big.png"} alt=""/>
            </div>
            <div className='row p-r-10 p-l-10 reservation-container'>
                {
                    reservationList.map((item, idx) => {
                        let age = getAgeFromBirthday(item.user.birthday)
                        return (
                            <div
                                key={idx}
                                className="reservation-item">
                                <div className="d-flex align-item-center m-b-40 width-100-percent">
                                    <img
                                        src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"}
                                        alt=""/>
                                    <div className="student-info">
                                        <div className="f-s-20 f-w-600 m-b-10 text-ellipsis">{item.user.name}</div>
                                        <div className="display-flex-important align-item-center">
                                            <div className="f-s-15">{ age >= 19 ? strings.g3 : (age == 18 ? strings.g2 : strings.g1)}</div>
                                            <div className="f-s-12 school">{item.user.school}</div>
                                        </div>
                                        <div
                                            className="consult-time">{moment(item.create_date).format("YY.MM.DD HH:mm")}</div>
                                    </div>
                                </div>
                                <button className="tag-create-btn-disable tag-create-btn"
                                        onClick={() => props.history.push("/clinic/consult_history/" + item.id.toString())}>{strings.consult}</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );

}
export default withRouter(ClinicExpert);

import React, {useContext} from "react";
import strings from "../../../lang/strings";
import {Page_settings} from "../../../config/page_settings";
import {getTimeFromSecond} from "../../../helper/common";


const RankingItem = (props) => {
    const context = useContext(Page_settings);

    return (
        <div className="room-ranking-item">
            <div className="user-rank">{props.rank}</div>
            <div className="f-s-12 m-l-15 text-ellipsis">{props.item.user.name}</div>
            <div className="flex-grow-1"/>
            <div className="m-l-15">
                <div className="period">{strings.month}</div>
                <div className={'user-rank'}>{getTimeFromSecond(props.item.month_time)}</div>
            </div>
            <div className="header-vertical-divider rank-divider"/>
            <div className="m-l-15">
                <div className="period">{strings.today}</div>
                <div className={'today'}>{getTimeFromSecond(props.item.today_time)}</div>
            </div>
            <img src={(props.item.user.profile != null || "") ?context.loadImage(props.item.user.profile) : "/assets/image/logo_big.png"} alt=""/>
        </div>
    );
}

export default RankingItem;

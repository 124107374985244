import React, { useContext } from "react";
import strings from "../../../lang/strings";
import { Page_settings } from "../../../config/page_settings";
import moment from "moment";
import { getVideoIdFromYoutubeURL } from "../../../helper/common";

const CoachChatItem = (props) => {
    const context = useContext(Page_settings);
    const { item, mine, coach, onDownload, onStartTest, onUse, onShowDoc, onShowVideo } = props;
    return (
        <div className={(mine ? "m-l-auto " : "m-r-auto m-l-20 ") + ("d-flex align-items-xs-end coach-chat")}>
            {
                !mine &&
                <img src={coach.image != null ? context.loadImage(coach.image) : "/assets/image/logo_big.png"}
                    style={{
                        width: 38,
                        height: 38,
                        borderRadius: 19,
                        marginRight: 10,
                        marginBottom: "auto",
                        objectFit: "cover"
                    }} alt="" />
            }
            {
                mine &&
                <div style={{
                    color: "#A8ACBA",
                    fontSize: 12,
                    margin: "0 5px 10px auto",
                    minWidth: 90,
                    paddingLeft: 20,
                    textAlign: 'right'
                }}>
                    {moment(item.create_date).format("a hh:mm")}
                </div>
            }
            <div style={!mine ? { flex: 1 } : {}}>
                {
                    !mine &&
                    <div className="name">{"AI " + coach.name}</div>
                }
                <div className="d-flex width-100-percent">
                    {
                        item.type != 4 && item.type != 6 &&
                        <div className={mine ? "right" : "left"} style={{ background: (item.file != null && item.file != '') ? 'none' : '' }}>
                            {
                                item.content != "" &&
                                <div style={{ wordBreak: "break-all" }}>
                                    {item.type == 3 ? JSON.parse(item.content).text : item.type == 5 ? JSON.parse(item.content).text : item.type == 7 ? JSON.parse(item.content).text : item.content}
                                </div>
                            }
                            {
                                item.file != null && item.file != "" &&
                                <button
                                    className="common-button p-0"
                                    onClick={() => onDownload(item)}>
                                    <img
                                        src={context.loadImage(item.file)}
                                        // style={{maxWidth: "100%", objectFit: "cover"}}
                                        alt="" />
                                </button>

                            }
                        </div>
                    }
                    {
                        !mine &&
                        <div style={{
                            color: "#A8ACBA",
                            fontSize: 12,
                            margin: "auto auto 10px 5px",
                        }}>
                            {moment(item.create_date).format("a hh:mm")}
                        </div>
                    }
                </div>
                {
                    !mine && item.type != 1 && item.type != 2 &&
                    <div className="functional-area">
                        {
                            item.type == 3 ?
                                <div style={{ display: "flex", flexDirection: 'column' }}>
                                    <div style={{ display: "flex", flexDirection: 'column', borderRadius: '10px 10px 0 0', background: '#DCF5DC', height: 185, alignItems: 'center', padding: 28 }}>
                                        <div style={{ color: '#222222', fontSize: 18, fontWeight: 600, lineHeight: '20px', letterSpacing: -0.72 }}>{"AI " + coach.name + " 테스트"}</div>
                                        <div style={{ color: '#222222', fontSize: 50, fontWeight: 600, lineHeight: '50px', marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>{JSON.parse(item.content).keyword}</div>
                                    </div>
                                    <div style={{ borderRadius: '0 0 10px 10px', background: '#fff', padding: 18 }}>
                                        <button style={{
                                            borderRadius: '5px',
                                            background: item.status == 0 ? '#04C886' : '#767981',
                                            border: 'none',
                                            width: '100%',
                                            paddingTop: 12,
                                            paddingBottom: 12,
                                            color: '#fefefe',
                                            fontSize: 14,
                                            fontWeight: 600,
                                            lineHeight: '15px'
                                        }}
                                            onClick={() => {
                                                if (item.status == 0) {
                                                    onStartTest(item);
                                                }
                                            }}>
                                            {item.status == 0 ? strings.test_start : strings.test_done}
                                        </button>
                                    </div>
                                </div>
                                :
                                item.type == 4 ?
                                    <div className="d-flex align-items-center justify-content-center" style={{ padding: '23px 47px', color: '#222', fontSize: 18, fontWeight: 600, letterSpacing: -0.72 }}>
                                        {"AI " + coach.name + " 테스트 결과 분석중"}
                                        <img src={"/assets/image/icon_test_analysing.png"} alt="" style={{ marginLeft: 13 }} />
                                    </div>
                                    :
                                    item.type == 5 ?
                                        <div style={{ padding: '23px 25px', fontSize: 15, fontWeight: 600, letterSpacing: -0.6 }}>
                                            <button className="d-flex align-items-center" style={{ width: '100%', height: 45, borderRadius: 100, border: '1px solid', borderColor: JSON.parse(item.content).yesno == 1 ? '#1B2738' : '#CFD7DF', padding: 10, background: JSON.parse(item.content).yesno == 1 ? '#1B2738' : '#fff' }}
                                                onClick={() => {
                                                    if (JSON.parse(item.content).yesno == 0) {
                                                        onUse(item, 1);
                                                    }
                                                }}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: JSON.parse(item.content).yesno == 1 ? '#1B2738' : '#303340', lineHeight: '26px', textAlign: 'center', background: '#fff' }}>{'A'}</div>
                                                <span style={{ marginLeft: 15, marginTop: 5, color: JSON.parse(item.content).yesno == 1 ? '#fff' : '#303340' }}>{'사용했습니다.'}</span>
                                            </button>
                                            <button className="d-flex align-items-center" style={{ width: '100%', height: 45, borderRadius: 100, border: '1px solid', borderColor: '#CFD7DF', padding: 10, background: '#fff', marginTop: 10 }}
                                                onClick={() => {
                                                    if (JSON.parse(item.content).yesno == 0) {
                                                        onUse(item, -1);
                                                    }
                                                }}>
                                                <div style={{ width: 26, height: 26, borderRadius: 13, border: '1px solid', borderColor: JSON.parse(item.content).yesno == -1 ? '#1B2738' : '#303340', lineHeight: '26px', textAlign: 'center', background: '#fff' }}>{'B'}</div>
                                                <span style={{ marginLeft: 15, marginTop: 5, color: JSON.parse(item.content).yesno == -1 ? '#fff' : '#303340' }}>{'사용하지 않았습니다.'}</span>
                                            </button>
                                        </div>
                                        :
                                        item.type == 6 ?
                                            <div className="d-flex align-items-center justify-content-center" style={{ padding: '23px 47px', color: '#222', fontSize: 18, fontWeight: 600, letterSpacing: -0.72 }}>
                                                <button style={{ width: '100%', height: 45, background: '#fff', border: 'none' }}
                                                    onClick={() => onShowDoc(JSON.parse(item.content).code)}>
                                                    {JSON.parse(item.content).name}
                                                </button>
                                            </div>
                                            :
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button className="d-flex align-items-center position-relative" style={{ width: '100%', height: 192, background: '#fff', border: 'none' }}
                                                    onClick={() => onShowVideo(JSON.parse(item.content).url)}>
                                                    <img src={"https://img.youtube.com/vi/" + getVideoIdFromYoutubeURL(JSON.parse(item.content).url) + "/0.jpg"} style={{ width: '100%', height: 192, position: 'absolute', left: 0, top: 0, objectFit: 'cover', borderRadius: 18 }} alt="" />
                                                </button>
                                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    );
}

export default CoachChatItem;

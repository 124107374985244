import React, {useContext} from "react";
import strings from "../../../lang/strings";
import moment from "moment";
import {Page_settings} from "../../../config/page_settings";


const QuestionListItem = (props) => {
    const context = useContext(Page_settings);
    const {item} = props
    return (
        <button className={("question-item ") + (!item.is_read ? "read-question" : " ")}
                onClick={() => props.onClick()}>
            <div className="d-flex align-item-center" style={{width: 500}}>
                {
                    item.file != null && item.file != "" &&
                    <img src={context.loadImage(item.file.split("#")[0])} alt=""/>
                }
                <div className="title">{item.content}</div>
            </div>

            <div className="m-l-auto d-flex align-item-center">
                {
                    !item.is_read &&
                    <div className="time">{moment(item.create_date).format("HH:mm")}</div>
                }
                <div className={item.is_read ? "read-button" : "read-button-read"}>
                    {item.is_read ? strings.read : strings.not_read}
                </div>
            </div>
        </button>
    );
}

export default QuestionListItem;

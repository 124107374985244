import React from "react";
import strings from "../../../lang/strings";

export default function LoadMoreButton(props) {
    return (
        <button
            className={"load-more-button display-flex-important align-item-center"}
            onClick={props.onClick}>
            <div className="f-s-16">{strings.load_more}</div>
            <div className="round-plus display-flex-important align-item-center justify-content-center">
                <img src={"../assets/image/icon_plus_white.png"} alt=""/>
            </div>
        </button>
    );
}

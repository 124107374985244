import {action, observable} from 'mobx';

export const rootStore = observable({

    me: null,
    setting: null,
    user_info: null,
    school: null,
    hope_school: null,
    notification: null,
    premium: null,
    signup_info: null,

    setSignUpInfo: info => {
        rootStore.signup_info = info;
        sessionStorage.setItem('signup_info', JSON.stringify(info));
    },

    signIn: user => {
        rootStore.me = user;
        sessionStorage.setItem('admin', JSON.stringify(user));
    },

    signOut: () => {
        rootStore.me = null;
        sessionStorage.setItem('admin', null);
        rootStore.user_info = null;
        sessionStorage.setItem("user_info", null);
    },

    saveSetting: setting => {
        rootStore.setting = setting;
        sessionStorage.setItem('setting', JSON.stringify(setting));
    },

    setProfile: user_info => {
        rootStore.user_info = user_info;
        sessionStorage.setItem("user_info", JSON.stringify(user_info));
    },

    setSchool: school => {
        rootStore.school = school;
        sessionStorage.setItem("school", JSON.stringify(school));
    },

    setHope: school => {
        rootStore.hope_school = school;
        sessionStorage.setItem("hope_school", JSON.stringify(school));
    },

    setNotification: (web, market) => {
        rootStore.user_info.alarm_web = web ? "ON":"OFF";
        rootStore.user_info.alarm_market = market ? "ON":"OFF";
        rootStore.notification = web ? "ON":"OFF";
        sessionStorage.setItem("user_info", JSON.stringify(rootStore.user_info));
        sessionStorage.setItem("notification", JSON.stringify(rootStore.notification));
    },

    setScreenSetting: (video, speaker, mirror) => {
        rootStore.user_info.video = video ? "ON":"OFF";
        rootStore.user_info.speaker = speaker ? "ON":"OFF";
        rootStore.user_info.mirror = mirror ? "ON":"OFF";
        sessionStorage.setItem("user_info", JSON.stringify(rootStore.user_info));
    },

    setPremium: premium => {
        rootStore.user_info.premium = premium;
        rootStore.premium = premium;
        sessionStorage.setItem("user_info", JSON.stringify(rootStore.user_info));
        sessionStorage.setItem("premium", JSON.stringify(rootStore.premium));
    },

    setPoint: point => {
        rootStore.user_info.point = point
        sessionStorage.setItem("user_info", JSON.stringify(rootStore.user_info));
    },

    get getSchool() {
        if (rootStore.school == null) {
            try {
                rootStore.school = JSON.parse(sessionStorage.getItem('school'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.school;
    },

    get getHope() {
        if (rootStore.hope_school == null) {
            try {
                rootStore.hope_school = JSON.parse(sessionStorage.getItem('hope_school'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.hope_school;
    },

    get getSetting() {
        if (rootStore.setting == null) {
            try {
                rootStore.setting = JSON.parse(sessionStorage.getItem('setting'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.setting;
    },

    get getMe() {
        if (rootStore.me == null) {
            try {
                rootStore.me = JSON.parse(sessionStorage.getItem('admin'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.me;
    },

    get getProfile() {
        if (rootStore.user_info == null) {
            try {
                rootStore.user_info = JSON.parse(sessionStorage.getItem('user_info'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.user_info;
    },

    get getPremium() {
        if (rootStore.premium == null) {
            try {
                rootStore.premium = JSON.parse(sessionStorage.getItem('premium'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.premium;
    },

    get getNotification() {
        if (rootStore.notification == null) {
            try {
                rootStore.notification = JSON.parse(sessionStorage.getItem('notification'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.notification;
    },

    get isSignedIn() {
        return rootStore.getMe !== null;
    },

    get token() {
        return rootStore.getMe?.token ?? '';
    },

    get nickname() {
        return rootStore.getMe?.nickname ?? '';
    },

    get getSignUpInfo() {
        if (rootStore.signup_info == null) {
            try {
                rootStore.signup_info = JSON.parse(sessionStorage.getItem('signup_info'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.signup_info;
    },

}, {
    signIn: action,
    signOut: action,
    saveSetting: action
});

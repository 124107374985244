import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import ReactPlayer from "react-player";
import {Page_settings} from "../../../config/page_settings";

const CloudVideoModal = (props) => {
        const context = useContext(Page_settings)
        const {show, toggle, item} = props;
        return (
            <Modal isOpen={show} toggle={() => toggle()} className='item-video-modal video-modal'>
                <ModalBody style={{padding: 0}}>
                    <div className='d-flex header align-item-center m-b-20'>
                        <div className='flex-grow-1'/>
                        <button className='modal-close-btn' onClick={() => toggle()}>
                            <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                        </button>
                    </div>
                    <ReactPlayer
                        url={context.loadImage(item.url)}
                        height={360}
                        width={740}
                        controls={true}
                    />
                </ModalBody>
            </Modal>
        );
}

export default CloudVideoModal;

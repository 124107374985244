import React, {useContext, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import StudyRoomInput from "../custom_input/study_room_input";
import {rootStore} from "../../../mobx/store";
import {Page_settings} from "../../../config/page_settings";
import {observer} from "mobx-react";

const EditProfileModal = observer((props) => {
    const context = useContext(Page_settings);
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [school, setSchool] = useState("");
    const [hopeSchool, setHopeSchool] = useState("");
    const [career, setCareer] = useState("");
    const [profile, setProfile] = useState("");
    const imageRef = useRef();
    const [imgFile, setImgFile] = useState("")
    const [url, setUrl] = useState("");
    const [selectProfile, setSelectProfile] = useState(false);
    const [edited, setEdited] = useState(false);

    const onImageChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            setSelectProfile(true);
            setImgFile(reader.result);
            setUrl(file);
            setEdited(true);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        setInfo();
    }, [rootStore.getProfile]);

    useEffect(() => {
        setSchool(rootStore.getSchool ?? "");
    }, [rootStore.getSchool]);

    useEffect(() => {
        setHopeSchool(rootStore.getHope ?? "");
    }, [rootStore.getHope]);

    const setInfo = () => {
        setEmail(rootStore.getProfile?.email ?? "");
        setNickname(rootStore.getProfile?.name ?? "");
        setSchool(rootStore.getProfile?.school ?? "");
        setProfile(rootStore.getProfile?.profile ?? "");
        setHopeSchool(rootStore.getProfile?.hope_school ?? "");
        setCareer(rootStore.getProfile?.hope_career ?? "");
    }

    const onEdit = () => {
        if (!edited) {
            return;
        }

        if (nickname == "") {
            alert(strings.enter_nick);
            return;
        }
        if (school == "") {
            alert(strings.school);
            return;
        }

        if (selectProfile) {
            let formData = new FormData();
            formData.append('file', url);

            context.post(
                'upload/image/profile',
                formData,
                response => {
                    setProfile(response.image);
                    editProfile(response.image);
                });
        } else {
            editProfile();
        }
    }

    const editProfile = (image) => {
        context.post(
            'member/editProfile',
            {
                name: nickname,
                profile: image ?? profile,
                school: school,
                hope_school: hopeSchool,
                hope_career: career
            },
            response => {
                context.get(
                    'member/getProfile',
                    {
                        id: 0
                    },
                    response => {
                        rootStore.setProfile(response.user_info);
                        context.handleProfileModal(false);
                    }
                );
            });
    }

    const onSchoolSearch = () => {
        context.handleSchoolSearch(true);
        context.handleProfileModal(false);
        context.setSchoolType(1);
    }

    const onHopeSearch = () => {
        context.handleSchoolSearch(true);
        context.handleProfileModal(false);
        context.setSchoolType(2);
    }

    const onOpened = () => {
        if (status == 1) {
            setInfo();
            rootStore.setSchool(rootStore.getProfile.school);
            rootStore.setHope(rootStore.getProfile.hope_school);
            setSelectProfile(false);
        } else {
            context.handleProfileStatus(1);
        }
    }

    const {show, toggle, status} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} onOpened={() => onOpened()} onClosed={() => setEdited(false)} className="account-info-modal">
            <ModalBody className="p-30">
                <div className='d-flex'>
                    <div className='f-s-20 f-w-600'>{strings.edit_profile}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" style={{width: 25, height: 25}}/>
                    </button>
                    <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e)}
                           ref={imageRef}/>
                </div>
                <div className="d-flex justify-content-center m-t-10 position-relative m-b-10">
                    <img className="user-profile"
                         src={selectProfile ? imgFile : (profile != null ? context.loadImage(profile) : "/assets/image/logo_big.png")}
                         alt=""/>
                    <button className="camera-btn" onClick={() => imageRef?.current.click()}>
                        <img src={"/assets/image/btn_camera.png"} alt=""/>
                    </button>
                </div>
                <StudyRoomInput
                    name={strings.nick_name}
                    required={true}
                    inputType={"text"}
                    value={nickname}
                    onChange={(v) => {
                        setNickname(v);
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.email}
                    inputType={"email"}
                    value={email}
                    onChange={(v) => {
                        setEmail(v);
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.school}
                    required={true}
                    search={true}
                    inputType={"text"}
                    disable={true}
                    value={school}
                    onSearch={() => onSchoolSearch()}
                    onChange={(v) => {
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.hope_school}
                    search={true}
                    disable={true}
                    inputType={"text"}
                    value={hopeSchool}
                    onSearch={() => onHopeSearch()}
                    onChange={(v) => {
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.hope_career}
                    inputType={"text"}
                    value={career}
                    onChange={(v) => {
                        setCareer(v);
                        setEdited(true);
                    }}/>
                <button className={('tag-create-btn ') + (edited ? '' : ' tag-create-btn-disable')} onClick={() => onEdit()}>
                    {strings.edit}
                </button>
            </ModalBody>
        </Modal>

    );
})

export default EditProfileModal;

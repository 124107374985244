import React, {useContext, useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";
import {observer} from "mobx-react";

const NotificationModal = observer((props) => {
    const context = useContext(Page_settings);
    const [notification, setNotification] = useState(false);
    const [info, setInfo] = useState(false);
    const {show, toggle} = props;
    const [first, setFirst] = useState(true);

    const onBack = () => {
        context.handleNotificationModal(false);
        context.handleSettingModal(true);
    }

    useEffect(() => {
        if (first) {
            if (rootStore.getProfile != null) {
                setInfo(rootStore.getProfile.alarm_market == "ON");
                setNotification(rootStore.getProfile.alarm_web == "ON");
                setFirst(false);
            }
        }
    })

    const updateSetting = (web, market) => {
        context.post(
            'member/updateSetting',
            {
                alarm_web: web ? "ON" : "OFF",
                alarm_market: market ? "ON" : "OFF"
            },
            response => {
                setNotification(web);
                setInfo(market)
                rootStore.setNotification(web, market);
            }
        );
    }

    useEffect(() => {

    }, [notification, info])

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className="p-30">
                <div className='d-flex modal-title-custom'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back_black.png"} alt="" className="modal-back"/>
                    </button>
                    <div className='m-l-10 f-s-20 f-w-600'>{strings.notification}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <button className='d-flex m-t-30 align-item-center  common-button width-100-percent'
                        onClick={() => updateSetting(!notification, info)}>
                    <div className='f-s-16 f-w-400'>{strings.notification_from_web}</div>
                    <div className='flex-grow-1'/>
                    <img src={notification ? "/assets/image/btn_slider_on.png" : "/assets/image/btn_slider_off.png"}
                         alt="" className="modal-slider-button"/>
                </button>
                <button className='d-flex m-t-20 align-item-center flex-grow-1 common-button width-100-percent m-b-300'
                        onClick={() => updateSetting(notification, !info)}>
                    <div className='f-s-16 f-w-400'>{strings.receive_marketing_info}</div>
                    <div className='flex-grow-1'/>
                    <img src={info ? "/assets/image/btn_slider_on.png" : "/assets/image/btn_slider_off.png"} alt=""
                         className="modal-slider-button"/>
                </button>
            </ModalBody>
        </Modal>
    );
})

export default NotificationModal;

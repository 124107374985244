import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import RankItem from "./rank_item";
import strings from "../../../lang/strings";
import { Page_settings } from "../../../config/page_settings";
import { getDecimal, getTimeFromSecond } from "../../../helper/common";
import moment from "moment";
import { withRouter } from "react-router-dom";

const DropdownRank = (props) => {
    const context = useContext(Page_settings);
    const [allTop, setAllTop] = useState("");
    const [growthTop, setGrowthTop] = useState("");
    const [effTop, setEffTop] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    useEffect(() => {
        getTopInfo("MONTH", "ALL", [0,1,2]);
        getTopInfo("MONTH", "GROWTH", 0);
        getTopInfo("MONTH", "STUDY", 0);
    }, [])

    const toggle = () => {
        // setDropdownOpen(!dropdownOpen)
        const path = window.location.pathname;
        props.history.push("/ranking")
    }
    const getTopInfo = (mode, type, isMe) => {
        context.get(
            "ranking/getTopInfo",
            {
                mode: mode,
                type: type,
                isMe: [isMe,isMe,isMe]
            },
            response => {
                console.log(response);
                if (type == "ALL") {
                    setAllTop(response.top_info) 
                } else if (type == "GROWTH") {
                    setGrowthTop(response.top_info)
                } else if (type == "STUDY") {
                    setEffTop(response.top_info)
                }
            }
        );
    }

    return (
        <div className="d-flex align-item-center">
            {
                allTop != "" && growthTop != "" && effTop != "" &&
                <Dropdown isOpen={dropdownOpen} toggle={() => toggle()} className="dropdown navbar-user"
                    tag="li">
                    <DropdownToggle tag="a" className="rank-dropdown rank-div">
                        {/* {props.type == 1 && */}
                        <div className="d-flex align-item-center rank-dropdown-item1" style={{ margin: '8px 0' }}>
                            {/* <div className="today_rank">
                                <img className='medal' src={'/assets/image/icon_medal_red.png'} alt="" />
                                <div>{strings.today_ranker}</div>
                            </div> */}
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={allTop.user.profile != null && allTop.user.profile != "" ? context.loadImage(allTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{allTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(allTop.real_time))}
                            </div>
                            {/* <div className="header-month-time">
                                <div className="header-month">{strings.month}</div>
                                <div
                                    className="header-time time-red">{getTimeFromSecond(parseInt(allTop.real_time))}</div>
                            </div> */}
                            {/* <div className="header-vertical-divider" />
                            <div className="header-month-time">
                                <div className="header-month">{strings.today}</div>
                                <div className="today-time">{getTimeFromSecond(parseInt(allTop.today_time))}</div>
                            </div> */}
                            {/* <img className="dropdown-arrow" src={"/assets/image/icon_dropdown_arrow.png"} alt=""/> */}
                        </div>
                        <div className="d-flex align-item-center rank-dropdown-item1" style={{ margin: '17px 0' }}>
                            {/* <div className="today_rank">
                                <img className='medal' src={'/assets/image/icon_medal_red.png'} alt="" />
                                <div>{strings.today_ranker}</div>
                            </div> */}
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={growthTop.user.profile != null && growthTop.user.profile != "" ? context.loadImage(growthTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{allTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(allTop.real_time))}
                            </div>
                            {/* <div className="header-month-time">
                                <div className="header-month">{strings.month}</div>
                                <div
                                    className="header-time time-red">{getTimeFromSecond(parseInt(growthTop.real_time))}</div>
                            </div> */}
                            {/* <div className="header-vertical-divider" />
                            <div className="header-month-time">
                                <div className="header-month">{strings.today}</div>
                                <div className="today-time">{getTimeFromSecond(parseInt(growthTop.today_time))}</div>
                            </div> */}
                            {/* <img className="dropdown-arrow" src={"/assets/image/icon_dropdown_arrow.png"} alt=""/> */}
                        </div>
                        <div className="d-flex align-item-center rank-dropdown-item1" style={{ margin: '9px 0' }}>
                            {/* <div className="today_rank">
                                <img className='medal' src={'/assets/image/icon_medal_red.png'} alt="" />
                                <div>{strings.today_ranker}</div>
                            </div> */}
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={effTop.user.profile != null && effTop.user.profile != "" ? context.loadImage(effTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{allTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(allTop.real_time))}
                            </div>
                            {/* <div className="header-month-time">
                                <div className="header-month">{strings.month}</div>
                                <div
                                    className="header-time time-red">{getTimeFromSecond(parseInt(effTop.real_time))}</div>
                            </div> */}
                            {/* <div className="header-vertical-divider" />
                            <div className="header-month-time">
                                <div className="header-month">{strings.today}</div>
                                <div className="today-time">{getTimeFromSecond(parseInt(effTop.today_time))}</div>
                            </div> */}
                            {/* <img className="dropdown-arrow" src={"/assets/image/icon_dropdown_arrow.png"} alt=""/> */}
                        </div>

                        {/* 반응형 디자인 */}
                        <div className="d-flex align-item-center rank-dropdown-item2">
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={allTop.user.profile != null && allTop.user.profile != "" ? context.loadImage(allTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{allTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(allTop.real_time))}
                            </div>
                            {/* <span className="ranking-header-address">{allTop.user.school}</span> */}
                        </div>
                        <div className="d-flex align-item-center rank-dropdown-item2" style={{ margin:13 }}>
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={growthTop.user.profile != null && growthTop.user.profile != "" ? context.loadImage(growthTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{growthTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(growthTop.real_time))}
                            </div>
                            {/* <span className="ranking-header-address">{growthTop.user.school}</span> */}
                        </div>
                        <div className="d-flex align-item-center rank-dropdown-item2">
                            <div className="rank">1</div>
                            {/* <img
                                style={{ objectFit: "cover" }}
                                src={effTop.user.profile != null && effTop.user.profile != "" ? context.loadImage(effTop.user.profile) : "/assets/image/logo_big.png"}
                                alt="" /> */}
                            <span className="ranker-name">{effTop.user.name}</span>
                            <div className='header-month-time ranker-name'>
                                {getTimeFromSecond(parseInt(effTop.real_time))}
                            </div>
                            {/* <span className="ranking-header-address">{effTop.user.school}</span> */}
                        </div>
                        {/* }
                        {props.type == 2 &&
                        <div className="d-flex align-item-center">
                            <img
                                src={allTop.user.profile != null && allTop.user.profile != "" ? context.loadImage(allTop.user.profile) : "/assets/image/logo_big.png"}
                                alt=""/>
                            <div className="ranking-header m-r-15 m-l-15 f-w-600">1</div>
                            <div className="f-s-17 f-w-600">{allTop.user.name}</div>
                            <div className="ranking-header-address">{allTop.user.school}</div>
                            <div className="header-month-time">
                                <div className="header-month">{strings.net_time}</div>
                                <div
                                    className="header-time time-red">{getTimeFromSecond(parseInt(allTop.real_time))}</div>
                            </div>
                            <div className="header-vertical-divider"/>
                            <div className="header-month-time">
                                <div className="header-month">{strings.growth_rate}</div>
                                <div
                                    className="header-time time-red">{getDecimal(allTop.growth_rate.toString()) + "%"}</div>
                            </div>
                            <div className="header-vertical-divider"/>
                            <div className="header-month-time">
                                <div className="header-month">{strings.learning_efficiency}</div>
                                <div
                                    className="header-time time-red">{getDecimal(allTop.learning_efficiency.toString()) + "%"}</div>
                            </div> */}
                        {/* <button className="ranking-header-btn">
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt=""/>
                            </button> */}

                        {/* </div>
                        } */}

                    </DropdownToggle>
                    <DropdownMenu className="media-list dropdown-menu-right rank-dropdown-menu" tag="ul">
                        <DropdownItem className="dropdown-header rank-dropdown-header" tag="li" header>
                            <div>{strings.best_rank}</div>
                            <div
                                className="sub-title">{moment(new Date).year().toString().substring(2, 4) + strings._y + " " + (moment(new Date).month() + 1).toString() + strings._m}</div>
                        </DropdownItem>
                        <DropdownItem className="rank-dropdown-item">
                            <RankItem
                                theme={'red'}
                                title={strings.this_month + " " + strings.no_1}
                                item={allTop}
                            />
                        </DropdownItem>
                        <DropdownItem className="rank-dropdown-item">
                            <RankItem
                                theme={'green'}
                                title={strings.grow_rate + " " + strings.no_1}
                                item={growthTop}
                            />
                        </DropdownItem>
                        <DropdownItem className="rank-dropdown-item">
                            <RankItem
                                theme={'blue'}
                                title={strings.mission + " " + strings.no_1}
                                item={effTop}
                            />
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => {
                                props.history.push("/ranking")
                            }}
                            className="dropdown-footer text-center display-flex-important align-item-center justify-content-center">
                            <div className={"load-more-button display-flex-important align-item-center"}>
                                <div className="f-s-16">{strings.load_more}</div>
                                <div
                                    className="round-plus display-flex-important align-item-center justify-content-center">
                                    <img src={"../assets/image/icon_plus_white.png"} alt="" />
                                </div>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            }
        </div>
    );
}

export default withRouter(DropdownRank);

import React, {useContext, useEffect} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from "moment";
import {Page_settings} from "../../../config/page_settings";

const QuestionModal = (props) => {
    const context = useContext(Page_settings);
    const {show, close, toggle, onAsk, onBack, item} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='item-search-modal question-list-modal question-detail-modal'>
            <ModalBody style={{backgroundColor: '#232228', borderRadius: 20, padding: 0}}>
                <div className='d-flex header align-item-center m-b-20'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back.png"} alt="" className="modal-close"/>
                    </button>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" style={{width: 25, height: 25}}/>
                    </button>
                </div>
                <div className="d-flex align-item-center">
                    <div className='f-s-16 flex-grow-1' style={{color: "white"}}>{strings.question_list}</div>
                    <button className="search-box" style={{width: "110px", background: "#0f84f4", justifyContent: "center"}}
                            onClick={() => onAsk()}>
                        {strings.ask_new_question}
                    </button>
                </div>
                <PerfectScrollbar className="question-detail" options={{suppressScrollX: true}}>
                    <div className="d-flex question-item">
                        <div className="flex-grow-1">
                            <div className="text-center" style={{width: "fit-content"}}>
                                <div className="title-detail">{item.content}</div>
                                <div className="time">{moment(item.create_date).format("HH:mm")}</div>
                            </div>
                        </div>
                        <div className={item.answer != null ? "read-button" : "read-button-read"} style={{marginRight:-10}}>
                            {item.answer != null ? strings.read : strings.not_read}
                        </div>
                    </div>
                    {
                        item.file != null && item.file != '' &&
                        item.file.split("#").map((photo, idx)=> {
                            return <img src={context.loadImage(photo)} key={idx}
                                        style={{width: 273, height: 200, borderRadius: 5, marginLeft: 10, marginBottom:10, objectFit: "cover"}} alt=""/>
                        })
                    }
                    {
                        item.file == null &&
                        <div style={{height: 120}}/>
                    }
                    <div className="mentor-answer">
                        <div className="mentor">{strings.mentor_chew}</div>
                        {
                            item.answer != null &&
                            <div className="answer m-b-10">{item.answer.content}</div>
                        }
                        {
                            item.answer != null && item.answer.file != null && item.answer.file != '' &&
                            item.answer.file.split("#").map((photo, idx)=> {
                                return <img src={context.loadImage(photo)} key={idx}
                                            style={{width: 260, height: 200, borderRadius: 5, marginLeft: 10, marginBottom:10, objectFit: "cover"}} alt=""/>
                            })
                        }
                        <img className="bubble" src={"/assets/image/icon_3_color_bubble.png"} alt=""/>
                    </div>

                </PerfectScrollbar>

            </ModalBody>
        </Modal>

    );
}

export default QuestionModal;

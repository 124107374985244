import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";

const TermModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle} = props;

    const onBack = () => {
        context.handleTermModal(false);
        context.handleSettingModal(true);
    }

    const onDetail = () => {
        context.handleTermModal(false);
        context.handleTermDetail(true);
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className="p-30">
                <div className='d-flex modal-title-custom'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back_black.png"} alt="" className="modal-back"/>
                    </button>
                    <div className='m-l-10 f-s-20 f-w-600'>{strings.terms}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <button
                    className='d-flex m-b-40 align-item-center common-button width-100-percent'
                    onClick={() => {
                        onDetail();
                        context.setTermType(1)
                    }}>
                    <div className='f-s-16 f-w-400'>{strings.term_service}</div>
                    <div className='flex-grow-1'/>
                    <img className="modal-next" src={"/assets/image/icon_arrow_right_black.png"} alt=""/>
                </button>
                <button
                    className='d-flex m-t-20 align-item-center common-button width-100-percent m-b-300'
                    onClick={() => {
                        onDetail();
                        context.setTermType(2)
                    }}>
                    <div className='f-s-16 f-w-400'>{strings.collect_info}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
            </ModalBody>
        </Modal>

    );
}

export default TermModal;

import React, { useRef, useState } from "react";
import TagMoreButton from "../button/tag_more_button";
import { checkIconList, colorList } from "../../../config/const";
import { isMobile } from "react-device-detect";

const TodoPlanItem = (props) => {
    const { item, autoFocus, onCheck, checked, colorIndex, content, onDelete, onEdit, onEditDone, onChange } = props;

    const [hover, setHover] = useState(false);
    const [editable, setEditable] = useState(autoFocus);
    const [focus, setFocus] = useState(autoFocus);

    const inputRef = useRef(null);

    return (
        <div className="mission-item" style={{ cursor: 'pointer' }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <img className="todo-icon" onClick={() => onCheck()}
                src={
                    checked
                        ? checkIconList[colorIndex]
                        : "/assets/image/icon_todo_check_normal_gray.png"}
                alt="" />
            <input ref={inputRef} className="input-transparent plan-input" disabled={!editable} value={editable ? content : item.title} 
            style={{ borderBottom: "1px solid " + (focus ? colorList[colorIndex] : '#ffffff') }}
            autoFocus={autoFocus}
            onChange={(e) => onChange(e.target.value)} 
            onBlur={() => {
                setFocus(false);
                setEditable(false);
                onEditDone();
            }}
            onFocus={() => {
                setFocus(true);
            }}
            />
            {
                (isMobile || hover) &&
                <TagMoreButton
                    onEdit={() => {
                        onEdit();
                        setEditable(true);
                        inputRef.current.focus();
                    }}
                    onDelete={() => onDelete()}
                />
            }
        </div>
    );
}

export default TodoPlanItem;

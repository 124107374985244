import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import StudentAddModal from './studentAddModal';
import CustomSearch from '../../components/control/custom_input/custom_search';
import strings from '../../lang/strings';
import CloudContainer from '../cabinet/cloud_container';
import { Page_settings } from '../../config/page_settings';
import DatalabModal from './Modals/datalabModal';
import Chewing_talk from '../../components/control/modal/chewing_talk';

const ClassInfo = observer((props) => {

  const [openInviteCode, setOpenInviteCode] = useState(false);
  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [openDataLab, setOpenDataLab] = useState(false);
  const [dataLabID, setDataLabID] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(true);
  const [keyword, setKeyword] = useState('');
  const context = useContext(Page_settings);

  

  // 여기는 이제 서버에서 불러오는 데이터가 될것임!

  const [studentInfo, setStudentInfo] = useState([]);

  const classId = props.match.params.id;

  useEffect(() => {
    loadStudentInfo();
}, []);

  const loadStudentInfo = () => {
    context.get(
        'member/getMemberList',
        {},
        response => {
          console.log(response.list)
          setStudentInfo(response.list)
        }
    );
}

const onclickDataLab = (id) => {
  setOpenDataLab(true);
  setDataLabID(id);
}

  const onClickInviteCode = () => {
    setOpenInviteCode(true);
  };

  const onClickAddStudent = () => {
    setOpenAddStudent(true);
  };

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const deleteStudentInfo = (idx) => {
    // 디비에 접근해서 클릭한 학생정보 데이터 삭제하기
    console.log(idx);
  }

  //useEffect 사용해서 id가 classId인 값인 데이터를 가져와서 화면에 랜더링해줘야댐 

  return (
    <AppWrap>
      <CloudContainer />
      <div class="class-head">
        <div class="class-head-left"> 
        {
        <CustomSearch
          placeholder={strings.classInfo_search_placeholder}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
          }}
        />

      }
        
          <img src="/assets/image/btn_audio_on.png"></img>
          <div class="class-head-left-child">
            <div class="class-head-left-title">서울고등학교 1학년 3반</div>
            <div class="class-head-left-subtitle">2023. 학생 19명</div>
          </div>
        </div>
        <div class="class-head-buttonContainer">
          <button class="class-head-inviteCode" onClick={onClickInviteCode}>초대코드 복사</button>
          <button class="class-head-addStudent" onClick={onClickAddStudent}>+ 학생 추가</button>
        </div>
      </div>
      <div class="class-studentInfo">
        학생 정보
      </div>

      {openInviteCode && (<Chewing_talk
        open={openInviteCode}
        onClose={() => {
          setOpenInviteCode(false);
        }}
      />)}

      {openAddStudent && (<StudentAddModal
        open={openAddStudent}
        onClose={() => {
          setOpenAddStudent(false);
        }}
      />)}

      {openDataLab && (<DatalabModal
        open={openDataLab}
        onClose={() => {
          setOpenDataLab(false);
        }}
        dataLabID={dataLabID}
      />)}

      { studentInfo ? (
        <div class="class-list-container">
          {studentInfo.map((item,idx)=>{
            const dystropy = item.misDystropy || item.dataDystropy ? 1 : null;
            return (
              <div
                key={idx}
                onMouseEnter={() => handleMouseEnter(idx)}
                onMouseLeave={handleMouseLeave}
                onClick={()=>onclickDataLab(item.id)}
                class={`classInfo-list-content ${dystropy ? 'classInfo-list-warning-content' : ''}`}>
                <div class="classInfo-list-content-head">
                  <div>
                    <img className="user-img"
                      src={item.profile != null && item.profile != "" ? context.loadImage(item.profile) : "/assets/image/logo_big.png"}
                    />
                    </div>
                    <div class="classInfo-list-studentInfo">
                      <div class="classInfo-list-name">{item.name}</div>
                      <div class="classInfo-list-school">{item.school} · {item.classInfo}</div>
                  </div>
                </div>
                {isHovered === idx ?
                    <div className="classInfo-dropdown-container" onClick={(e) => e.stopPropagation()} >
                      <img
                        src="/assets/image/icon_classinfo_more.png"
                        onClick={toggleDropdown}
                      />
                      {isDropdownVisible && (
                        <div className="classInfo-dropdown-menu">
                          <ul>
                            <li onClick={()=>deleteStudentInfo(idx)}>삭제</li>
                            {/* 다른 메뉴 아이템을 추가할 수 있습니다. */}
                          </ul>
                        </div>
                      )}
                    </div>
                 :null}
                {dystropy ? (
                  <div class="classInfo-dystropy">
                    <div class="classInfo-dystropy-flex">
                      미션 이상행동 <span class="classInfo-warningFont">{item.misDystropy}건</span>
                      
                      <img src={"/assets/image/icon_classinfo_warning_point.png"}/>
                      
                    </div>
                    <div class="classInfo-dystropy-flex">
                      데이터 이상행동 <span class="classInfo-warningFont">{item.dataDystropy}건</span>
                      <img src={"/assets/image/icon_classinfo_warning_point.png"}/>
                    </div>
                 </div>
                ) : (
                  <div class="classInfo-noworryFont">
                  이상 없음
                  </div> 
                )}
              
            </div>
            )
          })}
        </div>
      ) :
      (
      <div class="class-nothing">
        <img class="class-nothing-img" src="/assets/image/class_nothing.png"/>
      </div>
      )}
      
    </AppWrap>
  );
})

const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #0F84F4;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 10px auto;
  position: relative;
  width: 100%;
  height: 100vh; /* 화면 높이의 100%로 설정하여 화면 전체를 채우도록 만듭니다. */
`;

export default withRouter(ClassInfo);

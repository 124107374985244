import $ from "jquery";
import React, { useEffect, useMemo, useContext } from "react";
import { getTimeFromSecond } from "../../../helper/common";
import {Page_settings} from "../../../config/page_settings";

const RoomItem = (props) => {
    const context = useContext(Page_settings);
    const { item, selected, videoTrack, audioTrack, muteVideo, muteAudio, reverseCam } = props;

    useMemo(() => {
        console.log('useMemo videoTrack', muteVideo, videoTrack);
        if (videoTrack != undefined && videoTrack != null) {
            if (muteVideo == true) {
                videoTrack.stop()
            } else if (muteVideo == false) {
                const currentVideoComponentId = `#stream-player-${item.user.id}`;
                $(currentVideoComponentId).children().eq(1).remove();
                videoTrack.play(`stream-player-${item.user.id}`)
            }
        }
    }, [muteVideo, videoTrack])

    useMemo(() => {
        console.log('useMemo audioTrack', muteAudio, audioTrack);
        if (audioTrack != undefined && audioTrack != null) {
            if (muteAudio == true) {
                audioTrack.stop()
            } else if (muteAudio == false) {
                audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])

    useEffect(() => {
        console.log('useEffect videoTrack', muteVideo, videoTrack);
        if (videoTrack != undefined && videoTrack != null) {
            if (muteVideo == true) {
                videoTrack.stop()
            } else if (muteVideo == false) {
                const currentVideoComponentId = `#stream-player-${item.user.id}`;
                $(currentVideoComponentId).children().eq(1).remove();
                videoTrack.play(`stream-player-${item.user.id}`)
            }
        }
    }, [muteVideo, videoTrack])

    useEffect(() => {
        console.log('useEffect audioTrack', muteAudio, audioTrack);
        if (audioTrack != undefined && audioTrack != null) {
            if (muteAudio == true) {
                audioTrack.stop()
            } else if (muteAudio == false) {
                audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])

    useEffect(() => {
        console.log('useEffect reverseCam', reverseCam);
    }, [reverseCam])

    const getStudyTime = (study_time) => {
        return getTimeFromSecond(parseInt(study_time))
    }

    return (
        <div className={("study-room-item ") + (selected ? "study-room-selected" : "") + (reverseCam ? "camera-reverse" : "")}
            style={{ display: "flex", position: "relative" }}
            id={`stream-player-${item.user.id}`}>
            {
                (muteVideo || videoTrack == undefined || videoTrack == null) &&
                <div
                    className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                    <img src={item.user.profile != null && item.user.profile != "" ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"}
                        style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                        alt=""
                    />
                </div>
            }
            {
                !selected &&
                <div className="status">
                    <div className="user text-ellipsis">{item.user.name}</div>
                    {
                        item.status == 2 &&
                        <div>{getStudyTime(item.real_time)}</div>
                    }
                </div>
            }
            {
                item.reaction != undefined && item.reaction != null && item.reaction != "" &&
                <div className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0, background: '#000000D4' }}>
                    <img src={item.reaction.icon} alt="" />
                </div>
            }
        </div>
    );
}

export default RoomItem;

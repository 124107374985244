import React, {useContext, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import strings from "../../../lang/strings";
import {Page_settings} from "../../../config/page_settings";

const RecentVideoItem = (props) => {
    const context = useContext(Page_settings);
    const {item, onClick, onDelete} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <div className="video-item">
            <button className="video-button" onClick={() => onClick()}>
                <img className="video" src={context.loadImage(item.url.split(".")[0] + ".jpg")} alt=""/>
            </button>
            <div className="width-100-percent">
                <div className="d-flex m-b-5">
                    <div className="recent-title text-ellipsis">{item.title}</div>
                    <Dropdown isOpen={dropdownOpen} toggle={() => toggle()}
                              className="icon-more more">
                        <DropdownToggle tag="a" >
                            <img src={"/assets/image/icon_more_gray.png"} alt=""/>
                        </DropdownToggle>
                        <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                            <DropdownItem onClick={()=>onDelete()}>
                                {strings.delete}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="producer">{(item.subject != null ? item.subject: "") + " | " + (item.speaker != null ? item.speaker : "")}</div>
                <div className="producer">{item.company}</div>
                <div className="code-video">{item.code}</div>
            </div>
        </div>
    );
}

export default RecentVideoItem;

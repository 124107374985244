import React, { useContext, useState, useRef, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Page_settings } from "../../../config/page_settings";
import strings from '../../../lang/strings';
import { getTimeFromSecond } from "../../../helper/common";
import SweetAlert from "react-bootstrap-sweetalert";

const CoachingTestModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, item, onSubmit } = props;

    const [leaveAlert, setLeaveAlert] = useState(false);
    const [testTime, setTestTime] = useState(10);
    const timer = useRef(null);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setTestTime(testTime + 1);
    //     }, 1000)
    // }, [testTime])

    const startTimer = () => {
        //     timer.current = setInterval(() => {
        //         setTestTime(prevTime => prevTime + 1);
        //     }, 1000)
    }

    const stopTimer = () => {
        //     clearInterval(timer.current);
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="cloud-view-modal coaching-test-modal" onOpened={() => startTimer()}>
            <ModalBody>
                <div className="d-flex align-items-center p-l-30 p-r-20 p-t-20 p-b-20">
                    <div className="f-s-22 f-w-700">{strings.test}</div>
                    <div className='d-flex align-items-center m-l-auto m-r-auto'>
                        <img src={"/assets/image/icon_alarm_black.png"} alt="" />
                        <div className="test-time">{strings.test_time}</div>
                        <div className="time">{getTimeFromSecond(testTime)}</div>
                    </div>
                    <button className='modal-close-btn' onClick={() => { stopTimer(); toggle() }}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <DocViewer className="cloud-view-main" documents={[{ uri: context.loadImage('uploads/aitest/' + item + '.pdf') }]} pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false
                        },
                        pdfVerticalScrollByDefault: true
                    }} />
                <div className="p-l-35 p-r-25 p-t-15 p-b-15 width-100-percent d-flex align-items-center">
                    <div className='caution'>{strings.submit_caution}</div>
                    <button className="submit-button m-l-auto" onClick={() => setLeaveAlert(true)}>
                        {strings.submit}
                    </button>
                </div>
            </ModalBody>
            {(leaveAlert &&
                <SweetAlert showCancel
                    confirmBtnText={strings.submit}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={""}
                    customClass="alert-white2"
                    onConfirm={() => { setLeaveAlert(false); onSubmit(testTime) }}
                    onCancel={() => setLeaveAlert(false)}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel">
                    {strings.submit_desc}
                </SweetAlert>
            )}
        </Modal>
    );
}

export default React.memo(CoachingTestModal);

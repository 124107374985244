import React from "react";

const MissionItem = (props) => {
    const {theme, detail, item, onClick} = props;
    return (
        <button className="mission-item common-button" onClick={() => onClick()}>
            <img
                src={item.status == "Y" ? ("/assets/image/icon_mission_check_" + theme + "_checked.png") : ("/assets/image/icon_mission_check_" + theme + ".png")}
                alt=""/>
            <div className="mission-detail">{detail}</div>
        </button>
    );
}

export default MissionItem;

import strings from "../../lang/strings";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import ExpertChatListItem from "../../components/control/item/expert_chat_list_item";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpertChatItem from "../../components/control/item/expert_chat_item";
import moment from "moment";
import StarRatingCustom from "../../components/control/item/star_rating";
import {formatNumber, getDecimal} from "../../helper/common";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import { fetchToken, onMessageListener } from './../../helper/firebase';

const ConsultHistory = (props) => {
    const context = useContext(Page_settings);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userId, setUserId] = useState(-1);
    const [userType, setUserType] = useState("");
    const [keyword, setKeyword] = useState("");
    const [sortCode, setSortCode] = useState(0);
    const [clinicList, setClinicList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [partnerId, setPartnerId] = useState(0);
    const [partner, setPartner] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [clinicId, setClinicId] = useState(parseInt(props.match.params.clinic_id));
    const [expertInfo, setExpertInfo] = useState("");
    const [isTokenFound, setTokenFound] = useState(false);
    const [fcmToken, setFcmToken] = useState('');
    const [fileList, setFileList] = useState([])
    const imageRef = useRef();
    const [urlList, setUrlList] = useState([]);

    useEffect(() => {
        setUserType(rootStore.getProfile?.user_type ?? "STUDENT");
        setUserId(rootStore.getProfile?.id ?? 0);
    }, [rootStore.getProfile]);

    useEffect(() => {
        context.setContentBgType(2);

        const userType = rootStore.getProfile?.user_type;
        const navIndex = userType === 'STUDENT' ? 1 : 2;

        document.querySelectorAll('#sidebar .nav>li')[navIndex].classList.add('active');
        document.querySelectorAll('#sidebar .nav>li')[navIndex].getElementsByTagName('img')[0].src = '/assets/image/icon_menu_chewingchat_active.png';

        loadClinicList("", "abc", 1);

        return () => {
            context.setContentBgType(1);
            document.querySelectorAll('#sidebar .nav>li')[navIndex].classList.remove('active');
            document.querySelectorAll('#sidebar .nav>li')[navIndex].getElementsByTagName('img')[0].src = '/assets/image/icon_menu_chewingchat_inactive.png';
        }
    }, []);

    useEffect(() => {
        if (clinicId != -1) {
            getChatList(false);
            if (clinicList.length != 0 && userType == "STUDENT") {
                getExpertDetail(clinicList[partnerId].user.id);
            }
        }
    }, [clinicId]);

    fetchToken(setTokenFound, setFcmToken);

    useEffect(() => {
        if (fcmToken != '') {
            updateFcmToken();
        }
    }, [fcmToken]);

    onMessageListener().then(payload => {
        console.log('onMessageListener payload = ', payload);
        const chatItem = JSON.parse(payload.data.custom_data);
        if (!chatItem) {
            return;
        }

        // 현재 상담중인 전문가인 경우 채팅목록에 내용 추가
        if (clinicId == chatItem.clinic) {
            setChatList([chatItem, ...chatList]);

            // 전문가 목록 최근 메시지 내용 업데이트
            setClinicList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        } else { // 현재 상담중이 아닌 전문가인 경우 전문가목록 내용만 업데이트(최근 문자, 시간, 읽지 않은 채팅수)
            setClinicList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.unread_cnt = item.unread_cnt + 1;
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        }

    }).catch(err => console.log('failed: ', err));

    const loadClinicList = (keyword, order, page_num) => {
        context.get(
            "clinic/getClinicList",
            {
                keyword: keyword,
                order: order,
                page_num: page_num
            },
            response => {
                setClinicList(response.list);
                if (response.list.length != 0 && clinicId == 0) {
                    setClinicId(response.list[0].id);
                }
            }
        );
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const onSearch = () => {
        loadClinicList(keyword, userType == "STUDENT" ? strings.expert_list[sortCode].code : strings.clinic_list[sortCode].code, 1);
    };

    const getChatList = (setHistory) => {
        context.get(
            "clinic/getChatList",
            {
                clinic: clinicId,
                page_num: 1
            },
            response => {
                setChatList(response.list)
                setPartner(response.member_info)
                if (setHistory) {
                    setClinicList(prev => prev.map((item) => {
                        if (item.id === clinicId) {
                            item.content = response.list[0].content
                            item.chat_date = response.list[0].create_date
                        }
                        return item
                    }))
                }

            }
        );
    }

    const getExpertDetail = (expert_id) => {
        context.get(
            "clinic/getExpertDetail",
            {
                expert_id: expert_id
            },
            response => {
                setExpertInfo(response.expert_info);
            }
        );
    }

    const onSend = () => {
        let new_chat = chat.replace(/ /g, "")
        if (((chat != "" && new_chat != '') || fileList.length != 0) && clinicId != -1) {
            if (urlList.length != 0) {
                let formData = new FormData();
                urlList.forEach((entry, index) => {
                    formData.append('file', entry);
                });
                context.post(
                    'upload/images/room',
                    formData,
                    response => {
                        context.post(
                            "clinic/sendChat",
                            {
                                clinic: clinicId,
                                content: chat,
                                file: response.image
                            },
                            response => {
                                setChat("");
                                setUrlList([]);
                                setFileList([]);
                                getChatList(true);
                            }
                        );
                    });
            } else {
                context.post(
                    "clinic/sendChat",
                    {
                        clinic: clinicId,
                        content: chat,
                        file: ""
                    },
                    response => {
                        setChat("");
                        getChatList(true);
                    }
                );
            }
        }
    };

    const onLike = () => {
        context.post(
            "clinic/like",
            {
                expert_id: expertInfo.id,
                like: expertInfo.is_like == 0 ? 1 : -1
            },
            response => {
                getExpertDetail(expertInfo.id)
            }
        );
    }

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const handleEnterChat = e => {
        if (e.keyCode == 13) {
            onSend()
        }
    };

    const onClickClinic = (index) => {
        setPartnerId(index);
        setClinicId(clinicList[index].id);
        setClinicList(prev => prev.map((item, idx) => {
            if (index == idx) {
                item.unread_cnt = 0
            }
            return item
        }))
    }

    const onImageChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            setFileList([...fileList, reader.result]);
            setUrlList([...urlList, file]);
        };
        reader.readAsDataURL(file);
    };

    const delFile = (idx) => {
        setFileList(preList => preList.filter((item, index) => {
            return idx != index
        }));
        setUrlList(preList => preList.filter((item, index) => {
            return idx != index
        }));
    }

    const onDownload = (item) => {
        context.download(
            "GET",
            "clinic/download",
            {
                id: item.id
            },
            response => {
            }
        );
    }

    const updateFcmToken = () => {
        context.post(
            "member/updateFcmToken",
            {
                fcm_token: fcmToken,
            },
            response => {

            }
        );
    }

    return (
        <div className="main-content">
            <button
                className={"consultant-tab"}
                onClick={() => props.history.push("/clinic")}>
                {userType == "STUDENT" ? strings.consultant_expert : strings.reservation_history}
            </button>
            <button
                className={"consultant-tab consultant-selected"}
                onClick={() => window.location.reload()}>
                {strings.consultation_history}
            </button>
            <div className="consult-history">
                <div className="row height-full">
                    <div className={("expert-list ") + (userType == "EXPERT" || clinicId == -1 ? "col-sm-4" : "col-sm-3")}>
                        <div className="d-flex align-item-center p-10">
                            <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                                className="dropdown navbar-user rank-dropdown" tag="li" style={{ width: 120 }}>
                                <DropdownToggle tag="a"
                                    className="display-flex-important align-item-center profile-dropdown"
                                    style={{ background: "white", margin: 0 }}>
                                    <div className="flex-grow-1"
                                        style={{ color: "#3f3f3f" }}>{userType == "STUDENT" ? strings.clinic_list_student[sortCode].name : strings.clinic_list[sortCode].name}</div>
                                    <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                                    tag="ul">
                                    {
                                        userType == "STUDENT" &&
                                        strings.clinic_list_student.map((item, idx) =>
                                            <DropdownItem key={idx}
                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                onClick={() => {
                                                    setSortCode(idx);
                                                    loadClinicList(keyword, strings.clinic_list_student[idx].code, 1);
                                                }}
                                            >
                                                {item.name}
                                            </DropdownItem>
                                        )
                                    }
                                    {
                                        userType == "EXPERT" &&
                                        strings.clinic_list.map((item, idx) =>
                                            <DropdownItem key={idx}
                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                onClick={() => {
                                                    setSortCode(idx);
                                                    loadClinicList(keyword, strings.clinic_list[idx].code, 1);
                                                }}
                                            >
                                                {item.name}
                                            </DropdownItem>
                                        )
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            <button className="common-button m-l-auto"
                                onClick={() => setShowSearch(!showSearch)}>
                                <img src={"/assets/image/icon_search_black.png"} alt="" />
                            </button>
                        </div>
                        {
                            showSearch &&
                            <div className="position-relative m-b-10 m-r-10 m-l-10">
                                <input
                                    value={keyword}
                                    className="search-expert"
                                    type={"text"} onChange={(e) => setKeyword(e.target.value)}
                                    onKeyDown={handleEnter}
                                />
                                <button className="search-button" onClick={() => onSearch()}>
                                    <img src={"/assets/image/icon_search_black.png"} alt="" />
                                </button>
                            </div>
                        }
                        <PerfectScrollbar style={{ height: " calc(100% - 70px)" }} options={{ suppressScrollX: true }}>
                            {
                                clinicList.length != 0 &&
                                clinicList.map((item, idx) => {
                                    return (
                                        <ExpertChatListItem
                                            selected={item.id == clinicId}
                                            key={idx}
                                            item={item}
                                            onClick={() => {
                                                onClickClinic(idx)
                                            }}
                                            expert={userType == "EXPERT"}
                                        />
                                    )
                                })
                            }
                        </PerfectScrollbar>

                    </div>
                    <div className={("expert-chat-area ") + (userType == "EXPERT" || clinicId == -1 ? "col-sm-8 p-r-10" : "col-sm-7")}>
                        <div className="chat-input-container">
                            <input type='file' accept='image/*' className='hide'
                                   onChange={(e) => onImageChange(e, fileList.length)}
                                   ref={imageRef}/>
                            <input placeholder={userType == "EXPERT" ? strings.student_placeholder:strings.expert_placeholder}
                                type={"text"}
                                value={chat} onChange={(e) => setChat(e.target.value)}
                                onKeyDown={handleEnterChat}
                            />
                            <div>
                                {
                                    fileList.map((item, idx) => {
                                        return(
                                            <div className="position-relative m-r-15" style={{width:75}} key={idx}>
                                                <img style={{width: 75, height: 75, borderRadius: 5}} src={item} alt=""/>
                                                <button
                                                    style={{
                                                        border: "none",
                                                        background: "none",
                                                        position: "absolute",
                                                        top: -10,
                                                        right: -20
                                                    }}
                                                    onClick={() => {
                                                        delFile(idx)
                                                    }}
                                                >
                                                    <img src={"/assets/image/btn_delete.png"} alt=""/>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="d-flex m-t-10">
                                <button className="emoticon" onClick={() => {
                                    if (fileList.length == 0) {
                                        imageRef?.current.click()
                                    }
                                }}>
                                    <img src={"/assets/image/icon_link.png"} style={{width: 35, height: 35}} alt=""/>
                                </button>
                                <button
                                    className={("send ") + (((chat != "" && chat.replace(/ /g, "") != "")|| fileList.length !=0) && clinicId != -1 ? "send-active" : "")}
                                    onClick={() => onSend()}>{strings.send}</button>
                            </div>

                        </div>
                        <div className="expert-chat-area p-t-10">
                            {
                                chatList.length != 0 &&
                                chatList.map((item, idx) => {
                                    return (
                                        <ExpertChatItem
                                            mine={userId == item.sender}
                                            key={idx}
                                            item={item}
                                            partner={partner}
                                            expert={userType == "EXPERT"}
                                            onDownload={(item) => onDownload(item)}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    {
                        userType == "STUDENT" && expertInfo != "" &&
                        <PerfectScrollbar className="expert-detail col-sm-2" options={{ suppressScrollX: true }}>
                            <div className="d-flex">
                                <button className="more-button" onClick={() => onLike()}>
                                    <img
                                        src={expertInfo.is_like != 0 ? "/assets/image/icon_bookmark_active.png" : "/assets/image/icon_bookmark_inactive.png"}
                                        alt="" />
                                </button>
                            </div>
                            <div className="d-flex justify-content-center m-b-15">
                                <img style={{ width: 80, height: 80, borderRadius: 40, objectFit: "cover"}}
                                    src={expertInfo.profile != null && expertInfo.profile != '' ? context.loadImage(expertInfo.profile) : "/assets/image/logo_big.png"}
                                    alt="" />
                            </div>
                            <div
                                className="d-flex justify-content-center f-s-16 m-b-20 f-w-800">{expertInfo.name + " " + strings.expert}</div>
                            <div className="tendency f-w-600">{strings.consulting_tendency}</div>
                            <div className="d-flex m-b-20">
                                {
                                    expertInfo.tendency.split("#")[0] != "" &&
                                    expertInfo.tendency.split("#").map((item, idx) => {
                                        return (
                                            <div className="tendency-detail" key={idx}>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                                {
                                    expertInfo.tendency.split("#")[0] == "" &&
                                    <div className="m-b-10">{strings.no_tendency}</div>
                                }
                            </div>
                            <div className="tendency f-w-600">{strings.consult_time}</div>
                            <div className="f-s-14 m-b-20"
                                style={{ color: "#0f84f4" }}>{
                                Math.floor(Number(expertInfo.time) * 10) == 0?
                                    strings.not_available:
                                    expertInfo.time + strings.hours
                                }</div>
                            <div className="tendency f-w-600">{strings.average_response_time}</div>
                            <div
                                className="f-s-14 m-b-20">{
                                Math.floor(Number(expertInfo.response_time) * 10) == 0?
                                    strings.not_known:
                                    Number(expertInfo.response_time) > 24 ?
                                        strings.more_24 :
                                    strings.within + getDecimal(expertInfo.response_time.toString()) + strings.hours
                                }</div>
                            <div className="tendency f-w-600">{strings.clinic_duration}</div>
                            <div
                                className="f-s-14 m-b-20">{moment(expertInfo.clinic_start_date).format("YY.MM.DD") + "~" + moment(expertInfo.clinic_end_date).format("YY.MM.DD") + strings.conversion_value}</div>
                            <div className="tendency f-w-600">{strings.ratings}</div>
                            <div className="d-flex m-b-20 align-item-center">
                                <StarRatingCustom
                                    starCnt={expertInfo.score}
                                    fullStar={"/assets/image/icon_fav_active.png"}
                                    halfStar={"/assets/image/icon_fav_active.png"}
                                    emptyStar={"/assets/image/icon_fav_inactive.png"}
                                    disabled={true}
                                    size={25}
                                    padding={false}
                                />
                                <div
                                    className="f-s-14 m-l-auto">{strings.review + " " + formatNumber(expertInfo.review_count)}</div>
                            </div>
                            <button
                                className="view-button m-b-20"
                                onClick={() => {
                                    props.history.push("/clinic/expert_detail/" + clinicList[partnerId].user.id + "/0");
                                }}>{strings.view_details}</button>
                            <div className="tendency f-w-600">{strings.introduce}</div>
                            <div className="f-s-14 m-b-20"
                                style={{ whiteSpace: "break-space" }}>{expertInfo.intro}</div>
                        </PerfectScrollbar>
                    }
                </div>
            </div>
        </div>
    );
}
export default withRouter(ConsultHistory);

import strings from "../../../lang/strings";
import {useContext, useRef, useState} from "react";
import {Page_settings} from "../../../config/page_settings";
import StarRatingCustom from "./star_rating";
import Carousel from "react-multi-carousel";
import moment from "moment";


const StudentReviewItem = (props) => {
    const context = useContext(Page_settings);
    const {item, age, onClickImage} = props;
    const idCarousel = useRef();
    const [roomSelected, setRoomSelected] = useState(1);

    return (
        <div className="student-item">
            <div className="d-flex align-item-center m-b-20 ">
                <img src={item.user.profile !=null ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"} className="student-profile" alt=""/>
                <div className="width-100-percent">
                    <div className="f-s-20 f-w-600">{item.user.name}</div>
                    <div className="d-flex align-item-center width-100-percent">
                        <div
                            className="student-grade">{age >= 19 ? strings.g3 : (age == 18 ? strings.g2 : strings.g1)}</div>
                        <div className="student-school">{item.user.school}</div>
                        <StarRatingCustom
                            starCnt={item.score}
                            fullStar={"/assets/image/icon_fav_active.png"}
                            halfStar={"/assets/image/icon_fav_active.png"}
                            emptyStar={"/assets/image/icon_fav_inactive.png"}
                            disabled={true}
                            padding={false}
                            size={20}
                        />
                        <div className="f-s-20 f-w-600 m-l-10">{item.score}</div>
                        <div className="m-l-auto student-grade">{moment(item.create_date).format("YY.MM.DD a h:mm")}</div>
                    </div>
                </div>
            </div>
            <div className="review-container">
                {
                    item.file != "" &&
                    <div className="position-relative m-b-25">
                        <Carousel
                            ref={idCarousel}
                            draggable={false}
                            swipeable={false}
                            focusOnSelect={false}
                            centerMode={false}
                            containerClass={"student-review-carousel"}
                            itemClass="react-multi-carousel-item student-review-carousel-item"
                            responsive={{
                                desktop: {
                                    breakpoint: {max: 3000, min: 1024},
                                    items: 7,
                                    slidesToSlide: 1 // optional, default to 1.
                                },
                                tablet: {
                                    breakpoint: {max: 1024, min: 464},
                                    items: 7,
                                    slidesToSlide: 1 // optional, default to 1.
                                },
                                mobile: {
                                    breakpoint: {max: 464, min: 0},
                                    items: 1,
                                    slidesToSlide: 1 // optional, default to 1.
                                }
                            }}
                            arrows={false}
                            showDots={false}
                        >
                            {
                                item.file.split("#").map((item, idx) =>
                                    <button className="common-button p-0 width-100-percent" onClick={() => onClickImage(idx)} key={idx}>
                                        <img
                                            src={context.loadImage(item)}
                                            className="student-review-img"
                                            alt=""/>
                                    </button>
                                )
                            }
                        </Carousel>
                        <button className={("review-carousel-btn-left ") + (roomSelected == 1 ? "display-none" : "")}
                                onClick={() => {
                                    idCarousel?.current?.previous();
                                    setRoomSelected(roomSelected - 1)
                                }}/>
                        <button
                            className={("review-carousel-btn-right ") + ((roomSelected >= 4 || item.file.split("#").length < 8) ? "display-none" : "")}
                            onClick={() => {
                                idCarousel?.current?.next();
                                setRoomSelected(roomSelected + 1)
                            }}/>
                    </div>
                }
                <div>{item.content}</div>
            </div>
        </div>
    );
}

export default StudentReviewItem;

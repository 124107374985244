import React from "react";

export default function StudyBottomButton(props) {
    return (
        <button
            className={"study-bottom-button"}
            disabled={props.disable}
            onClick={() => props.onClick()}>
            <div>
                <div>
                    <img src={props.image} alt=""/>
                </div>
                <div className="number-badge">{props.badge}</div>
                {props.value}
            </div>
        </button>
    );
}

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import ClassAddModal from './classAddModal';
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomSearch from '../../components/control/custom_input/custom_search';
import strings from '../../lang/strings';
import CloudContainer from '../cabinet/cloud_container';

const Class = observer((props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [classInfo,setClassInfo] = useState([{id:1,img : "/assets/image/btn_audio_on.png", schoolInfo : "서울고등학교 1학년 3반", year : "2023"},{id:2,img : "/assets/image/btn_audio_on.png", schoolInfo : "서울고등학교 1학년 3반", year : "2023"},{id:3,img : "/assets/image/btn_audio_on.png", schoolInfo : "서울고등학교 1학년 3반", year : "2023"},{id:4,img : "/assets/image/btn_audio_on.png", schoolInfo : "서울고등학교 1학년 3반", year : "2023"}])
  const [keyword,setKeyword] = useState('');
  

  const onClickButton = () => {
    setIsOpen(true);
  };

  const handleClick = (item) =>{
    history.push("/class/"+ item.id)
  }

  return (
    
    <AppWrap>
      <div className="main-content">
      <CloudContainer />
      <div className="class-head">
        <div className="class-myClass">
          나의 클래스
        </div>
      <Button onClick={onClickButton}>+ 클래스 추가</Button>
      </div>

      {isOpen && (<ClassAddModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />)}

      {
        <CustomSearch
          placeholder={strings.class_search_placeholder}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
          }}
        />

      }

      { classInfo ? (
        <div className="class-list-container">
          {classInfo.map((item,idx)=>{
            return (
              <div className="class-list-content" onClick={()=>handleClick(item)}>
                <div><img src={item.img}></img></div>
                <div className="class-schoolInfo">{item.schoolInfo}</div>
                <div className="class-year">{item.year}</div>
              </div>
            )
          })}
        </div>
      ) :
      (
      <div className="class-nothing">
        <img className="class-nothing-img" src="/assets/image/class_nothing.png"/>
      </div>
      )}
      </div>
    </AppWrap>
    
  );
})

const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #0F84F4;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 10px auto;
  position: relative;
  width: 100%;
  height: 100vh; /* 화면 높이의 100%로 설정하여 화면 전체를 채우도록 만듭니다. */
`;

export default withRouter(Class);

import React, { useState } from "react";

export default function StudentSearch(props) {

    return (
            <div className="class-search  align-item-center box-shadow" style={{flex:8}}>             
                <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={props.onChange} onKeyUp={props.onKeyUp} 
                />
                <button className="common-button" > 
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>
                </button>
            </div>
    );
}
import React, { useContext, useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';


const ScreenSettingModal = (props) => {
    const context = useContext(Page_settings);
    const { show, close, video, speaker, reverse, toggle, name, profile, cameraType, onConfirm, onBack } = props;

    const [videoEnable, setVideoEnable] = useState(video)
    const [speakerEnable, setSpeakerEnable] = useState(speaker)
    const [reverseEnable, setReverseEnable] = useState(reverse)

    return (
        <Modal isOpen={show} toggle={() => toggle()} className='screen-setting-modal'>
            <ModalBody style={{ backgroundColor: '#232228', borderRadius: 20, padding: 0 }}>
                <div className='d-flex header align-item-center'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back.png"} alt="" className="modal-close" />
                    </button>
                    <div className='f-s-20 m-l-10' style={{ color: "white" }}>{strings.screen_settings}</div>
                    <div className='flex-grow-1' />
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div style={{ height: 250 }}>
                    {
                        videoEnable &&
                        <div style={{ height: '100%', position: "relative" }}>
                            <Webcam 
                            audio={false} 
                            mirrored={ isMobile ? reverseEnable : !reverseEnable } />
                            <div className='name-badge d-center'>{name}</div>
                            <div className='camera-badge d-center'>{cameraType}</div>
                        </div>
                    }
                    {
                        !videoEnable &&
                        <div className='d-center' style={{ height: '100%' }}>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + (profile != null && profile != "" ? context.loadImage(profile) : "/assets/image/logo_big.png") + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: 120,
                                    width: 120,
                                    borderRadius: 60
                                }} />
                                <div style={{ color: "white", textAlign: "center" }} className='f-s-20 mt-3'>{name}</div>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ height: 150 }} className='d-flex'>
                    <div className='flex-grow-1 d-center' onClick={() => setVideoEnable(!videoEnable)} style={{ cursor: 'pointer' }}>
                        <div>
                            <img src={videoEnable ? "/assets/image/btn_video_on.png" : "/assets/image/btn_video_off.png"}
                                alt="" className='setting-image' />
                            <div className='setting-btn-text'>{videoEnable ? strings.video_off : strings.video_on}</div>
                        </div>
                    </div>
                    <div className='flex-grow-1 d-center' onClick={() => setSpeakerEnable(!speakerEnable)}
                        style={{ cursor: 'pointer' }}>
                        <div>
                            <img src={speakerEnable ? "/assets/image/btn_audio_on.png" : "/assets/image/btn_audio_off.png"}
                                alt="" className='setting-image' />
                            <div className='setting-btn-text'>{speakerEnable ? strings.speaker_off : strings.speaker_on}</div>
                        </div>
                    </div>
                    <div className='flex-grow-1 d-center' onClick={() => setReverseEnable(!reverseEnable)}
                        style={{ cursor: 'pointer' }}>
                        <div>
                            <img src={"/assets/image/btn_reverse.png"} alt="" className='setting-image' />
                            <div className='setting-btn-text'>{strings.reverse}</div>
                        </div>
                    </div>
                </div>
                <div className='btn-confirm d-center' onClick={() => onConfirm(videoEnable, speakerEnable, reverseEnable)}>
                    {strings.confirm}
                </div>
            </ModalBody>
        </Modal>

    );
}

export default ScreenSettingModal;

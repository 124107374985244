import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Page_settings } from '../../config/page_settings';
import Sidebar_nav from './sidebar_nav.jsx';
import { Link } from "react-router-dom";

const Sidebar = () => {
    return (
        <Page_settings.Consumer>
            {({ toggleMobileSidebar }) => (
                <React.Fragment>
                    <div id="sidebar" className={'sidebar custom-sidebar'}>
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar} >
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link to="/" className="navbar-brand">
                                <img className="logo" src={'/assets/image/0001.png'} alt="" />
                            </Link>
                        </div>
                        <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                            <Sidebar_nav />
                        </PerfectScrollbar>
                    </div>
                    <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar} />
                </React.Fragment>
            )}
        </Page_settings.Consumer>
    )
}

export default Sidebar;

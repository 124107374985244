import React, { useRef, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import ConferenceItem from "../../../components/control/item/conference_item";
import ConferenceCarouselButton from "../../../components/control/button/conference_carousel_button";
import HideButton from "../../../components/control/button/hide_button";
import { isMobile } from "react-device-detect";

const ConferenceTop = (props) => {
    const { mine, memberList, reaction, localVideoTrack, muteVideo, reverseCamera, name, profile, screenTrack, screenShare, myShare, isStudent, showBoard } = props;
    const idCarousel = useRef();
    const [roomSelected, setRoomSelected] = useState(0);
    const [carouselOpen, setCarouselOpen] = useState(!mine || memberList.length != 0);

    useEffect(() => {
        if (screenTrack != undefined && screenTrack != null) {
            if (screenShare)
                screenTrack.play('screenShareElement');
            else
                screenTrack.stop();
        }
    }, [screenTrack, screenShare])

    return (
        <div className="conference-main" style={{ height: isMobile ? "calc(100vh - 205px)" : "calc(100vh - 135px)" }}>
            {
                carouselOpen && !isStudent &&
                <div className="conference-top">
                    {
                        (!mine || memberList.length != 0) &&
                        <div>
                            <Carousel
                                ref={idCarousel}
                                draggable={true}
                                swipeable={true}
                                // focusOnSelect={true}
                                // centerMode={true}
                                containerClass={"conference-room-container"}
                                itemClass="react-multi-carousel-item custom-carousel-item"
                                responsive={{
                                    LargeDesktop: {
                                        breakpoint: { max: 4000, min: 1400 },
                                        items: 5,
                                        slidesToSlide: 5
                                    },
                                    desktop: {
                                        breakpoint: { max: 1400, min: 1024 },
                                        items: 3,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1,
                                        slidesToSlide: 1 // optional, default to 1.
                                    }
                                }}
                                arrows={false}
                                showDots={false}
                            // slidesToSlide={3}
                            >
                                {
                                    !mine &&
                                    <div className={"conference-room-item position-relative" + (reverseCamera ? " camera-reverse" : "")}
                                        style={{ display: "flex", position: "relative" }}
                                        id={`local-player`}>
                                        {
                                            (muteVideo || localVideoTrack == undefined || localVideoTrack == null) &&
                                            <div
                                                className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                                                <img src={profile}
                                                    style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                                                    alt=""
                                                />
                                            </div>
                                        }
                                        <div className="user text-ellipsis">{name}</div>
                                        {
                                            reaction != undefined && reaction != null && reaction != "" &&
                                            <div className="width-100-percent position-absolute height-full d-flex align-item-center justify-content-around top-0" style={{ background: '#000000D4' }}>
                                                <img src={reaction} alt="" />
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    memberList.map((item, idx) =>
                                        <ConferenceItem
                                            key={idx}
                                            item={item}
                                            videoTrack={item.videoTrack}
                                            audioTrack={item.audioTrack}
                                            muteVideo={item.muteVideo}
                                            muteAudio={item.muteAudio}
                                            reverseCam={item.reverseCam}
                                        />
                                    )

                                }
                            </Carousel>
                            <ConferenceCarouselButton
                                more={roomSelected != 0}
                                direction={'left'}
                                onClick={() => {
                                    if (roomSelected != 0) {
                                        idCarousel?.current?.goToSlide(roomSelected - 1);
                                        // idCarousel?.current?.next();
                                        setRoomSelected(roomSelected - 1);
                                    } else {
                                        // idCarousel?.current?.goToSlide(memberList.length - 1);
                                        // setRoomSelected(memberList.length - 1);
                                    }
                                }}
                            />
                            <ConferenceCarouselButton
                                more={roomSelected != memberList.length - 1}
                                direction={'right'}
                                onClick={() => {
                                    if (roomSelected != memberList.length - 1) {
                                        idCarousel?.current?.goToSlide(roomSelected + 1);
                                        setRoomSelected(roomSelected + 1);
                                    } else {
                                        // idCarousel?.current?.goToSlide(0);
                                        // setRoomSelected(0);
                                    }
                                }}
                            />
                        </div>
                    }


                </div>
            }
            {
                showBoard &&
                <div style={{ position: 'absolute', top: carouselOpen && !isStudent ? 190 : 0, zIndex: 999, width: '100%' }}>
                    <HideButton
                        opened={carouselOpen}
                        onClick={() => {
                            if (isStudent) {
                                return;
                            }
                            setCarouselOpen(!carouselOpen)
                        }
                        }
                    />
                </div>
            }
            <div style={{ background: "white", height: showBoard && carouselOpen && !isStudent ? "calc(100% - 190px)" : '100%', width: "100%", display: 'flex', flexDirection: 'row' }} id="whiteboardDiv">
                <div style={{ width: '100%', height: '100%', display: screenShare && !myShare ? 'none' : 'block' }} id="whiteboardSDKElement">

                </div>
                <div id="screenShareElement" style={{ width: '100%', height: '100%', display: screenShare && !myShare ? 'block' : 'none' }}></div>
            </div>
        </div>

    );
}
export default ConferenceTop;

import React from 'react';
import './aitutor.scss'

import CloudContainer from '../cabinet/cloud_container';

const AiTutor = (props) => {

    return (
        <div className="cloud-content row">
            <CloudContainer />
            <div className="ai-tutor d-flex flex-column">
                <div className='d-flex p-2 justify-content-center'>
                   <img className='img-chew' src='../assets/image/img_chewchew_01.png'/>
                </div>
                <div className="ai-tutor-text d-flex p-2 justify-content-center">아직 준비중 입니다.</div>
            </div>
        </div>
    );

}

export default AiTutor;

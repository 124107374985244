import React, {useRef, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";
import PerfectScrollbar from "react-perfect-scrollbar";

const MentorAskModal = (props) => {
    const [subjectDropdown, setSubjectDropdown] = useState(false);
    const [gradeDropdown, setGradeDropdown] = useState(false);
    const [subjectId, setSubjectId] = useState(-1);
    const [gradeId, setGradeId] = useState(-1);
    const subjectList = strings.subject_list;
    const gradeList = strings.grade_list;
    const [question, setQuestion] = useState("")
    const [fileList, setFileList] = useState([])
    const imageRef = useRef();
    const [urlList, setUrlList] = useState([]);

    const toggleSubject = () => {
        setSubjectDropdown(!subjectDropdown);
    }

    const toggleGrade = () => {
        setGradeDropdown(!gradeDropdown);
    }

    const onImageChange = (e, idx) => {

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            setFileList([...fileList, reader.result]);
            setUrlList([...urlList, file]);
        };
        reader.readAsDataURL(file);
    };

    const delFile = (idx) => {
        setFileList(preList => preList.filter((item, index) => {
            return idx != index
        }));
        setUrlList(preList => preList.filter((item, index) => {
            return idx != index
        }));
    }

    const {show, close, toggle, onAsk, onPhoto} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='mentor-chew-modal mentor-ask'>
            <ModalBody style={{backgroundColor: '#232228', borderRadius: 20, padding: 0}}>
                <div className='d-flex header align-item-center m-b-20'>
                    <img src={"/assets/image/icon_3_color_bubble.png"} alt=""
                         style={{width: 75, height: 60, position: "absolute", top: -35, left: 50}}/>
                    <div style={{marginTop: 25, color: "white", fontSize: 20}}>{strings.mentor_chew}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="m-t-20 f-s-16 m-b-20" style={{color: "#4795e1"}}>{strings.enter_want}</div>
                <div className="d-flex align-item-center">
                    <div className="f-s-16 flex-grow-1" style={{color: "white"}}>{strings.question}</div>
                    <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e, fileList.length)}
                           ref={imageRef}/>
                    <Dropdown isOpen={subjectDropdown} toggle={() => toggleSubject()}
                              className="mentor-dropdown">
                        <DropdownToggle tag="a">
                            <div className="chat-member-count" style={{color: "#858496", width: 'auto'}}>
                                <div style={{
                                    width: 70,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {subjectId == -1 ? strings.subject : subjectList[subjectId]}
                                </div>
                                {
                                    !subjectDropdown &&
                                    <img className="triangle" src={"/assets/image/icon_triangle_down.png"} alt=""/>
                                }
                                {
                                    subjectDropdown &&
                                    <img className="triangle" src={"/assets/image/icon_triangle_up.png"} alt=""/>
                                }
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="media-list dropdown-menu chat-menu" tag="ul">
                            <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                                {
                                    subjectList.map((item, idx) =>
                                        <DropdownItem
                                            key={idx}
                                            style={{borderBottom: (idx + 1) != subjectList.length ? "#494852 1px solid" : ""}}
                                            onClick={() => setSubjectId(idx)}>
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown isOpen={gradeDropdown} toggle={() => toggleGrade()}
                              className="mentor-dropdown">
                        <DropdownToggle tag="a">
                            <div className="chat-member-count" style={{color: "#858496", width: 'auto'}}>
                                <div style={{
                                    width: 70,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {gradeId == -1 ? strings.grade : gradeList[gradeId]}
                                </div>

                                {
                                    !gradeDropdown &&
                                    <img className="triangle" src={"/assets/image/icon_triangle_down.png"} alt=""/>
                                }
                                {
                                    gradeDropdown &&
                                    <img className="triangle" src={"/assets/image/icon_triangle_up.png"} alt=""/>
                                }
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="media-list dropdown-menu chat-menu" tag="ul">
                            <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                                {
                                    gradeList.map((item, idx) =>
                                        <DropdownItem
                                            key={idx}
                                            style={{borderBottom: (idx + 1) != gradeList.length ? "#494852 1px solid" : ""}}
                                            onClick={() => setGradeId(idx)}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="search-box" style={{height: 100, marginRight: 0, width: "auto", marginTop: 10}}>
                        <textarea className="input-transparent" style={{resize: "none", color: "white", height: 80}}
                                  value={question}
                                  onChange={(v) => setQuestion(v.target.value)}
                        />
                </div>

                <div className="d-flex align-item-center m-t-20">
                    <div className="f-s-16 flex-grow-1" style={{color: "white"}}>{strings.file}</div>
                    {
                        fileList.length != 0 &&
                        <div className="d-flex">
                            <button className="upload-btn" onClick={() => imageRef?.current.click()}>
                                <div>{strings.file_photo}</div>
                                <img src={"/assets/image/icon_photo.png"} alt=""/>
                            </button>

                            <button className="upload-btn" onClick={() => onPhoto()}>
                                <div>{strings.camera}</div>
                                <img src={"/assets/image/icon_camera.png"} alt=""/>
                            </button>
                        </div>
                    }

                </div>
                <div className="search-box"
                     style={{height: 100, marginRight: 0, width: "auto", marginTop: 10, padding: "0 10px"}}>
                    {
                        fileList.length == 0 &&
                        <div className="d-flex" style={{width: "100%"}}>
                            <div className="ask-file" style={{borderRight: "#3d3c41 1px solid"}}>
                                <button style={{border: "none", background: "none"}}
                                        onClick={() => imageRef?.current.click()}>
                                    <img src={"/assets/image/icon_photo.png"} alt=""/>
                                    <div style={{
                                        whiteSpace: "break-spaces",
                                        lineHeight: "16px",
                                        color: "#a5a4ae",
                                        marginTop: 5
                                    }}>
                                        {strings.upload_photo}
                                    </div>
                                </button>
                            </div>
                            <div className="ask-file">
                                <button style={{border: "none", background: "none"}} onClick={() => onPhoto()}>
                                    <img src={"/assets/image/icon_camera.png"} alt=""/>
                                    <div style={{color: "#a5a4ae", lineHeight: "16px", marginTop: 5}}>
                                        {strings.take_photo}
                                    </div>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        // fileList.length != 0 &&
                        <PerfectScrollbar className="d-flex" style={{paddingTop: 10}} options={{suppressScrollY: true}}>
                            {
                                fileList.map((item, idx) =>
                                    <div className="position-relative m-r-15" key={idx}>
                                        <img style={{width: 80, height: 80, borderRadius: 5}} src={item} alt=""/>
                                        <button
                                            style={{
                                                border: "none",
                                                background: "none",
                                                position: "absolute",
                                                top: -10,
                                                right: -20
                                            }}
                                            onClick={() => {
                                                delFile(idx)
                                            }}
                                        >
                                            <img src={"/assets/image/btn_delete.png"} alt=""/>
                                        </button>
                                    </div>
                                )
                            }
                        </PerfectScrollbar>
                    }

                </div>
                <div className={"d-flex justify-content-center"}>
                    <button className={"ask-button"} style={{marginTop: 20, width: 300, flex: "none"}}
                            onClick={() => onAsk(subjectId, gradeId, question, urlList)}>
                        {strings.ask}
                        <img src={"/assets/image/icon_arrow_right.png"} alt=""/>
                    </button>
                </div>
            </ModalBody>
        </Modal>

    );
}

export default MentorAskModal;

import React, {useContext, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";
import {formatNumber} from "../../../helper/common";
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";

const ShopConfirmModal = (props) => {

    const {show, toggle, onClosed, item, onGet} = props;
    const context = useContext(Page_settings)
    const [step, setStep] = useState(0)

    const onClick = () => {
        if (step === 0) {
            context.post(
                "product/buy",
                {
                    id: item.id
                },
                response => {
                    setStep(1);
                    rootStore.setPoint(response.point);
                    onGet(response.point)
                }
            )
        } else toggle()
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} onClosed={onClosed} className="shop-confirm-modal" onOpened={() => setStep(0)}>
            <ModalBody>
                <div className="d-flex">
                    <div
                        className="f-s-23 f-w-700">{step === 0 ? strings.reward_exchange : strings.exchange_confirmation}</div>
                    <button className="common-button m-l-auto" onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                {
                    step === 0 &&
                    <div className="exchange-content">
                        <div className="row m-0">
                            <label className="text-center">
                                <span className="f-w-600 m-r-5 width-fit p-0">{item.title}</span>
                                <span className="m-r-5 width-fit p-0">{strings.get_the}</span>
                                <span className="f-w-600 width-fit p-0">{formatNumber(item.point)}</span>
                                <span className="m-r-5 width-fit p-0">{strings.for}</span>
                            </label>
                        </div>
                    </div>
                }
                {
                    step === 1 &&
                    <div className="exchange-content f-w-600">{strings.exchange_complete}</div>
                }
                <button className="exchange-button"
                        onClick={() => onClick()}>{step === 0 ? strings.get : strings.confirm}</button>
            </ModalBody>
        </Modal>
    );
}

export default ShopConfirmModal;

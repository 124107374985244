import strings from "../../lang/strings";
import { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Page_settings } from "../../config/page_settings";
import { getAgeFromBirthday, getDecimal, getTimeFromSecond } from "../../helper/common";
import { rootStore } from "../../mobx/store";
import { observer } from "mobx-react";
import CloudContainer from "../cabinet/cloud_container";

const Ranking = observer((props) => {

    const context = useContext(Page_settings)
    const benchmark = strings.benchmark_list;
    const [table, setTable] = useState("ALL");
    const [keyword, setKeyword] = useState("");
    const [period, setPeriod] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [userInfo, setUserInfo] = useState("");
    const [allTop, setAllTop] = useState("");
    const [growthTop, setGrowthTop] = useState("");
    const [effTop, setEffTop] = useState("");
    const [pageNum, setPageNum] = useState(1);
    const [scroll, setScroll] = useState(0);
    const [loadMore, setLoadMore] = useState(false);
    const [header, setHeader] = useState(false);
    const theme = [
        "month", "growth", "efficiency"
    ];
    const rankers = [allTop, growthTop, effTop];
    const [premium, setPremium] = useState(0);

    const scrollRef = useRef();

    useEffect(() => {
        setPremium(rootStore.getProfile?.premium ?? 0);
        setPremium(rootStore.getPremium ?? 0);
    }, [rootStore.getPremium, rootStore.getProfile]);

    useEffect(() => {
        context.setHeaderType(2);
        getRankingList(strings.ranking_period[period].code, "ALL", "", 1);
        getTopInfo(strings.ranking_period[period].code, "ALL", 0);
        getTopInfo(strings.ranking_period[period].code, "GROWTH", 0);
        getTopInfo(strings.ranking_period[period].code, "STUDY", 0);
        getTopInfo(strings.ranking_period[period].code, "ALL", 1);
        return () => {
            context.setHeaderType(1);
        }
    }, []);

    useEffect(() => {
        getRankingList(strings.ranking_period[period].code, table, keyword, 1);
        getTopInfo(strings.ranking_period[period].code, table, 1);
    }, [table, period]);

    useEffect(() => {
        getTopInfo(strings.ranking_period[period].code, "ALL", 0);
        getTopInfo(strings.ranking_period[period].code, "GROWTH", 0);
        getTopInfo(strings.ranking_period[period].code, "STUDY", 0);
        getTopInfo(strings.ranking_period[period].code, table, 1);
    }, [period]);

    const getTopInfo = (mode, type, isMe) => {
        context.get(
            "ranking/getTopInfo",
            {
                mode: mode,
                type: type,
                isMe: isMe
            },
            response => {
                if (isMe == 0) {
                    if (type == "ALL") {
                        setAllTop(response.top_info)
                    } else if (type == "GROWTH") {
                        setGrowthTop(response.top_info)
                    } else if (type == "STUDY") {
                        setEffTop(response.top_info)
                    }
                } else {
                    setUserInfo(response.top_info)
                }

            }
        );
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const onSearch = () => {
        getRankingList(strings.ranking_period[period].code, table, keyword, 1)
    }

    const getRankingList = (mode, type, keyword, pageNum) => {
        context.get(
            "ranking/getList",
            {
                mode: mode,
                type: type,
                keyword: keyword,
                page_num: pageNum
            },
            response => {
                if (pageNum == 1) {
                    setDataList(response.list);
                    setPageNum(1)
                } else {
                    setDataList([...dataList, ...response.list]);
                    setPageNum(pageNum);
                    setLoadMore(response.list.length == response.limit);
                }
            }
        );
    }

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const onEnd = () => {
        if (scroll <= 3) {
            setScroll(scroll + 1)
        }
        if (loadMore && scroll == 2) {
            getRankingList(strings.ranking_period[period].code, table, keyword, pageNum + 1)
        }
    }

    const listenToScroll = (scrollElement) => {
        const winScroll = scrollElement.scrollTop;
        const _height = document.getElementsByClassName("ranking-top")[0].offsetHeight ?? 0;
        if (winScroll >= _height) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    };

    const checkScrollTop = () => {
        if (header) {
            document.getElementsByClassName("ranking-scroll")[0].scrollTo({
                left: 0,
                top: document.getElementsByClassName("ranking-top")[0].offsetHeight
            })
        }
    }

    return (
        <div className="ranking-content">
            <CloudContainer/>
            <PerfectScrollbar ref={scrollRef} className="ranking-scroll" options={{ suppressScrollX: true }} onYReachEnd={() => {
                onEnd()
            }} onScrollUp={() => setScroll(0)} onYReachStart={() => setScroll(0)} onScrollY={scrollElement => listenToScroll(scrollElement)}>
                {
                    allTop != "" && growthTop != "" && effTop != "" &&
                    <div className="row ranking-top">
                        <img className="logo" src={"/assets/image/logo_big.png"} alt="" />
                        {
                            rankers.map((item, idx) => {
                                return (
                                    <div className={"col-lg-4 m-b-30 " + theme[idx]} key={idx}>
                                        <div className="f-s-20 m-b-20 benchmark">{(idx == 0 ? strings.ranking_period_desc[period].name : "") + benchmark[idx]}</div>
                                        <div className="detail-tab">
                                            <div className="d-flex align-item-center m-t-20 m-b-20 p-l-15 p-r-15">
                                                <img className="profile"
                                                    src={item.user.profile != null && item.user.profile != "" ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"}
                                                    alt="" />
                                                <div className="d-block width-100-percent">
                                                    <div className="d-flex">
                                                        <div className="name text-ellipsis">{item.user.name}</div>
                                                        {
                                                            item.user.premium == 1 &&
                                                            <img className="ranking-clinic" src={"/assets/image/icon_premium.png"} alt="" />
                                                        }                                                    </div>
                                                    <div className="d-flex align-item-center">
                                                        <div className="indicator">{strings.addr}</div>
                                                        <div className="address">{item.user.school}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="rate-detail">
                                                <div className="m-auto">
                                                    <div
                                                        className="f-s-20 d-flex justify-content-center">{getTimeFromSecond(parseInt(item.real_time))}</div>
                                                    <div className="indicator m-t-15">{strings.net_time}</div>
                                                </div>
                                                <div className="m-auto">
                                                    <div
                                                        className="f-s-20 d-flex justify-content-center">{getDecimal(item.growth_rate.toString()) + "%"}</div>
                                                    <div className="indicator m-t-15">{strings.growth_rate}</div>
                                                </div>
                                                <div className="m-auto">
                                                    <div
                                                        className="f-s-20 d-flex justify-content-center">{getDecimal(item.learning_efficiency.toString()) + "%"}</div>
                                                    <div
                                                        className="indicator m-t-15">{strings.learning_efficiency}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div className="table-container">
                    <div className={"table-header width-100-percent d-flex align-item-center" + (header ? " ranking-table-header" : "")}>
                        {
                            strings.ranking_button.map((item, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        className={("table-button ") + (table == item.code ? "table-button-selected" : "")}
                                        onClick={() => {
                                            setTable(item.code)
                                            checkScrollTop();
                                        }}
                                    >{item.name}</button>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex m-b-20 width-100-percent align-item-center">
                        <div className="position-relative" style={{ width: "350px" }}>
                            <input
                                value={keyword}
                                className="search-expert"
                                style={{ background: "#f7f8fa" }}
                                type={"text"} onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={handleEnter}
                            />
                            <button className="search-button" onClick={() => onSearch()}>
                                <img src={"/assets/image/icon_search_black.png"} alt="" />
                            </button>
                        </div>
                        <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                            className="dropdown navbar-user rank-dropdown" tag="li"
                            style={{ width: 90, marginLeft: "auto" }}>
                            <DropdownToggle tag="a"
                                className="display-flex-important align-item-center profile-dropdown"
                                style={{ background: "white", margin: 0 }}>
                                <div className="flex-grow-1"
                                    style={{ color: "#3f3f3f" }}>{strings.ranking_period[period].name}</div>
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                {
                                    strings.ranking_period.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                setPeriod(idx)
                                            }}
                                        >
                                            {item.name}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="ranking-table-container">
                        <table className="ranking-table">
                            <thead className="head">
                                <tr>
                                    {
                                        strings.table_head.map((item, idx) => {
                                            return (
                                                <th width={idx == 0 ? "35%" : (idx != 5 ? "10%" : "15%")}
                                                    className={idx == 0 ? "name-tab p-t-10 p-b-10 p-r-0" : "p-t-10 p-b-10 p-r-0 p-l-0"}
                                                    key={idx}>{item}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataList.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td className="p-l-10 p-r-10 p-t-20 p-b-20">
                                                    <div className="width-100-percent">
                                                        <div className="d-flex align-item-center">
                                                            <div className="table-rank">{idx + 1}</div>
                                                            <img className="table-profile-img"
                                                                src={item.user.profile != null && item.user.profile != "" ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"}
                                                                alt="" />
                                                            <div
                                                                className="ranking-user-name text-ellipsis">{item.user.name}</div>
                                                            {
                                                                item.user.premium == 1 &&
                                                                <img src={"/assets/image/icon_premium.png"} className="m-l-5" alt="" />
                                                            }
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>{getTimeFromSecond(parseInt(item.real_time))}</td>
                                                <td>{getDecimal(item.learning_efficiency.toString()) + "%"}</td>
                                                <td>{getDecimal(item.growth_rate.toString()) + "%"}</td>
                                                <td>{item.mission_count}</td>
                                                <td>{getAgeFromBirthday(item.user.birthday) >= 19 ? strings.g3 : (getAgeFromBirthday(item.user.birthday) == 18 ? strings.g2 : strings.g1)}</td>
                                                <td>{item.user.school}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </PerfectScrollbar>
            {
                userInfo != "" &&
                <div className="mine">
                    <table width={"100%"}>
                        <thead className="head">
                            <tr>
                                {
                                    strings.table_head.map((item, idx) => {
                                        return (
                                            <th width={idx == 0 ? "35%" : (idx != 5 ? "10%" : "15%")}
                                                className={idx == 0 ? "name-tab" : ""}
                                                style={{ padding: 0 }}
                                                key={idx} />
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-10">
                                    <div className="d-flex align-item-center">
                                        <div className="table-rank" style={{ color: "white" }}>{userInfo.ranking}</div>
                                        <img className="table-profile-img"
                                            src={userInfo.user.profile != null && userInfo.user.profile != "" ? context.loadImage(userInfo.user.profile) : "/assets/image/logo_big.png"}
                                            alt="" />
                                        <div>{userInfo.user.name}</div>
                                        {
                                            premium == 1 &&
                                            <img src={"/assets/image/icon_premium.png"} className="m-l-5" alt="" />
                                        }
                                    </div>
                                </td>
                                <td>{getTimeFromSecond(parseInt(userInfo.real_time))}</td>
                                <td>{userInfo.learning_efficiency}</td>
                                <td>{userInfo.growth_rate}</td>
                                <td>{userInfo.mission_count}</td>
                                <td>{getAgeFromBirthday(userInfo.user.birthday) >= 19 ? strings.g3 : (getAgeFromBirthday(userInfo.user.birthday) == 18 ? strings.g2 : strings.g1)}</td>
                                <td>{userInfo.user.school}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );

})

export default Ranking;

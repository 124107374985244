import React from "react";

export default function AddItemButton(props) {
    return (
        <button
            className={("add-item-button ") + (props.inputStatus == 0? "disabled-btn" : "")}
            onClick={() => props.onClick()}
            disabled={props.disable}
        >
            <img src={"/assets/image/icon_plus_white.png"} alt=""/>
        </button>
    );
}

import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Page_settings } from '../../../config/page_settings';
import HeaderBlack from "../../../components/header/header_black";
import { AGORA_APP_ID, AGORA_REGION, AGORA_WHITEBOARD_AK, AGORA_WHITEBOARD_APP_IDENTIFIER, AGORA_WHITEBOARD_REGION, AGORA_WHITEBOARD_SK, SOCKET_URL } from "../../../config/const";
import ConferenceRight from "./conference_right";
import ConferenceTop from "./conference_top";
import ConferenceBottom from "./conferece_bottom";
import ScreenSettingModal from "../../../components/control/modal/screen_setting_modal";
import { getRandomNumber, getTimeFromSecond } from "../../../helper/common";
import io from 'socket.io-client';
import { rootStore } from "../../../mobx/store";
import strings from "../../../lang/strings";
import { Store } from "react-notifications-component";
import NewSettingModal from "../../../components/control/modal/new_setting_modal";
import RoomInfoModal from "../../../components/control/modal/room_info_modal";
import MemberInfoModal from "../../../components/control/modal/member_info_modal";
import AgoraRTC from 'agora-rtc-sdk-ng';
import { createFastboard } from '@netless/fastboard-core';
import { apps, createUI } from '@netless/fastboard-ui';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import fixWebmDuration from 'fix-webm-duration';
import axios from 'axios';

let socket = null;

let channelParameters = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
    screenTrack: null,
};

let remoteScreenShare = false;

const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

let fastboard = null;
let fastboardUI = null;
let isRecording = false;
let recorder = null;
const chunks = [];

const ChallengeRoom = observer((props) => {
    const context = useContext(Page_settings);
    const idRef = useRef();
    const roomId = parseInt(props.match.params.id);
    const [roomTime, setRoomTime] = useState(0);
    const [showSettingModal, setShowSettingModal] = useState(false)
    const [videoSetting, setVideoSetting] = useState(true)
    const [speakerSetting, setSpeakerSetting] = useState(true)
    const [cameraSetting, setCameraSetting] = useState(true)
    const [memberList, setMemberList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [userName, setUserName] = useState("");
    const [owner, setOwner] = useState("");
    const [title, setTitle] = useState("");
    const [mine, setMine] = useState(false);
    const [showReaction, setShowReaction] = useState(false);
    const [reaction, setReaction] = useState("");
    const [reactionTick, setReactionTick] = useState(0);
    const [reactionTime, setReactionTime] = useState(0);
    const [rand, setRand] = useState(0)
    const [showSetting, setShowSetting] = useState(false);
    const [showRoomInfo, setShowRoomInfo] = useState(false);
    const [roomInfo, setRoomInfo] = useState("");
    const [profile, setProfile] = useState("");
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [memberInfo, setMemberInfo] = useState("");
    const [agoraRtmToken, setAgoraRtmToken] = useState('');
    const [agoraRtcToken, setAgoraRtcToken] = useState('');
    const [meetingNumber, setMeetingNumber] = useState('');
    const [boardRoomToken, setBoardRoomToken] = useState('');
    const [boardUuid, setBoardUuid] = useState('');
    const [meetingRole, setMeetingRole] = useState(2);
    const [leaveAlert, setLeaveAlert] = useState(false);
    const [startPressed, setStartPressed] = useState(true);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [ownerInfo, setOwnerInfo] = useState({});
    const [ownerVideoTrack, setOwnerVideoTrack] = useState(null);
    const [ownerAudioTrack, setOwnerAudioTrack] = useState(null);
    const [screenShare, setScreenShare] = useState(false);
    const [boardSplit, setBoardSplit] = useState(false);
    const [chatMemberList, setChatMemberList] = useState([]);
    const [selectedChatMember, setSelectedChatMember] = useState(0);
    // const [remoteScreenShare, setRemoteScreenShare] = useState(false);

    const listRef = useRef(memberList);
    const chatMemberListRef = useRef(chatMemberList);

    // 💡 hold a reference to video element
    const screenShareVideoRef = useRef(null);

    const fileRef = useRef();

    const localPlayerContainer = document.createElement('div');

    const captureScreen = async () => {
        // 💡 will prompt the user for a screen to share
        const screenShareStream = await navigator.mediaDevices.getDisplayMedia({
            // we only want video for now, but can easily specify other options
            video: true,
            // video: {
            //     displaySurface: 'monitor'
            // },
            // audio: true,
            // preferCurrentTab: true
        });

        // screenShareStream.onremovetrack((stream, event) => {
        //     console.log('onremovetrack', event);
        // })

        // 💡 connect stream to video element via srcObject
        if (screenShareVideoRef) screenShareVideoRef.current.srcObject = screenShareStream;
    }

    const onSend = (chat) => {
        let message = {
            room: roomId,
            type: 1,
            content: chat,
            sender: rootStore.getProfile.id,
            receiver: !mine ? roomInfo.user.id : chatMemberList[selectedChatMember].member,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        socket.emit("new message", message)
        setChatList([message, ...chatList]);
    }

    // reference to your canvas node, might vary depending on your setup
    const canvas = document.createElement("canvas");
    // and get the 2D rendering context for the canvas
    let ctx = canvas.getContext("2d");

    // kick off the drawing process 
    const startDrawing = () => {
        requestAnimationFrame(loop);
    }

    // requestAnimationFrame loop. Each frame, we draw to the canvas.
    const loop = () => {
        if (!isRecording) {
            return;
        }

        draw();
    }

    // our drawing function
    const draw = () => {
        // 👈 DRAWING COMMANDS HERE!
        const canvasElement = document.getElementById('recordingCanvas');
        const canvasContext = canvasElement.getContext('2d');
        const { width, height } = canvasElement;
        const shareElement = document.getElementById('whiteboardDiv');

        if (!isRecording || shareElement == null) {
            return
        }

        // clear out the entire canvas and paint from scratch
        canvasContext.clearRect(0, 0, width, height);

        // draw our screen share in top-left
        // would need to do real math to get proper aspect ratio.
        canvasContext.drawImage(screenShareVideoRef.current, 0, 0, width, height);
        if (recorder != null && recorder.state == 'active') {
            recorder.requestData();
        }

        if (!isRecording) {
            return
        }
        requestAnimationFrame(loop);
    }

    let startTime;

    const setupRecording = () => {
        canvas.id = 'recordingCanvas';
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        canvas.style.position = 'absolute';
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.zIndex = -1;
        canvas.style.width = window.innerWidth + 'px';
        canvas.style.height = window.innerHeight + 'px';

        document.getElementById('conferenceRoom').appendChild(canvas);

        ctx = canvas.getContext("2d");

        // create a MediaStream from our canvas
        // the `30` here is frames per second, feel free to set your own FPS
        const canvasStream = canvas.captureStream(30);

        // combine the canvas stream and mic stream (from above) by collecting
        //  tracks from each.
        const combinedStream = new MediaStream([
            ...canvasStream.getTracks()
        ]);

        // create a recorder
        recorder = new MediaRecorder(combinedStream, {
            // requested media type, basically limited to webm 🤦‍♂️
            mimeType: "video/webm;codecs=vp9"
        });

        // collect blobs when available
        recorder.ondataavailable = (evt) => {
            chunks.push(evt.data);
        }

        // when starting the recording, track the start time
        recorder.onstart = () => {
            startTime = performance.now();
        }

        // when recorder stops (via recorder.stop()), handle blobs
        recorder.onstop = (e) => {

        }
    }

    const stopRecording = () => {
        isRecording = false;

        if (chunks.length == 0) {
            return;
        }

        const recordedBlob = new Blob(chunks, { type: chunks[0].type });

        // 💡 manually compute duration, and patch the blob
        const duration = performance.now() - startTime;
        fixWebmDuration(recordedBlob, duration, (patchedBlob) => {
            saveRecordedFile(patchedBlob);

            chunks.splice(0, chunks.length);

            canvas.remove();
        })
    }

    const saveRecordedFile = (blob) => {
        console.log(blob);
        let fileName = moment().format('YYYYMMDD_hhmmss');
        let mimeType = '.webm';
        let entry = new File([blob], fileName + mimeType, {
            type: 'video/webm'
        });

        let formData = new FormData();
        formData.append('file', entry);

        context.post(
            "upload/file/cloud",
            formData,
            response => {
                let type = "/assets/image/icon_file_drive.png";
                let list = [{ title: fileName + "_레코딩" + mimeType, size: blob.size, type: type, url: response.file }]
                context.post(
                    "cloud/add",
                    {
                        file_data: list
                    },
                    resp => {
                    }
                );
            }
        );
    }

    useEffect(() => {
        setUserName(rootStore.getProfile?.name ?? "");
        setProfile(rootStore.getProfile?.profile ?? "")
    }, [rootStore.getProfile])

    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);
        idRef?.current?.focus();

        getRoomDetail();
        if (rootStore.getProfile != null) {
            setVideoSetting(rootStore.getProfile.video == "ON");
            setSpeakerSetting(rootStore.getProfile.speaker == "ON");
            setCameraSetting(rootStore.getProfile.mirror == "ON");
        }

        const timer = window.setInterval(() => {
            setRoomTime(prevTime => prevTime + 1);
        }, 1000);
        if (!socket) {
            socket = io(SOCKET_URL, {
                auth: {
                    token: props.rootStore.token,
                }
            });
        }

        socket.on('connect', () => {
            socket.emit('add user', roomId);
        });
        socket.on('disconnect', () => {
        });
        socket.on('reconnect', data => {
            socket.emit('add user', roomId);
        });
        socket.on("kickout", data => {
            if (data.member == rootStore.getProfile.id) {
                props.history.push("/room/study/conference")
                addNotification('warning', strings.kicked)
            }
        });
        socket.on("screen share", data => {
            console.log('screen share socket event', data);
            if (data.user != rootStore.getProfile.id) {
                setScreenShare(false);
                // setRemoteScreenShare(data.status == 1);
                remoteScreenShare = data.status == 1
            }
        });
        socket.connect();

        captureScreen();

        setupRecording();

        return () => {
            context.handleSetPageHeader(1);
            context.handleSetPageSidebar(true);
            window.clearInterval(timer);
            socket.disconnect();
            socket.off('connect');
            socket.off('disconnect');
            socket.off('reconnect');
            socket.off('new message');
            socket.off('user joined');
            socket.off('user left');
            socket.off('kickout');
            socket.off("cam mirror");
            socket.off("screen share");
            socket = null;

            if (channelParameters.localAudioTrack != null && channelParameters.localAudioTrack != '') {
                channelParameters.localAudioTrack.close();
            }

            if (channelParameters.localVideoTrack != null && channelParameters.localVideoTrack != '') {
                channelParameters.localVideoTrack.close();
            }

            agoraEngine.leave();
            console.log("You left the channel");

            recorder.stop();
            stopRecording();
            recorder = null;

            if (fastboardUI != null) {
                fastboardUI.destroy();
                fastboardUI = null;
            }

            if (fastboard != null) {
                fastboard.destroy();
                fastboard = null;
            }

            // Refresh the page for reuse
            // window.location.reload();
        }
    }, []);

    useEffect(() => {
        // socket.on('new message', data => {
        //     setChatList([data, ...chatList]);
        // });
        socket.on('new message', data => {
            if (data.receiver.id == rootStore.getProfile.id) {
                if (data.sender.id == chatMemberList[selectedChatMember].member) {
                    setChatList([data, ...chatList]);
                } else {
                    setChatMemberList(prevList => prevList.map((item, idx) => {
                        if ((item.member == data.sender.id) && (data.sender.id != chatMemberList[selectedChatMember].member)) {
                            // item.unread++;
                            // setUnreadAll(unreadAll + 1)
                        }
                        return item;
                    }))
                }
            }
        });
        return () => {
            if (socket) {
                socket.off("new message");
            }
        }
    }, [chatList, chatMemberList]);

    useEffect(() => {
        listRef.current = memberList;
    }, [memberList]);

    useEffect(() => {
        chatMemberListRef.current = chatMemberList;
    }, [chatMemberList])

    useEffect(() => {
        if (boardSplit) {
        }
    }, [boardSplit])

    useEffect(() => {
        socket.on('user joined', data => {
            // 방장인 경우
            console.warn('user joined', data, roomInfo);
            if (data.owner_id == data.user_info.id) {
                setOwnerInfo({
                    user: data.user_info,
                    muteVideo: data.user_info.video == 'OFF',
                    muteAudio: data.user_info.speaker == 'OFF',
                    reverseCam: data.user_info.mirror == 'ON'
                })
            } else { // 방장이 아닌 경우
                setMemberList(prevList => prevList.map((item, idx) => {
                    item.user = item.member == data.user_info.id ? data.user_info : item.user;
                    item.muteVideo = item.member == data.user_info.id ? data.user_info.video == 'OFF' : item.muteVideo;
                    item.muteAudio = item.member == data.user_info.id ? data.user_info.speaker == 'OFF' : item.muteAudio;
                    item.reverseCam = item.member == data.user_info.id ? data.user_info.mirror == 'ON' : item.reverseCam;
                    return item;
                }));
                if (getMemberIndex(data.user_info.id) < 0) {
                    console.warn('user joined audience', data);
                    let user_info = data.user_info;
                    user_info.this_month_real_time = data.user_info.user_this_month_real_time;
                    user_info.today_study_time = data.user_info.user_today_study_time;
                    setMemberList([...listRef.current, { member: data.user_info.id, user: user_info, muteVideo: false, muteAudio: false, reverseCam: false }]);
                }
            }

            if (data.owner_id == rootStore.getProfile.id || data.owner_id == data.user_info.id) {
            // setChatMemberList(prevList => prevList.map((item, idx) => {
            //     console.warn('joined user chat', item);
            //     item.user = item.member == data.user_info.id ? data.user_info : item.user;
            //     item.status = item.member == data.user_info.id ? 0 : item.status;
            //     return item;
            // }));
            if (getChatMemberIndex(data.user_info.id) < 0) {
                setChatMemberList([...chatMemberListRef.current, { user: data.user_info, status: 0, member: data.user_info.id, unread: 0 }]);
            }
            if (chatMemberList.length == 0) {
                loadChatList(data.user_info.id);
            }
        };
        });

        socket.on('user left', data => {
            setMemberList(prevList => prevList.filter((item, idx) => {
                return (item.user.id != data.user_info.id)
            }))
            setChatMemberList(prevList => prevList.map((item, idx) => {
                if (item.member == data.user_info.id) {
                    item.status = 1
                }
                return item
            }))
        });

        socket.on('cam mirror', data => {
            setMemberList(prevList => prevList.map((item, idx) => {
                if (item.member == data.user.id) {
                    item.reverseCam = data.reverse
                }
                return item
            }))
        });
    }, [memberList, chatList]);

    useEffect(() => {
        if (roomInfo != "" && roomInfo.user != undefined) {
            getMemberList();
            // getChatList();
            getChatMemberList();
        }
    }, [roomInfo]);

    const getMemberIndex = (memberId) => {
        console.log('getMemberIndex', listRef.current);
        for (let i = 0; i < listRef.current.length; i++) {
            if (listRef.current[i].member == memberId) {
                return i;
            }
        }
        return -1;
    }
    
    const getChatMemberIndex = (memberId) => {
        console.log('getChatMemberIndex', chatMemberListRef.current);
        for (let i = 0; i < chatMemberListRef.current.length; i++) {
            if (chatMemberListRef.current[i].member == memberId) {
                return i;
            }
        }
        return -1;
    }

    const getRoomDetail = () => {
        context.get(
            'room/detail',
            {
                id: roomId
            },
            response => {
                setRoomInfo(response.room)
                setRoomTime(response.room.study_time != null ? parseInt(response.room.study_time) : 0);
                setOwner(response.room.user.name);
                setTitle(response.room.title);
                setMine(rootStore.getProfile.id == response.room.user.id);
                setMeetingNumber(response.room.meeting_number);
                setBoardUuid(response.room.whiteboard_uuid);
                setMeetingRole(response.room.user.id == rootStore.getProfile?.id ? 1 : 2);
            }
        );
    }

    useEffect(() => {
        if (meetingNumber != "") {
            getAgoraToken(meetingNumber);
        }
    }, [meetingNumber, meetingRole])

    useEffect(() => {
        if (boardUuid != "") {
            getWhiteBoardRoomToken();
        }
    }, [boardUuid])

    useEffect(() => {
        if (reactionTime == 3) {
            clearInterval(reactionTick)
            setReactionTime(0);
            setShowReaction(false);
            setReaction("")
            socket.emit("reaction", { room: roomId, reaction: "" });
        }
    }, [reactionTime]);

    useEffect(() => {
        socket.on('reaction', data => {
            setMemberList(prev => prev.map((item) => {
                if (item.user.id == data.user_info.id) {
                    item.reaction = data.reaction
                }
                return item
            }));
        });
    }, [memberList]);

    useEffect(() => {
        if (roomTime % 60 == 0) {
            setRand(getRandomNumber(3))
        }
    }, [roomTime])

    const onKick = (item) => {
        socket.emit("kickout", { room: roomId, member: item.member })
    }

    const getMemberList = () => {
        context.get(
            'room/getMemberList',
            {
                room: roomId,
                type: 2
            },
            response => {
                let list = response.list.filter((item) => {
                    if ((item.member != parseInt(rootStore.getProfile.id)) && item.status != 1) {
                        item.reaction = "";
                        return item
                    }
                })
                setMemberList(list);
            }
        );
    }

    const getChatMemberList = () => {
        context.get(
            'room/getMemberList',
            {
                room: roomId,
                type: 1
            },
            response => {
                const isMyRoom = roomInfo.user.id == rootStore.getProfile.id; 
                let list = response.list.filter((item, idx) => {
                    // 방장인 경우 본인을 제외한 회원들만 필터링, 방장이 아닌 경우에는 방장만 필터링
                    return isMyRoom ? (item.member != parseInt(rootStore.getProfile.id)) : (item.member == parseInt(roomInfo.user.id))
                })
                if (list.length != 0) {
                    loadChatList(list[0].member);
                    setChatMemberList(list.map((item, idx) => {
                        item.reaction = ""
                        // setUnreadAll(item.unread + unreadAll);
                        return item
                    }));
                }
            }
        );
    }

    const onSelectPartner = (idx) => {
        loadChatList(chatMemberList[idx].member)
        setSelectedChatMember(idx);
        setChatMemberList(prevList => prevList.map((item, index) => {
            if (index == idx) {
                // setUnreadAll(unreadAll - item.unread)
                item.unread = 0;
            }
            return item
        }));
    }

    const loadChatList = (id) => {
        context.get(
            'room/getChatList',
            {
                room: roomId,
                target_member: id,
                page_num: 1
            },
            response => {
                setChatList(response.list);
            }
        );
    }

    const getChatList = () => {
        context.get(
            'room/getGroupChatList',
            {
                room: roomId,
                page_num: 1
            },
            response => {
                setChatList(response.list);
            }
        );
    }

    const toggleSweetAlert = () => {
        setLeaveAlert(!leaveAlert)
    }

    const onOut = () => {
        if (startPressed) {
            setLeaveAlert(true);
        } else {
            props.history.push("/room/study/conference");
        }
    }

    const leavePage = () => {
        recorder.stop();
        setTimeout(() => {
            props.history.push("/room/study/conference")
        }, 1000)
    }

    const onSettingConfirm = (video, speaker, reverse) => {
        context.post(
            'room/updateSetting',
            {
                video: video ? "ON" : "OFF",
                speaker: speaker ? "ON" : "OFF",
                mirror: reverse ? "ON" : "OFF",
            },
            response => {
                setScreenSetting(video, speaker, reverse);
                setShowSettingModal(false);
                rootStore.setScreenSetting(video, speaker, reverse);
            }
        );
    }

    const setScreenSetting = async (video, speaker, reverse) => {
        if (videoSetting != video) {
            if (video) {
                await agoraEngine.publish(channelParameters.localVideoTrack);
                channelParameters.localVideoTrack.play('local-player');
            } else {
                await agoraEngine.unpublish(channelParameters.localVideoTrack);
                channelParameters.localVideoTrack.stop();
            }
            setVideoSetting(video);
        }

        if (speakerSetting != speaker) {
            if (speaker) {
                await agoraEngine.publish(channelParameters.localAudioTrack);
            } else {
                await agoraEngine.unpublish(channelParameters.localAudioTrack);
            }
            setSpeakerSetting(speaker)
        }

        if (cameraSetting != reverse) {
            socket.emit("cam mirror", { room: roomId, user: rootStore.getProfile.id, reverse: reverse });
            setCameraSetting(reverse);
        }
    }

    const onClickReaction = (item) => {
        socket.emit("reaction", { room: roomId, reaction: item });
        setShowReaction(true);
        setReaction(item)
        clearInterval(reactionTick);
        setReactionTime(0);
        setReactionTick(setInterval(() => {
            setReactionTime(prevTime => prevTime + 1);
        }, 1000));
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-left',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const onInvite = () => {
        const tempElem = document.createElement('textarea');
        tempElem.value = document.location.href;
        document.body.appendChild(tempElem);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        addNotification('success', '', strings.url_copied)
    }

    const onShare = async () => {
        if (remoteScreenShare) {
            console.log('other user sharing');
            addNotification('warning', '', '다른 사용자가 화면공유를 하고 있습니다.')
            return;
        }

        if (screenShare) {
            // await channelParameters.screenTrack.replaceTrack(channelParameters.localVideoTrack, true);
            console.log('screenShare stopped')
            if (mine) {
                setOwnerVideoTrack(channelParameters.localVideoTrack);
            } else {
                setLocalVideoTrack(channelParameters.localVideoTrack);
            }

            await agoraEngine.unpublish(channelParameters.screenTrack);
            channelParameters.screenTrack.stop();
            channelParameters.screenTrack = null;

            if (channelParameters.localVideoTrack != null) {
                await agoraEngine.publish(channelParameters.localVideoTrack);
                if (mine) {
                    channelParameters.localVideoTrack.play('owner-player');
                } else {
                    channelParameters.localVideoTrack.play('local-player');
                }
            }

            setScreenShare(false);
        } else {
            channelParameters.screenTrack = await AgoraRTC.createScreenVideoTrack({
                displaySurface: 'window',
                selfBrowserSurface: 'exclude'
            });
            channelParameters.screenTrack.on('track-ended', async () => {
                console.log('screenTrack track-ended')
                if (mine) {
                    setOwnerVideoTrack(channelParameters.localVideoTrack);
                } else {
                    setLocalVideoTrack(channelParameters.localVideoTrack);
                }

                await agoraEngine.unpublish(channelParameters.screenTrack);
                channelParameters.screenTrack.stop();
                channelParameters.screenTrack = null;

                if (channelParameters.localVideoTrack != null) {
                    await agoraEngine.publish(channelParameters.localVideoTrack);
                    if (mine) {
                        channelParameters.localVideoTrack.play('owner-player');
                    } else {
                        channelParameters.localVideoTrack.play('local-player');
                    }
                }

                setScreenShare(false);

                // 화면공유 중지 상태를 방에 입장한 사용자들에게 알리기
                let message = {
                    room: roomId,
                    user: rootStore.getProfile.id,
                    status: 0
                }
                socket.emit("screen share", message);
            });

            if (channelParameters.localVideoTrack != null) {
                await agoraEngine.unpublish(channelParameters.localVideoTrack);
                channelParameters.localVideoTrack.stop();
                // await channelParameters.localVideoTrack.replaceTrack(channelParameters.screenTrack, true);
            }

            if (mine) {
                setOwnerVideoTrack(channelParameters.screenTrack);
            } else {
                setLocalVideoTrack(channelParameters.screenTrack);
            }

            // 화면공유 시작 상태를 방에 입장한 사용자들에게 알리기
            let message = {
                room: roomId,
                user: rootStore.getProfile.id,
                status: 1
            }
            socket.emit("screen share", message);

            setTimeout(async () => {
                await agoraEngine.publish(channelParameters.screenTrack);
            }, 2000);

            if (mine) {
                channelParameters.screenTrack.play('owner-player');
            } else {
                channelParameters.screenTrack.play('local-player');
            }

            setScreenShare(true);
            // setRemoteScreenShare(false);
            remoteScreenShare = false;
        }
    }

    const onSplit = async () => {
        if (boardSplit) {
            setBoardSplit(false);
        } else {
            setBoardSplit(true);
        }
    }

    function getAgoraToken(channelName) {
        context.get(
            'room/getAgoraRtcToken',
            {
                channelName: channelName
            },
            response => {
                setAgoraRtcToken(response.token);
                startMeeting(response.token)
            }
        );
    }

    function getWhiteBoardRoomToken() {
        console.log('meetingRole = ', meetingRole);
        context.get(
            'room/getWhiteBoardRoomToken',
            {
                role: 'writer', //meetingRole == 1 ? 'writer' : 'reader',
                uuid: boardUuid
            },
            response => {
                setBoardRoomToken(response.token);
                joinWhiteBoardRoom(response.token);
            }
        );
    }

    async function startMeeting(token) {
        let options = {
            appId: AGORA_APP_ID,
            channel: meetingNumber,
            token: token,
            uid: rootStore.getProfile.id,
            role: mine ? 'host' : 'audience'
        };

        console.log(options);
        localPlayerContainer.style.width = "100%";
        localPlayerContainer.style.height = "100%";

        agoraEngine.on("user-published", async (user, mediaType) => {
            await agoraEngine.subscribe(user, mediaType);
            console.log("subscribe success", user.uid);

            const memberIndex = getMemberIndex(user.uid);
            console.log('publish user id = ', memberIndex, roomInfo.user.id);

            if (remoteScreenShare && channelParameters.screenTrack == null) {
                console.log('publish screen share', user.uid);
                channelParameters.screenTrack = user.videoTrack
            }

            // 방장인 경우
            if (roomInfo.user.id == user.uid) {
                console.log('user-published host');
                setOwnerAudioTrack(user.audioTrack);
                setOwnerVideoTrack(user.videoTrack);
            } else { // 방장이 아닌 경우
                console.log('user-published audience');
                if (memberIndex >= 0) {
                    setMemberList(prevList => prevList.map((item, idx) => {
                        if (item.member == user.uid) {
                            item.videoTrack = mediaType == 'video' ? user.videoTrack : item.videoTrack;
                            item.audioTrack = mediaType == 'audio' ? user.audioTrack : item.audioTrack;
                            item.muteVideo = item.user.video == "OFF";;
                            item.muteAudio = false;
                            item.reverseCam = item.user.mirror == "ON";
                        }
                        return item;
                    }))
                } else {
                    setMemberList([...listRef.current, {
                        user: {},
                        member: user.uid,
                        videoTrack: mediaType == 'video' ? user.videoTrack : null,
                        audioTrack: mediaType == 'audio' ? user.audioTrack : null,
                        muteVideo: false,
                        muteAudio: false,
                        reverseCam: false,
                    }]);
                }
            }

            agoraEngine.on("user-unpublished", user => {
                console.log(user.uid + "has left the channel");
                // 방장인 경우
                if (roomInfo.user.id == user.uid) {
                    if (mediaType == 'video') {
                        setOwnerAudioTrack(null);
                    }
                    if (mediaType == 'audio') {
                        setOwnerVideoTrack(null);
                    }
                } else { // 방장이 아닌 경우
                    setMemberList(prevList => prevList.map((item, idx) => {
                        console.warn('unpublish user', item);
                        if (item.member == user.uid) {
                            if (mediaType == 'video') {
                                item.videoTrack = null;
                            }
                            if (mediaType == 'audio') {
                                item.audioTrack = null
                            }
                        }
                        return item;
                    }))
                }
            });
        });

        agoraEngine.setClientRole(options.role);

        await agoraEngine.join(options.appId, options.channel, options.token, options.uid);

        channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
            // optimizationMode: 'motion',
            encoderConfig: '720p'
        });

        if (mine) {
            setOwnerAudioTrack(channelParameters.localAudioTrack);
            setOwnerVideoTrack(channelParameters.localVideoTrack);
        } else {
            setLocalAudioTrack(channelParameters.localAudioTrack);
            setLocalVideoTrack(channelParameters.localVideoTrack);
        }

        // document.querySelector('#local-player').append(localPlayerContainer);

        if (options.role == 'host') {
            let publishTracks = [channelParameters.localVideoTrack];
            if (speakerSetting) {
                publishTracks.push(channelParameters.localAudioTrack);
            }
            await agoraEngine.publish(publishTracks);

            if (videoSetting) {
                if (mine) {
                    channelParameters.localVideoTrack.play('owner-player');
                } else {
                    channelParameters.localVideoTrack.play('local-player');
                }
            }

            channelParameters.localAudioTrack.stop();
            // if (speakerSetting) {
            //     channelParameters.localAudioTrack.play();
            // }
            console.log("publish success!");
        }
    }

    async function joinWhiteBoardRoom(roomToken) {
        fastboard = await createFastboard({
            // [1]
            sdkConfig: {
                appIdentifier: AGORA_WHITEBOARD_APP_IDENTIFIER,
                region: "sg", // "cn-hz" | "us-sv" | "sg" | "in-mum" | "gb-lon"
            },
            // [2]
            joinRoom: {
                uid: '' + rootStore.getProfile?.id,
                uuid: boardUuid,
                roomToken: roomToken,
                // (optional)
                userPayload: {
                    nickName: "foo",
                },
            },
            // [3] (optional)
            managerConfig: {
                cursor: true,
            },
            // [4] (optional)
            // netlessApps: [],
        });

        fastboardUI = createUI(fastboard, document.getElementById("whiteboardSDKElement"));

        apps.clear();
        apps.push({
            icon: "https://chewing1.com/assets/image/icon_file_upload.png",
            kind: "Plyr",
            label: "Upload",
            onClick: (app) => {
                console.log('upload button clicked!');
                // app.insertImage("https://api.chewing1.com/uploads/room/04.png")
                const docElement = document.querySelector('.telebox-box');
                if (docElement) {
                    document.querySelector('.telebox-box .telebox-titlebar .telebox-titlebar-icon-close').click();
                } else {
                    fileRef?.current.click();
                }
            }
        });

        isRecording = true;
        startDrawing();
        recorder.start();
    }

    const onFileChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            uploadDocFile(reader.result, file);
        };
        reader.readAsDataURL(file);
    };

    const uploadDocFile = (file, url) => {
        let formData = new FormData();
        formData.append('file', url);

        context.post(
            "upload/file/whiteboard",
            formData,
            response => {
                let uploadedUrl = response.file;
                context.toggleLoadingBar(true);
                getWhiteBoardToken(uploadedUrl);
            }
        );
    }

    // 파일변환을 위한 화이트보드 토큰을 요청하는 함수
    const getWhiteBoardToken = async (url) => {
        const result = await axios.post("https://api.netless.link/v5/tokens/teams", {
            "accessKey": AGORA_WHITEBOARD_AK,
            "secretAccessKey": AGORA_WHITEBOARD_SK,
            "lifespan": 0,
            "role": "admin"
        }, {
            headers: {
                "region": AGORA_WHITEBOARD_REGION,
                'Content-Type': 'application/json'
            }
        });

        if (result.status == 201) {
            const token = result.data;
            convertDocFile(url, token);
        } else {
            // 오류 발생
            context.toggleLoadingBar(false);
        }
    }

    // 서버에 업로드한 파일을 페이지별로 이미지로 변환 요청하는 함수
    const convertDocFile = async (url, token) => {
        const result = await axios.post("https://api.netless.link/v5/projector/tasks", {
            "resource": context.loadImage(url),
            "type": "static",
            "scale": 3,
        }, {
            headers: {
                "token": token,
                "region": AGORA_WHITEBOARD_REGION,
                'Content-Type': 'application/json'
            }
        });

        if (result.status == 201) {
            const uuid = result.data.uuid;
            setTimeout(() => {
                getConvertedList(token, uuid);
            }, 5000);
        } else {
            // 오류 발생
            context.toggleLoadingBar(false);
        }
    }

    // 변환된 이미지 목록을 가져오는 함수
    const getConvertedList = async (token, uuid) => {
        const result = await axios.get("https://api.netless.link/v5/projector/tasks/" + uuid, {
            headers: {
                "token": token,
                "region": AGORA_WHITEBOARD_REGION,
                'Content-Type': 'application/json'
            }
        });

        if (result.status == 200) {
            // 결과 데이터 샘플
            // {
            //     "uuid": "a66d69640fa44fa7826fe10cbdf732d1",
            //     "type": "static",
            //     "status": "Finished",
            //     "convertedPercentage": 100,
            //     "pageCount": 101,
            //     "images": {
            //         "1": {
            //             "width": 648,
            //             "height": 799,
            //             "url": "https://chewing.s3.ap-northeast-2.amazonaws.com/staticConvert/a66d69640fa44fa7826fe10cbdf732d1/1.png"
            //         },
            //         "2": {
            //             "width": 648,
            //             "height": 799,
            //             "url": "https://chewing.s3.ap-northeast-2.amazonaws.com/staticConvert/a66d69640fa44fa7826fe10cbdf732d1/2.png"
            //         },
            //     }
            // }
            if (result.data.status == 'Converting') {
                setTimeout(() => {
                    getConvertedList(token, uuid);
                }, 5000);
            } else if (result.data.status == 'Finished') {
                context.toggleLoadingBar(false);
                showDocs(uuid, result.data.images, result.data.pageCount);
            }
        } else {
            // 오류 발생
            context.toggleLoadingBar(false);
        }
    }

    const showDocs = async (uuid, images, pageCount) => {

        let scenes = [];
        for (let idx = 1; idx <= pageCount; idx++) {
            const key = `'${idx}'`;
            const imageData = images[idx];

            // Scene Template
            // {
            //     name: '1',
            //     ppt: {
            //         width: 648,
            //         height: 799,
            //         src: 'https://chewing.s3.ap-northeast-2.amazonaws.com/staticConvert/a66d69640fa44fa7826fe10cbdf732d1/1.png',
            //     }
            // },

            let scene = {
                name: key,
                ppt: {
                    width: imageData.width,
                    height: imageData.height,
                    src: imageData.url,
                }
            }

            scenes.push(scene);
        }

        await fastboard.insertDocs({
            title: '',
            fileType: 'pdf',
            scenePath: '/pdf/' + uuid,     //  '/pdf/a66d69640fa44fa7826fe10cbdf732d1',
            scenes: scenes,
        })
    }

    return (
        <React.Fragment>
            <HeaderBlack
                roomTime={getTimeFromSecond(roomTime)}
                realTimeshow={false}
            />
            <div className="display-flex-important" id='conferenceRoom'>
                <ConferenceRight
                    memberList={chatMemberList}
                    chatList={chatList}
                    onSend={(chat) => onSend(chat)}
                    reaction={reaction}
                    rand={rand}
                    mine={mine}
                    ownerInfo={ownerInfo}
                    videoTrack={ownerVideoTrack}
                    audioTrack={ownerAudioTrack}
                    muteVideo={!videoSetting}
                    muteAudio={false}
                    reverseCamera={cameraSetting}
                    profile={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/logo_big.png"}
                    showBoard={boardUuid != ''}
                    partner={selectedChatMember}
                    onSelectPartner={(idx) => onSelectPartner(idx)}
                />
                <ConferenceTop
                    mine={mine}
                    initHide={memberList.length == 0}
                    memberList={memberList}
                    localVideoTrack={localVideoTrack}
                    localAudioTrack={localAudioTrack}
                    reaction={reaction}
                    muteVideo={!videoSetting}
                    reverseCamera={cameraSetting}
                    name={rootStore.getProfile.name}
                    profile={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/logo_big.png"}
                    splitBoard={boardSplit}
                    screenTrack={channelParameters.screenTrack}
                    screenShare={remoteScreenShare}
                    myShare={screenShare}
                    isStudent={rootStore.getProfile?.user_type == 'STUDENT'}
                    showBoard={boardUuid != ''}
                />
                <ConferenceBottom
                    roomInfo={roomInfo}
                    mine={mine}
                    memberList={memberList}
                    onSetting={() => setShowSetting(true)}
                    onOut={() => onOut()}
                    owner={owner}
                    participants={memberList.length + 1}
                    title={title}
                    onClickReaction={(item) => onClickReaction(item)}
                    onInvite={() => onInvite()}
                    onKick={(item) => onKick(item)}
                    onInfo={(item) => {
                        setMemberInfo(item);
                        setShowInfoModal(true)
                    }}
                    onShare={() => {
                        onShare();
                    }}
                    onSplit={() => {
                        onSplit();
                    }}
                />
                <input ref={fileRef} type='file' accept='application/pdf' className='hide' onChange={(e) => onFileChange(e)} />
                <video ref={screenShareVideoRef} autoPlay playsInline muted style={{ width: '95%', height: '95%', position: 'absolute', left: 0, top: 0, zIndex: -1 }} />
                {
                    showReaction &&
                    <div className="reaction-alarm">
                        <div className="reaction-alarm-content">
                            <img src={reaction.icon} alt="" />
                            {reaction.desc}
                        </div>
                    </div>
                }
                <NewSettingModal
                    show={showSetting}
                    toggle={() => setShowSetting(!showSetting)}
                    mine={mine}
                    onScreenSettings={() => {
                        setShowSetting(false);
                        setShowSettingModal(true);
                    }}
                    onRoomInfo={() => {
                        setShowSetting(false);
                        setShowRoomInfo(true);
                    }}
                />
                <RoomInfoModal
                    show={showRoomInfo}
                    mine={mine}
                    toggle={() => setShowRoomInfo(!showRoomInfo)}
                    onBack={() => {
                        setShowRoomInfo(false);
                        setShowSetting(true);
                    }}
                    roomInfo={roomInfo}
                    onUpdate={() => getRoomDetail()}
                />
                <MemberInfoModal
                    show={showInfoModal}
                    toggle={() => setShowInfoModal(!showInfoModal)}
                    memberInfo={memberInfo}
                />
                {
                    showSettingModal &&
                    <ScreenSettingModal
                        show={showSettingModal}
                        name={userName}
                        profile={profile}
                        cameraType={'전면'}
                        toggle={() => setShowSettingModal(!showSettingModal)}
                        close={() => setShowSettingModal(false)}
                        video={videoSetting}
                        speaker={speakerSetting}
                        reverse={cameraSetting}
                        onConfirm={(video, speaker, reverse) => {
                            onSettingConfirm(video, speaker, reverse)
                        }}
                        onBack={() => {
                            setShowSettingModal(false);
                            setShowSetting(true);
                        }}
                    />
                }
                {(leaveAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.leave}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black"
                        onConfirm={() => leavePage()}
                        onCancel={() => toggleSweetAlert()}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {'나가시겠습니까?'}
                    </SweetAlert>
                )}
            </div>
        </React.Fragment>
    );
})

export default withRouter(inject('rootStore')(ChallengeRoom));

import {inject} from 'mobx-react';
import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import {Page_settings} from '../../config/page_settings';
import strings from '../../lang/strings';
import {rootStore} from "../../mobx/store";
import {extractNumber, getMinuteFromSecond} from "../../helper/common";


const Find = (props) => {
    const context = useContext(Page_settings);
    const [type, setType] = useState(parseInt(props.match.params.type));
    const [idAuth, setIdAuth] = useState(0);
    const [passwordAuth, setPasswordAuth] = useState(0);
    const [resultId, setResultId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [phonePwd, setPhonePwd] = useState("");
    const [code, setCode] = useState("");
    const [codePwd, setCodePwd] = useState("");
    const [phoneSentPwd, setPhoneSentPwd] = useState(false);
    const [phoneSent, setPhoneSent] = useState(false);
    const [codeCheck, setCodeCheck] = useState(false);
    const [codeCheckPwd, setCodeCheckPwd] = useState(false);
    const [countDown, setCountDown] = useState(180);
    const [countDownPwd, setCountDownPwd] = useState(180);
    const [tick, setTick] = React.useState(0);
    const [tickPwd, setTickPwd] = React.useState(0);


    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
        }
    }, []);

    useEffect(() => {
        if (countDown == 0) {
            setCountDown(180)
            clearInterval(tick)
        }
    }, [countDown])

    const onFindId = () => {
        if (phone == "" || code == "") return
        if (idAuth == 0) {
            context.post(
                "auth/findEmail",
                {
                    phone: phone,
                    auth_code: code
                },
                response => {
                    setResultId(response.email);
                    setIdAuth(1)
                }
            );
        } else {
            props.history.push("/login")
        }

    }

    const onFindPwd = () => {
        if (phone == "" || code == "" || email == "") return
        if (passwordAuth == 0) {
            context.post(
                "auth/findPwd",
                {
                    email: email,
                    phone: phonePwd,
                    auth_code: codePwd
                },
                response => {
                    setPassword(response.tmp_pwd);
                    setPasswordAuth(1)
                }
            );
        } else {
            props.history.push("/login")
        }
    }

    const onSend = () => {
        if (phone == "") {
            return
        }
        context.post(
            "auth/requestAuthCode",
            {
                phone: phone,
            },
            response => {
                setPhoneSent(true);
                setCodeCheck(false)
                setCountDown(180);
                clearInterval(tick)
                setTick(setInterval(() => {
                    setCountDown(prevTime => prevTime - 1);
                }, 1000));
            }
        );

    }
    const onSendPwd = () => {
        if (phonePwd == "") {
            return
        }
        context.post(
            "auth/requestAuthCode",
            {
                phone: phonePwd,
            },
            response => {
                setPhoneSentPwd(true);
                setCodeCheckPwd(false)
                setCountDownPwd(180);
                clearInterval(tickPwd)
                setTickPwd(setInterval(() => {
                    setCountDownPwd(prevTime => prevTime - 1);
                }, 1000));
            }
        );

    }

    return (
        <React.Fragment>
            <div id="header" className="header navbar-default login-header">
                <div className="navbar-header">
                    <img className="logo" src={'/assets/image/logo_black.png'} alt=""/>
                </div>
            </div>

            <div className="login-container">
                <div className="login-title">{strings.find_account}</div>
                <div className="login-content">
                    <div className="d-flex">
                        <button
                            className={("find-id ") + (type == 0 ? "find-id-active" : "")}
                            onClick={() => setType(0)}
                        >{strings.find_id}</button>
                        <button
                            className={("find-id find-password ") + (type == 1 ? "find-id-active" : "")}
                            onClick={() => setType(1)}
                        >{strings.find_password}</button>
                    </div>
                    <div className="id-info">
                        {
                            type == 0 && idAuth == 0 &&
                            strings.you_can_check_id
                        }
                        {
                            type == 0 && idAuth == 1 &&
                            <div>
                                {strings.this_is_your_id}
                                <div className="result-id">{resultId}</div>
                            </div>
                        }
                        {
                            type == 1 && passwordAuth == 0 &&
                            <div>
                                {strings.enter_registered_id}
                            </div>
                        }
                        {
                            type == 1 && passwordAuth == 1 &&
                            <div>
                                {strings.this_is_temporary_password}
                                <div className="temp-password">{password}</div>
                            </div>
                        }

                    </div>
                    {
                        type == 1 && passwordAuth == 0 &&
                        <div>
                            <input
                                className="login-input m-b-10"
                                type="text"
                                autoComplete={'off'}
                                placeholder={strings.enter_your_email}
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                }}/>
                            <div className="position-relative">
                                <input
                                    className="login-input m-b-10"
                                    type="text"
                                    autoComplete={'off'}
                                    placeholder={strings.enter_phone}
                                    value={phonePwd}
                                    onChange={e => {
                                        setPhonePwd(extractNumber(e.target.value));
                                    }}/>
                                <button className={("phone-send ") + (phonePwd != "" ? "send-active" : "")}
                                        onClick={() => onSendPwd()}>{phoneSentPwd ? strings.re_send : strings.send}</button>
                            </div>
                            <div className="position-relative">
                                <input type="text"
                                       className="login-input m-b-25"
                                       placeholder={strings.enter_code}
                                       value={codePwd}
                                       onChange={e => {
                                           setCodePwd(extractNumber(e.target.value));
                                       }}
                                       disabled={codeCheckPwd || !phoneSentPwd}/>
                                {
                                    phoneSentPwd && !codeCheckPwd &&
                                    <div className="d-flex align-item-center">
                                        <div className="count-down"
                                             style={{right: 20}}>{getMinuteFromSecond(countDownPwd)}</div>
                                    </div>

                                }
                            </div>
                        </div>

                    }
                    {
                        type == 0 && idAuth == 0 &&
                        <div>
                            <div className="position-relative">
                                <input
                                    className="login-input m-b-10"
                                    type="text"
                                    autoComplete={'off'}
                                    placeholder={strings.enter_phone}
                                    value={phone}
                                    onChange={e => {
                                        setPhone(extractNumber(e.target.value));
                                    }}/>
                                <button className={("phone-send ") + (phone != "" ? "send-active" : "")}
                                        onClick={() => onSend()}>{phoneSent ? strings.re_send : strings.send}</button>
                            </div>
                            <div className="position-relative">
                                <input type="text"
                                       className="login-input m-b-25"
                                       placeholder={strings.enter_code}
                                       value={code}
                                       onChange={e => {
                                           setCode(extractNumber(e.target.value));
                                       }}
                                       disabled={codeCheck || !phoneSent}/>
                                {
                                    phoneSent && !codeCheck &&
                                    <div className="d-flex align-item-center">
                                        <div className="count-down"
                                             style={{right: 20}}>{getMinuteFromSecond(countDown)}</div>
                                    </div>

                                }
                            </div>
                        </div>


                    }
                    {
                        type == 0 &&
                        <button className="auth-phone"
                                onClick={() => onFindId()}>{idAuth == 0 ? strings.authenticate_phone : strings.go_login}</button>
                    }
                    {
                        type == 1 &&
                        <button className="auth-phone"
                                onClick={() => onFindPwd()}>{passwordAuth == 0 ? strings.authenticate_email : strings.go_login}</button>
                    }
                </div>

            </div>
        </React.Fragment>
    );
}

export default withRouter(inject('rootStore')(Find));

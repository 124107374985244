import React, {useContext, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Page_settings} from "../config/page_settings";


const ChatDropdown = (props) =>{
    const [dropdownOpen, setDropdownOpen] = useState(false);


    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const context = useContext(Page_settings);
    return (
            <Dropdown isOpen={dropdownOpen} toggle={()=>toggle()}
                      className="chat-dropdown">
                <DropdownToggle tag="a">
                    <div className="chat-member-count">
                        <img src={"/assets/image/icon_user_white.png"} alt=""/>
                        <div className="p-r-5" style={{ lineHeight: '16px' }}>{props.memberList.length}</div>
                        {
                            !dropdownOpen &&
                            <img className="triangle" src={"/assets/image/icon_triangle_down.png"} alt=""/>
                        }
                        {
                            dropdownOpen &&
                            <img className="triangle" src={"/assets/image/icon_triangle_up.png"} alt=""/>
                        }
                    </div>
                </DropdownToggle>
                <DropdownMenu className="media-list dropdown-menu chat-menu" tag="ul">
                    {
                        props.memberList.map((item, idx) =>
                            <DropdownItem onClick={() => props.onSelect(idx)} key={idx}>
                                <div className={("chat-dropdown-item ") + (props.partner == idx ? "item-selected" : "")}>
                                    <img className="user-image" src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"} alt=""/>
                                    {
                                        props.dotShow &&
                                        <div className={("online ") + (item.status == 1 ? "offline ":"") + (item.unread != 0 ? "unread":"")}/>

                                    }
                                    <div className="f-w-100">
                                        <div className="user-name text-ellipsis">{item.user.name}</div>
                                        <div className="school text-ellipsis">{item.user.school}</div>
                                    </div>
                                    {
                                        props.partner == idx &&
                                        <img className="selected-user" src={"/assets/image/icon_account_box.png"} alt=""/>
                                    }
                                </div>
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </Dropdown>
        );
}

export default ChatDropdown;

import React from 'react';
import {Link, Route} from 'react-router-dom';
import { rootStore } from '../../mobx/store';

class SidebarNavList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: -1,
            clicked: -1
        };
    }

    handleExpand(e, i, match) {
        e.preventDefault();
        this.setState(state => ({
            active: (state.active == i ? -1 : i),
            clicked: 1
        }));
    }

    render() {
        const userType = rootStore.getProfile?.user_type;
        if (userType === 'STUDENT' && this.props.data.path === '/class') {
            return null;
        }
        let icon_active = this.props.data.icon_active &&
            <img className="menu-icon" src={this.props.data.icon_active} alt="" style={{ marginLeft:18 }} />;
        let icon_inactive = this.props.data.icon_inactive &&
            <img className="menu-icon" src={this.props.data.icon_inactive} alt="" style={{ marginLeft:18 }} />;
        let label = this.props.data.label && <span className="label label-theme m-l-5">{this.props.data.label}</span>;
        let badge = this.props.data.badge && <span className="badge pull-right">{this.props.data.badge}</span>;
        let title = this.props.data.title && <span>{this.props.data.title} {label}</span>;
        let highlight = this.props.data.highlight && <i className="fa fa-paper-plane text-theme"/>;

        return (
            <Route path={this.props.data.path} exact={this.props.data.exact} children={({match}) => (
                <li
                    className={(match ? "active " : "") + ((this.props.active || (this.props.clicked == -1 && match) || this.props.data.search) ? 'expand ' : 'closed ') + (this.props.data.children ? "has-sub " : "")}>
                    {this.props.data.children ? (
                        <Link to={this.props.data.path}
                              onClick={this.props.expand}> {badge} {match ? icon_active : icon_inactive} {title} {highlight}</Link>
                    ) : (
                        <Link
                            to={this.props.data.path} 
                            onClick={this.props.sub ? ()=>{} : this.props.expand}>  {match ? icon_active : icon_inactive} {badge} {title} {highlight}</Link>
                    )}
                    {this.props.data.children && (
                        <ul
                            className={"sub-menu " + (((this.props.active || (this.props.clicked == -1 && match) || this.props.data.search)) ? 'd-block ' : 'd-none')}>
                            {this.props.data.children && this.props.data.children.map((submenu, i) => (
                                <SidebarNavList
                                    data={submenu}
                                    key={i}
                                    sub={true}
                                    expand={(e) => this.handleExpand(e, i, match)}
                                    active={i == this.state.active}
                                    clicked={this.state.clicked}
                                />
                            ))}
                        </ul>
                    )}
                </li>
            )}/>
        );
    }
}

export default SidebarNavList;

import strings from "../../lang/strings";
import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Store } from 'react-notifications-component';
import RoadmapViewModal from "../../components/control/modal/roadmap_view_modal";
import { getAchievementRate } from "../../helper/common";
import YoutubeViewModal from "../../components/control/modal/youtube_view_modal";
import RoadmapSolveModal from "../../components/control/modal/roadmap_solve_modal";
import CloudContainer from "../cabinet/cloud_container";

const RoadMap = (props) => {
    const context = useContext(Page_settings);
    const [userId, setUserId] = useState(-1);
    const [coachList, setCoachList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [expandSubject, setExpandSubject] = useState(false);
    const [detailSubject, setDetailSubject] = useState(1);
    const [roadMapList, setRoadMapList] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemTestScore, setSelectedItemTestScore] = useState(0);
    const [selectedItemCourseList, setSelectedItemCourseList] = useState([]);
    const [showVideo, setShowVideo] = useState(false);
    const [showProblem, setShowProblem] = useState(false);
    const [showGuide, setShowGuide] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const scrollRef = useRef();
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    useEffect(() => {
        setUserId(rootStore.getProfile?.id ?? 0);
    }, [rootStore.getProfile]);

    useEffect(() => {
        loadCoachList("");
    }, []);

    const loadCoachList = (keyword) => {
        context.get(
            "coach/getCoachList",
            {
                keyword: keyword
            },
            response => {
                const list = response.list;
                setCoachList(list);
                setSelectedSubject(list[0]);
                loadRoadmapList(list[0], 1);
            }
        );
    }

    const loadRoadmapList = (coach, type) => {
        context.get(
            "coach/getRoadmapData",
            {
                coach,
                type
            },
            response => {
                const list = response.list;
                setRoadMapList(list);
            }
        );
    }

    const onClickSubject = (item) => {
        setSelectedSubject(item);
        setDetailSubject(1);
        loadRoadmapList(item.id, 1);
    }

    const onClickSubItem = (middleItem, item) => {
        context.get(
            "coach/getStudyData",
            {
                code: middleItem.code
            },
            response => {
                const list = response.list;
                setSelectedItemCourseList(list);
                setShowDetail(true);
            }
        );
        setSelectedItemTestScore(middleItem.score);
        setSelectedItem(item);
    }

    const onWatchVideo = () => {
        if (selectedItem.url == null || selectedItem.url == '') {
            alert("영상 준비중입니다.");
            return;
        }
        setShowVideo(true);
        studySubsection('WATCH');
    }

    const onSolve = async () => {
        const url = context.loadImage('uploads/aistudy/p_' + selectedItem.code + '.pdf');
        const existResult = await fetch(url, { method: 'HEAD' });
        if (existResult.ok) {
            setShowProblem(true);
            studySubsection('SOLVE');
        } else {
            alert("문제 준비중입니다.");
        }
    }

    const studySubsection = (type) => {

        context.post(
            "coach/studySubsection",
            {
                code: selectedItem.code,
                type
            },
            response => {
                console.log('studySubsection Success');
            }
        );
    }

    const onCourseItem = (course) => {
        const code = course.code.split('_')[1];
        context.get(
            "coach/getSubsection",
            {
                code
            },
            response => {
                const { item, score, parent_code } = response;

                onClickSubItem({ code: parent_code, score }, item);
            }
        );
    }

    const handleDragStart = (e) => {
        const startX = e.pageX - scrollRef.current._container.offsetLeft;
        const startY = e.pageY - scrollRef.current._container.offsetTop;
        const scrollLeft = scrollRef.current._container.scrollLeft;
        const scrollTop = scrollRef.current._container.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true);
    }

    const handleDragEnd = (e) => {
        setIsMouseDown(false);
    }

    const handleDrag = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current._container.offsetLeft;
        const y = e.pageY - scrollRef.current._container.offsetTop;
        const walkX = x - mouseCoords.current.startX;
        const walkY = y - mouseCoords.current.startY;
        scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    return (
        <div className="main-content roadmap-container">
            <CloudContainer/>
            <div className="roadmap">
                <div className="width-full height-full" style={{ marginLeft: 0, marginRight: 0, display: "flex", flexDirection: 'column' }}>
                    <div className="width-full subject-list">
                        <div className="selected-subject">{"AI " + (selectedSubject.name == undefined ? '' : selectedSubject.name)}</div>
                        {
                            expandSubject &&
                            <div className="subject_wrapper">
                                <PerfectScrollbar options={{ suppressScrollY: true }}>
                                    <div className="subjects">
                                        {
                                            coachList.length != 0 &&
                                            coachList.map((item, idx) => {
                                                return (
                                                    <button key={idx} className="subject-item" onClick={() => onClickSubject(item)}>
                                                        <img className="subject-image"
                                                            src={item.image != null ? context.loadImage(item.image) : "/assets/image/logo_big.png"}
                                                            alt="" />
                                                        <div
                                                            className="subject-name">{"AI " + item.name}</div>
                                                    </button>
                                                )
                                            })
                                        }

                                    </div>
                                </PerfectScrollbar>
                            </div>
                        }
                        <button className="expand-button" onClick={() => setExpandSubject(!expandSubject)}>
                            <img src={"/assets/image/" + (expandSubject ? "btn_collapse_arrow" : "btn_expand_arrow") + ".png"} alt="" />
                        </button>
                    </div>
                    {
                        selectedSubject.name == '수학' &&
                        <div className="width-full subject-detail">
                            <button className={"subject-detail-item" + (detailSubject == 1 ? " selected" : "")} onClick={() => {
                                setDetailSubject(1);
                                loadRoadmapList(selectedSubject.id, 1);
                            }}>
                                {'수연산'}
                            </button>
                            <button className={"subject-detail-item" + (detailSubject == 2 ? " selected" : "")} onClick={() => {
                                setDetailSubject(2);
                                loadRoadmapList(selectedSubject.id, 2);
                            }}>
                                {'문자와식'}
                            </button>
                            <button className={"subject-detail-item" + (detailSubject == 3 ? " selected" : "")} onClick={() => {
                                setDetailSubject(3);
                                loadRoadmapList(selectedSubject.id, 3);
                            }}>
                                {'함수'}
                            </button>
                            <button className={"subject-detail-item" + (detailSubject == 4 ? " selected" : "")} onClick={() => {
                                setDetailSubject(4);
                                loadRoadmapList(selectedSubject.id, 4);
                            }}>
                                {'기하'}
                            </button>
                            <button className={"subject-detail-item" + (detailSubject == 5 ? " selected" : "")} onClick={() => {
                                setDetailSubject(5);
                                loadRoadmapList(selectedSubject.id, 5);
                            }}>
                                {'확률과 통계'}
                            </button>
                            <div style={{ marginLeft: 'auto', position: 'relative' }}>
                                <button style={{ border: 'none', background: 'transparent' }} onClick={() => setShowGuide(!showGuide)}>
                                    <img src={"/assets/image/btn_guide.png"} alt="" />
                                </button>
                                {
                                    showGuide &&
                                    <div className="d-flex justify-content-center flex-column" style={{ position: 'absolute', right: 8, bottom: 19, padding: '12px 18px', width: 129, borderRadius: 10, background: 'white', boxShadow: '2px 4px 10px 0px rgba(103, 103, 103, 0.25)' }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: 14, height: 14, borderRadius: 2, background: '#04C886' }}/>
                                            <div style={{ color: '#222', fontSize: 12, fontStyle: 'normal', fontWeight: 500, lineHeight: '18px', letterSpacing: -0.24, marginLeft: 8}}>100% 성취도</div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{ marginTop: 6 }}>
                                            <div style={{ width: 14, height: 14, borderRadius: 2, background: '#4B596D' }}/>
                                            <div style={{ color: '#222', fontSize: 12, fontStyle: 'normal', fontWeight: 500, lineHeight: '18px', letterSpacing: -0.24, marginLeft: 8}}>1~99% 성취도</div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{ marginTop: 6 }}>
                                            <div style={{ width: 14, height: 14, borderRadius: 2, background: '#E6EBF3' }}/>
                                            <div style={{ color: '#222', fontSize: 12, fontStyle: 'normal', fontWeight: 500, lineHeight: '18px', letterSpacing: -0.24, marginLeft: 8}}>0% 성취도</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="width-full roadmap-wrapper" style={{ marginTop: 35 }}>
                        <PerfectScrollbar ref={scrollRef} style={{ height: '100%', cursor: "grab" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
                            {
                                roadMapList.map((mapItem, index) => (
                                    <div key={index} className="d-flex align-items-center" style={{ marginTop: 53 }}>
                                        <div className="roadmap-subject-title">{mapItem.name}</div>
                                        {
                                            mapItem.list.map((subItem, index) => (
                                                <div key={index} className="roadmap-middle-item" style={{ marginRight: (index < mapItem.list.length - 1) ? 18 : 0 }}>
                                                    <div className="name">{subItem.name}</div>
                                                    {
                                                        subItem.list.map((studyItem, index) => (
                                                            <div key={index} className={"sub-item" + (getAchievementRate(subItem.testScore, studyItem.watch_count, studyItem.solve_count) == 100 ? ' done' : getAchievementRate(subItem.testScore, studyItem.watch_count, studyItem.solve_count) > 0 ? ' progress' : '')}
                                                                style={{ marginRight: (index < subItem.list.length - 1) ? 16 : 0 }} onClick={() => onClickSubItem(subItem, studyItem)}>
                                                                {studyItem.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
            {
                showDetail &&
                <RoadmapViewModal
                    show={showDetail}
                    toggle={() => setShowDetail(!showDetail)}
                    onView={() => {
                        setShowDetail(false);
                        onWatchVideo();
                    }}
                    onSolve={() => {
                        setShowDetail(false);
                        onSolve();
                    }}
                    onCourseItem={(course) => {
                        setShowDetail(false);
                        onCourseItem(course);
                    }}
                    item={selectedItem}
                    testScore={selectedItemTestScore}
                    courseList={selectedItemCourseList}
                />
            }
            {
                showVideo &&
                <YoutubeViewModal
                    show={showVideo}
                    toggle={() => {
                        setShowVideo(!showVideo);
                        loadRoadmapList(selectedSubject.id, detailSubject);
                    }}
                    title={selectedItem.name}
                    url={selectedItem.url}
                />
            }
            {
                showProblem &&
                <RoadmapSolveModal
                    show={showProblem}
                    toggle={() => {
                        setShowProblem(!showProblem);
                        loadRoadmapList(selectedSubject.id, detailSubject);
                    }}
                    item={selectedItem}
                />
            }
        </div>
    );
}
export default withRouter(RoadMap);

import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import ClinicUser from "./clinic_user";
import {rootStore} from "../../mobx/store";
import {observer} from "mobx-react";
import ClinicExpert from "./clinic_expert";
import CloudContainer from "../cabinet/cloud_container";
import { Page_settings } from "../../config/page_settings";

const Clinic = observer((props) => {
    const context = useContext(Page_settings);

    const [userType, setUserType] = useState("");
    const [userName, setUserName] = useState("");
    const [profile, setProfile] = useState("");
    const [title, setTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [score, setScore] = useState(0);
    const [review, setReview] = useState(0);

    useEffect(() => {
        context.setContentBgType(2);
        
        const userType = rootStore.getProfile?.user_type;
        const navIndex = userType === 'STUDENT' ? 1 : 2;

        document.querySelectorAll('#sidebar .nav>li')[navIndex].classList.add('active');
        document.querySelectorAll('#sidebar .nav>li')[navIndex].getElementsByTagName('img')[0].src = '/assets/image/icon_menu_chewingchat_active.png';

        return () => {
            context.setContentBgType(1);
            document.querySelectorAll('#sidebar .nav>li')[navIndex].classList.remove('active');
            document.querySelectorAll('#sidebar .nav>li')[navIndex].getElementsByTagName('img')[0].src = '/assets/image/icon_menu_chewingchat_inactive.png';
        }
    }, []);

    useEffect(() => {
        setUserType(rootStore.getProfile?.user_type ?? "STUDENT");
        setUserName(rootStore.getProfile?.name ?? "");
        setProfile(rootStore.getProfile?.profile ?? "");
        setTitle(rootStore.getProfile?.title ?? "");
        setDepartment(rootStore.getProfile?.department ?? "");
        setScore(rootStore.getProfile?.score ?? "");
        setReview(rootStore.getProfile?.review_count ?? "");
    }, [rootStore.getProfile]);

    return (
        <div className="row">
            <CloudContainer />
            {
                userType == "STUDENT" &&
                <ClinicUser/>
            }
            {
                userType == "EXPERT" &&
                <ClinicExpert
                    userName={userName}
                    profile={profile}
                    title={title}
                    department={department}
                    score={score}
                    review={review}
                />
            }
        </div>


    )


});
export default withRouter(Clinic);

import React, {useContext, useEffect, useRef, useState} from "react";
import { withRouter } from "react-router-dom";
import MissionComponent from './mission_component';
import strings from "../../lang/strings";
import {Page_settings} from "../../config/page_settings";

const Multi = (props) => {

    const context = useContext(Page_settings);

    const [studyMissionList, setStudyMissionList] = useState([]);
    const [plannerMissionList, setPlannerMissionList] = useState([]);
    const [cabinetMissionList, setCabinetMissionList] = useState([]);
    const periodList = ["HOUR", "DAY", "MONTH"];
    const [period, setPeriod] = useState(0);

    useEffect(() => {
        getMissionList("");
    }, []);

    useEffect(() => {
        getCompareData(periodList[period]);
    }, [studyMissionList, cabinetMissionList, plannerMissionList, period])


    const getMissionList = (type) => {
        let studyList = [];
        let cabinetList = [];
        let plannerList = [];
        context.get(
            "member/getMissionList",
            {
                type: type
            },
            response => {
                response.list.forEach(item => {
                    if (item.type == "STUDY") {
                        studyList.push(item)
                    } else if (item.type == "CABINET") {
                        cabinetList.push(item)
                    } else if (item.type == "CLINIC") {
                        plannerList.push(item)
                    }
                });
                setStudyMissionList(studyList);
                setCabinetMissionList(cabinetList);
                setPlannerMissionList(plannerList);
            }
        );
    }

    const onClickMission = (item) => {
        context.post(
            "member/changeMissionStatus",
            {
                id: item.id,
                status: item.status == "Y" ? "N" : "Y"
            },
            response => {
                if (item.type == "STUDY") {
                    setStudyMissionList(prev => prev.map((it, idx) => {
                        if (it.id == item.id) {
                            it.status = it.status == "Y" ? "N" : "Y"
                        }
                        return it
                    }))
                }
            }
        );
    }

    const getCompareData = (mode) => {
        // context.get(
        //     "datalab/getCompareData",
        //     {
        //         mode: mode
        //     },
        //     response => {
        //     }
        // );
    }

    return (
        <div className="study-status m-t-30">
            <div className="row gy-3">
                <div className="p-r-0">
                    <div className="multi-memo box-shadow">
                        <div>
                        <img className="float-end memo-img" src={"../../assets/image/img_li_05.png"}/>
                        </div>
                        <textarea
                                className="memo-input"
                                // rows={7}
                                // value={memo}
                                // onBlur={() => onSave()}
                                // onChange={(e) => setMemo(e.target.value)}
                                />
                    </div>
                        <MissionComponent
                            theme={"blue"}
                            title={strings.study_cafe_mission}
                            missionList={studyMissionList}
                            onClick={(item) => onClickMission(item)}
                        />
                </div>
            </div>
        </div>
    );
}

export default withRouter(Multi);

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import DropdownProfile from './dropdown/profile.jsx';
import {Page_settings} from '../../config/page_settings.js';
import DropdownNotification from "./dropdown/notification";
import TimeShow from "../control/item/time_show";
import RealTimeShow from "../control/item/real_time_show";

const HeaderBlack = (props) => {
    let {roomTime, realTime, realTimeShow} = props;

    return (
        <Page_settings.Consumer>
            {({toggleMobileSidebar, pageSidebar}) => (
                <div id="header" className="header navbar-default header-black">
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <img className="logo" src={'/assets/image/logo_white.png'} alt=""/>
                        </div>
                        {pageSidebar && (
                            <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                            </button>
                        )}
                    </div>
                    <div style={{display: 'flex'}}>
                        <TimeShow
                            time={roomTime}
                        />
                        {
                            realTimeShow &&
                            <RealTimeShow
                                time={realTime}
                            />
                        }

                    </div>
                    <ul className="navbar-nav navbar-right">
                        <DropdownNotification theme={"black"}/>
                        <DropdownProfile theme={"black"}/>
                    </ul>
                </div>
            )}
        </Page_settings.Consumer>
    )
}

export default HeaderBlack;

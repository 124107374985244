import strings from "../../../lang/strings";
import { useContext } from "react";
import { Page_settings } from "../../../config/page_settings";
import moment from "moment";

const CoachListItem = (props) => {
    const context = useContext(Page_settings);
    const { item, selected, onClick } = props;

    return (
        <button className={("coach-item " + (selected ? "selected" : ""))} onClick={() => onClick()}>
            <img className="profile"
                src={item.image != null ? context.loadImage(item.image) : "/assets/image/logo_big.png"}
                alt="" />
            <div
                className="name">{"AI " + item.name}</div>
            <div
                className="time">{item.chat_date != "" ? moment(item.chat_date).format("a hh:mm") : ""}</div>
        </button>
    );
}

export default CoachListItem;

import React, { useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import RankingItem from "./ranking_item";
import PlannerChatButton from "../../../components/control/button/planner_chat_button";
import PlannerButton from "../../../components/control/button/planner_button";
import PlanItem from "../../../components/control/item/plan_item";
import PlanAddButton from "../../../components/control/button/plan_add_button";
import PlanInput from "../../../components/control/custom_input/plan_input";
import ChatInput from "../../../components/control/custom_input/chat_input";
import ChatDropdown from "../../../components/chat_dropdown";
import SweetAlert from 'react-bootstrap-sweetalert';
import ChatItem from "../../../components/control/item/chat_item";
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import { rootStore } from "../../../mobx/store";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getRandomNumber } from "../../../helper/common";
import { colorList } from "../../../config/const";

const RightContainer = (props) => {
    const context = useContext(Page_settings);
    const [plannerChat, setPlannerChat] = useState(0);
    const [selectedTag, setSelectedTag] = useState(0);
    const [todoList, setTodoList] = useState([])
    const [addPlan, setAddPlan] = useState(false);
    const [newPlan, setNewPlan] = useState("");
    const [chat, setChat] = useState("");
    const [curPlan, setCurPlan] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [delIdx, setDelIdx] = useState(-1);
    const [rand, setRand] = useState(0);
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        getTagList();
        setRand(getRandomNumber(3));
    }, []);

    const getTagList = () => {
        context.get(
            'planner/getTagList',
            {
                planner_date: moment(moment.now()).format("YYYY-MM-DD"),
            },
            response => {
                setTagList(response.list);
                if (response.list.length != 0) {
                    setTodoList(response.list[selectedTag].todo_list.map((item, idx) => {
                        return {
                            id: item.id,
                            status: item.status,
                            title: item.title,
                            editable: false,
                            color: response.list[selectedTag].color
                        };
                    }));
                    let total = 0;
                    let done = 0;
                    response.list.forEach((item) => {
                        item.todo_list.forEach((itList) => {
                            total += 1;
                            if (itList.status == "Y") {
                                done += 1
                            }
                        })
                    });
                    props.onCheck(done / total * 100);
                }
            }
        );
    }

    const getTodoList = (idx) => {
        const color = tagList[idx].color;
        setTodoList(tagList[idx].todo_list.map((item, idx) => {
            return {
                id: item.id,
                status: item.status,
                title: item.title,
                editable: false,
                color: color
            };
        }));
    }

    const onAddPlan = () => {
        if (newPlan == "") return
        context.post(
            'planner/addTodo',
            {
                show_date: moment().format('YYYY-MM-DD'),
                tag: tagList[selectedTag].id,
                title: newPlan,
                id: ''
            },
            response => {
                getTagList();
            }
        );
        setAddPlan(false);
    };

    const onDeletePlan = (idx) => {
        setDelIdx(idx);
        toggleSweetAlert();
    };

    const toggleSweetAlert = () => {
        setDeleteAlert(!deleteAlert);
    };

    const onConfirmPlan = (idx) => {
        setTodoList(todoList.map((item, index) => {
            item.title = idx == index ? curPlan : item.title;
            item.status = idx == index ? false : item.status;
            item.editable = idx == index ? false : item.editable;
            return item;
        }));

        context.post(
            'planner/addTodo',
            {
                show_date: moment().format('YYYY-MM-DD'),
                tag: tagList[selectedTag].id,
                title: curPlan,
                id: todoList[idx].id
            },
            response => {
                getTagList();
            }
        );

    };

    const onEditPlan = (idx) => {
        setCurPlan(todoList[idx].title);
        setTodoList(todoList.map((item, index) => {
            item.editable = idx == index ? true : item.editable;
            return item;
        }));
    };

    const onChange = (text) => {
        setCurPlan(text);
    }

    const doDelete = () => {
        context.post(
            'planner/deleteTodo',
            {
                id: todoList[delIdx].id
            },
            response => {
                // getTagList();
                setTodoList(prev => prev.filter((item, index) => delIdx != index));
                toggleSweetAlert();
            }
        );
    };

    const onCheckTodo = (idx) => {
        let tag = tagList[selectedTag];
        let status = tag.todo_list[idx].status == 'Y' ? 'N' : 'Y';
        context.post(
            'planner/changeTodoStatus',
            {
                id: tag.todo_list[idx].id,
                status: status
            },
            response => {
                getTagList();
            }
        );
    }

    return (
        <div className="study-room-right-container d-flex flex-column">
            <div className="ranking">
                <div className="display-flex-important align-items-xs-end">
                    <div>{strings.ranking}</div>
                    <div
                        className="sub-title">{moment(new Date).year().toString().substring(2, 4) + strings._y + " " + (moment(new Date).month() + 1).toString() + strings._m}</div>
                </div>
                {
                    props.rankingList.map((item, idx) => {
                        return (
                            <RankingItem
                                key={idx}
                                item={item}
                                rank={idx + 1}
                            />
                        )
                    })
                }
            </div>
            <div className="planner-chat">
                <PlannerChatButton
                    selected={plannerChat == 0}
                    value={strings.planner}
                    isNew={false}
                    onClick={() => setPlannerChat(0)}
                />
                <PlannerChatButton
                    selected={plannerChat == 1}
                    value={strings.chatting}
                    isNew={props.unreadAll > 0}
                    onClick={() => setPlannerChat(1)}
                />
            </div>
            {
                plannerChat == 0 &&
                <div className="planner-chat-container">
                    <PerfectScrollbar className="d-flex p-b-10 h-auto" options={{ suppressScrollY: true }}>
                        {
                            tagList.map((item, idx) =>
                                <PlannerButton
                                    key={idx}
                                    value={item.title}
                                    selectedColor={item.color}
                                    selected={idx == selectedTag}
                                    onClick={() => {
                                        setSelectedTag(idx);
                                        getTodoList(idx);
                                        setAddPlan(false);
                                        setNewPlan("");
                                    }}
                                />
                            )
                        }
                    </PerfectScrollbar>
                    <div className="plan-container">
                        {
                            tagList.length != 0 &&
                            <div style={{ maxHeight: 'calc(100% - 45px)', overflowY: 'auto' }}>
                                <div className="m-b-40">
                                    {
                                        todoList.map((item, idx) =>
                                            <PlanItem
                                                key={idx}
                                                item={item}
                                                value={curPlan}
                                                onEdit={() => onEditPlan(idx)}
                                                onDelete={() => onDeletePlan(idx)}
                                                onConfirm={() => onConfirmPlan(idx)}
                                                onChange={(text) => onChange(text)}
                                                onCheckTodo={() => onCheckTodo(idx)}
                                            />
                                        )
                                    }
                                    {
                                        addPlan &&
                                        <PlanInput
                                            value={newPlan}
                                            onChange={(v) => setNewPlan(v)}
                                            type={"text"}
                                            onAddPlan={() => onAddPlan()}
                                        />
                                    }
                                </div>
                                <PlanAddButton
                                    onClick={() => {
                                        setAddPlan(true);
                                        setNewPlan("")
                                    }}
                                />
                            </div>
                        }
                        {
                            tagList.length == 0 &&
                            <div className="no-item">{strings.no_plan_today}</div>
                        }
                    </div>
                </div>
            }
            {
                plannerChat == 1 &&
                <div className="chatting-container d-flex flex-column" style={{ height: 'calc(100% - 600px)' }}>
                    <div className="p-15">
                        {
                            props.memberList.length != 0 &&
                            <div className="opponent">
                                <img className="user-image"
                                    src={props.memberList[props.partner].user.profile != null ? context.loadImage(props.memberList[props.partner].user.profile) : "/assets/image/logo_big.png"}
                                    alt="" />
                                <div
                                    className={("online online-new ") + (props.memberList[props.partner].status != 0 ? "offline" : "")} />

                                <div className="text-ellipsis opponent-name">{props.memberList[props.partner].user.name}</div>
                                <ChatDropdown
                                    memberList={props.memberList}
                                    onSelect={(idx) => {
                                        props.onSelectPartner(idx);
                                    }}
                                    partner={props.partner}
                                    dotShow={true}
                                />
                            </div>
                        }
                        {/* {
                            props.memberList.length == 0 && <div className="height-100" />
                        } */}
                    </div>
                    <div className="d-flex flex-column" style={{ flex: 1, overflowY: 'auto', paddingBottom: '5px' }}>
                        <div className="chat-area" style={{ flex: 1 }}>
                            {
                                props.chatList.map((item, idx) =>
                                    <ChatItem
                                        key={idx}
                                        item={item}
                                        imageShow={false}
                                        mine={item.sender == rootStore.getProfile.id}
                                    />
                                )
                            }
                            {
                                props.memberList.length == 0 &&
                                <div className="no-item m-b-30">{strings.no_member}</div>
                            }
                        </div>
                    </div>
                </div>
            }

            {
                plannerChat == 1 && props.memberList.length != 0 &&
                <ChatInput
                    placeholder={strings.enter_chat}
                    onChange={(v) => setChat(v)}
                    value={chat}
                    inputType={"text"}
                    onSend={() => {
                        if (chat != "" && chat.replace(/ /g, "") != "") {
                            props.onSendChat(chat);
                            setChat("")
                        }
                    }}
                />
            }
            <div className={"ai-couch-container"}>
                <div className={"ai-coach-balloon " + (props.type == 1 ? "after-start" : (props.type == 2 ? "study-event" : (props.type == 3 ? "study-event-red" : "")))}>
                    <div className={"ai-couch"}>{strings.ai_couch}</div>
                    {
                        props.type == 0 &&
                        <div>{strings.before_start_chew[rand]}</div>
                    }
                    {
                        props.type != 0 &&
                        <div>{props.chew.txt}</div>
                    }
                </div>
                {
                    props.type == 0 &&
                    <img className="couch-image" src={"/assets/image/img_li_01.png"} alt="" />
                }
                {
                    props.type != 0 &&
                    <img
                        className={"couch-image"}
                        src={props.chew.chew} alt="" />
                }
            </div>
            {(deleteAlert &&
                <SweetAlert showCancel
                    confirmBtnText={strings.delete}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={strings.delete_planner}
                    customClass="alert-black"
                    onConfirm={() => doDelete()}
                    onCancel={() => toggleSweetAlert()}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                >
                    {strings.delete_planner_alert}
                </SweetAlert>
            )}

        </div>
    );
}

export default RightContainer;

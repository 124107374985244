import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


function Shop(props) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const history = useHistory();
  const toggle = () => {
    // setDropdownOpen(!dropdownOpen)
    history.push("/shop")
}

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={() => toggle()}
        className="dropdown navbar-user rank-dropdown" tag="li">
        <DropdownToggle tag="a" className="display-flex-important align-item-center">
          <img
            src={'/assets/image/icon_chewingShop.png'}
            alt="" />
        </DropdownToggle>
      </Dropdown>
    </>
  )
}

export default Shop
import React, { useEffect, useState, useContext } from "react";
import strings from "../../../lang/strings";
import PlannerChatButton from "../../../components/control/button/planner_chat_button";
import ChatInput from "../../../components/control/custom_input/chat_input";
import ChatItem from "../../../components/control/item/chat_item";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from "../../../config/page_settings";
import ChatDropdown from "../../../components/chat_dropdown";

const ConferenceRight = (props) => {
    const context = useContext(Page_settings);
    const { memberList, chatList, ownerInfo, videoTrack, audioTrack, muteVideo, muteAudio, reverseCamera, mine, reaction, onSend, showBoard, partner } = props;
    const [chat, setChat] = useState("")
    const [temperature, setTemperature] = useState(70);
    const [ownerCamZoom, setOwnerCamZoom] = useState(false);

    useEffect(() => {
        if (videoTrack != undefined && videoTrack != null) {
            if (mine) {
                if (muteVideo == true) {
                    videoTrack.stop()
                } else if (muteVideo == false) {
                    videoTrack.play(`owner-player`)
                }
            } else {
                videoTrack.play(`owner-player`)
            }
        }
    }, [muteVideo, videoTrack])

    useEffect(() => {
        if (audioTrack != undefined && audioTrack != null) {
            if (mine) {
                if (muteAudio == true) {
                    audioTrack.stop()
                } else if (muteAudio == false) {
                    audioTrack.play()
                }
            } else {
                audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])

    useEffect(() => {
        setOwnerCamZoom(!showBoard);
    }, [showBoard])

    const onClickOwner = () => {
        if (showBoard) {
            setOwnerCamZoom(!ownerCamZoom);
        }
    }

    useEffect(() => {
        const whiteBoardElement = document.getElementById('whiteboardDiv');
        const ownerPlayerElement = document.getElementById('owner-player');
        const whiteboardLeftElements = document.getElementsByClassName('fastboard-left');
        const whiteboardBottomLeftElements = document.getElementsByClassName('fastboard-bottom-left');
        const whiteboardBottomRightElements = document.getElementsByClassName('fastboard-bottom-right');
        if (ownerCamZoom) {
            ownerPlayerElement.style.width = whiteBoardElement.clientWidth + 'px';
            ownerPlayerElement.style.height = whiteBoardElement.clientHeight + 'px';
            ownerPlayerElement.style.position = 'fixed';
            ownerPlayerElement.style.left = '0px';
            if (whiteboardLeftElements.length > 0) {
                whiteboardLeftElements[0].style.zIndex = -1;
            }
            if (whiteboardBottomLeftElements.length > 0) {
                whiteboardBottomLeftElements[0].style.zIndex = -1;
            }
            if (whiteboardBottomRightElements.length > 0) {
                whiteboardBottomRightElements[0].style.zIndex = -1;
            }
        } else {
            ownerPlayerElement.style.width = '100%';
            ownerPlayerElement.style.height = '190px';
            ownerPlayerElement.style.position = 'relative';
            if (whiteboardLeftElements.length > 0) {
                whiteboardLeftElements[0].style.zIndex = 200;
            }
            if (whiteboardBottomLeftElements.length > 0) {
                whiteboardBottomLeftElements[0].style.zIndex = 200;
            }
            if (whiteboardBottomRightElements.length > 0) {
                whiteboardBottomRightElements[0].style.zIndex = 200;
            }
        }

        console.log('whiteBoardElement size =>', whiteBoardElement.clientWidth, whiteBoardElement.clientHeight, ownerCamZoom);
    }, [ownerCamZoom])

    return (
        <div className="conference-room-right-container d-flex flex-column">
            <div id="owner-player" className={"user-camera d-flex align-item-center justify-content-center" + (reverseCamera == true ? " camera-reverse" : "")} onClick={onClickOwner}>
                {
                    ((mine && muteVideo == true) || videoTrack == undefined || videoTrack == null) &&
                    <img
                        src={ownerInfo.user != undefined && ownerInfo.user.profile != null && ownerInfo.user.profile != "" ? context.loadImage(ownerInfo.user.profile) : "/assets/image/logo_big.png"}
                        className="position-absolute"
                        style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                        alt="" />
                }
                {
                    reaction != "" &&
                    <img src={reaction} className="position-absolute" alt="" />
                }
            </div>
            <div className="planner-chat">
                <PlannerChatButton
                    selected={true}
                    value={strings.chatting}
                    isNew={false}
                />
                <div className="chat-member-count m-l-auto" style={{ width: 'auto', height: 'auto', background: 'none' }}>
                    <img src={"/assets/image/icon_user_gray.png"} alt="" />
                    <div className="p-r-5" style={{ lineHeight: '17px', color: '#747383' }}>{memberList.length}</div>
                </div>
            </div>
            <div className="chatting-container d-flex flex-column" style={{ flex: 1, overflowY: 'auto', paddingBottom: '5px' }}>
                {
                    mine && memberList.length > 0 &&
                    <div className="p-15">
                        <div className="opponent">
                            <img className="user-image"
                                src={memberList[partner].user.profile != null ? context.loadImage(memberList[partner].user.profile) : "/assets/image/logo_big.png"}
                                alt="" />
                            <div
                                className={("online online-new ") + (memberList[partner].status != 0 ? "offline" : "")} />

                            <div className="text-ellipsis opponent-name">{memberList[partner].user.name}</div>
                            <ChatDropdown
                                memberList={memberList}
                                onSelect={(idx) => {
                                    props.onSelectPartner(idx);
                                }}
                                partner={partner}
                                dotShow={true}
                            />
                        </div>
                    </div>
                }
                <div className="chat-area" style={{ flex: 1 }}>
                    {chatList.length != 0 &&
                        chatList.map((item, idx) => {
                            return (
                                <ChatItem
                                    key={idx}
                                    item={item}
                                    imageShow={false}
                                    mine={item.sender == rootStore.getProfile.id}
                                    // mine={item.notice == undefined ? item.sender.id == rootStore.getProfile.id : false}
                                    // profile={item.notice == undefined ? item.sender.profile : ""}
                                />)
                        })
                    }
                </div>
            </div>
            <ChatInput
                placeholder={strings.enter_chat}
                onChange={(v) => setChat(v)}
                value={chat}
                inputType={"text"}
                onSend={() => {
                    if (chat != "" && chat.replace(/ /g, "") != "") {
                        onSend(chat);
                        setChat("")
                    }
                }}
            />
            {/* <div className="ai-couch-container conference-couch">
                <div className='temperature-container'>
                    <div className="display-flex-important align-item-center width-100-percent">
                        <div className="temperature">{strings.temperature}</div>
                        <div className="width-100-percent position-relative">
                            <div className="progress rounded-corner width-100-percent progress-background">
                                <div className="progress-bar f-s-10 f-w-600" style={{
                                    width: `${temperature}%`,
                                    backgroundColor: "red",
                                    transition: 'all .2s ease-out',
                                    minWidth: '30px',
                                }}
                                />
                            </div>
                            <img className="fire" src={"/assets/image/icon_fire.png"}
                                 style={{right: `${93 - temperature}%`}}/>
                        </div>

                        <div className="f-s-16 progress-number">{strings.hot}</div>
                    </div>
                </div>
                <div className={"ai-couch"}>{strings.ai_chewing}</div>
                <div>{strings.before_start_chew[props.rand]}</div>
                <img className="couch-image" src={"/assets/image/img_ai_bot4.png"} alt=""/>
            </div> */}

        </div>
    );
}
export default ConferenceRight;

import React, {useContext} from "react";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";

const ChatItem = (props) => {
    const context = useContext(Page_settings)
    const {item, imageShow, mine, profile} = props;
    return (
        <div>
            {
                item.notice == undefined &&
                <div className={(!mine? "m-l-auto ":"m-r-auto ")+("d-flex w-100 m-b-5")}>
                    {
                        (!mine && imageShow) &&
                        <img src={profile != null && profile != '' ? context.loadImage(profile) : "/assets/image/logo_big.png"} style={{width: 37, height: 37, borderRadius: 19, marginRight: 10, objectFit: "cover"}} alt=""/>
                    }
                    <div className={(!mine? "m-l-auto ":"m-r-auto ")+("d-flex flex-column w-100")}>
                        {/* <div className="user-name">{mine ? '' : item.sender.name }</div> */}
                        <div className="d-flex  align-items-xs-end">
                    {   mine &&
                    <div style={{color:"#73727e", fontSize: 13, marginRight: 2, marginLeft: "auto"}}>{moment(item.create_date).format("HH:mm")}</div>
                    }
                    <div className={mine? "right":"left"}>{item.content}</div>
                    {   !mine &&
                    <div style={{color:"#73727e", fontSize: 13, marginLeft: 2, marginRight: "auto"}}>{moment(item.create_date).format("HH:mm")}</div>
                    }
                    </div>
                    </div>
                </div>
            }
            {
                item.notice != undefined &&
                    <div className="notice d-flex">{item.notice}</div>
            }
        </div>

    );
}

export default ChatItem;

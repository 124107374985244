import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import strings from '../../../lang/strings';

const StartTestModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, item, onStart } = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="start-test-modal">
            <ModalBody>
                <div className="d-flex align-items-center">
                    <div className="f-s-20 f-w-600 flex-grow-1">{strings.test}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div className="d-flex align-items-center flex-column">
                    <img style={{ marginTop: 26 }} src={"/assets/image/img_start_test.png"} alt="" />
                    <div className='desc'>{strings.test_desc}</div>
                </div>
                <div className="width-100-percent d-flex">
                    <button
                        className="start-button"
                        onClick={() => onStart(item)}>
                        {strings.test_start}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default React.memo(StartTestModal);

import React, {useContext, useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import PerfectScrollbar from 'react-perfect-scrollbar';
import RecentVideoItem from "../item/recent_video_item";
import SearchVideoItem from "../item/search_video_item";
import {Page_settings} from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";

const ItemSearchModal = (props) => {

    const {
        show,
        close,
        toggle,
        onQR,
        onClickVideo,
        categorySelected,
        showVideoSearch,
        videoCategoryList,
        videoKeyword,
        onClickCategory,
        onChange,
        onClear,
        onEnter,
        videoResultList,
        onClickKeyword,
        onLoadMore
    } = props;

    const context = useContext(Page_settings);
    const [keywordList, setKeywordList] = useState([]);
    const [showKeyword, setShowKeyword] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [delIdx, setDelIdx] = useState(-1);
    const [scroll, setScroll] = useState(0)

    const toggleSweetAlert = () => {
        setDeleteAlert(!deleteAlert);
    };

    const onDelete = (idx) => {
        toggleSweetAlert();
        setDelIdx(idx)
    }

    const onFocus = () => {
        if (videoKeyword == "") {
            setShowKeyword(true);
        }

        context.get(
            "room/getItemKeywordList",
            {},
            response => {
                setKeywordList(response.list);
            }
        );

        if (videoKeyword != "") {

        }
    }

    const toggleKeyword = () => {
        setShowKeyword(!showKeyword);
    }

    const doDelete = () => {
        context.post(
            "room/deleteItemView",
            {
                id: videoList[delIdx].id
            },
            response => {
                setVideoList(prevList => prevList.filter((item, index) => {
                    return index != delIdx
                }));
                toggleSweetAlert()
            }
        );
    }

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onEnter();
        }
    };

    const loadRecent = () => {
        context.get(
            "room/getItemViewList",
            {
                page_num: 1,
            },
            response => {
                setVideoList(response.list);
            }
        )
    }

    const onEnd = () => {
        if (scroll <= 3) {
            setScroll(scroll + 1)
        }
        if (scroll == 2) {
            onLoadMore()
        }
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className='item-search-modal' onOpened={() => loadRecent()}>
            <ModalBody className="p-0 position-relative">
                <div className='d-flex header align-item-center m-b-20'>
                    <div className='f-s-16' style={{color: "white"}}>{strings.item_search}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="d-flex">
                    <div className="search-box">
                        <input type={"text"} className="input-transparent p-r-30"
                               value={videoKeyword} placeholder={strings.item_search_placeholder}
                               onChange={(v) => {
                                   onChange(v.target.value);
                                   setShowKeyword(false);
                                   if (v.target.value == "") {
                                       loadRecent();
                                   }
                               }}
                               onClick={() => onFocus()}
                               onKeyDown={handleEnter}/>
                        <button className="transparent-button" onClick={() => onClear()}>
                            <img
                                src={videoKeyword == "" ? "/assets/image/icon_search_white.png" : "/assets/image/icon_close_white.png"}
                                className="modal-close"
                                alt=""/>
                        </button>
                    </div>
                    <button className="search-box QR" onClick={() => onQR()}>
                        <img className="m-r-5" src={"/assets/image/icon_qr_code.png"} alt=""/>
                        {strings.qr}
                    </button>
                </div>
                <Dropdown isOpen={showKeyword} toggle={() => toggleKeyword()}
                          >
                    <DropdownToggle className="keyword-toggle"/>
                    <DropdownMenu className="media-list dropdown-menu keyword-container" tag="ul">
                        <PerfectScrollbar className="keyword-scroll" options={{suppressScrollX:true}}>
                            {

                                keywordList.map((item, idx) => {
                                    return (
                                        <div key={idx}>
                                            <button
                                                className={("keyword-item ") + (idx == 0 ? "top-item" : "")}
                                                onClick={() => {
                                                    onClickKeyword(item.keyword);
                                                    toggleKeyword();
                                                }}
                                            >{item.keyword}</button>
                                        </div>
                                    )
                                })
                            }
                        </PerfectScrollbar>

                    </DropdownMenu>
                </Dropdown>

                {
                    !categorySelected &&
                    <div className="d-flex">
                        <PerfectScrollbar className="video-search-result" options={{suppressScrollX: true}} >
                            {
                                showVideoSearch &&
                                videoCategoryList.map((item, idx) => {
                                    return (
                                        <button className="search-video-item" key={idx}
                                                onClick={() => onClickCategory(idx)}>
                                            <img src={"/assets/image/icon_search_white.png"} alt=""/>
                                            <div className={"d-flex"}>
                                                {
                                                    item.split("").map((char, charIdx) => {
                                                        let searchIdx = item.toLowerCase().indexOf(videoKeyword.toLowerCase())
                                                        return (
                                                            <div
                                                                className={(searchIdx == -1 ? '' : (charIdx >= searchIdx && charIdx < searchIdx + videoKeyword.length) ? 'searched-category ' : '') + (char == " " ? "space-char" : "")}
                                                                key={charIdx}>{char}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </button>
                                    );
                                })
                            }
                        </PerfectScrollbar>
                        <div className="recent-video">
                            <div className="m-l-10">
                                {strings.recent_view_video}
                            </div>
                            <PerfectScrollbar className="question-list" options={{suppressScrollX: true}} onYReachEnd={() => onEnd()}
                                              onScrollUp={() => setScroll(0)} onYReachStart={() => setScroll(0)}>
                                {
                                    videoList.map((item, idx) =>
                                        <RecentVideoItem
                                            key={idx}
                                            item={item}
                                            onClick={() => onClickVideo(item)}
                                            onDelete={() => onDelete(idx)}
                                        />
                                    )
                                }
                            </PerfectScrollbar>
                        </div>
                    </div>
                }
                {
                    categorySelected &&
                    <PerfectScrollbar className="result-area" options={{suppressScrollX: true}}>
                        {
                            videoResultList.map((item, idx) => {
                                return (
                                    <SearchVideoItem
                                        key={idx}
                                        item={item}
                                        onClick={() => onClickVideo(item)}
                                    />
                                )
                            })
                        }
                    </PerfectScrollbar>
                }
            </ModalBody>
            {(deleteAlert &&
                <SweetAlert showCancel
                            confirmBtnText={strings.delete}
                            confirmBtnBsStyle="primary"
                            cancelBtnText={strings.cancel}
                            cancelBtnBsStyle="default"
                            title={strings.delete_video}
                            customClass="alert-black"
                            onConfirm={() => doDelete()}
                            onCancel={() => toggleSweetAlert()}
                            confirmBtnCssClass="alert-confirm"
                            cancelBtnCssClass="alert-cancel"
                >
                    {strings.delete_planner_alert}
                </SweetAlert>
            )}
        </Modal>
    );
}

export default ItemSearchModal;

import DatePicker from "react-datepicker";
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './planner.scss'
import strings from "../../lang/strings";
import moment from "moment";
import TagItem from "../../components/control/item/tag_item";
import { Store } from 'react-notifications-component';
import SweetAlert from "react-bootstrap-sweetalert";
import TagModal from "../../components/control/modal/tag_modal";
import TodoModal from "../../components/control/modal/todo_modal";
import { Page_settings } from "../../config/page_settings";
import { getTimeFromSecond } from "../../helper/common";
import CloudContainer from "./cloud_container";

const Planner = (props) => {
    const context = useContext(Page_settings);
    const [selDate, setSelDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [showEmpty, setShowEmpty] = useState(true);
    const [planList, setPlanList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [planId, setPlanId] = useState(0);
    const [memberId, setMemberId] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [comment, setComment] = useState('');
    const [memo, setMemo] = useState('');
    const [tagList, setTagList] = useState([]);
    const [showTagModal, setShowTagModal] = useState(false);
    const [showTodoModal, setShowTodoModal] = useState(false);
    const [tagName, setTagName] = useState('');
    const [tagColor, setTagColor] = useState('#C92121');
    const [todoName, setTodoName] = useState('');
    const [tagIdx, setTagIdx] = useState(-1);
    const [todoIdx, setTodoIdx] = useState(-1);
    const [showDelAlert, setShowDelAlert] = useState(false);
    const [showDelTodoAlert, setShowDelTodoAlert] = useState(false);
    const [delIdx, setDelIdx] = useState(-1);
    const [delTagIdx, setDelTagIndex] = useState(-1);
    const [delTodoIdx, setDelTodoIndex] = useState(-1);
    const [realTime, setRealTime] = useState('');
    const [availableTime, setAvailableTime] = useState('');

    useEffect(() => {
        context.handleSetPlannerPageHeader(true);
        return () => {
            context.handleSetPlannerPageHeader(false);
        }
    }, []);

    useEffect(() => {
        getPlanList();
        getCalendarPlanList();
    }, [startDate]);

    useEffect(() => {
        getPlanDetail();
    }, [selDate]);

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    }

    const getPlanList = () => {
        let start = moment(startDate).format('YYYY-MM') + '-01';
        let end_date = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        let end = moment(end_date).format('YYYY-MM-DD');
        context.get(
            'planner/getList',
            {
                from: start,
                to: end
            },
            response => {
                setPlanList(response.list.map((item) => {
                    return item
                }));
            }
        );
    }

    const getCalendarPlanList = () => {
        let start = moment(startDate).format('YYYY-MM') + '-01';
        let end_date = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        let end = moment(end_date).format('YYYY-MM-DD');
        context.get(
            'planner/getCalendarEventList',
            {
                from: start,
                to: end
            },
            response => {
                setEventList(response.list.map((item) => {
                    return item
                }));
            }
        );
    }

    const getPlanDetail = () => {
        let date = moment(selDate).format('YYYY-MM-DD');
        context.get(
            'planner/getItem',
            {
                planner_date: date,
            },
            response => {
                if (response.planner_info != null) {
                    setPlanId(response.planner_info.id);
                    setMemberId(response.planner_info.member ?? '');
                    setComment(response.planner_info.comment ?? '');
                    setMemo(response.planner_info.memo ?? '');
                    setFeedback(response.planner_info.feedback ?? '');
                } else {
                    setPlanId('');
                    setMemberId('');
                    setComment('');
                    setMemo('');
                    setFeedback('');
                }
                if (response.time_info != null) {
                    setRealTime(parseInt(response.time_info.real_time) ?? 0);
                    // setAvailableTime(parseInt(response.time_info.ai_time) ?? 0);
                    const lastTime = moment().format('YYYY-MM-DD 23:59:59');
                    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    const availableSeconds = moment(lastTime).diff(moment(currentTime), 'seconds');
                    setAvailableTime(availableSeconds);
                } else {
                    setRealTime(0);
                    setAvailableTime(0);
                }

                context.get(
                    'planner/getTagList',
                    {
                        planner_date: date,
                    },
                    response => {
                        setTagList(response.list);
                        setShowEmpty(response.list.length == 0);
                    }
                );
            }
        );
    }

    const weekdays = [
        strings.weekdays.monday,
        strings.weekdays.tuesday,
        strings.weekdays.wednesday,
        strings.weekdays.thursday,
        strings.weekdays.friday,
        strings.weekdays.saturday
    ];

    const getDataIndex = (date) => {
        let idx = -1;
        for (let i = 0; i < eventList.length; i++) {
            if (eventList[i].show_date == moment(date).format('YYYY-MM-DD')) {
                idx = i;
                break;
            }
        }
        return idx;
    }

    const onClickFeedback = () => {
        if (showFeedback) onSave(3);
        setShowFeedback(!showFeedback);
    }

    const onEditTag = (idx) => {
        setTagName(tagList[idx].title);
        setTagColor(tagList[idx].color);
        setTagIdx(idx);
        setShowTagModal(true);
    }

    const onDeleteTag = (idx) => {
        setDelIdx(idx);
        toggleSweetAlert();
    }

    const toggleSweetAlert = () => {
        setShowDelAlert(!showDelAlert);
    }

    const doDelete = () => {
        context.post(
            'planner/deleteTag',
            {
                id: tagList[delIdx].id,
            },
            response => {
                getPlanList();
                getCalendarPlanList();
                getPlanDetail();
                toggleSweetAlert();
            }
        );

    }

    const onEditTodo = (tagIndex, idx) => {
        setTagIdx(tagIndex);
        setTodoIdx(idx);
    }

    const onEditTodoDone = (tagIndex, idx, todo) => {
        onConfirmTodo(tagIndex, idx, todo);
        setTagIdx(-1);
        setTodoIdx(-1);
    }

    const onDeleteTodo = (tagIndex, idx) => {
        setDelTagIndex(tagIndex);
        setDelTodoIndex(idx);
        setShowDelTodoAlert(!showDelTodoAlert)
    }

    const doDeleteTodo = () => {
        let tag = tagList[delTagIdx];
        context.post(
            'planner/deleteTodo',
            {
                id: tag.todo_list[delTodoIdx].id,
            },
            response => {
                tag.todo_list = tag.todo_list.filter((item, index) => {
                    return delTodoIdx != index
                });
                tagList[delTagIdx] = tag;
                setTagList(tagList);
                setShowDelTodoAlert(!showDelTodoAlert)
            }
        );
    }

    const onCheckTodo = (tagIndex, idx) => {
        let tag = tagList[tagIndex];
        let status = tag.todo_list[idx].status == 'Y' ? 'N' : 'Y';
        context.post(
            'planner/changeTodoStatus',
            {
                id: tag.todo_list[idx].id,
                status: status
            },
            response => {
                getPlanDetail();
            }
        );
    }

    const onAddTag = () => {
        setTagIdx(-1);
        setTagName('');
        setShowTagModal(true);
    }

    const onAddTodo = (tagIndex) => {
        setTagIdx(tagIndex);
        setTodoIdx(-1);
        const emptyTodoItem = {
            create_date: "",
            id: 0,
            status: "N",
            status_date: "",
            tag: tagList[tagIndex].id,
            title: "",
        }
        tagList[tagIndex].todo_list.push(emptyTodoItem);
    }

    const onConfirmTag = () => {
        if (tagName == '') {
            addNotification('warning', '', strings.input_tag_name)
            return;
        }
        context.post(
            'planner/addTag',
            {
                planner_date: moment(selDate).format('YYYY-MM-DD'),
                title: tagName,
                color: tagColor,
                id: tagIdx > -1 ? tagList[tagIdx].id : ''
            },
            response => {
                getPlanList();
                getCalendarPlanList();
                getPlanDetail();
                setShowTagModal(false);
            }
        );
    }

    const onConfirmTodo = (tagIndex, todoIndex, todo) => {
        if (todo == '') {
            if (tagList[tagIndex].todo_list[todoIndex].id == 0) {    // 추가작성중인 투두인 경우 해당 투두목록에서 삭제
                tagList[tagIndex].todo_list = tagList[tagIndex].todo_list.slice(0, todoIndex);
            } else {
                addNotification('warning', '', strings.input_todo_name)
            }
            return;
        }
        let tag = tagList[tagIndex];
        context.post(
            'planner/addTodo',
            {
                show_date: moment(selDate).format('YYYY-MM-DD'),
                tag: tag.id,
                title: todo,
                id: tag.todo_list[todoIndex].id > 0 ? tag.todo_list[todoIndex].id : ''
            },
            response => {
                getPlanDetail();
                getCalendarPlanList();
            }
        );
    }

    const checkEvent = (date) => {
        let result = [];
        for (let i = 0; i < eventList.length; i++) {
            if (eventList[i].show_date == moment(date).format('YYYY-MM-DD')) {
                if (result.filter(e => e.tag == eventList[i].tag).length == 0 && result.length < 4) {
                    result.push(eventList[i]);
                }
            }
        }

        return result;
    }

    const onSave = () => {
        context.post(
            'planner/add',
            {
                planner_date: moment(selDate).format('YYYY-MM-DD'),
                comment: comment,
                memo: memo,
                feedback: feedback,
            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        );
    }

    function isEmptyObj(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true;
    }
    // test
    return (
        <div className="main-content planner-container row">
            <CloudContainer />
                <div className='col-lg-4 p-r-0'>
                    <div className="calendar-container">
                        <div className={'calendar'}>
                            <DatePicker
                                inline
                                selected={selDate}
                                onChange={(date) => setSelDate(date)}
                                showNeighboringMonth={false}
                                renderDayContents={(dayOfMonth, date) => {
                                    let className = '';
                                    let planIndex = getDataIndex(date);
                                    if (moment(date).format('YYYY-MM-DD') == moment(selDate).format('YYYY-MM-DD')) {
                                        className = 'day-selected';
                                    } else if (planIndex < 0) {
                                        className = 'day-disable';
                                    } else {
                                        className = 'day-active';
                                    }
                                    let event = checkEvent(date);
                                    return (
                                        <div className={'day-area'}>
                                            {
                                                className == 'day-active' &&
                                                <div className={className}>
                                                    {dayOfMonth}
                                                    {
                                                        event.length > 2 &&
                                                        <div>
                                                            <div className="event-area">
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                                    }}
                                                                />
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                                        marginLeft: 2
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="event-area" style={{ marginTop: 2 }}>
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[2]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[2]) ? "transparent" : event[2].color
                                                                    }}
                                                                />
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[3]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[3]) ? "transparent" : event[3].color,
                                                                        marginLeft: 2
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        event.length < 3 &&
                                                        <div className="event-area">
                                                            <div
                                                                className="event-day"
                                                                style={{
                                                                    display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                                    backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                                }}
                                                            />
                                                            <div
                                                                className="event-day"
                                                                style={{
                                                                    display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                                    backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                                    marginLeft: 2
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                className != 'day-active' &&
                                                <div className={className}>{dayOfMonth}</div>
                                            }
                                        </div>
                                    )
                                }}
                                renderCustomHeader={(params => {
                                    return (
                                        <div style={{ width: '100%' }}>
                                            <div className="calendar-header">
                                                <button className={'btn month-btn'} onClick={() => {
                                                    setStartDate(new Date(moment(startDate).subtract(1, 'months').format('YYYY-MM-DD')));
                                                    params.decreaseMonth();
                                                }}>
                                                    <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                                        src={"/assets/image/icon_calendar_prev.png"} />
                                                </button>
                                                <div style={{ flex: 1 }}>
                                                    <div className={"header-month-text"}>
                                                        {(params.monthDate.getMonth() + 1).toString() + strings.common.m}
                                                    </div>
                                                    <div className={"header-year-text"}>
                                                        {(params.monthDate.getFullYear()).toString()}
                                                    </div>
                                                </div>
                                                <button className={'btn month-btn'} onClick={() => {
                                                    setStartDate(new Date(moment(startDate).add(1, 'months').format('YYYY-MM-DD')));
                                                    params.increaseMonth();
                                                }}>
                                                    <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                                        src={"/assets/image/icon_calendar_next.png"} />
                                                </button>
                                            </div>
                                            <div className="weekDay-area d-flex mt-2">
                                                <div
                                                    className={selDate.getDay() == 0 ? 'weekday-select' : 'weekday-normal'}>{strings.weekdays.sunday}</div>
                                                {
                                                    weekdays.map((item, idx) =>
                                                        <div key={idx}
                                                            className={selDate.getDay() == idx + 1 ? 'weekday-select weekDay-space' : 'weekday-normal weekDay-space'}>{item}</div>
                                                    )
                                                }
                                            </div>
                                            <div className="divider mt-1" />
                                        </div>
                                    )
                                })}
                            />
                        </div>
                        <div className='time-area'>
                            <div>
                                <div style={{ color: 'white', fontSize: 15, fontWeight: 700 }}>{strings.real_time}</div>
                                <div className="time-bg d-center" style={{ marginTop: 5, paddingLeft: 12, paddingRight: 12, justifyContent: 'flex-end' }}>
                                    <div className="real-time">{getTimeFromSecond(realTime)}</div>
                                    <div className="real-time-micro">{'.000'}</div>
                                </div>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div style={{ color: 'white', fontSize: 15, fontWeight: 700 }}>{strings.available_time}</div>
                                <div className="time-bg d-center" style={{ marginTop: 5, paddingLeft: 12, paddingRight: 12, justifyContent: 'flex-end' }}>
                                    <div className="available-time">{getTimeFromSecond(availableTime)}</div>
                                    <div className="available-time-micro">{'.000'}</div>
                                </div>
                            </div>
                        </div>
                        <div className='comment-area'>
                            {strings.comment} <br />
                            <textarea
                                className="comment-input"
                                rows={7}
                                value={comment}
                                onBlur={() => onSave()}
                                onChange={(e) => setComment(e.target.value)} />
                        </div>
                        <div className='memo-area'>
                            {strings.memo} <br />
                            <textarea
                                className="comment-input"
                                rows={7}
                                value={memo}
                                onBlur={() => onSave()}
                                onChange={(e) => setMemo(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 right-area'>
                    <div className='d-flex'>
                        <div className='f-s-20 f-w-600'>{strings.todo_list}</div>
                        <div className='flex-grow-1' />
                        <button className='btn-add-tag d-center' onClick={() => onAddTag()}>
                            <img src={"/assets/image/icon_plus_white.png"} alt="" style={{ width: 15, height: 15 }} />
                            <div className='f-s-16 m-l-10'>{strings.add_tag}</div>
                        </button>
                    </div>
                    <div className='todo-area'>
                        <div className='tag-area'>
                            {
                                showEmpty &&
                                <div style={{ paddingTop: 140 }}>
                                    <img src={"/assets/image/img_empty_todo list.png"} alt="" className='todo-image' />
                                    <div className='todo-hint'>{strings.todo_add_hint}</div>
                                </div>
                            }
                            {
                                !showEmpty &&
                                tagList.map((item, idx) =>
                                    <div key={idx}>
                                        <TagItem
                                            item={item}
                                            selectedTag={tagList[tagIdx] == undefined ? 0 : tagList[tagIdx].id}
                                            onDelete={() => onDeleteTag(idx)}
                                            onEdit={() => onEditTag(idx)}
                                            onAddTodo={() => onAddTodo(idx)}
                                            onTodoEdit={(index) => onEditTodo(idx, index)}
                                            onTodoDelete={(index) => onDeleteTodo(idx, index)}
                                            onTodoCheck={(index) => onCheckTodo(idx, index)}
                                            onTodoEditDone={(index, todo) => onEditTodoDone(idx, index, todo)}
                                        />
                                    </div>
                                )
                            }
                            <div style={{ height: 40 }} />
                        </div>
                        {
                            showFeedback &&
                            <div className='feedback-area'>
                                <textarea
                                    className="feedback-input"
                                    placeholder={strings.input_feedback}
                                    rows={7}
                                    value={feedback}
                                    onBlur={() => onSave()}
                                    onChange={(e) => setFeedback(e.target.value)} />
                            </div>
                        }
                        <button className='btn-feedback d-center' onClick={() => onClickFeedback()}>
                            <img
                                src={showFeedback ? "/assets/image/icon_arrow_down_white.png" : "/assets/image/icon_arrow_up_white.png"}
                                alt="" style={{ width: 15, height: 10 }} />
                            <div className='f-s-16 m-l-10'>{strings.feedback}</div>
                        </button>
                    </div>
                </div>
                <TagModal
                    show={showTagModal}
                    value={tagName}
                    tagIdx={tagIdx}
                    curColor={tagColor}
                    toggle={() => setShowTagModal(!showTagModal)}
                    close={() => setShowTagModal(false)}
                    onChange={(text) => setTagName(text)}
                    onConfirm={() => onConfirmTag()}
                    onChangeColor={(color) => setTagColor(color)}
                />
                <TodoModal
                    show={showTodoModal}
                    value={todoName}
                    todoIdx={todoIdx}
                    toggle={() => setShowTodoModal(!showTodoModal)}
                    close={() => setShowTodoModal(false)}
                    onChange={(text) => setTodoName(text)}
                    onConfirm={() => onConfirmTodo()}
                />
                {(showDelAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.delete}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={strings.delete_tag}
                        customClass="alert-white"
                        onConfirm={() => doDelete()}
                        onCancel={() => toggleSweetAlert()}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel-white"
                    >
                        {strings.delete_planner_alert}
                    </SweetAlert>
                )}
                {(showDelTodoAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.delete}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={strings.delete_todo}
                        customClass="alert-white"
                        onConfirm={() => doDeleteTodo()}
                        onCancel={() => setShowDelTodoAlert(!showDelTodoAlert)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel-white"
                    >
                        {strings.delete_planner_alert}
                    </SweetAlert>
                )}
        </div>
    );

}

export default Planner;

import strings from "../../lang/strings";
import React from "react";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";

const SignupStep3 = (props) => {

    const {
        school,
        hopeSchool,
        onChangeHope,
        onChangeCareer,
        career,
        onSearch,
        onDone
    } = props;

    const onNext = () => {
        if (school != "") {
            onDone()
        }
    }
    return (
        <div className="step3">
            <div className="d-flex">
                <div className="login-item">{strings.school}</div>
                <div className="login-item-optional">{strings.include_graduation}</div>
            </div>
            <div className="position-relative">
                <input type={"text"}
                       className="login-input m-b-40"
                       placeholder={strings.search_school}
                       value={school}
                       disabled={true}/>
                <button className="sign-search" onClick={() => onSearch()}>
                    <img src={"/assets/image/icon_search_black.png"} alt=""/>
                </button>
            </div>
            <div className="d-flex">
                <div className="login-item">{strings.hope_school}</div>
                <div className="login-item-optional">{strings.optional}</div>
            </div>
            <input type={"text"}
                   className="login-input m-b-40"
                   placeholder={strings.desired_school}
                   value={hopeSchool}
                   onChange={e => {
                       onChangeHope(e.target.value);
                   }}/>
            <div className="d-flex">
                <div className="login-item">{strings.hope_career}</div>
                <div className="login-item-optional">{strings.optional}</div>
            </div>
            <input type={"text"}
                   className="login-input m-b-40"
                   placeholder={strings.desired_career}
                   value={career}
                   onChange={e => {
                       onChangeCareer(e.target.value);
                   }}/>


            <button onClick={() => onNext()}
                    className={("login-button m-b-40 m-t-40 ") + ((school != "") ? "login-button-enable" : "")}>{strings.done}</button>
        </div>
    );
}

export default withRouter(inject('rootStore')(SignupStep3));

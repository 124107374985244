import strings from "../../lang/strings";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import { onMessageListener } from './../../helper/firebase';
import CoachListItem from "../../components/control/item/coach_list_item";
import CoachChatItem from "../../components/control/item/coach_chat_item";
import StartTestModal from "../../components/control/modal/start_test_modal";
import CoachingTestModal from "../../components/control/modal/coaching_test_modal";
import CoachingAnswerModal from "../../components/control/modal/coaching_answer_modal";
import YoutubeViewModal from "../../components/control/modal/youtube_view_modal";
import DocViewModal from "../../components/control/modal/doc_view_modal";
import CloudContainer from '../cabinet/cloud_container';

const ChatCoaching = (props) => {
    const context = useContext(Page_settings);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userId, setUserId] = useState(-1);
    const [keyword, setKeyword] = useState("");
    const [sortCode, setSortCode] = useState(0);
    const [coachList, setCoachList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [coachId, setCoachId] = useState(-1);
    const [selectedCoach, setSelectedCoach] = useState({});
    const [fileList, setFileList] = useState([])
    const imageRef = useRef();
    const [urlList, setUrlList] = useState([]);
    const [testItem, setTestItem] = useState({});
    const [showStartTest, setShowStartTest] = useState(false);
    const [showTest, setShowTest] = useState(false);
    const [testTime, setTestTime] = useState(0);
    const [testAnswer, setTestAnswer] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showDoc, setShowDoc] = useState(false);
    const [docUrl, setDocUrl] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState('');

    useEffect(() => {
        setUserId(rootStore.getProfile?.id ?? 0);
    }, [rootStore.getProfile]);

    useEffect(() => {
        loadCoachList("");
    }, []);

    useEffect(() => {
        if (coachId != -1) {
            getChatList(false);
        }
    }, [coachId]);

    onMessageListener().then(payload => {
        console.log('onMessageListener payload = ', payload);
        const chatItem = JSON.parse(payload.data.custom_data);
        if (!chatItem) {
            return;
        }

        // 현재 상담중인 전문가인 경우 채팅목록에 내용 추가
        if (coachId == chatItem.clinic) {
            setChatList([chatItem, ...chatList]);

            // 전문가 목록 최근 메시지 내용 업데이트
            setCoachList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        } else { // 현재 상담중이 아닌 전문가인 경우 전문가목록 내용만 업데이트(최근 문자, 시간, 읽지 않은 채팅수)
            setCoachList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.unread_cnt = item.unread_cnt + 1;
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        }

    }).catch(err => console.log('failed: ', err));

    const loadCoachList = (keyword) => {
        context.get(
            "coach/getCoachList",
            {
                keyword: keyword
            },
            response => {
                setCoachList(response.list);
            }
        );
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const onSearch = () => {
        loadCoachList(keyword);
    };

    const onChatSearch = () => {

    };

    const onChatSettings = () => {

    };

    const onRoadMap = () => {
        props.history.push("/ai/chat/roadmap");
    };

    const getChatList = (setHistory) => {
        context.get(
            "coach/getChatList",
            {
                coach: coachId,
                page_num: 1
            },
            response => {
                setChatList(response.list)
                if (setHistory) {
                    setCoachList(prev => prev.map((item) => {
                        if (item.id === coachId) {
                            item.content = response.list[0].content
                            item.chat_date = response.list[0].create_date
                        }
                        return item
                    }))
                }

            }
        );
    }

    const onSend = () => {
        let new_chat = chat.replace(/ /g, "")
        if (((chat != "" && new_chat != '') || fileList.length != 0) && coachId != -1) {
            if (urlList.length != 0) {
                let formData = new FormData();
                urlList.forEach((entry, index) => {
                    formData.append('file', entry);
                });
                context.post(
                    'upload/images/coach',
                    formData,
                    response => {
                        context.post(
                            "coach/sendChat",
                            {
                                coach: coachId,
                                content: chat,
                                file: response.image
                            },
                            response => {
                                setChat("");
                                setUrlList([]);
                                setFileList([]);
                                getChatList(true);
                            }
                        );
                    });
            } else {
                context.post(
                    "coach/sendChat",
                    {
                        coach: coachId,
                        content: chat,
                        file: ""
                    },
                    response => {
                        setChat("");
                        getChatList(true);
                    }
                );
            }
        }
    };

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const handleEnterChat = e => {
        if (e.keyCode == 13) {
            onSend()
        }
    };

    const onClickCoach = (index) => {
        setCoachId(coachList[index].id);
        setSelectedCoach(coachList[index]);
        setCoachList(prev => prev.map((item, idx) => {
            if (index == idx) {
                item.unread_cnt = 0
            }
            return item
        }))
    }

    const onImageChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            setFileList([...fileList, reader.result]);
            setUrlList([...urlList, file]);
        };
        reader.readAsDataURL(file);
    };

    const delFile = (idx) => {
        setFileList(preList => preList.filter((item, index) => {
            return idx != index
        }));
        setUrlList(preList => preList.filter((item, index) => {
            return idx != index
        }));
    }

    const onDownload = (item) => {
        context.download(
            "GET",
            "clinic/download",
            {
                id: item.id
            },
            response => {
            }
        );
    }

    const onStartTest = (item) => {
        setTestItem(item);
        setShowStartTest(true);
    }

    const onQuestionAnswer = (item, answer) => {
        context.post(
            "coach/answerQuestion",
            {
                chat_id: item.id,
                answer: answer
            },
            response => {
                getChatList(true);
            }
        );
    }

    const onShowDoc = (docCode) => {
        setDocUrl('uploads/aistudy/' + docCode + '.pdf');
        setShowDoc(true);
    }
    
    const onShowVideo = (url) => {
        setYoutubeUrl(url);
        setShowVideo(true);
    }

    const getAnswer = () => {
        const code = JSON.parse(testItem.content).code
        context.get(
            "coach/getTestAnswer",
            {
                code: code,
            },
            response => {
                const answer = response.answer;
                setTestAnswer(answer.split('#'));
                setShowAnswer(true);
            }
        );
    }

    const onSubmitAnswer = (answers) => {

        context.post(
            "coach/testComplete",
            {
                chat_id: testItem.id,
                answer: answers.join('#'),
                time: testTime
            },
            response => {
                getChatList(true);
            }
        );
    }

    return (
        <div className="main-content">
            <CloudContainer/>
            <div className="ai-coach-bg">
                <div className="row height-full" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className={("coach-list ") + ("col-sm-3")}>
                        <div className="d-flex align-item-center p-20">
                            <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                                className="dropdown navbar-user rank-dropdown" tag="li" style={{ width: 80 }}>
                                <DropdownToggle tag="a"
                                    className="display-flex-important align-item-center sort-dropdown"
                                    style={{ background: "white", margin: 0 }}>
                                    <div className="flex-grow-1"
                                        style={{ color: "#3f3f3f" }}>{strings.clinic_list[sortCode].name}</div>
                                    <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                                    tag="ul">
                                    {
                                        strings.clinic_list.map((item, idx) =>
                                            <DropdownItem key={idx}
                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                onClick={() => {
                                                    setSortCode(idx);
                                                    loadCoachList(keyword);
                                                }}
                                            >
                                                {item.name}
                                            </DropdownItem>
                                        )
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            <button className="common-button m-l-auto"
                                onClick={() => setShowSearch(!showSearch)}>
                                <img src={"/assets/image/icon_search_black.png"} alt="" />
                            </button>
                        </div>
                        {
                            showSearch &&
                            <div className="position-relative m-b-10 m-r-10 m-l-10">
                                <input
                                    value={keyword}
                                    className="search-expert"
                                    type={"text"} onChange={(e) => setKeyword(e.target.value)}
                                    onKeyDown={handleEnter}
                                />
                                <button className="search-button" onClick={() => onSearch()}>
                                    <img src={"/assets/image/icon_search_black.png"} alt="" />
                                </button>
                            </div>
                        }
                        <PerfectScrollbar style={{ height: "calc(100% - 70px)", paddingLeft: 10, paddingRight: 10 }} options={{ suppressScrollX: true }}>
                            {
                                coachList.length != 0 &&
                                coachList.map((item, idx) => {
                                    return (
                                        <CoachListItem
                                            selected={item.id == coachId}
                                            key={idx}
                                            item={item}
                                            onClick={() => {
                                                onClickCoach(idx)
                                            }}
                                        />
                                    )
                                })
                            }
                        </PerfectScrollbar>

                    </div>
                    <div className={("chat-area ") + ("col-sm-9")}>
                        {
                            coachId == -1 &&
                            <div className="chat-empty">
                                <img src={"/assets/image/icon_ai_coach.png"} alt="" />
                                <div className="conversation">{strings.chat_with_coach}</div>
                            </div>
                        }
                        <div className={"chat-input-container" + (coachId == -1 ? " d-none" : "")}>
                            <input type='file' accept='image/*' className='hide'
                                onChange={(e) => onImageChange(e, fileList.length)}
                                ref={imageRef} />
                            <input placeholder={strings.coach_chat_placeholder}
                                type={"text"}
                                value={chat} onChange={(e) => setChat(e.target.value)}
                                onKeyDown={handleEnterChat}
                            />
                            <div>
                                {
                                    fileList.map((item, idx) => {
                                        return (
                                            <div className="position-relative m-r-15" style={{ width: 75 }} key={idx}>
                                                <img style={{ width: 75, height: 75, borderRadius: 5 }} src={item} alt="" />
                                                <button
                                                    style={{
                                                        border: "none",
                                                        background: "none",
                                                        position: "absolute",
                                                        top: -10,
                                                        right: -20
                                                    }}
                                                    onClick={() => {
                                                        delFile(idx)
                                                    }}
                                                >
                                                    <img src={"/assets/image/btn_delete.png"} alt="" />
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="d-flex m-t-20">
                                <button className="emoticon" onClick={() => {
                                    if (fileList.length == 0) {
                                        imageRef?.current.click()
                                    }
                                }}>
                                    <img src={"/assets/image/icon_link.png"} style={{ width: 35, height: 35 }} alt="" />
                                </button>
                                <button
                                    className={("send ") + (((chat != "" && chat.replace(/ /g, "") != "") || fileList.length != 0) && coachId != -1 ? "send-active" : "")}
                                    onClick={() => onSend()}>{strings.send}</button>
                            </div>
                        </div>
                        <div className={"coach-chat-area p-t-10" + (coachId == -1 ? " d-none" : "")}>
                            {
                                chatList.length != 0 &&
                                chatList.map((item, idx) => {
                                    return (
                                        <CoachChatItem
                                            mine={userId == item.sender}
                                            key={idx}
                                            item={item}
                                            coach={selectedCoach}
                                            onDownload={(item) => onDownload(item)}
                                            onStartTest={(item) => onStartTest(item)}
                                            onUse={(item, use) => onQuestionAnswer(item, use)}
                                            onShowDoc={(code) => onShowDoc(code)}
                                            onShowVideo={(url) => onShowVideo(url)}
                                        />
                                    );
                                })
                            }
                        </div>
                        <div className={"chat-area-top" + (coachId == -1 ? " d-none" : "")}>
                            <img className="profile"
                                src={selectedCoach.image != null ? context.loadImage(selectedCoach.image) : "/assets/image/logo_big.png"}
                                alt="" />
                            <div className="name">{"AI " + selectedCoach.name}</div>
                            <div className="function-buttons">
                                <button className="search" onClick={() => onChatSearch()}>
                                    <img src={"/assets/image/icon_search_black.png"} alt="" />
                                </button>
                                <button className="settings" onClick={() => onChatSettings()}>
                                    <img src={"/assets/image/icon_settings_black.png"} alt="" />
                                </button>
                                <button className="roadmap" onClick={() => onRoadMap()}>
                                    {selectedCoach.name + " 로드맵"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showStartTest &&
                <StartTestModal
                    show={showStartTest}
                    toggle={() => setShowStartTest(!showStartTest)}
                    onStart={() => {
                        setShowTest(true);
                        setShowStartTest(false);
                    }}
                    item={testItem}
                />
            }
            {
                showTest &&
                <CoachingTestModal
                    show={showTest}
                    toggle={() => setShowTest(!showTest)}
                    onSubmit={(time) => {
                        setTestTime(time);
                        setShowTest(false);
                        getAnswer();
                    }}
                    item={JSON.parse(testItem.content).code}
                />
            }
            {
                showAnswer &&
                <CoachingAnswerModal
                    show={showAnswer}
                    toggle={() => setShowAnswer(!showAnswer)}
                    onConfirm={(wrongAnswers) => {
                        setShowAnswer(false);
                        onSubmitAnswer(wrongAnswers);
                    }}
                    answers={testAnswer}
                />
            }
            {
                showVideo &&
                <YoutubeViewModal
                    show={showVideo}
                    toggle={() => setShowVideo(!showVideo)}
                    url={youtubeUrl}
                />
            }
            {
                showDoc &&
                <DocViewModal 
                    show={showDoc}
                    toggle={() => setShowDoc(!showDoc)}
                    url={docUrl}
                />
            }
        </div>
    );
}
export default withRouter(ChatCoaching);

import React, {useContext, useEffect} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {getGradeFromBirthday, getTimeFromSecond} from "../../../helper/common";

const MemberInfoModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle, memberInfo} = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="member-info-modal">
            <ModalBody>
                <div className='d-flex m-b-10 align-item-center'>
                    <div className='f-s-20 f-w-600 m-l-10'>{strings.info}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center m-b-20">
                    {
                        memberInfo != "" &&
                        <img
                            className="member-profile"
                            src={memberInfo.user.profile != null ? context.loadImage(memberInfo.user.profile) : "/assets/image/logo_big.png"}
                            alt=""/>
                    }
                </div>
                <div className="member-nick">{memberInfo.user?.nickname ?? ""}</div>
                <div className="info-cont">
                    <div className="d-flex width-full m-b-20">
                        <div className="title">{strings.name}</div>
                        <div className="info-detail text-ellipsis">{memberInfo.user?.name ?? ""}</div>
                    </div>
                    <div className="d-flex m-b-20">
                        <div className="title">{strings.age}</div>
                        {
                            memberInfo != "" &&
                            <div>{getGradeFromBirthday(memberInfo.user.birthday)}</div>
                        }
                    </div>
                    <div className="d-flex width-full">
                        <div className="title">{strings.school}</div>
                        <div className="info-detail text-ellipsis">{memberInfo.user?.school ?? ""}</div>
                    </div>
                </div>
                <div className="f-s-18 f-w-600 m-b-10">{strings.study_time}</div>
                <div className="study-time-cont">
                    <div className="flex-1 text-center">
                        <div className="time-indicator">{strings.today}</div>
                        <div className="f-s-15">{getTimeFromSecond(parseInt(memberInfo.user?.today_study_time ?? 0))}</div>
                    </div>
                    <div className="vertical-divider"/>
                    <div className="flex-1 text-center">
                        <div className="time-indicator">{strings.real_time}</div>
                        <div
                            className="f-s-15 real-time">{getTimeFromSecond(parseInt(memberInfo.user?.this_month_real_time ?? 0))}</div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default MemberInfoModal;

import React, {useContext, useEffect, useRef, useState} from "react";
import GoalInput from "../../components/control/custom_input/goal_input";
import PeriodButton from "../../components/control/button/period_button";
import Status from "./status";
import TodoList from "./todo_list";
import MissionComponent from "./mission_component";
import strings from "../../lang/strings";
import ClinicTab from "./clinic_tab";
import {rootStore} from "../../mobx/store";
import {observer} from "mobx-react";
import {Page_settings} from "../../config/page_settings";
import {withRouter} from "react-router-dom";
import moment from "moment";

const Dashboard = observer((props) => {
    const context = useContext(Page_settings);
    const [goalToday, setGoalToday] = useState("");
    const [userName, setUserName] = useState("");
    const [edit, setEdit] = useState(true);
    const goalRef = useRef();
    const [todo, setTodo] = useState("");
    const [selectedTag, setSelectedTag] = useState(0);
    const [tagColor, setTagColor] = useState("");
    const [tagTitle, setTagTitle] = useState("");
    const [inputStatus, setInputStatus] = useState(0);
    const inputRef = useRef();

    const [tagList, setTagList] = useState([]);
    const periodList = ["HOUR", "DAY", "MONTH"];

    const [realTimeToday, setRealTimeToday] = useState(0);
    const [efficiencyToday, setEfficiencyToday] = useState(0);
    const [todoTotal, setTodoTotal] = useState(0);
    const [todoDone, setTodoDone] = useState(0);
    const [goalAchToday, setGoalAchToday] = useState(0);
    const [goalAchieve, setGoalAchieve] = useState(0);
    const [realTimeYesterday, setRealTimeYesterday] = useState(0);
    const [goalAchYesterday, setGoalAchYesterday] = useState(0);
    const [efficiencyYesterday, setEfficiencyYesterday] = useState(0);
    const [period, setPeriod] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [goalDone, setGoalDone] = useState(0);
    const [goalTotal, setGoalTotal] = useState(0);
    const [totalTimeToday, setTotalTimeToday] = useState(0);
    const [totalTimeYesterday, setTotalTimeYesterday] = useState(0);
    const [missionYesterday, setMissionYesterday] = useState(0);
    const [missionTotalYesterday, setMissionTotalYesterday] = useState(0);
    const [missionToday, setMissionToday] = useState(0);
    const [missionTotalToday, setMissionTotalToday] = useState(0);
    const [down3, setDown3] = useState([]);
    const [up3, setUp3] = useState([]);

    const [studyMissionList, setStudyMissionList] = useState([]);
    const [plannerMissionList, setPlannerMissionList] = useState([]);
    const [cabinetMissionList, setCabinetMissionList] = useState([]);

    useEffect(() => {
        if (efficiencyToday * 100 < 30) {
            setStatusId(0)
        } else if (efficiencyToday * 100 <= 50) {
            setStatusId(1)
        } else if (efficiencyToday * 100 <= 70) {
            setStatusId(2)
        } else if (efficiencyToday * 100 <= 90) {
            setStatusId(3)
        } else if (efficiencyToday * 100 <= 100) {
            setStatusId(4)
        }
    }, [efficiencyToday])

    useEffect(() => {
        setUserName(rootStore.getProfile?.name ?? "")
    }, [rootStore.getProfile]);

    useEffect(() => {
        getGoal();
        getMissionList("");
        getTagList();
    }, []);

    useEffect(() => {
        if (!edit) {
            goalRef?.current.focus()
        }
    }, [edit]);

    useEffect(() => {
        if (inputStatus == 1) {
            inputRef?.current.focus()
        }
    }, [inputStatus])

    useEffect(() => {
        if (tagList.length != 0) {
            setTagColor(tagList[selectedTag].color);
            setTagTitle(tagList[selectedTag].title);
        }
    }, [selectedTag, tagList]);

    useEffect(() => {
        getCompareData(periodList[period]);
    }, [studyMissionList, cabinetMissionList, plannerMissionList, period, tagList])

    useEffect(() => {
        let start_date = "";
        let end_date = "";
        if (period == 0) {
            start_date = moment();
            end_date = moment();
        } else if (period == 1) {
            start_date = moment().startOf('week');
            end_date = moment();
        } else if (period == 2) {
            start_date = moment().startOf("month");
            end_date = moment();
        }
        getPlannerData(start_date, end_date)
    }, [period, tagList]);

    const getMissionList = (type) => {
        let studyList = [];
        let cabinetList = [];
        let plannerList = [];
        context.get(
            "member/getMissionList",
            {
                type: type
            },
            response => {
                response.list.forEach(item => {
                    if (item.type == "STUDY") {
                        studyList.push(item)
                    } else if (item.type == "CABINET") {
                        cabinetList.push(item)
                    } else if (item.type == "CLINIC") {
                        plannerList.push(item)
                    }
                });
                setStudyMissionList(studyList);
                setCabinetMissionList(cabinetList);
                setPlannerMissionList(plannerList);
            }
        );
    }

    const getGoal = () => {
        context.get(
            "member/getGoal",
            {},
            response => {
                if (response.goal_info != null) {
                    setGoalToday(response.goal_info.title);
                }
            }
        );
    }

    const updateGoal = () => {
        context.post(
            "member/updateGoal",
            {
                title: goalToday,
            },
            response => {
                setEdit(true);
            }
        );
    }

    const onClickMission = (item) => {
        context.post(
            "member/changeMissionStatus",
            {
                id: item.id,
                status: item.status == "Y" ? "N" : "Y"
            },
            response => {
                if (item.type == "STUDY") {
                    setStudyMissionList(prev => prev.map((it, idx) => {
                        if (it.id == item.id) {
                            it.status = it.status == "Y" ? "N" : "Y"
                        }
                        return it
                    }))
                } else if (item.type == "CABINET") {
                    setCabinetMissionList(prev => prev.map((it, idx) => {
                        if (it.id == item.id) {
                            it.status = it.status == "Y" ? "N" : "Y"
                        }
                        return it
                    }))
                } else if (item.type == "CLINIC") {
                    setPlannerMissionList(prev => prev.map((it, idx) => {
                        if (it.id == item.id) {
                            it.status = it.status == "Y" ? "N" : "Y"
                        }
                        return it
                    }))
                }
            }
        );
    }

    const getCompareData = (mode) => {
        context.get(
            "datalab/getCompareData",
            {
                mode: mode
            },
            response => {
                setRealTimeToday(parseInt(response.today.study_real_time));
                setRealTimeYesterday(parseInt(response.yesterday.study_real_time) / 3600);
                setGoalAchToday(response.today.todo_total_count != 0 ? response.today.todo_complete_count / response.today.todo_total_count : 0);
                setGoalTotal(response.today.todo_total_count);
                setGoalDone(response.today.todo_complete_count);
                setGoalAchYesterday(response.yesterday.todo_total_count != 0 ? response.yesterday.todo_complete_count / response.yesterday.todo_total_count : 0);
                setEfficiencyToday(parseInt(response.today.study_total_time) != 0 ? parseInt(response.today.study_real_time) / parseInt(response.today.study_total_time) : 0)
                setEfficiencyYesterday(parseInt(response.yesterday.study_total_time) != 0 ? parseInt(response.yesterday.study_real_time) / parseInt(response.yesterday.study_total_time) : 0)
                setTodoDone(response.today.todo_complete_count);
                setTodoTotal(response.today.todo_total_count);
                setMissionToday(parseInt(response.today.mission_complete_count));
                setMissionTotalToday(parseInt(response.today.mission_total_count));
                setMissionYesterday(parseInt(response.yesterday.mission_complete_count));
                setMissionTotalYesterday(parseInt(response.yesterday.mission_total_count));
                setTotalTimeYesterday(parseInt(response.yesterday.study_total_time) / 3600);
                setTotalTimeToday(parseInt(response.today.study_total_time) / 3600);
            }
        );
    }

    const getPlannerData = (from, to) => {
        context.get(
            "datalab/getPlannerData",
            {
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                setGoalDone(response.todo.complete_count);
                setGoalTotal(response.todo.total_count);
                if (response.todo.total_count != 0) {
                    setGoalAchieve(parseInt(response.todo.complete_count) / parseInt(response.todo.total_count) * 100)
                } else {
                    setGoalAchieve(0)
                }
                setUp3(response.tag_top)
                setDown3(response.tag_low)
            }
        );
    }

    const onAddTarget = () => {
        if (tagList.length == 0) {
            props.history.push("/cabinet/cloud/planner");
        } else {
            if (inputStatus == 0) {
                setInputStatus(1);
                return;
            }
            if (todo == "") return
            context.post(
                'planner/addTodo',
                {
                    show_date: moment().format('YYYY-MM-DD'),
                    tag: tagList[selectedTag].id,
                    title: todo,
                    id: ''
                },
                response => {
                    getTagList();
                    setTodo("");
                    setInputStatus(0);
                }
            );
        }
    }

    const onCheck = (idx) => {
        let tag = tagList[selectedTag];
        let status = tag.todo_list[idx].status == 'Y' ? 'N' : 'Y';
        context.post(
            'planner/changeTodoStatus',
            {
                id: tag.todo_list[idx].id,
                status: status
            },
            response => {
                getTagList();
            }
        );
    }

    const getTagList = () => {
        context.get(
            'planner/getTagList',
            {
                planner_date: moment(moment.now()).format("YYYY-MM-DD"),
            },
            response => {
                setTagList(response.list);
            }
        );
    }

    return (
        <div className="main-content">
            <div className="display-flex-important user-name-goal">
                <div className="user-name">{userName}</div>
                <GoalInput
                    placeholder={strings.write_goal_today}
                    inputType={'text'}
                    value={goalToday}
                    onChange={(v) => {
                        setGoalToday(v)
                    }}
                    disabled={edit}
                    onEnter={() => updateGoal()}
                    onPencil={() => setEdit(!edit)}
                    onBlur={() => updateGoal()}
                    inputRef={goalRef}
                />
            </div>
            <div className="display-flex-important col-lg-8 p-0">
                <button className="common-button col-md-6 p-0 text-left">
                    <div className="to-goal">{strings.to_my_goal}</div>
                </button>
                <div className="display-flex-important col-md-6 p-0" style={{ justifyContent: 'flex-end' }}>
                    {
                        strings.period_list.map((item, index) =>
                            <PeriodButton
                                key={index}
                                selected={item.code == period}
                                onClick={() => setPeriod(item.code)}
                                value={item.name}
                            />
                        )
                    }
                </div>
            </div>
            <div className="row">
                <Status
                    realTimeToday={realTimeToday}
                    efficiency={efficiencyToday}
                    todoTotal={todoTotal}
                    todoDone={todoDone}
                    goalAchToday={goalAchToday}
                    goalAchieve={goalAchieve}
                    realTimeYesterday={realTimeYesterday}
                    goalAchYesterday={goalAchYesterday}
                    efficiencyYesterday={efficiencyYesterday}
                    period={period}
                    statusId={statusId}
                    total={goalTotal}
                    goalDone={goalDone}
                    missionToday={missionToday}
                    missionTotalToday={missionTotalToday}
                    missionYesterday={missionYesterday}
                    missionTotalYesterday={missionTotalYesterday}
                    totalTimeYesterday={totalTimeYesterday}
                    totalTimeToday={totalTimeToday}
                    up3={up3}
                    down3={down3}
                />
                <TodoList
                    tagList={tagList}
                    onCheck={(idx) => onCheck(idx)}
                    onAddTarget={() => {
                        onAddTarget()
                    }}
                    onChangeTodo={(v) => setTodo(v)}
                    onMoveSlide={(slide) => {
                        if (slide == tagList.length) {
                            setSelectedTag(0)
                        } else {
                            setSelectedTag(slide)
                        }
                    }}
                    tagColor={tagColor}
                    tagTitle={tagTitle}
                    todo={todo}
                    inputStatus={inputStatus}
                    inputRef={inputRef}
                />
            </div>
            <div className="today-mission">{strings.today_mission}</div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <MissionComponent
                            theme={"blue"}
                            title={strings.study_cafe_mission}
                            missionList={studyMissionList}
                            onClick={(item) => onClickMission(item)}
                        />
                        <MissionComponent
                            theme={"green"}
                            title={strings.clinic_mission}
                            missionList={plannerMissionList}
                            onClick={(item) => onClickMission(item)}
                        />
                        <MissionComponent
                            theme={"yellow"}
                            title={strings.cabinet_mission}
                            missionList={cabinetMissionList}
                            onClick={(item) => onClickMission(item)}
                        />
                    </div>
                </div>
                <ClinicTab
                    onClick={() => props.history.push("/clinic")}
                />
            </div>
        </div>
    );
});

export default withRouter(Dashboard);

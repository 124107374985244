import React, {useContext, useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";
import {observer} from "mobx-react";

const ChangePasswordModal = observer((props) => {
    const context = useContext(Page_settings);
    const {show, toggle} = props;
    const [email, setEmail] = useState("");
    const [oldPwd, setOldPwd] = useState("");
    const [newPwd, setNewPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [newPwdAlert, setPwdAlert] = useState("");
    const [confirmAlert, setConfirmAlert] = useState("");
    const [signType, setSignType] = useState("");

    useEffect(() => {
        setEmail(rootStore.getProfile?.email ?? "");
        setSignType(rootStore.getProfile?.login_type ?? "");
    }, [rootStore.getProfile]);

    const onBack = () => {
        context.handleChangePasswordModal(false);
        context.handleAccountModal(true);
    }

    const onConfirm = () => {
        if (oldPwd != "" && newPwd != "" && confirmPwd != "") {
            if (newPwd.length < 8) {
                setPwdAlert(true);
            } else if (newPwd != confirmPwd) {
                setConfirmAlert(true)
            } else {
                context.post(
                    "member/changePwd",
                    {
                        old_pwd: oldPwd,
                        pwd: newPwd
                    },
                    response => {
                        onBack()
                    }
                );
            }
        }
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className={"p-30"}>
                <div className='d-flex m-b-40'>
                    <button className='modal-close-btn p-l-0' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back_black.png"} alt="" className="modal-back"/>
                    </button>
                    <div className='f-s-20 f-w-600'>{strings.change_password}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                {
                    signType == "EMAIL" &&
                    <div>
                        <div className="f-s-16 f-w-600 m-t-10 m-b-10">{strings.email}</div>
                        <div className="account-info m-b-25">{email}</div>
                        <div className="f-s-16 f-w-600 m-b-10">{strings.old_password}</div>
                        <div className="account-info account-info-input m-b-25">
                            <input
                                type={"password"}
                                onChange={(v) => setOldPwd(v.target.value)}
                                className="input-transparent f-s-16"
                                value={oldPwd}
                                placeholder={strings.enter_password}
                            />
                        </div>
                        <div className="f-s-16 f-w-600 m-b-10">{strings.new_password}</div>
                        <div
                            className={("account-info account-info-input ") + (newPwdAlert ? "signup-error" : "m-b-25")}>
                            <input
                                type={"password"}
                                onChange={(v) => {
                                    setNewPwd(v.target.value);
                                    setPwdAlert(false);
                                }}
                                className="input-transparent f-s-16"
                                value={newPwd}
                                placeholder={strings.password_placeholder}
                            />
                        </div>
                        {
                            newPwdAlert &&
                            <div className="sign-error-text m-b-25">{strings.invalid_password}</div>
                        }
                        <div className="f-s-16 f-w-600 m-b-10">{strings.password_confirm}</div>
                        <div className={("account-info account-info-input ") + (confirmAlert ? "signup-error" : "")}>
                            <input
                                type={"password"}
                                onChange={(v) => {
                                    setConfirmPwd(v.target.value);
                                    setConfirmAlert(false);
                                }}
                                className="input-transparent f-s-16"
                                value={confirmPwd}
                                placeholder={strings.re_enter_password}
                            />
                        </div>
                        {
                            confirmAlert &&
                            <div className="sign-error-text">{strings.wrong_password}</div>
                        }
                        <button onClick={() => onConfirm()}
                                className={"tag-create-btn-disable " + ((oldPwd != "" && newPwd != "" && confirmPwd != "") ? "tag-create-btn" : "")}>{strings.confirm}</button>
                    </div>
                }
                {
                    signType != "EMAIL" &&
                    <div>
                        <div className="google-sign m-b-30">
                            <img src={"/assets/image/btn_" + (signType == "GOOGLE" ? "google" : "naver") + ".png"} alt=""/>
                            {email}
                        </div>
                        <div className="google-sign-info">{strings.you_cannot_change_password_sns}</div>
                        <button onClick={() => onBack()}
                                className="tag-create-btn-disable tag-create-btn">{strings.confirm}</button>
                    </div>
                }

            </ModalBody>
        </Modal>
    );
})

export default ChangePasswordModal;

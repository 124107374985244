import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import ReactPlayer from "react-player";
import {Page_settings} from "../../../config/page_settings";

const VideoModal = (props) => {
        const context = useContext(Page_settings)
        const {show, close, toggle, onBack, item} = props;
        return (
            <Modal isOpen={show} toggle={() => toggle()} className='item-search-modal video-modal'>
                <ModalBody style={{backgroundColor: '#232228', borderRadius: 20, padding: 0}}>
                    <div className='d-flex header align-item-center m-b-20'>
                        <button className='modal-close-btn' onClick={() => onBack()}>
                            <img src={"/assets/image/icon_arrow_back.png"} alt="" className="modal-close"/>
                        </button>
                        <div className='flex-grow-1'/>
                        <button className='modal-close-btn' onClick={() => close()}>
                            <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                        </button>
                    </div>
                    <ReactPlayer
                        url={context.loadImage(item.url)}
                        height={360}
                        width={740}
                        controls={true}
                    />
                    <div className={"d-flex m-r-20 m-t-10"}>
                        <div style={{color:"white"}}>
                            <div className="f-s-15 m-b-5 video-title">{item.title}</div>
                            <div style={{fontSize:14, color:"#88868e"}}>{item.company}</div>
                        </div>
                        <div className="code">{item.code}</div>
                    </div>
                </ModalBody>
            </Modal>
        );
}

export default VideoModal;

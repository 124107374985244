import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'

const NewSettingModal = (props) => {
    const { show, toggle, mine, onScreenSettings, onRoomInfo } = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="setting-modal">
            <ModalBody className="p-30">
                <div className='d-flex modal-title-custom'>
                    <div className='f-s-24 f-w-600'>{strings.settings}</div>
                    <div className='flex-grow-1' />
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close" />
                    </button>
                </div>
                {
                    (mine == undefined || mine == true ) &&
                    <button className='d-flex align-item-center common-button width-100-percent'
                        onClick={() => onScreenSettings()}>
                        <div className='f-s-19 f-w-400'>{strings.screen_settings}</div>
                        <div className='flex-grow-1' />
                        <img src={"/assets/image/icon_carousel_right_white.png"} alt="" className="modal-next" />
                    </button>
                }
                <button className='d-flex m-t-40 align-item-center common-button width-100-percent'
                    onClick={() => onRoomInfo()}>
                    <div className='f-s-19 f-w-400'>{strings.room_info}</div>
                    <div className='flex-grow-1' />
                    <img src={"/assets/image/icon_carousel_right_white.png"} alt="" className="modal-next" />
                </button>
            </ModalBody>
        </Modal>
    );
}

export default NewSettingModal;

import React from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";

const MentorModal = (props) => {

    const {show, close, toggle, onAsk, onQuestionList} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='mentor-chew-modal'>
            <ModalBody style={{backgroundColor: '#232228', borderRadius: 20, padding: 0}}>
                <div className='d-flex header align-item-center m-b-20'>
                    <img src={"/assets/image/icon_3_color_bubble.png"} alt=""
                         style={{width: 75, height: 60, position: "absolute", top: -35, left: 50}}/>
                    <div style={{marginTop: 25, color: "white", fontSize: 20}}>{strings.mentor_chew}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="m-t-20 f-s-16" style={{color: "#4795e1"}}>{strings.mentor_ask}</div>
                <div className={"d-flex"}>
                    <button className={"question-button"}
                            onClick={() => onQuestionList()}>{strings.question_list}</button>
                    <button className={"ask-button"} onClick={() => onAsk()}>
                        {strings.ask}
                        <img src={"/assets/image/icon_arrow_right.png"} alt=""/>
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default MentorModal;

import React from "react";

export default function PlanAddButton(props) {
    return (
        <button
            className="plan-add-button"
            onClick={props.onClick}>
            <img src={"/assets/image/icon_plus_white.png"} alt=""/>
        </button>
    );
}

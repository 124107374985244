import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import PerfectScrollbar from 'react-perfect-scrollbar';
import QuestionListItem from "../item/question_list_item";

const QuestionListModal = (props) => {
    const {show, data, close, toggle, onAsk, onRead, onKeyword, onSearchKeyword, keyword, onLoadMore} = props;
    const [scroll, setScroll] = useState(0)

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearchKeyword()
        }
    };

    const onEnd = () => {
        if (scroll <= 3) {
            setScroll(scroll + 1)
        }
        if (scroll == 2) {
            onLoadMore(scroll)
        }
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className='item-search-modal question-list-modal'>
            <ModalBody style={{backgroundColor: '#232228', borderRadius: 20, padding: 0}}>
                <div className='d-flex header align-item-center m-b-20'>
                    <div className='f-s-16' style={{color: "white"}}>{strings.question_list}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="d-flex flex-grow-1">
                    <div className="search-box flex-grow-1">
                        <input type={"text"} className="input-transparent"
                               value={keyword} placeholder={strings.item_search_placeholder}
                               onChange={(v) => onKeyword(v.target.value)}
                               onKeyDown={handleEnter}
                        />
                        <button style={{background: "none", border: "none"}} onClick={() => onSearchKeyword()}>
                            <img src={"/assets/image/icon_search_white.png"} alt=""/>
                        </button>
                    </div>
                    <button className="search-box" style={{width: "110px", background: "#0f84f4", justifyContent: "center"}}
                            onClick={() => onAsk()}>
                        {strings.ask_a_question}
                    </button>
                </div>
                <PerfectScrollbar style={{height: 350, marginTop: 10}} options={{suppressScrollX: true}} onYReachEnd={() => onEnd()}
                                  onScrollUp={() => setScroll(0)} onYReachStart={() => setScroll(0)}>
                    {
                        data.map((item, idx) =>{
                            return(
                                <QuestionListItem
                                    key={idx}
                                    item={item}
                                    onClick={() => onRead(item)}
                                />
                            )
                        }


                        )
                    }
                </PerfectScrollbar>

            </ModalBody>
        </Modal>
    );
}

export default QuestionListModal;

import React, { useContext, useState, useRef, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import strings from '../../../lang/strings';
import PerfectScrollbar from "react-perfect-scrollbar";
import { getAchievementRate } from '../../../helper/common';

const RoadmapViewModal = (props) => {
    const context = useContext(Page_settings)
    const { item, show, toggle, testScore, courseList, onView, onSolve, onCourseItem } = props;

    const [roadMapList, setRoadMapList] = useState([]);

    const loadRoadmapList = () => {
        context.get(
            "coach/getRoadmapAllData",
            {
                coach: 1,
                type: 0
            },
            response => {
                const list = response.list;
                setRoadMapList(list);
            }
        );
    }

    const checkCourseContains = (code) => {
        let result = false;
        courseList.map((course, index) => {
            if (course.code.split('_')[1] == code) {
                result = true;
            }
        });

        return result;
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="roadmap-view-modal" onOpened={() => loadRoadmapList()}>
            <ModalBody>
                <div className="d-flex align-items-center m-b-30">
                    <div className="title flex-grow-1">{item.name}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div className='view-main'>
                    <PerfectScrollbar style={{ height: '100%' }}>
                        {
                            roadMapList.map((mapItem, index) => (
                                <div key={index} className="d-flex align-items-center" style={{ marginTop: 53, marginBottom: index == roadMapList.length - 1 ? 20 : 0 }}>
                                    <div className="roadmap-subject-title">{mapItem.name}</div>
                                    {
                                        mapItem.list.map((subItem, index) => (
                                            <div key={index} className={"roadmap-middle-item"} style={{ marginRight: (index < mapItem.list.length - 1) ? 18 : 0 }}>
                                                <div className="name">{subItem.name}</div>
                                                {
                                                    subItem.list.map((studyItem, index) => (
                                                        <div key={index} className={"sub-item" + (checkCourseContains(studyItem.code) ? " active" : "") + (getAchievementRate(subItem.testScore, studyItem.watch_count, studyItem.solve_count) == 100 ? ' done' : getAchievementRate(subItem.testScore, studyItem.watch_count, studyItem.solve_count) > 0 ? ' progress' : '')}
                                                            style={{ marginRight: (index < subItem.list.length - 1) ? 12 : 0 }}>
                                                            {studyItem.name}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </PerfectScrollbar>
                </div>
                <div className='d-flex align-items-center' style={{ marginTop: 22 }}>
                    <div className='info'>{strings.video_count}</div>
                    <div className='info-data' style={{ marginLeft: 6 }}>{item.watch_count + strings._times}</div>
                    <div className='info' style={{ marginLeft: 26 }}>{strings.solve_count}</div>
                    <div className='info-data' style={{ marginLeft: 6 }}>{item.solve_count + strings._times}</div>
                    <div className='info' style={{ marginLeft: 26 }}>{strings.achievement_rate}</div>
                    <div className='info-data' style={{ marginLeft: 6 }}>{getAchievementRate(testScore, item.watch_count, item.solve_count) + '%'}</div>
                </div>
                <div className='course-text'>{strings.roadmap_course}</div>
                <div className='width-full' style={{ marginTop: 12 }}>
                    <PerfectScrollbar options={{ suppressScrollY: true }}>
                        <div className='d-flex align-items-center'>
                            {
                                courseList.map((course, index) => (
                                    <div key={index} className='d-flex align-items-center'>
                                        <div className={'course-item' + (course.progress == 1 ? ' done' : '')} onClick={() => onCourseItem(course)}>
                                            {course.name}
                                        </div>
                                        <div className={'course-item-line' + (index == courseList.length - 1 ? ' d-none' : '')} />
                                    </div>
                                ))
                            }
                        </div>
                    </PerfectScrollbar>
                </div>
                <div className="p-t-35 width-100-percent d-flex align-items-center">
                    <button className="buttons view-button" style={{ marginRight: 8 }} onClick={() => onView()}>
                        {strings.view_video}
                    </button>
                    <button className="buttons solve-button" onClick={() => onSolve()}>
                        {strings.solve_problem}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default RoadmapViewModal;

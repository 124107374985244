import React from "react";

export default function StudyRoomButton(props) {
    return (
        <button
            className={props.selected? "study-room-button-selected":"study-room-button"}
            onClick={props.onClick}>{props.value}
        </button>
    );
}

import React from "react";

export default function PlanInput(props) {
    return (
        <div className="plan-input-container">
            <img className="todo-check" src={"/assets/image/icon_todo_check_normal_white.png"} alt=""/>
            <input className="input-transparent plan-input" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength}
                   value={props.value} onChange={(e) => props.onChange(e.target.value)} onKeyDown={(e) => { if (e.keyCode == 13) { props.onAddPlan() } }}
            />
            <button className="check" onClick={props.onAddPlan}>
                <img src={'/assets/image/icon_check_white.png'} alt=""/>
            </button>
        </div>
    );
}


import {useContext, useEffect, useState} from "react";
import strings from "../../lang/strings";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import ExpertItem from "../../components/control/item/expert_item";
import {withRouter} from "react-router-dom";
import WriteReview from "../../components/control/modal/write_review";
import ReviewDetailModal from "../../components/control/modal/review_detail_modal";
import {Store} from "react-notifications-component";
import {Page_settings} from "../../config/page_settings";

const ClinicUser = (props) => {
    const context = useContext(Page_settings);
    const [keyword, setKeyword] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortCode, setSortCode] = useState(0);
    const [expertList, setExpertList] = useState([]);
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [reviewDetailShow, setReviewDetailShow] = useState(false);
    const [expert, setExpert] = useState("");
    const [star, setStar] = useState(0);
    const [findFav, setFindFav] = useState(false);

    useEffect(() => {
        loadExpertList("", "", "abc", 1);
    }, []);

    useEffect(() => {
        loadExpertList("", keyword, strings.expert_list[sortCode].code, 1);
    }, [sortCode]);

    const loadExpertList = (like, keyword, order, page_num) => {
        context.get(
            "clinic/getExpertList",
            {
                like: like,
                keyword: keyword,
                order: order,
                page_num: page_num
            },
            response => {
                setExpertList(response.list);
            }
        );
    }

    const onSearch = () => {
        loadExpertList(findFav ? 1 : "", keyword, strings.expert_list[sortCode].code, 1);
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const onFavourite = (item) => {
        context.post(
            "clinic/like",
            {
                expert_id: item.id,
                like: item.is_like ? -1 : 1
            },
            response => {
                setExpertList(prevState => prevState.map((it, index) => {
                    if (item.id == it.id) {
                        it.is_like = !it.is_like;
                    }
                    return it;
                }));
            }
        );
    }

    const onFindFavourite = () => {
        if (!findFav) {
            loadExpertList(1, keyword, strings.expert_list[sortCode].code, 1);
        } else {
            loadExpertList("", keyword, strings.expert_list[sortCode].code, 1);
        }
        setFindFav(!findFav)
    }

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const onReview = (expertId) => {
        props.history.push("/clinic/expert_detail/" + expertId + "/" + "2");
    }

    const onBook = (item) => {
        if (item.clinic_status == 1 || item.clinic_status == 3) {
            return
        }
        context.post(
            "clinic/postClinic",
            {
                expert_id: item.id
            },
            response => {
                setExpertList(prev => prev.map((it, idx) => {
                    if (item.id == it.id) {
                        if (it.clinic_status == 0) {
                            it.clinic_status = 1
                        } else if (it.clinic_status == 2) {
                            setExpert(item);
                            setReviewModalShow(true);
                        }
                    }
                    return it;
                }));
            }
        );
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-left',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const postReview = (review, fileList) => {
        context.post(
            "clinic/postReview",
            {
                clinic: expert.clinic_id,
                content: review,
                score: star,
                file: fileList.length != 0 ? fileList.join("#") : "",
            },
            response => {
                setReviewDetailShow(false);
                setExpertList(prev => prev.map((it, idx) => {
                    if (expert.id == it.id) {
                        it.clinic_status = 3
                    }
                    return it;
                }))
            }
        );
    }

    const onExpertDetail = (expertId) => {
        props.history.push("/clinic/expert_detail/" + expertId + "/" + "0");
    }

    const onWriteDone = (review, fileList) => {
        if (fileList.length != 0) {
            let formData = new FormData();
            fileList.forEach((entry, index) => {
                formData.append('file', entry);
            });

            context.post(
                'upload/images/room',
                formData,
                response => {
                    postReview(review, response.image);
                });
        } else {
            postReview(review, []);
        }

    }

    return (
        <div className="main-content">
            <button
                className={"consultant-tab consultant-selected"}
                onClick={() => window.location.reload()}>
                {strings.consultant_expert}
            </button>
            <button
                className={"consultant-tab"}
                onClick={() => props.history.push("/clinic/consult_history/-1")}>
                {strings.consultation_history}
            </button>
            <div className="search-area">
                <div className="position-relative m-b-10">
                    <input
                        value={keyword}
                        className="search-expert"
                        type={"text"} onChange={(e) => setKeyword(e.target.value)}
                        placeholder={strings.consulting_placeholder}
                        onKeyDown={handleEnter}
                    />
                    <button className="search-button" onClick={() => onSearch()}>
                        <img src={"/assets/image/icon_search_black.png"} alt=""/>
                    </button>
                </div>
                <div className="d-flex m-t-20 align-item-center">
                    <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                              className="dropdown navbar-user rank-dropdown" tag="li" style={{width: 120}}>
                        <DropdownToggle tag="a" className="display-flex-important align-item-center profile-dropdown"
                                        style={{background: "white", margin: 0}}>
                            <div className="flex-grow-1"
                                 style={{color: "#3f3f3f"}}>{strings.expert_list[sortCode].name}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt=""/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                            {
                                strings.expert_list.map((item, idx) =>
                                    <DropdownItem key={idx}
                                                  className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                  onClick={() => {
                                                      setSortCode(idx);
                                                  }}>
                                        {item.name}
                                    </DropdownItem>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                    <button className={("fav-button ") + (findFav ? "fav-on" : "")} onClick={() => onFindFavourite()}>
                        {strings.favourite}
                    </button>
                    <div className="tip">{strings.want_know_best_tip}</div>
                    <button className="fav-button"
                            onClick={() => window.open("https://www.chewing.ai/", "_blank")}
                            style={{width: 100, textAlign: "left", padding: "0 10px", marginLeft: 10}}>
                        <div className="flex-grow-1">{strings.go}</div>
                        <img src={"/assets/image/icon_arrow_right_black.png"} alt=""/>
                    </button>
                </div>
            </div>
            {
                expertList.map((item, idx) => {
                    return (
                        <ExpertItem
                            key={idx}
                            item={item}
                            onReview={() => onReview(item.id)}
                            onBook={() => onBook(item)}
                            onFavourite={() => onFavourite(item)}
                            onExpertDetail={() => onExpertDetail(item.id)}/>
                    );
                })
            }
            <WriteReview
                show={reviewModalShow}
                toggle={() => setReviewModalShow(!reviewModalShow)}
                close={() => setReviewModalShow(false)}
                expert={expert}
                onNext={(starCnt) => {
                    setStar(starCnt);
                    setReviewModalShow(false);
                    setReviewDetailShow(true);
                }}/>
            <ReviewDetailModal
                show={reviewDetailShow}
                toggle={() => setReviewDetailShow(!reviewDetailShow)}
                close={() => setReviewDetailShow(false)}
                expert={expert}
                onPhoto={() => {
                    addNotification('info', strings.in_progress)
                }}
                maxImageError={() => {
                    addNotification('warning', strings.you_cannot_select_more)
                }}
                onWrite={(review, fileList) => onWriteDone(review, fileList)}
            />
        </div>
    );

}
export default withRouter(ClinicUser);

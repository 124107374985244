import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import strings from "../../../lang/strings";


const MoreButton = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {onEdit, onDelete} = props;

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }
    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggle()}
                  className="icon-more">
            <DropdownToggle tag="a">
                <img src={"/assets/image/icon_more_gray.png"} alt=""/>
            </DropdownToggle>
            <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                <DropdownItem style={{borderBottom: "#494852 1px solid"}} onClick={() => onEdit()}>
                    {strings.edit}
                </DropdownItem>
                <DropdownItem onClick={() => onDelete()} style={{color: "#e96869"}}>
                    {strings.delete}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default MoreButton;

import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";

const TermDetailModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle, type} = props;

    const onBack = () => {
        context.handleTermModal(true);
        context.handleTermDetail(false);
    }
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className="p-30 h-540">
                <div className='d-flex m-b-5'>
                    <button className='modal-close-btn p-l-0' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back_black.png"} alt="" className="modal-back"/>
                    </button>
                    <div
                        className='m-l-10 f-s-20 f-w-600'>{type == 1 ? strings.term_service : strings.collect_info}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <iframe className="term-detail"
                        src={type == 1 ? context.loadImage("uploads/terms/service.html") : context.loadImage("uploads/terms/privacy.html")}/>
            </ModalBody>
        </Modal>
    );
}

export default TermDetailModal;

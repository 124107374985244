import React, {useContext, useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";
import {observer} from "mobx-react";

const AccountModal = observer((props) => {
    const context = useContext(Page_settings);
    const {show, toggle} = props;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        setName(rootStore.getProfile?.name ?? "");
        setEmail(rootStore.getProfile?.email ?? "");
    }, [rootStore.getProfile]);

    const onChangePassword = () => {
        context.handleAccountModal(false);
        context.handleChangePasswordModal(true);
    }
    const onSignOut = () => {
        context.handleAccountModal(false);
        context.handleSignOutModal(true);
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody>
                <div className='d-flex m-b-40'>
                    <div className='f-s-20 f-w-600'>{strings.account_info}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="f-s-16 f-w-600 m-t-10 m-b-10">{strings.name}</div>
                <div className="account-info m-b-25">{name}</div>
                <div className="f-s-16 f-w-600 m-t-10 m-b-10">{strings.email}</div>
                <div className="account-info m-b-40">{email}</div>
                <div className="horizontal-divider m-b-40"/>
                <button className='d-flex align-item-center flex-grow-1 common-button width-100-percent'
                        onClick={() => onChangePassword()}>
                    <div className='f-s-16 f-w-400'>{strings.change_password}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
                <button className='d-flex m-t-40 align-item-center common-button width-100-percent'
                        onClick={() => onSignOut()}>
                    <div className='f-s-16 f-w-400'>{strings.sign_out}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
            </ModalBody>
        </Modal>

    );
})

export default AccountModal;

import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../lang/strings";
import StudentSearch from "./customSearch/studentSearch";
import { Page_settings } from '../../config/page_settings';
import {rootStore} from "../../mobx/store";

function StudentAddModal({ onClose }) {
  const [wholeTextArray,setWholeTextArray] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [dropDownList, setDropDownList] = useState(wholeTextArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)
  const [profile, setProfile] = useState("");

  

  const context = useContext(Page_settings);

  useEffect(() => {
    loadStudentInfo();
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
  }
}, []);

  useEffect(()=>{
    showDropDownList()
  }, [inputValue])

  const showDropDownList = () => {
    if (inputValue === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = wholeTextArray.filter((dataItem) =>
      dataItem.name.includes(inputValue) || dataItem.email.includes(inputValue)
      );
      setDropDownList(choosenTextList)
    }
  }

  const changeInputValue = event => {
    setInputValue(event.target.value)
    setIsHaveInputValue(true)
  }

  const clickDropDownItem = clickedItem => {
    setInputValue(clickedItem)
    setIsHaveInputValue(false)
  }

  const handleDropDownKey = event => {
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem(dropDownList[dropDownItemIndex].name)
        setDropDownItemIndex(-1)
      }
    }
  }


  const loadStudentInfo = () => {
    context.get(
        'member/getMemberList',
        {},
        response => {
          console.log(response.list);
          setWholeTextArray(response.list);
        }
    );
}

  const handleClose = () => {
    onClose?.();
  };


  return (
      <Overlay>
        <ModalWrap>
          <div class="classAddModal-head">
            <h4>학생 추가</h4>
            <img onClick={handleClose} src="/assets/image/icon_close_gray.png"/>
          </div>
          <div class="classAddModal-subTitle">
            학생 검색
          </div>
          <div class="studentAddModal-searchContainer" isHaveInputValue={isHaveInputValue}>
          <StudentSearch
            placeholder={strings.student_search_placeholder}
            inputType={'text'}
            value={inputValue}
            onChange={changeInputValue}
            onKeyUp={handleDropDownKey}
        />
          <Button>추가</Button>
          </div>
          {isHaveInputValue && (
        <DropDownBox>
          {dropDownList.length === 0 && (
            <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
          )}
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem(dropDownItem.name)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                <div className="studentAddModal-dropdown-container">
                  <div className='studentAddModal-dropdown-profile'>
                    <img className="user-img"
                      src={dropDownItem.profile != null && dropDownItem.profile != "" ? context.loadImage(dropDownItem.profile) : "/assets/image/logo_big.png"}
                       />
                  </div>
                  <div className="studentAddModal-dropdown-name-mail">
                    <div>{dropDownItem.name}</div>
                    <div>{dropDownItem.email}</div>
                  </div>
                  <div className="studentAddModal-dropdown-school">
                    <div>{dropDownItem.school}</div>
                  </div>
                </div>
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top\: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: #DFE3E8;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  flex:2;
  margin-left: 10px;
  &:hover {
    background-color: #898989;
  }
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 72%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
  &.selected {
    background-color: #EFF3F9;
    border: 1px solid #EFF3F9;
    border-radius: 8px;
    
  }
`

export default StudentAddModal;
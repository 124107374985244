import React, { useState } from 'react';
import TodoPlanItem from "./todo_plan_item";
import TagMoreButton from "../button/tag_more_button";
import { colorList } from "../../../config/const";

const TagItem = (props) => {
    const [hover, setHover] = useState(false);
    const [todoName, setTodoName] = useState('');

    const { item, selectedTag, onDelete, onEdit, onTodoEdit, onTodoDelete, onTodoCheck, onAddTodo, onTodoEditDone } = props;
    return (
        <div>
            <div className='d-flex align-items-center' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div onClick={() => onAddTodo()} className='tag-btn d-center'
                    style={{ backgroundColor: item.color }}>
                    <div>{item.title}</div>
                    <div className='tag-plus d-center'
                        style={{ backgroundColor: 'rgba(249,249,249,0.3)' }}>
                        <img src={"/assets/image/icon_plus_white.png"} alt="" style={{ width: 10, height: 10 }} />
                    </div>
                </div>
                {
                    hover == true &&
                    <TagMoreButton
                        onEdit={() => onEdit()}
                        onDelete={() => onDelete()}
                    />
                }
            </div>
            {
                item.todo_list.map((todo, idx) => {
                    return (
                        <TodoPlanItem
                            key={idx}
                            idx={idx}
                            item={todo}
                            autoFocus={selectedTag == item.id && todo.id == 0}
                            colorIndex={colorList.indexOf(item.color)}
                            content={todoName}
                            checked={todo.status == 'Y'}
                            onCheck={() => onTodoCheck(idx)}
                            onDelete={() => onTodoDelete(idx)}
                            onEdit={() => {onTodoEdit(idx); setTodoName(todo.title)}}
                            onEditDone={() => onTodoEditDone(idx, todoName)}
                            onChange={(text) => {setTodoName(text) }}
                        />
                    )
                })
            }
        </div>
    );
}

export default TagItem;

import React, {useContext, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";
import {rootStore} from "../../../mobx/store";

const SearchSchoolModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle, type, onSearchDone} = props;
    const [schoolListAll, setSchoolListAll] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolName, setSchoolName] = useState("");
    const [schoolSelected, setSchoolSelected] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const getSchoolListAll = async () => {
        context.get(
            'member/getSchoolList',
            {
                keyword: schoolName
            },
            response => {
                setSchoolListAll(response.list);
            });
    }

    const onChange = (v) => {
        setSchoolName(v);

        let list = [];
        for (let i = 0; i < schoolListAll.length; i++) {
            if (schoolListAll[i].schoolNm.toLowerCase().indexOf(v.toLowerCase()) > -1) {
                list.push(schoolListAll[i].schoolNm);
            }
        }
        setSchoolList(list);
        setShowSearch(v != "");
        setSchoolSelected(false);
    }

    const onBack = () => {
        if (type == 3) {
            toggle();
            return;
        }
        context.handleSchoolSearch(false);
        context.handleProfileModal(true);
    }

    const onDelete = () => {
        if (schoolName != "") {
            setSchoolName("")
            setShowSearch(false);
            setSchoolSelected(false);
        }
    }

    const onConfirm = () => {
        if (type == 3) {
            onSearchDone(schoolName);
        }
        if (type == 1) {
            rootStore.setSchool(schoolName);
        }
        if (type == 2) {
            rootStore.setHope(schoolName);
        }
        onBack();
    }

    const onOpened = () => {
        if (type == 3) {
            return;
        }
        getSchoolListAll();
        context.handleProfileStatus(2);
        setSchoolName("");
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} onOpened={() => onOpened()} className="account-info-modal">
            <ModalBody className="p-30">
                <div className='d-flex m-b-20'>
                    <div
                        className='m-l-10 f-s-20 f-w-600'>{type == 1 ? strings.school_search : strings.hope_search}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="study-room-input m-b-20">
                    <input placeholder={strings.enter_school_name} type={"text"}
                           value={schoolName} onChange={(e) => onChange(e.target.value)}
                    />
                    <button className="transparent-button" onClick={() => onDelete()}>
                        <img
                            src={schoolName == "" ? "/assets/image/icon_search_black.png" : "/assets/image/icon_close_black.png"}
                            className="modal-close"
                            alt=""/>
                    </button>
                </div>
                <PerfectScrollbar className="school-result" options={{suppressScrollX: true}}>
                    {
                        showSearch &&
                        schoolList.map((item, idx) => {
                            return (
                                <button className="search-item" key={idx} onClick={() => {
                                    setSchoolName(item);
                                    setSchoolSelected(true);
                                    setShowSearch(false);
                                }}>
                                    <img src={"/assets/image/icon_search_black.png"} alt=""/>
                                    <div className={"d-flex"}>
                                        {
                                            item.split("").map((char, charIdx) => {
                                                let searchIdx = item.toLowerCase().indexOf(schoolName.toLowerCase())
                                                return (
                                                    <div
                                                        className={(searchIdx == -1 ? '' : (charIdx >= searchIdx && charIdx < searchIdx + schoolName.length) ? 'searched ' : '') + (char == " " ? "space-char" : "")}
                                                        key={charIdx}>{char}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </button>
                            )
                        })
                    }
                </PerfectScrollbar>
                <button className={("school-confirm-disable ") + (schoolSelected ? "school-confirm" : "")}
                        onClick={() => onConfirm()}>
                    {strings.confirm}
                </button>
            </ModalBody>
        </Modal>
    );
}

export default SearchSchoolModal;

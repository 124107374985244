import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import strings from "../../../lang/strings";
import PerfectScrollbar from "react-perfect-scrollbar";
import NotificationItem from "./notification-item";
import { Page_settings } from "../../../config/page_settings";
import { observer } from "mobx-react";
import { rootStore } from "../../../mobx/store";

const DropdownNotification = observer((props) => {
    const context = useContext(Page_settings);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { theme } = props;
    const [notificationList, setNotificationList] = useState([]);
    const [showNoti, setShowNoti] = useState("OFF");
    const [numUnread, setNumUnread] = useState(0)

    useEffect(() => {
        if (showNoti == "ON") {
            getNotificationList();
        }
    }, [showNoti]);

    useEffect(() => {
        let num = 0;
        notificationList.map((item) => {
            if (item.status == 0) {
                num++
            }
        });
        setNumUnread(num)
    }, [notificationList])

    useEffect(() => {
        setShowNoti(rootStore.getNotification ?? "OFF");
        setShowNoti(rootStore.getProfile?.alarm_web ?? "OFF");
    }, [rootStore.getNotification, rootStore.getProfile]);


    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const getNotificationList = () => {
        context.get(
            "member/getNotificationList",
            {
                page_num: 1
            },
            response => {
                setNotificationList(response.list)
            }
        );
    }

    const onMark = (item) => {
        if (item.status == 1) {
            context.post(
                "member/deleteNotification",
                {
                    id: item.id
                },
                response => {
                    setNotificationList(prev => prev.filter((it) => it.id != item.id))
                }
            );
        } else {
            context.post(
                "member/readNotification",
                {
                    id: item.id
                },
                response => {
                    setNotificationList(prev => prev.map((it) => {
                        if (it.id == item.id) {
                            it.status = 1
                        }
                        return it
                    }))
                }
            );

        }
    }

    const onTurn = () => {
        context.handleNotificationModal(true);
    }


    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggle()} className="dropdown noti-dropdown"
            tag="li">
            <DropdownToggle className="f-s-14" tag="a">
                <img
                    src={theme == "white" ? '/assets/image/icon_bell.png' : '/assets/image/icon_notifications.png'}
                    alt="" />
                {
                    numUnread > 0 &&
                    <span className="red-dot" style={{ right: theme == "white" ? 15 : 20 }} />
                }
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu dropdown-menu-right rank-dropdown-menu" tag="ul">
                <DropdownItem className="dropdown-header notification-header" header>
                    <div className="">{strings.notifications}</div>
                </DropdownItem>
                
                {
                    notificationList.length !== 0 ?
                    <PerfectScrollbar className="noti-dropmenu" options={{ suppressScrollX: true }}>
                        {
                            notificationList.map((item, idx) => {
                                return (
                                    <NotificationItem
                                        key={idx}
                                        item={item}
                                        onMark={() => onMark(item)}
                                        onTurn={() => onTurn()}
                                        last={idx + 1 == notificationList.length}
                                    />
                                )
                            })
                        }
                    </PerfectScrollbar>
                    :
                    <div className='noti-dropmenu'>
                        <div className='noti-empty'>{strings.notification_empty}</div>
                    </div>
                }

            </DropdownMenu>

        </Dropdown>
    );
})

export default DropdownNotification;

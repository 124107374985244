import React, {useContext, useState} from "react";
import {Page_settings} from "../../../config/page_settings";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import strings from "../../../lang/strings";

const ParticipantItem = (props) => {
    const context = useContext(Page_settings)
    const {item, mine, onKick, onInfo} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <div className="participant">
            <img className="profile"
                 src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/logo_big.png"} alt=""/>
            <div>
                <div className="user-name text-ellipsis">{item.user.name}</div>
                <div className="user-school text-ellipsis">{item.user.school}</div>
            </div>
            <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                      className="participant-dropdown more">
                <DropdownToggle tag="a">
                    <img src={"/assets/image/icon_more_horitonal_gray.png"} alt=""/>
                </DropdownToggle>
                <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                    <DropdownItem onClick={() => onInfo()}>
                        {strings.info}
                    </DropdownItem>
                    {
                        mine &&
                        <DropdownItem onClick={() => onKick()} className="kick-out">
                            {strings.kick_out}
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default ParticipantItem;

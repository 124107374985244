import React from "react";
import strings from "../lang/strings";

import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import Clinic from "../pages/clinic/clinic";
import Cloud from "../pages/cabinet/cloud";
import Shop from "../pages/shop/shop";
import Ranking from "../pages/ranking/ranking";
import Study from "../pages/room/study";
import Challenge from "../pages/room/challenge";
import Planner from "../pages/cabinet/planner";
import Report from "../pages/cabinet/report";
import CreateStudyRoom from "../pages/room/create_study_room";
import StudyRoom from "../pages/room/study/study_room";
import ChallengeRoom from "../pages/room/challenge/challenge_room";
import ConsultHistory from "../pages/clinic/consult_history";
import Signup from "../pages/auth/signup";
import Find from "../pages/auth/find";
import ExpertDetail from "../pages/clinic/expert_detail";
import AiTutor from "../pages/clinic/aitutor";
import Multi from "../pages/dashboard/multi";
import ChatCoach from "../pages/ai/chat_coach";
import RoadMap from "../pages/ai/roadmap";
import Class from "../pages/class/class";
import ClassInfo from "../pages/class/classInfo";

export const Extra = [
    {
        path: '/login', title: strings.login.login, exact: true, private: false,
        component: () => <Login/>
    },
    {
        path: '/signup/:step', title: strings.signup, exact: true, private: false,
        component: () => <Signup/>
    },
    {
        path: '/class/:id', title: strings.class_info, exact: true, private: false,
        component: () => <ClassInfo/>
    },
    {
        path: '/find/:type', title: strings.signup, exact: true, private: false,
        component: () => <Find/>
    },
    {
        path: '/room/create_study_room/:type', title: strings.create_study_room, exact: true, private: false,
        component: () => <CreateStudyRoom/>
    },
    {
        path: '/room/study/:type', title: strings.my_room, exact: true, private: false,
        component: () => <Challenge/>
    },
    {
        path: '/room/study/study_room/:id', title: strings.study_room, exact: true, private: true,
        component: () => <StudyRoom/>
    },
    {
        path: '/room/challenge/challenge_room/:id', title: strings.challenge_room, exact: true, private: true,
        component: () => <ChallengeRoom/>
    },
    {
        path: '/clinic/consult_history/:clinic_id', title: strings.consultation_history, exact: true, private: true,
        component: () => <ConsultHistory/>
    },
    {
        path: '/clinic/expert_detail/:expert_id/:tab', title: strings.consultation_history, exact: true, private: true,
        component: () => <ExpertDetail/>
    },
    {
        path: '/room/study/conference', title: strings.menu.conference_room, exact: true, private: true,
        component: () => <Challenge/>
    },
    {
        path: '/ranking',
        title: strings.menu.ranking,
        exact: true,
        private: true,
        component: () => <Ranking/>
    },
    {
        path: '/shop',
        icon_inactive: '/assets/image/icon_menu_shop_inactive.png',
        icon_active: '/assets/image/icon_menu_shop_active.png',
        title: strings.menu.chewing_shop,
        exact: true,
        private: true,
        component: () => <Shop/>
    },

];

const Menu = [
    // {
    //     path: '/dashboard',
    //     icon_inactive: '/assets/image/icon_menu_lounge_inactive.png',
    //     icon_active: '/assets/image/icon_menu_lounge_active.png',
    //     title: strings.menu.lounge,
    //     exact: true,
    //     private: true,
    //     component: () => <Dashboard/>
    // },
    {
        path: '/class',
        icon_inactive: '/assets/image/icon_class_inactive.png',
        icon_active: '/assets/image/icon_class_active.png',
        title: strings.menu.class,
        exact: true,
        private: true,
        component: () => <Class/>
    },
    {
        path: '/room/study',
        icon_inactive: '/assets/image/icon_menu_study_cafe_inactive.png',
        icon_active: '/assets/image/icon_menu_study_cafe_active.png',
        title: strings.menu.study_cafe,
        children: [
            {
                path: '/room/study', title: strings.menu.study_room, exact: true, private: true,
                component: () => <Study/>
            },
            {
                path: '/room/study/conference', title: strings.menu.conference_room, exact: true, private: true,
                component: () => <Challenge/>
            },
        ]
    },
    {
        path: '/clinic',
        icon_inactive: '/assets/image/icon_menu_chewingchat_inactive.png',
        icon_active: '/assets/image/icon_menu_chewingchat_active.png',
        title: strings.menu.clinic,
        exact: true,
        private: true,
        component: () => <Clinic/>
    },
//    {
//        path: '/ai/chat',
//        icon_inactive: '/assets/image/icon_menu_aitutor_inactive.png',
//        icon_active: '/assets/image/icon_menu_aitutor_active.png',
//        title: strings.menu.ai_coaching,
//        children: [
//            {
//                path: '/ai/chat', title: strings.menu.chat_coaching, exact: true, private: true,
//                component: () => <ChatCoach/>
//            },
//            {
//                path: '/ai/chat/roadmap', title: strings.menu.road_map, exact: true, private: true,
//                component: () => <RoadMap/>
//            },
//        ]
//    },
    {
        path: '/cabinet/cloud/planner',
        icon_inactive: '/assets/image/icon_menu_planner_inactive.png',
        icon_active: '/assets/image/icon_menu_planner_active.png',
        title: strings.menu.planner,
        exact: true,
        private: true,
        component: () => <Planner/>
        
    },
    {
        path: '/cabinet/cloud',
        icon_inactive: '/assets/image/icon_menu_cloud_inactive.png',
        icon_active: '/assets/image/icon_menu_cloud_active.png',
        title: strings.menu.cloud,
        exact: true,
        private: true,
        component: () => <Cloud/>
    },
    {
        path: '/cabinet/cloud/report',
        icon_inactive: '/assets/image/icon_menu_datalab_inactive.png',
        icon_active: '/assets/image/icon_menu_datalab_active.png',
        title: strings.menu.data_lab,
        exact: true,
        private: true,
        component: () => <Report/>
        
    },
    

    // {
    //     path: '/shop',
    //     icon_inactive: '/assets/image/icon_menu_shop_inactive.png',
    //     icon_active: '/assets/image/icon_menu_shop_active.png',
    //     title: strings.menu.chewing_shop,
    //     exact: true,
    //     private: true,
    //     component: () => <Shop/>
    // },
    // {
    //     path: '/ranking',
    //     icon_inactive: '/assets/image/icon_menu_rank_inactive.png',
    //     icon_active: '/assets/image/icon_menu_ranking_active.png',
    //     title: strings.menu.ranking,
    //     exact: true,
    //     private: true,
    //     component: () => <Ranking/>
    // },
];

export default Menu;

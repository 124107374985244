import React, { useContext, useEffect, useState } from "react";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import moment from "moment";
import ReportProgressBar from "../../../components/control/progress_bar/report_progress_bar";
import { barColorList, emoticonList } from "../../../config/const";
import { Doughnut, Line } from 'react-chartjs-2';
import ProgressBar from "../../../components/control/progress_bar/progress_bar";
import { dateForm, getDaysFromStartEnd, getDecimal, getTimeFromSecond } from "../../../helper/common";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";
import { Store } from "react-notifications-component";
import Cloud from "../../cabinet/cloud";
import CloudContainer from "../../cabinet/cloud_container";

const DatalabModal = (props) => {

    const [periodOpen, setPeriodOpen] = useState(false);
    const context = useContext(Page_settings);
    const [period, setPeriod] = useState(-1)
    const [yearSelectOpen, setYearSelectOpen] = useState(false);
    const [monthSelectOpen, setMonthSelectOpen] = useState(false);
    const [daySelectOpen, setDaySelectOpen] = useState(false);
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState(-1);
    const [monthList, setMonthList] = useState([]);
    const [month, setMonth] = useState(-1);
    const [dayList, setDayList] = useState([]);
    const [day, setDay] = useState(-1);
    const [currentPeriod, setCurrentPeriod] = useState("");
    const [goalAchieve, setGoalAchieve] = useState(0);
    const [goalDone, setGoalDone] = useState(0);
    const [goalTotal, setGoalTotal] = useState(0);
    const [seriesTotal, setSeriesTotal] = useState([]);
    const [seriesReal, setSeriesReal] = useState([]);
    const [up3, setUp3] = useState([]);
    const [attendance, setAttendance] = useState(0);
    const [down3, setDown3] = useState([]);
    const [studyCafe, setStudyCafe] = useState(0);
    const [cabinet, setCabinet] = useState(0);
    const [nothing, setNothing] = useState(0)
    const [realTimeYesterday, setRealTimeYesterday] = useState(0);
    const [totalTimeYesterday, setTotalTimeYesterday] = useState(0);
    const [realTimeToday, setRealTimeToday] = useState(0);
    const [totalTimeToday, setTotalTimeToday] = useState(0);
    const [goalAchYesterday, setGoalAchYesterday] = useState(0);
    const [goalAchToday, setGoalAchToday] = useState(0);
    const [efficiencyYesterday, setEfficiencyYesterday] = useState(0);
    const [efficiencyToday, setEfficiencyToday] = useState(0);
    const [efficiency, setEfficiency] = useState(0);
    const [missionYesterday, setMissionYesterday] = useState(0);
    const [missionTotalYesterday, setMissionTotalYesterday] = useState(0);
    const [missionToday, setMissionToday] = useState(0);
    const [missionTotalToday, setMissionTotalToday] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [usedCloud, setUsedCloud] = useState(0);
    const [totalCloud, setTotalCloud] = useState(0);
    const timeLine = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const monthLine = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    const [weekLine, setWeekLine] = useState([]);
    const [premium, setPremium] = useState(0);
    const userId = props.dataLabID;

    const handleClose = () => {
      props.onClose?.();
    };

    

    const lineChart = {
        data: {
            labels: period == 2 ? monthLine : (period == 1 ? weekLine : timeLine),
            datasets: [{
                label: strings.study_time,
                borderColor: '#0f84f4',
                backgroundColor: '#0f84f4',
                pointRadius: 1,
                data: seriesTotal,
                pointHoverBackgroundColor: "rgba(231,243,254,0.3)",
                pointHoverRadius: 10,
                fill: false
            }, {
                label: strings.real_time,
                borderColor: '#04c886',
                backgroundColor: '#04c886',
                pointRadius: 1,
                data: seriesReal,
                pointHoverBackgroundColor: "rgba(230,250,243,0.3)",
                pointHoverRadius: 10,
                fill: false

            }]
        },
        options: {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        font: {
                            size: 15
                        },
                        boxWidth: 11,
                        boxHeight: 2,
                        textAlign: "center",
                    }
                },
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    callbacks: {
                        label: function (context) {
                            let data = context.dataset.data;
                            let label = context.dataset.label;
                            let index = context.dataIndex;
                            return label + " " + getTimeFromSecond(data[index]);
                        }
                    }
                }
            },
            interaction: {
                mode: "nearest",
                intersect: false,
                axis: "x"
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            return getTimeFromSecond(value);
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        }
    }
    const doughnutChart = {
        data: {
            labels: [strings.study, strings.menu.clinic, strings.menu.cabinet],
            datasets: [{
                data: [attendance, studyCafe, cabinet, nothing],
                backgroundColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
                borderColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
                borderWidth: 2,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            cutout: "90%",
            plugins: {
                legend: {
                    display: false
                },
            }
        }
    }
    const goalChart = {
        data: {
            datasets: [{
                data: [goalAchieve, 100 - goalAchieve],
                backgroundColor: ['#1d1f40', '#dadada'],
                borderColor: ['#1d1f40', '#dadada'],
                borderWidth: 2,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            cutout: "70%",
            rotation: 270,
            circumference: 180,
            plugins: {
                legend: {
                    display: false
                },
            }
        }

    }

    useEffect(() => {
        context.setHeaderType(2);
        context.handleSetPlannerPageHeader(true);
        getYearList();
        getMonthList();
        getDayList(moment(new Date()).daysInMonth());
        getPlannerData(userId, new Date(), new Date());
        getMissionData(userId, new Date(), new Date());
        getCompareData(userId, "HOUR");
        return () => {
            context.setHeaderType(1);
            context.handleSetPlannerPageHeader(false);
        }
    }, []);

    // useEffect(() => {
    //     setPremium(rootStore.getProfile.premium ?? 0)
    //     getCloudDate()
    // }, [rootStore.getPremium]);

    useEffect(() => {
        if (period == 0) {
            getCompareData(userId, "HOUR")
            getPlannerData(userId, new Date, new Date);
            getMissionData(userId, new Date, new Date);
        } else if (period == 1) {
            getCompareData(userId, "DAY");
            getPlannerData(userId, moment(new Date).startOf("week").toDate(), new Date)
            getMissionData(userId, moment(new Date).startOf("week").toDate(), new Date)
        } else if (period == 2) {
            getCompareData(userId, "MONTH")
            getPlannerData(userId, moment(new Date).startOf("month").toDate(), new Date)
            getMissionData(userId, moment(new Date).startOf("month").toDate(), new Date)
        }
    }, [period]);

    useEffect(() => {
        if (efficiency < 30) {
            setStatusId(0)
        } else if (efficiency <= 50) {
            setStatusId(1)
        } else if (efficiency <= 70) {
            setStatusId(2)
        } else if (efficiency <= 90) {
            setStatusId(3)
        } else if (efficiency <= 100) {
            setStatusId(4)
        }

    }, [efficiency])

    // const getCloudDate = () => {
    //     context.get(
    //         "datalab/getCloudData",
    //         {},
    //         response => {
    //             setTotalCloud(Number(response.total_limit));
    //             setUsedCloud(Number(response.used_capacity) < 0 ? 0 : Number(response.used_capacity))
    //         }
    //     )
    // }

    const getToday = () => {
        let date = new Date()
        setCurrentPeriod(moment(date).year().toString() + strings._y + " " + dateForm(moment(date).month() + 1) + strings._m + " " + dateForm(moment(date).date()) + strings._d);
    }

    const getChartData = (userId ,mode, from, to) => {
        context.get(
            "datalab/getChartData",
            {
                userId : userId,
                mode: mode,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                let total = [];
                let real = [];
                let totalSum = 0;
                let realSum = 0;
                response.list.forEach(item => {
                    total.push(parseInt(item.total_diff));
                    totalSum += parseInt(item.total_diff);
                    real.push(parseInt(item.real_diff));
                    realSum += parseInt(item.real_diff);
                });
                setSeriesTotal(total);
                setSeriesReal(real);
                setEfficiency(totalSum != 0 ? (realSum / totalSum) * 100 : 0);
            }
        );
    }

    const getPlannerData = (userId, from, to) => {
        context.get(
            "datalab/getPlannerData",
            {   
                userId : userId,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                setGoalDone(response.todo.complete_count);
                setGoalTotal(response.todo.total_count);
                if (response.todo.total_count != 0) {
                    setGoalAchieve(parseInt(response.todo.complete_count) / parseInt(response.todo.total_count) * 100)
                } else {
                    setGoalAchieve(0)
                }
                setUp3(response.tag_top)
                setDown3(response.tag_low)
            }
        );
    }

    const getMissionData = (from, to) => {
        context.get(
            "datalab/getMissionData",
            {
                userId : userId,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                if (response.mission_count == 0) {
                    setNothing(1);
                } else {
                    setNothing(0);
                    let typeList = [];
                    response.mission.forEach(item => {
                        typeList.push(item.type)
                        if (item.type == "STUDY") {
                            setAttendance(item.total_count)
                        } else if (item.type == "CABINET") {
                            setCabinet(item.total_count)
                        } else if (item.type == "CLINIC") {
                            setStudyCafe(item.total_count)
                        }
                    });
                    if (typeList.indexOf("STUDY") == -1) {
                        setAttendance(0)
                    }
                    if (typeList.indexOf("CABINET") == -1) {
                        setCabinet(0)
                    }
                    if (typeList.indexOf("CLINIC") == -1) {
                        setStudyCafe(0)
                    }
                }
            }
        );
    }

    const getCompareData = (userId, mode) => {
        const from = moment().format('YYYY-MM-DD');
        const to = moment().format('YYYY-MM-DD');
        context.get(
            "datalab/getCompareData",
            {
                userId : userId,
                mode: mode,
                from,
                to
            },
            response => {
                setRealTimeToday(parseInt(response.today.study_real_time) / 3600);
                setTotalTimeToday(parseInt(response.today.study_total_time) / 3600);
                setRealTimeYesterday(parseInt(response.yesterday.study_real_time) / 3600);
                setTotalTimeYesterday(parseInt(response.yesterday.study_total_time) / 3600);
                setGoalAchToday(response.today.todo_total_count != 0 ? response.today.todo_complete_count / response.today.todo_total_count * 100 : 0);
                setGoalAchYesterday(response.yesterday.todo_total_count != 0 ? response.yesterday.todo_complete_count / response.yesterday.todo_total_count * 100 : 0);
                setEfficiencyToday(parseInt(response.today.study_total_time) != 0 ? parseInt(response.today.study_real_time) / parseInt(response.today.study_total_time) * 100 : 0)
                setEfficiencyYesterday(parseInt(response.yesterday.study_total_time) != 0 ? parseInt(response.yesterday.study_real_time) / parseInt(response.yesterday.study_total_time) * 100 : 0)
                setMissionToday(parseInt(response.today.mission_complete_count));
                setMissionTotalToday(parseInt(response.today.mission_total_count));
                setMissionYesterday(parseInt(response.yesterday.mission_complete_count));
                setMissionTotalYesterday(parseInt(response.yesterday.mission_total_count));
            }
        );
    }

    useEffect(() => {
        if (period == 2) {
            // setCurrentPeriod(yearList[year] + " " + monthList[month])
            setCurrentPeriod(yearList[year])
            getChartData(userId,"MONTH", yearList[year].split("년")[0] + "-" + "01" + "-" + "01", yearList[year].split("년")[0] + "-" + "12" + "-" + "31")
        } else if (period == 1) {
            if (day > dayList.length - 1) {
                setDay(0);
            }
            if (year != -1) {
                getDayFromWeek(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0]);
            }
            setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day])
        } else if (period == 0) {
            if (dayList.length < 10) {
                getDayList(moment(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0] + ".01").daysInMonth());
            }
            setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day]);
            getChartData(userId,"HOUR", yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + dayList[day].split("일")[0], yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + dayList[day].split("일")[0]);
        } else {
            getToday();
            getChartData(userId,"HOUR", new Date, new Date);
        }
    }, [period, year, month, day, dayList])

    useEffect(() => {
        if (period == 1) {
            setDayList(strings.weekList);
            return
        }
        if (year != -1 && month != -1) {
            getDayList(moment(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0] + ".01").daysInMonth())
            // setDay(0)
        }
    }, [year, month]);

    const getYearList = () => {
        let now = moment(new Date()).year();
        let list = [];
        for (let i = now - 10; i < now + 1; i++) {
            list.push(i.toString() + strings._y)
        }
        setYearList(list)
        setYear(yearList.length - 1)
    }

    const getMonthList = () => {
        let list = [];
        let month = ""
        for (let i = 1; i < 13; i++) {
            month = dateForm(i) + strings._m
            list.push(month)
        }
        setMonthList(list)
    }

    const getDayList = (days) => {
        let list = [];
        let day = "";
        for (let i = 1; i <= days; i++) {
            day = dateForm(i) + strings._d
            list.push(day)
        }
        setDayList(list)
    }

    const getDayFromWeek = (momentObj) => {
        let obj = moment(momentObj), first, last, weekCount, weekStart, weekEnd, lastDay;
        first = obj.startOf("month").week();
        last = obj.endOf("month").week();
        lastDay = obj.endOf("month").day();
        if (last < first) {
            weekCount = 52 + first - last;
        } else {
            weekCount = last - first + 1;
        }
        if (weekCount < 5 || lastDay < 6) {
            setDayList(strings.weekList);
        } else {
            setDayList(strings.weekList5);
        }
        weekStart = moment(moment(yearList[year].split("년")[0] + "." + (first + day).toString(), "YYYY.ww").startOf("week").toDate()).format("YYYY-MM-DD");
        weekEnd = moment(moment(yearList[year].split("년")[0] + "." + (first + day).toString(), "YYYY.ww").endOf("week").toDate()).format("YYYY-MM-DD");
        setWeekLine(getDaysFromStartEnd(moment(weekStart).date(), moment(weekEnd).date(), weekStart))
        getChartData(userId,"DAY", weekStart, weekEnd)
    }

    const togglePeriod = () => {
        setPeriodOpen(!periodOpen);
    }

    const toggleYear = () => {
        setYearSelectOpen(!yearSelectOpen);
    }

    const toggleMonth = () => {
        setMonthSelectOpen(!monthSelectOpen);
    }

    const toggleDay = () => {
        setDaySelectOpen(!daySelectOpen);
    }

    const periodFix = () => {
        if (period == -1) {
            setPeriod(0);
        }
        if (year == -1) {
            setYear(yearList.indexOf(moment(new Date()).year().toString() + strings._y));
        }
        if (month == -1) {
            let mm = moment(new Date()).month() + 1;
            mm = dateForm(mm) + strings._m
            setMonth(monthList.indexOf(mm))
        }
        if (day == -1) {
            setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d))
        }
    }

    const vaildDate = (yearIdx, monthIdx, dayIdx) => {
        const yIdx = yearIdx == -1 ? yearList.indexOf(moment(new Date()).year().toString() + strings._y) : yearIdx;
        const mIdx = monthIdx == -1 ? monthList.indexOf(dateForm(moment(new Date()).month() + 1) + strings._m) : monthIdx;
        const dIdx = dayIdx == -1 ? dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d) : dayIdx;

        if (yIdx == undefined || mIdx == undefined || dIdx == undefined) {
            return false;
        }
        const dateStr = yearList[yIdx].split("년")[0] + "-" + monthList[mIdx].split("월")[0] + "-" + dayList[dIdx].split("일")[0]
        var date = moment(dateStr);
        return date.isValid();
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    };
 
    return (
      <Overlay>
        <ModalWrap>

        <div className="main-content report-container row">
        <CloseButton onClick={handleClose} src="/assets/image/icon_close_gray.png"/>
            {/* <div className="cloud-container">
                <div className="d-flex align-bottom m-b-15">
                    <div className="cloud-used f-w-600">{getDecimal((usedCloud / 1024).toString()) + strings._GB}</div>
                    <div
                        className="f-s-15 f-w-600 flex-grow-1">{"/ " + getDecimal((totalCloud / 1024).toString()) + strings._GB}</div>
                    <div
                        className="cloud-free">{strings.free + " " + (totalCloud - usedCloud > 0 ? getDecimal(((totalCloud - usedCloud) / 1024).toString()) : "0") + strings._GB}</div>
                </div>
                <ProgressBar
                    percent={usedCloud / totalCloud * 100}
                    color={"#0f84f4"}
                    height={5}
                    width={"100%"}
                />
                {
                    premium == 0 &&
                    <button className="m-t-20" onClick={() => window.open("https://www.chewing.ai/", "_blank")}>{strings.increase_capacity}</button>
                }
            </div> */}
            <div className="report-top m-b-15">
                <div className="date">{currentPeriod}</div>
                <div className="report-efficiency">
                    <div className="f-s-16 f-w-600 m-r-20">{strings.study_effi}</div>
                    <ProgressBar
                        percent={efficiency}
                        color={barColorList[statusId]}
                        width={"220px"}
                        backgroundColor={"#f4f4f4"}
                    />
                    <div className="f-s-15 progress-number">{getDecimal(efficiency.toString()) + "%"}</div>
                    <div className="report-emoticon">
                        <img src={emoticonList[statusId]} alt="" />
                        <div>{strings.status_list[statusId]}</div>
                    </div>
                </div>
            </div>
            <div className="d-flex m-b-10">
                <Dropdown isOpen={periodOpen} toggle={() => togglePeriod()}
                    className="dropdown navbar-user report-dropdown m-r-auto" tag="li">
                    <DropdownToggle tag="a"
                        className="display-flex-important align-item-center profile-dropdown report-dropdown">
                        <div
                            className="flex-grow-1">{period != -1 ? strings.report_period[period].name : strings.report_period[0].name}</div>
                        <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                        {
                            strings.report_period.map((item, idx) =>
                                <DropdownItem key={idx}
                                    className="dropdown-header display-flex-important justify-content-center f-s-15"
                                    onClick={() => {
                                        periodFix()
                                        setPeriod(item.code);
                                    }}
                                >
                                    {item.name}
                                </DropdownItem>
                            )
                        }
                    </DropdownMenu>
                </Dropdown>

                <Dropdown isOpen={yearSelectOpen} toggle={() => toggleYear()}
                    className="dropdown navbar-user report-dropdown" tag="li" style={{ width: "100px" }}>
                    <DropdownToggle tag="a"
                        className="display-flex-important align-item-center profile-dropdown report-dropdown">
                        <div
                            className="flex-grow-1">{year != -1 ? yearList[year] : moment(new Date()).year().toString() + strings._y}</div>
                        <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                        {
                            yearList.map((item, idx) =>
                                <DropdownItem key={idx}
                                    className="dropdown-header display-flex-important justify-content-center f-s-15"
                                    onClick={() => {
                                        if (!vaildDate(idx, month, day)) {
                                            addNotification('warning', '유효하지 않은 날짜입니다.')
                                            return;
                                        }

                                        periodFix()
                                        setYear(idx)
                                    }}
                                >
                                    {item}
                                </DropdownItem>
                            )
                        }
                    </DropdownMenu>
                </Dropdown>

                {
                    period != 2 &&
                    <Dropdown isOpen={monthSelectOpen} toggle={() => toggleMonth()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{month != -1 ? monthList[month] : dateForm(moment(new Date()).month() + 1) + strings._m}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                            {
                                monthList.map((item, idx) =>
                                    <DropdownItem key={idx}
                                        className="dropdown-header display-flex-important justify-content-center f-s-15"
                                        onClick={() => {
                                            if (!vaildDate(year, idx, day)) {
                                                addNotification('warning', '유효하지 않은 날짜입니다.')
                                                return;
                                            }
                                            periodFix()
                                            setMonth(idx)
                                        }}
                                    >
                                        {item}
                                    </DropdownItem>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                }
                {
                    period <= 1 &&
                    <Dropdown isOpen={daySelectOpen} toggle={() => toggleDay()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{day != -1 ? dayList[day] : moment(new Date()).date().toString() + strings._d}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu
                            className="dropdown-menu dropdown-menu-right profile-dropdown-menu "
                            tag="ul">
                            <PerfectScrollbar className="report-dropdown-menu" options={{ suppressScrollX: true }}>
                                {
                                    dayList.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                if (!vaildDate(year, month, idx)) {
                                                    addNotification('warning', '유효하지 않은 날짜입니다.')
                                                    return;
                                                }
                                                periodFix()
                                                setDay(idx)
                                            }}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </PerfectScrollbar>
                        </DropdownMenu>
                    </Dropdown>
                }
            </div>
            <div className="report-line-chart">
                <Line data={lineChart.data} options={lineChart.options} />
                <div
                    className="report-chart-x">{period == 2 ? strings.month : (period == 1 ? strings.week : strings.time)}</div>
                <div className="report-chart-y">{strings.time}</div>
            </div>
            <div className="row">
                <div className="col-xl-7 d-flex p-0 m-t-15">
                    <div className="goal-achieve m-l-10">
                        <div className="f-s-18 f-w-600">{strings.goal_achievement}</div>
                        <div className="d-flex width-100-percent justify-content-center align-bottom"
                            style={{ height: 150 }}>
                            <div className="goal-hint report-percent p-b-10">0%</div>
                            <div className="position-relative d-flex align-item-center justify-content-center" style={{ width: '70%' }}>
                                <div className="d-flex align-item-center goal-chart">
                                    <Doughnut data={goalChart.data} options={goalChart.options} />
                                </div>
                                <div className="report-chart-label">
                                    <div className="f-s-35 f-w-600">{getDecimal(goalAchieve.toString())}</div>
                                    <div className="achieve-number f-s-20">%</div>
                                </div>
                                <div className="report-chart-label">
                                    <div className="goal-hint">{goalDone.toString() + "/" + goalTotal.toString()}</div>
                                </div>
                            </div>

                            <div className="goal-hint report-percent p-b-10">100%</div>

                        </div>
                        <div className="report-divider" />
                        <div className="up-top m-t-25">
                            <div className="m-r-auto">{strings.up_top}</div>
                            {
                                up3.map((item, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="report-goal-item"
                                            style={{ background: item.color }}
                                        >{item.title}</div>
                                    )
                                })
                            }

                        </div>
                        <div className="up-top m-t-20">
                            <div className="m-r-auto">{strings.down_top}</div>
                            {
                                down3.map((item, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="report-goal-item"
                                            style={{ background: item.color }}
                                        >{item.title}</div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div className="goal-achieve mission">
                        <div className="d-flex align-item-center">
                            <div className="f-s-18 f-w-600 m-r-auto">{strings.mission_accomplished}</div>
                            <img src={"/assets/image/icon_info.png"} alt="" />
                            <div className="report-mission-time">{moment(moment.now()).format("YYYY/MM/DD hh:mm")}</div>
                        </div>
                        <div className="report-divider m-t-20 m-b-20" />
                        <div className="d-flex align-item-center m-t-20">
                            <div className="doughnut">
                                <div className="position-relative d-flex align-item-center justify-content-center width-100-percent">
                                    <Doughnut data={doughnutChart.data} options={doughnutChart.options} />
                                    <div className="mission-total">
                                        {(attendance + studyCafe + cabinet).toString() + strings._Times}
                                    </div>
                                    <div className="total">{strings.total}</div>
                                </div>
                            </div>
                            <div className="report-mission">
                                <div className="d-flex align-item-center m-b-10">
                                    <div className="report-blue-dot" />
                                    <div className="report-mission-title">{strings.study}</div>
                                </div>
                                <div className="report-mission-times">{attendance.toString() + strings._times}</div>
                                <div className="d-flex align-item-center m-b-10">
                                    <div className="report-green-dot" />
                                    <div className="report-mission-title">{strings.menu.cabinet}</div>
                                </div>
                                <div className="report-mission-times">{studyCafe.toString() + strings._times}</div>
                                <div className="d-flex align-item-center m-b-10">
                                    <div className="report-yellow-dot" />
                                    <div className="report-mission-title">{strings.menu.clinic}</div>
                                </div>
                                <div className="report-mission-times" style={{ marginBottom: 0 }}>{cabinet.toString() + strings._times}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 d-flex p-0 m-t-15">
                    <div className="goal-achieve comparison">
                        <div className="d-flex align-item-center">
                            <div className="f-s-18 f-w-600 m-r-auto">{strings.comparison}</div>
                            <img src={"/assets/image/icon_info.png"} alt="" />
                            <div className="report-mission-time">{moment(moment.now()).format("YYYY/MM/DD hh:mm")}</div>
                        </div>
                        <div className="report-divider m-t-25 m-b-25" />
                        <div className="d-flex align-item-center m-b-15">
                            <div className="report-gray-dot" />
                            <div
                                className="report-comparison m-r-20">{period == -1 ? strings.past_list[0] : strings.past_list[period]}</div>
                            <div className="report-blue-dot" />
                            <div
                                className="report-comparison">{period == -1 ? strings.present_list[0] : strings.present_list[period]}</div>
                        </div>
                        <ReportProgressBar
                            yesterday={getDecimal(realTimeYesterday.toString()) + strings._h}
                            yesterdayPercent={totalTimeYesterday != 0 ? realTimeYesterday / totalTimeYesterday * 100 : 0}
                            today={getDecimal(realTimeToday.toString()) + strings._h}
                            todayPercent={totalTimeToday != 0 ? realTimeToday / totalTimeToday * 100 : 0}
                            indicator={strings.realTime}
                        />
                        <ReportProgressBar
                            yesterday={getDecimal(goalAchYesterday.toString()) + "%"}
                            yesterdayPercent={goalAchYesterday}
                            today={getDecimal(goalAchToday.toString()) + "%"}
                            todayPercent={goalAchToday}
                            indicator={strings.goal_ach}
                        />
                        <ReportProgressBar
                            yesterday={getDecimal(efficiencyYesterday.toString()) + "%"}
                            yesterdayPercent={efficiencyYesterday}
                            today={getDecimal(efficiencyToday.toString()) + "%"}
                            todayPercent={efficiencyToday}
                            indicator={strings.efficiency}
                        />
                        <ReportProgressBar
                            yesterday={missionYesterday.toString() + strings._times}
                            yesterdayPercent={missionTotalYesterday != 0 ? missionYesterday / missionTotalYesterday * 100 : 0}
                            today={missionToday.toString() + strings._times}
                            todayPercent={missionTotalToday != 0 ? missionToday / missionTotalToday * 100 : 0}
                            indicator={strings.mission}
                        />
                    </div>
                    <div className="goal-achieve predicted position-relative">
                        <div className="d-flex align-item-center">
                            <div className="f-s-18 f-w-600 m-r-auto text-center">{strings.predicted_growth}</div>
                        </div>
                        <div className="predicted-coming">
                            <div>
                                <img src={"/assets/image/img_digging.png"} alt="" />
                                <div className="m-t-25">{strings.coming_soon}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </ModalWrap>
        </Overlay>
    );

}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 80vw;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

const CloseButton = styled.img`
  position: fixed;
  width: 50px;
  height: 30px;
  top: 0;
  right: 0;
  z-index: 9999;
`;

export default withRouter(DatalabModal);

import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Page_settings } from '../../../config/page_settings';
import RightContainer from "./right_container";
import BottomContainer from "./bottom_container";
import HeaderBlack from "../../../components/header/header_black";
import { getRandomNumber, getTimeFromSecond } from "../../../helper/common";
import { AGORA_APP_ID, SOCKET_URL } from "../../../config/const";
import RoomItem from "../../../components/control/item/room_item";
import ScreenSettingModal from "../../../components/control/modal/screen_setting_modal";
import ItemSearchModal from "../../../components/control/modal/item_search_modal";
import VideoModal from "../../../components/control/modal/video_modal";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import strings from "../../../lang/strings";
import MentorModal from "../../../components/control/modal/mentor_modal";
import MentorAskModal from "../../../components/control/modal/mentor_ask_modal";
import QuestionListModal from "../../../components/control/modal/question_list_modal";
import QuestionModal from "../../../components/control/modal/question_modal";
import io from 'socket.io-client';
import { rootStore } from "../../../mobx/store";
import StudyMaterialModal from "../../../components/control/modal/study_material_modal";
import NewSettingModal from "../../../components/control/modal/new_setting_modal";
import RoomInfoModal from "../../../components/control/modal/room_info_modal";
import SweetAlert from "react-bootstrap-sweetalert";
import MemberInfoModal from "../../../components/control/modal/member_info_modal";
import '../room.scss'
import AgoraRTC from 'agora-rtc-sdk-ng';
import moment from 'moment';

let socket = null;
let channelParameters =
{
    // A variable to hold a local audio track.
    localAudioTrack: null,
    // A variable to hold a local video track.
    localVideoTrack: null,
    // A variable to hold a remote audio track.
    remoteAudioTrack: null,
    // A variable to hold a remote video track.
    remoteVideoTrack: null,
    // A variable to hold the remote user id.s
    remoteUid: null,
};

const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

const StudyRoom = observer((props) => {
    const context = useContext(Page_settings);
    const idRef = useRef();
    const roomId = parseInt(props.match.params.id);
    const [roomTime, setRoomTime] = useState(0);
    const [studyTime, setStudyTime] = useState(0);
    const [rankingList, setRankingList] = useState([]);
    const [showSettingModal, setShowSettingModal] = useState(false);
    const [videoSetting, setVideoSetting] = useState(true)
    const [speakerSetting, setSpeakerSetting] = useState(true)
    const [cameraSetting, setCameraSetting] = useState(true)
    const [showSearch, setShowSearch] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [video, setVideo] = useState({});
    const [question, setQuestion] = useState({});
    const [showMentor, setShowMentor] = useState(false);
    const [showAskModal, setShowAskModal] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showQuestionDetail, setShowQuestionDetail] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [time, setTime] = React.useState(0);
    const [tick, setTick] = React.useState(0);
    const [startPressed, setStartPressed] = useState(false);
    const [endPressed, setEndPressed] = useState(false);
    const [keyword, setKeyword] = useState("")
    const [chatList, setChatList] = useState([]);
    const [unreadAll, setUnreadAll] = useState(0);
    const [partner, setPartner] = useState(0);
    const [videoKeyword, setVideoKeyword] = useState("");
    const [videoCategoryList, setVideoCategoryList] = useState([]);
    const [showVideoSearch, setShowVideoSearch] = useState(false);
    const [categorySelected, setCategorySelected] = useState(false);
    const [userName, setUserName] = useState("");
    const [owner, setOwner] = useState("");
    const [mine, setMine] = useState(false);
    const [title, setTitle] = useState("");
    const [materialModalShow, setMaterialModalShow] = useState(false);
    const [videoResultList, setVideoResultList] = useState([]);
    const [questionListPageNum, setQuestionListPageNum] = useState(1);
    const [videoListPageNum, setVideoListPageNum] = useState(1);
    const [questionListLoadMore, setQuestionListLoadMore] = useState(false);
    const [videoListLoadMore, setVideoListLoadMore] = useState(false);
    const [showReaction, setShowReaction] = useState(false);
    const [reaction, setReaction] = useState("");
    const [reactionTick, setReactionTick] = useState(0);
    const [reactionTime, setReactionTime] = useState(0);
    const [aiTick, setAiTick] = useState(0);
    const [aiTime, setAiTime] = useState(0);
    const [aiTickNew, setAiTickNew] = useState(0);
    const [aiTimeNew, setAiTimeNew] = useState(0);
    const [chew, setChew] = useState(strings.studying_chew[0]);
    const [rand, setRand] = useState(0);
    const [aiType, setAiType] = useState(0);
    const [todoCheck, setTodoCheck] = useState(false);
    const [realTime, setRealTime] = useState(0);
    const [todayTime, setTodayTime] = useState(0);
    const [studyGrade, setStudyGrade] = useState(0);
    const [participants, setParticipants] = useState(0);
    const [showSetting, setShowSetting] = useState(false);
    const [showRoomInfo, setShowRoomInfo] = useState(false);
    const [roomInfo, setRoomInfo] = useState("");
    const [profile, setProfile] = useState("");
    const [leaveAlert, setLeaveAlert] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [memberInfo, setMemberInfo] = useState("");
    const [agoraRtmToken, setAgoraRtmToken] = useState('');
    const [agoraRtcToken, setAgoraRtcToken] = useState('');
    const [meetingNumber, setMeetingNumber] = useState('');
    const [meetingRole, setMeetingRole] = useState(2);

    const listRef = useRef(memberList);

    // Dynamically create a container in the form of a DIV element to play the local video track.
    const localPlayerContainer = document.createElement('div');

    useEffect(() => {
        setUserName(rootStore.getProfile?.name ?? "")
        setProfile(rootStore.getProfile?.profile ?? "")
    }, [rootStore.getProfile]);

    const onChange = (v) => {
        setVideoKeyword(v);
        let videoCategoryListALL = [];

        context.get(
            "room/getItemList",
            {
                keyword: v,
                page_num: 1
            },
            response => {
                response.list.forEach(item => {
                    videoCategoryListALL.push(item.title);
                    let list = [];
                    videoCategoryListALL.forEach(item => {
                        if (item.toLowerCase().indexOf(v.toLowerCase()) > -1) {
                            list.push(item);
                        }
                    })
                    setVideoCategoryList(list);
                    setShowVideoSearch(v != "");
                    setCategorySelected(false);
                })
            }
        );
    }

    const getVideoList = (keyword, order, pageNum) => {
        context.get(
            "room/getItemList",
            {
                keyword: keyword,
                order: order,
                page_num: pageNum
            },
            response => {
                if (pageNum == 1) {
                    setVideoResultList(response.list)
                } else {
                    setVideoResultList([...videoResultList, ...response.list]);
                    setVideoListPageNum(pageNum);
                }
                setVideoListLoadMore(response.list.length == response.limit)
            }
        );
    }

    const onClear = () => {
        if (videoKeyword != "") {
            setVideoKeyword("")
            setShowVideoSearch(false);
            setCategorySelected(false);
        }
    }

    const toggleSweetAlert = () => {
        setLeaveAlert(!leaveAlert)
    }

    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);
        idRef?.current?.focus();

        getMemberList();
        getRoomDetail();
        setRand(getRandomNumber(3))
        if (rootStore.getProfile != null) {
            setVideoSetting(rootStore.getProfile.video == "ON");
            setSpeakerSetting(rootStore.getProfile.speaker == "ON");
            setCameraSetting(rootStore.getProfile.mirror == "ON");
        }

        const timer = window.setInterval(() => {
            setRoomTime(prevTime => prevTime + 1);
            setRealTime(prev => prev + 1);
            setMemberList(prev => prev.map((item, index) => {
                // 공부중인 사용자들의 시간계수
                if (item.status == 2) {
                    item.real_time = parseInt(item.real_time) + 1;
                }
                return item
            }))
        }, 1000);
        if (!socket) {
            socket = io(SOCKET_URL, {
                auth: {
                    token: props.rootStore.token,
                }
            });
        }
        socket.on('connect', () => {
            socket.emit('add user', roomId);
        });
        socket.on('disconnect', () => {
        });
        socket.on('reconnect', data => {
            socket.emit('add user', roomId);
        });
        socket.on("kickout", data => {
            if (data.member == rootStore.getProfile.id) {

                props.history.push("/room/study")
                addNotification('warning', strings.kicked)
            }
        })

        socket.connect();

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
            window.clearInterval(timer);
            socket.disconnect();
            socket.off('connect');
            socket.off('disconnect');
            socket.off('reconnect');
            socket.off('new message');
            socket.off('reaction');
            socket.off('kickout');
            socket = null;

            // Destroy the local audio and video tracks.
            if (channelParameters.localAudioTrack != null && channelParameters.localAudioTrack != '') {
                channelParameters.localAudioTrack.close();
            }

            if (channelParameters.localVideoTrack != null && channelParameters.localVideoTrack != '') {
                channelParameters.localVideoTrack.close();
            }

            if (agoraEngine != null) {
                // Leave the channel
                agoraEngine.leave();
                console.log("You left the channel");
                // Refresh the page for reuse
                window.location.reload();
            }
        }
    }, []);

    useEffect(() => {
        socket.on('new message', data => {
            if (data.receiver.id == rootStore.getProfile.id) {
                if (data.sender.id == memberList[partner].member) {
                    setChatList([data, ...chatList]);
                } else {
                    setMemberList(prevList => prevList.map((item, idx) => {
                        if ((item.member == data.sender.id) && (data.sender.id != memberList[partner].member)) {
                            item.unread++;
                            setUnreadAll(unreadAll + 1)
                        }
                        return item;
                    }))
                }
            }
        });
        return () => {
            if (socket) {
                socket.off("new message");
            }
        }
    }, [chatList, memberList]);

    useEffect(() => {
        listRef.current = memberList;
        let n = 0;
        memberList.forEach((item) => {
            if (item.status != 1) {
                n = n + 1
            }
        })
        setParticipants(n)
        socket.on("study started", data => {
            setMemberList(prev => prev.map((item, idx) => {
                if (item.user.id == data.user_info.user.id) {
                    item.status = data.user_info.status;
                    item.real_time = data.user_info.real_time;
                }
                return item
            }));
        });
        socket.on("study ended", data => {
            setMemberList(prev => prev.map((item, idx) => {
                if (item.user.id == data.user_info.user.id) {
                    item.status = data.user_info.status;
                    item.real_time = data.user_info.real_time;
                }
                return item
            }));
        });
        socket.on('reaction', data => {
            setMemberList(prev => prev.map((item, idx) => {
                if (item.user.id == data.user_info.id) {
                    item.reaction = data.reaction
                }
                return item
            }));
        });
        return () => {
            if (socket) {
                socket.off("study started");
                socket.off("study ended");
                socket.off("user left");
                socket.off("reaction")
            }
        }
    }, [memberList]);

    useEffect(() => {
        socket.connect();
        socket.on('user joined', async data => {
            setMemberList(prevList => prevList.map((item, idx) => {
                console.warn('joined user', item);
                item.user = item.member == data.user_info.id ? data.user_info : item.user;
                item.muteVideo = item.member == data.user_info.id ? data.user_info.video == 'OFF' : item.muteVideo;
                item.muteAudio = item.member == data.user_info.id ? data.user_info.speaker == 'OFF' : item.muteAudio;
                item.reverseCam = item.member == data.user_info.id ? data.user_info.mirror == 'ON' : item.reverseCam;
                item.status = item.member == data.user_info.id ? 0 : item.status;
                return item;
            }));
            if (getMemberIndex(data.user_info.id) < 0) {
                setMemberList([...listRef.current, { user: data.user_info, status: 0, member: data.user_info.id, unread: 0, muteVideo: false, muteAudio: false, reverseCam: false }]);
            }
            if (memberList.length == 0) {
                loadChatList(data.user_info.id);
            }
        });
        socket.on('user left', data => {
            setMemberList(prevList => prevList.map((item, idx) => {
                if (item.member == data.user_info.id) {
                    item.status = 1
                }
                return item
            }))
        });
        socket.on('cam mirror', data => {
            setMemberList(prevList => prevList.map((item, idx) => {
                if (item.member == data.user.id) {
                    item.reverseCam = data.reverse
                }
                return item
            }))
        });
        return () => {
            if (socket) {
                socket.off("user joined");
                socket.off("user left");
                socket.off("cam mirror");
            }
        }

    }, [memberList, chatList]);

    useEffect(() => {

    }, [speakerSetting])

    const getMemberIndex = (memberId) => {
        for (let i = 0; i < listRef.current.length; i++) {
            if (listRef.current[i].member == memberId) {
                return i;
            }
        }
        return -1;
    }

    const onSelectPartner = (idx) => {
        loadChatList(memberList[idx].member)
        setPartner(idx);
        setMemberList(prevList => prevList.map((item, index) => {
            if (index == idx) {
                setUnreadAll(unreadAll - item.unread)
                item.unread = 0;
            }
            return item
        }));
    }

    const loadChatList = (id) => {
        context.get(
            'room/getChatList',
            {
                room: roomId,
                target_member: id,
                page_num: 1
            },
            response => {
                setChatList(response.list);
            }
        );
    }

    const getUserInfo = async (id, videoTrack, audioTrack) => {
        console.warn('user published memberList', listRef.current);
        await context.get(
            'room/getMemberInfo',
            {
                room: roomId,
                member: id,
            },
            response => {
                const memberIndex = getMemberIndex(id);
                console.warn('getuserinfo memberIndex', memberIndex, listRef.current);
                if (memberIndex < 0) {
                    setMemberList([...listRef.current, {
                        user: response.user_info,
                        reaction: "",
                        status: 0,
                        member: id,
                        unread: 0,
                        videoTrack: videoTrack,
                        audioTrack: audioTrack,
                        muteVideo: false,
                        muteAudio: false,
                    }]);
                } else {
                    setMemberList(prevList => prevList.map((item, idx) => {
                        if (item.member == id) {
                            item.videoTrack = videoTrack;
                            item.audioTrack = audioTrack;
                            item.muteVideo = false;
                            item.muteAudio = false;
                            item.status = 0;
                        }
                        return item;
                    }))
                }
                console.log('getuserinfo response');
            }
        );
        console.log('getuserinfo mutex release');
    }

    const getRoomDetail = () => {
        context.get(
            'room/detail',
            {
                id: roomId
            },
            response => {
                setRoomInfo(response.room)
                setRoomTime(response.room.study_time != null ? parseInt(response.room.study_time) : 0);
                setStudyTime(response.room.real_time != null ? parseInt(response.room.real_time) : 0);
                setRankingList(response.ranking_list.list);
                setOwner(response.room.user.name);
                setMine(response.room.user.id == parseInt(rootStore.getProfile.id))
                setTitle(response.room.title);
                setRealTime(parseInt(response.room.today_real_time));
                setTodayTime(parseInt(response.room.today_study_time));
                setStudyGrade(parseInt(response.room.today_study_time) == 0 ? 0 : parseInt(response.room.today_real_time) / parseInt(response.room.today_study_time));
                setMeetingNumber(response.room.meeting_number);
                setMeetingRole(response.room.user.id == rootStore.getProfile?.id ? 1 : 2);
            }
        );
    }

    useEffect(() => {
        if (meetingNumber != "") {
            getAgoraToken(meetingNumber);
        }
    }, [meetingNumber, meetingRole])

    const onClickKeyword = (keyword) => {
        setVideoKeyword(keyword);
        getVideoList(keyword, "latest", 1);
        setCategorySelected(true);
        setShowVideoSearch(false);
    }

    const getMemberList = () => {
        context.get(
            'room/getMemberList',
            {
                room: roomId,
                type: 1
            },
            response => {
                let list = response.list.filter((item, idx) => {
                    return (item.member != parseInt(rootStore.getProfile.id))
                })
                if (list.length != 0) {
                    loadChatList(list[0].member);
                    setMemberList(list.map((item, idx) => {
                        item.reaction = ""
                        setUnreadAll(item.unread + unreadAll);
                        return item
                    }));
                }
            }
        );
    }

    const onRoomStart = () => {
        if (!startPressed) {
            setAiTime(0)
            if (endPressed) {
                setAiType(2);
                setChew(strings.studying_event[6]);
                setAiTickNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
            } else {
                setAiType(1);
                setChew(strings.studying_chew[rand])
            }
            socket.emit("study start", roomId);
            clearInterval(tick);
            clearInterval(aiTick);
            setTick(setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000));
            setAiTick(setInterval(() => {
                setAiTime(prevTime => prevTime + 1);
            }, 1000));
            setStartPressed(true);
        } else {
            if (600 < aiTime && aiTime < 900) {
                setAiType(2);
                setChew(strings.studying_event[2]);
                setAiTickNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
            } else {
                setAiType(0);
            }
            socket.emit("study end", roomId);
            clearInterval(tick);
            clearInterval(aiTick);
            setAiTime(0);
            setEndPressed(true);
            setStartPressed(false);
        }
    };

    useEffect(() => {
        if (aiTime % 2400 == 0 && aiTime != 0) {
            if (aiTime % 3600 == 0 && aiTime != 0) {
                setAiTickNew(0)
                setAiTimeNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
                setChew(strings.studying_event_red[0]);
                setAiType(3);
            } else {
                setChew(strings.studying_event[0])
                setAiTickNew(0)
                setAiTimeNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
                setAiType(2);
            }
        }
        if (aiTime == 4200) {
            setAiTickNew(0)
            setAiTimeNew(0)
            setChew(strings.studying_event[2])
            clearInterval(aiTickNew)
            setAiTickNew(setInterval(() => {
                setAiTimeNew(prevTime => prevTime + 1);
            }, 1000));
            setAiType(2);
        }
    }, [aiTime]);

    useEffect(() => {
        if (aiTimeNew > 60) {
            if (startPressed) {
                setAiType(1);
                setChew(strings.studying_chew[rand])
            } else {
                setAiType(0);
                setChew(strings.before_start_chew[rand])
            }
            clearInterval(aiTickNew)
            setAiTimeNew(0)
        }
    }, [aiTimeNew])

    const onOut = () => {
        if (startPressed) {
            setLeaveAlert(true);
        } else {
            props.history.push("/room/study");
        }

    };

    const onSearch = () => {
        setShowSearch(true)
    };

    const onClickVideo = (item) => {
        context.get(
            "room/getItemDetail",
            {
                id: item.id
            },
            response => {
                setShowSearch(false);
                setVideo(item);
                setShowVideo(true)
            }
        )
    };

    const onMentor = () => {
        setShowMentor(true)
    };

    const onQR = () => {
        addNotification('info', strings.in_progress)
    };

    const onNewQuestionAsk = () => {
        setShowQuestionModal(false);
        setKeyword("")
        setShowQuestionDetail(false);
        setShowAskModal(true);
    };

    const onRead = (item) => {
        context.get(
            "room/getQuestionItem",
            {
                question: item.id
            },
            response => {
                setQuestion(response.item);
                setShowQuestionModal(false);
                setShowQuestionDetail(true);
            }
        );
    };

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const onAsk = () => {
        setShowMentor(false);
        setShowAskModal(true);
    };

    const onQuestionAsk = (subjectId, gradeId, question, fileList) => {
        if (question == "") {
            addNotification('warning', strings.enter_question)
            return;
        }
        if (subjectId == -1) {
            addNotification('warning', strings.select_subject)
            return;
        }
        if (gradeId == -1) {
            addNotification('warning', strings.select_grade)
            return;
        }


        if (fileList.length != 0) {
            let formData = new FormData();
            fileList.forEach((entry, index) => {
                formData.append('file', entry);
            });

            context.post(
                'upload/images/room',
                formData,
                response => {
                    uploadQuestion(question, subjectId, gradeId, response.image);
                });
        } else {
            uploadQuestion(question, subjectId, gradeId, []);
        }

    };

    const uploadQuestion = (question, subjectId, gradeId, image) => {
        context.post(
            'room/addQuestion',
            {
                room: roomId,
                content: question,
                file: image.length != 0 ? image.join("#") : "",
                subject: strings.subject_list[subjectId],
                grade: strings.grade_list[gradeId]
            },
            response => {
                getQuestionList(1);
                setShowAskModal(false);
                addNotification('success', strings.question_add_success);
                setShowQuestionModal(true);
            });
    }

    const onQuestionList = () => {
        setShowMentor(false);
        getQuestionList(1);
        setShowQuestionModal(true);
    };

    const getQuestionList = (pageNum) => {
        context.get(
            'room/getQuestionList',
            {
                room: roomId,
                keyword: keyword,
                page_num: pageNum
            },
            response => {
                if (pageNum == 1) {
                    setQuestionList(response.list);
                    setQuestionListPageNum(1);
                } else {
                    setQuestionList([...questionList, ...response.list]);
                    setQuestionListPageNum(pageNum);
                    setQuestionListLoadMore(response.list.length == response.limit)
                }
            }
        );
    }

    const onPhoto = () => {
        addNotification('info', strings.in_progress)
    };

    const onSettingConfirm = (video, speaker, reverse) => {
        context.post(
            'room/updateSetting',
            {
                video: video ? "ON" : "OFF",
                speaker: speaker ? "ON" : "OFF",
                mirror: reverse ? "ON" : "OFF",
            },
            response => {
                setScreenSetting(video, speaker, reverse);
                setShowSettingModal(false);
                rootStore.setScreenSetting(video, speaker, reverse)
            }
        );
    }

    const setScreenSetting = async (video, speaker, reverse) => {
        if (videoSetting != video) {
            if (video) {
                await agoraEngine.publish(channelParameters.localVideoTrack);
                channelParameters.localVideoTrack.play('local-player');
            } else {
                await agoraEngine.unpublish(channelParameters.localVideoTrack);
                channelParameters.localVideoTrack.stop();
            }
            setVideoSetting(video);
        }

        if (speakerSetting != speaker) {
            if (speaker) {
                await agoraEngine.publish(channelParameters.localAudioTrack);
                // channelParameters.localAudioTrack.setEnabled(true);
            } else {
                await agoraEngine.unpublish(channelParameters.localAudioTrack);
                // channelParameters.localAudioTrack.setEnabled(false);
            }
            setSpeakerSetting(speaker);
        }

        if (cameraSetting != reverse) {
            socket.emit("cam mirror", { room: roomId, user: rootStore.getProfile.id, reverse: reverse });
            setCameraSetting(reverse);
        }
    }

    const onSendChat = (chat) => {
        let message = {
            room: roomId,
            type: 1,
            content: chat,
            sender: props.rootStore.getProfile.id,
            receiver: memberList[partner].member,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        socket.emit("new message", message)
        setChatList([message, ...chatList]);
    }

    const onClickReaction = (item) => {
        socket.emit("reaction", { room: roomId, reaction: item });
        setShowReaction(true);
        setReaction(item)
        clearInterval(reactionTick);
        setReactionTime(0);
        setReactionTick(setInterval(() => {
            setReactionTime(prevTime => prevTime + 1);
        }, 1000));
    }

    useEffect(() => {
        if (reactionTime == 3) {
            clearInterval(reactionTick)
            setReactionTime(0);
            setShowReaction(false);
            setReaction("")
            socket.emit("reaction", { room: roomId, reaction: "" });
        }
    }, [reactionTime]);

    useEffect(() => {
        if (realTime != 0) {
            let grade = Math.floor((time + todayTime) / realTime * 5)
            if (grade == studyGrade || !startPressed) return
            if (grade > studyGrade) {
                setAiType(2);
                setChew(strings.studying_event[5]);
                setAiTickNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
            } else {
                setAiType(3);
                setChew(strings.studying_event_red[1]);
                setAiTickNew(0)
                clearInterval(aiTickNew)
                setAiTickNew(setInterval(() => {
                    setAiTimeNew(prevTime => prevTime + 1);
                }, 1000));
            }
            setStudyGrade(grade)
        }
    }, [time])

    const onCheckTodo = (percent) => {
        if (!startPressed) return
        if (!todoCheck && percent >= 50 && percent < 70) {
            setChew(strings.studying_event[3])
            setAiTickNew(0)
            setAiTimeNew(0)
            clearInterval(aiTickNew)
            setAiTickNew(setInterval(() => {
                setAiTimeNew(prevTime => prevTime + 1);
            }, 1000));
            setAiType(2);
        }
        if (percent == 100) {
            setChew(strings.studying_event[4])
            setAiTickNew(0)
            setAiTimeNew(0)
            clearInterval(aiTickNew)
            setAiTickNew(setInterval(() => {
                setAiTimeNew(prevTime => prevTime + 1);
            }, 1000));
            setAiType(2);
        }
    }

    const onInvite = () => {
        const tempElem = document.createElement('textarea');
        tempElem.value = document.location.href;
        document.body.appendChild(tempElem);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        addNotification('success', '', strings.url_copied)
    }

    const onKick = (item) => {
        socket.emit("kickout", { room: roomId, member: item.member })
    }

    function getAgoraToken(channelName) {
        context.get(
            'room/getAgoraRtcToken',
            {
                channelName: channelName
            },
            response => {
                setAgoraRtcToken(response.token);
                startMeeting(response.token)
            }
        );
    }

    async function startMeeting(token) {
        let options = {
            // Pass your App ID here.
            appId: AGORA_APP_ID,
            // Set the channel name.
            channel: meetingNumber,
            // Pass your temp token here.
            token: token,
            // Set the user ID.
            uid: rootStore.getProfile.id,
            // Set the user role
            role: 'host'
        };

        // Set the local video container size.
        localPlayerContainer.style.width = "100%";
        localPlayerContainer.style.height = "100%";

        agoraEngine.on("user-published", async (user, mediaType) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event.
            await agoraEngine.subscribe(user, mediaType);
            console.log("subscribe success", user.uid, mediaType);
            // Subscribe and play the remote video in the container If the remote user publishes a video track.
            const memberIndex = getMemberIndex(user.uid);
            console.log('publish user id = ', memberIndex);
            if (memberIndex >= 0) {
                setMemberList(prevList => prevList.map((item, idx) => {
                    console.warn('publish exist user', item);
                    if (item.member == user.uid) {
                        item.videoTrack = mediaType == 'video' ? user.videoTrack : item.videoTrack;
                        item.audioTrack = mediaType == 'audio' ? user.audioTrack : item.audioTrack;
                        item.muteVideo = item.user.video == "OFF";
                        item.muteAudio = false;
                        item.reverseCam = item.user.mirror == "ON";
                    }
                    return item;
                }))
            } else {
                setMemberList([...listRef.current, {
                    user: {},
                    status: 0,
                    member: user.uid,
                    unread: 0,
                    videoTrack: mediaType == 'video' ? user.videoTrack : null,
                    audioTrack: mediaType == 'audio' ? user.audioTrack : null,
                    muteVideo: false,
                    muteAudio: false,
                    reverseCam: false,
                }]);
            }
            // Listen for the "user-unpublished" event.
            agoraEngine.on("user-unpublished", user => {
                console.log("user-unpublished", user.uid, mediaType);
                setMemberList(prevList => prevList.map((item, idx) => {
                    console.warn('unpublish user', item);
                    if (item.member == user.uid) {
                        if (mediaType == 'video') {
                            item.videoTrack = null;
                        }
                        if (mediaType == 'audio') {
                            item.audioTrack = null
                        }
                    }
                    return item;
                }))
            });
        });

        agoraEngine.setClientRole(options.role);
        // Join a channel.
        await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
        // Create a local audio track from the audio sampled by a microphone.
        channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a local video track from the video captured by a camera.
        channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // Append the local video container to the page body.
        // document.querySelector('#local-player').append(localPlayerContainer);

        // Publish the local audio and video track if the user joins as a host.
        if (options.role == 'host') {
            // Publish the local audio and video tracks in the channel.
            let publishTracks = [channelParameters.localVideoTrack];
            if (speakerSetting) {
                publishTracks.push(channelParameters.localAudioTrack);
            }
            await agoraEngine.publish(publishTracks);
            // await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
            if (videoSetting) {
                // Play the local video track.
                channelParameters.localVideoTrack.play('local-player');
            }

            channelParameters.localAudioTrack.stop();
            // if (!speakerSetting) {
            // channelParameters.localAudioTrack.play();
            // channelParameters.localVideoTrack.setEnabled(false);
            // }
            console.log("publish success!");
        }
    }

    return (
        <React.Fragment>
            <HeaderBlack
                roomTime={getTimeFromSecond(roomTime)}
                realTimeShow={true}
                realTime={getTimeFromSecond(studyTime + time)}
            />
            <div className="display-flex-important study-room">
                <div className="study-room-main row">
                    <div className={"study-room-item study-room-selected" + (cameraSetting ? " camera-reverse" : "")} id='local-player'
                        style={{ display: "flex", position: 'relative' }}>
                        {
                            !videoSetting &&
                            <div
                                className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                                <img src={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/logo_big.png"}
                                    style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                                    alt=""
                                />
                            </div>
                        }
                        {
                            showReaction && reaction != "" &&
                            <div
                                className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                                <img src={reaction} alt="" />
                            </div>
                        }
                    </div>
                    {
                        memberList.map((item, idx) => {
                            if (item.status != 1) {
                                return (
                                    <RoomItem
                                        key={idx}
                                        item={item}
                                        videoTrack={item.videoTrack}
                                        audioTrack={item.audioTrack}
                                        muteVideo={item.muteVideo}
                                        muteAudio={false}
                                        reverseCam={item.reverseCam}
                                    />
                                )
                            }
                        }
                        )
                    }
                </div>
                <RightContainer
                    memberList={memberList}
                    rankingList={rankingList}
                    onSelectPartner={(idx) => onSelectPartner(idx)}
                    chatList={chatList}
                    onSendChat={(chat) => onSendChat(chat)}
                    unreadAll={unreadAll}
                    partner={partner}
                    startPressed={startPressed}
                    endPressed={endPressed}
                    chew={chew}
                    type={aiType}
                    onCheck={(percent) => onCheckTodo(percent)}
                />
                <BottomContainer
                    roomInfo={roomInfo}
                    mine={mine}
                    memberList={memberList}
                    onStart={() => onRoomStart()}
                    onSearch={() => onSearch()}
                    onMentor={() => onMentor()}
                    owner={owner}
                    participants={participants + 1}
                    title={title}
                    startPressed={startPressed}
                    onMaterial={() => setMaterialModalShow(true)}
                    onClickReaction={(item) => onClickReaction(item)}
                    onOut={() => {
                        onOut()
                    }}
                    onSetting={() => setShowSetting(true)}
                    onInvite={() => onInvite()}
                    onKick={(item) => onKick(item)}
                    onInfo={(item) => {
                        setMemberInfo(item);
                        setShowInfoModal(true)
                    }}
                />
                {
                    showReaction &&
                    <div className="reaction-alarm">
                        <div className="reaction-alarm-content">
                            <img src={reaction.icon} alt="" />
                            {reaction.desc}
                        </div>
                    </div>
                }
                <NewSettingModal
                    show={showSetting}
                    toggle={() => setShowSetting(!showSetting)}
                    onScreenSettings={() => {
                        setShowSetting(false);
                        setShowSettingModal(true);
                    }}
                    onRoomInfo={() => {
                        setShowSetting(false);
                        setShowRoomInfo(true);
                    }}
                />
                <RoomInfoModal
                    show={showRoomInfo}
                    mine={mine}
                    toggle={() => setShowRoomInfo(!showRoomInfo)}
                    onBack={() => {
                        setShowRoomInfo(false);
                        setShowSetting(true);
                    }}
                    roomInfo={roomInfo}
                    onUpdate={() => getRoomDetail()}
                />
                <MemberInfoModal
                    show={showInfoModal}
                    toggle={() => setShowInfoModal(!showInfoModal)}
                    memberInfo={memberInfo}
                />
                {
                    showSettingModal &&
                    <ScreenSettingModal
                        show={showSettingModal}
                        name={userName}
                        profile={profile}
                        cameraType={'전면'}
                        toggle={() => setShowSettingModal(!showSettingModal)}
                        close={() => setShowSettingModal(false)}
                        video={videoSetting}
                        speaker={speakerSetting}
                        reverse={cameraSetting}
                        onConfirm={(video, speaker, reverse) => {
                            onSettingConfirm(video, speaker, reverse);
                        }}
                        onBack={() => {
                            setShowSettingModal(false);
                            setShowSetting(true);
                        }}
                    />
                }
                {
                    showSearch &&
                    <ItemSearchModal
                        show={showSearch}
                        toggle={() => setShowSearch(!showSearch)}
                        close={() => setShowSearch(false)}
                        onClickVideo={(item) => {
                            onClickVideo(item)
                        }}
                        onQR={() => {
                            onQR()
                        }}
                        videoKeyword={videoKeyword}
                        videoCategoryList={videoCategoryList}
                        showVideoSearch={showVideoSearch}
                        categorySelected={categorySelected}
                        onChange={(v) => onChange(v)}
                        videoResultList={videoResultList}
                        onClickKeyword={(keyword) => onClickKeyword(keyword)}
                        onClickCategory={(idx) => {
                            getVideoList(videoCategoryList[idx], "latest", 1);
                            setVideoKeyword(videoCategoryList[idx]);
                            setCategorySelected(true);
                            setShowVideoSearch(false);
                        }}
                        onEnter={() => {
                            getVideoList(videoKeyword, "latest", 1);
                            setCategorySelected(true);
                            setShowVideoSearch(false);
                        }}
                        onLoadMore={() => {
                            if (videoListLoadMore) {
                                getVideoList(videoKeyword, "latest", videoListPageNum + 1)
                            }
                        }}
                        onClear={() => onClear()}
                    />
                }
                {
                    showVideo &&
                    <VideoModal
                        show={showVideo}
                        toggle={() => setShowVideo(!showVideo)}
                        close={() => setShowVideo(false)}
                        onBack={() => {
                            setShowSearch(true);
                            setShowVideo(false);
                        }}
                        item={video}
                    />
                }
                {
                    showMentor &&
                    <MentorModal
                        show={showMentor}
                        toggle={() => setShowMentor(!showMentor)}
                        close={() => setShowMentor(false)}
                        onAsk={() => onAsk()}
                        onQuestionList={() => onQuestionList()}
                    />
                }
                {
                    showAskModal &&
                    <MentorAskModal
                        show={showAskModal}
                        toggle={() => setShowAskModal(!showAskModal)}
                        close={() => setShowAskModal(false)}
                        onAsk={(subjectId, gradeId, question, fileList) => onQuestionAsk(subjectId, gradeId, question, fileList)}
                        onPhoto={() => onPhoto()}
                    />
                }
                {
                    showQuestionModal &&
                    <QuestionListModal
                        show={showQuestionModal}
                        data={questionList}
                        keyword={keyword}
                        onKeyword={(text) => setKeyword(text)}
                        toggle={() => setShowQuestionModal(!showQuestionModal)}
                        close={() => {
                            setShowQuestionModal(false);
                            setKeyword("")
                        }}
                        onAsk={() => onNewQuestionAsk()}
                        onRead={(item) => onRead(item)}
                        onSearchKeyword={() => getQuestionList(1)}
                        onLoadMore={(ss) => {
                            if (questionListLoadMore) {
                                getQuestionList(questionListPageNum + 1);
                            }
                        }}
                    />
                }
                {
                    showQuestionDetail &&
                    <QuestionModal
                        show={showQuestionDetail}
                        onBack={() => {
                            setShowQuestionDetail(false);
                            setShowQuestionModal(true);
                        }}
                        toggle={() => setShowQuestionDetail(!showQuestionDetail)}
                        close={() => setShowQuestionDetail(false)}
                        onAsk={() => onNewQuestionAsk()}
                        item={question}
                    />
                }
                <StudyMaterialModal
                    show={materialModalShow}
                    close={() => setMaterialModalShow(false)}
                    toggle={() => setMaterialModalShow(!materialModalShow)}
                />
                {(leaveAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.leave}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black"
                        onConfirm={() => props.history.push("/room/study")}
                        onCancel={() => toggleSweetAlert()}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {strings.leave_alert}
                    </SweetAlert>
                )}
            </div>
        </React.Fragment>
    );
});

export default withRouter(inject('rootStore')(StudyRoom));

import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../lang/strings";
import OrgaSearch from "./customSearch/orgaSearch";
import ClassSearch from "./customSearch/classSearch";


function ClassAddModal({ onClose }) {

  const [orgaSearchValue, setOrgaSearchValue] = useState("");
  const [choiceYear, setChoiceYear] = useState("연도");
  const [choiceGrade, setChoiceGrade] = useState("학년");
  const [inputClass, setInputClass] = useState("");

  const handleClose = () => {
    onClose?.();
  };
  return (
      <Overlay>
        <ModalWrap>
          <div class="classAddModal-head">
            <h4>클래스 추가</h4>
            <img onClick={handleClose} src="/assets/image/icon_close_gray.png"/>
          </div>
          <div class="classAddModal-subTitle">
            기관 검색
          </div>
          <div>
          <OrgaSearch
            placeholder={strings.organization_search_placeholder}
            inputType={'text'}
            value={orgaSearchValue}
            onChange={(v) => {
              setOrgaSearchValue(v)
            }}
            onSearch={() => {
              //여기에 기관 뜨는 데이터 넣어야할듯 
            }}
        />
          </div>
          <div className="classAddModal-secondContent">
            <div className="col-md-2">
              <div class="classAddModal-subTitle">연도</div>
              <Dropdown>
                 <Dropdown.Toggle variant="success" id="dropdown-class" className="classAddModal-dropdown">
                  {choiceYear}
                 </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { strings.class_year.map((item,index)=>{
                      return(
                      <Dropdown.Item onClick={()=> {setChoiceYear(item.year)}}>
                        {item.year}
                      </Dropdown.Item>
                    )
                    })}
                  </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-2">
              <div class="classAddModal-subTitle">학년</div>
              <Dropdown>
                 <Dropdown.Toggle variant="success" id="dropdown-basic" className="classAddModal-dropdown">
                  {choiceGrade}
                 </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { strings.class_grade.map((item,index)=>{
                      return(
                      <Dropdown.Item onClick={()=> {setChoiceGrade(item.grade)}}>
                        {item.grade}
                      </Dropdown.Item>
                    )
                    })}
                  </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-8">
              <div class="classAddModal-subTitle">반</div>
              <ClassSearch
                placeholder={strings.class_input_placeholder}
                inputType={'text'}
                value={inputClass}
                onChange={(v) => {
                  setInputClass(v)
                }}
                onSearch={() => {
                  //여기에 기관 뜨는 데이터 넣어야할듯 
                }}
        />
            </div>
          </div>

          <div class="classAddModal-thumbnail">
            섬네일 이미지
          </div>
          <div class="classAddModal-imgAdd">
            <img src="/assets/image/class_addImg.png"/>
            <div class="classAddModal-choiceImg">이미지를 선택하세요</div>
          </div>
          <Button >확인</Button>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: #DFE3E8;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  margin-top: 20px;
  &:hover {
    background-color: #898989;
  }
`;
export default ClassAddModal;
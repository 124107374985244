import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import YouTube from 'react-youtube';
import { getVideoIdFromYoutubeURL } from '../../../helper/common';

const YoutubeViewModal = (props) => {

    const context = useContext(Page_settings);

    const { title, show, toggle, url } = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="youtube-view-modal">
            <ModalBody className="p-0">
                <div className="topbar">
                    <div className="title">{title ?? ''}</div>
                    <button className="common-button close-btn m-l-auto" onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <YouTube videoId={getVideoIdFromYoutubeURL(url)} opts={{ playerVars: { autoPlay: 1 } }} />
                </div>
                <div className='bottom-bar'></div>
            </ModalBody>
        </Modal>

    );
}

export default YoutubeViewModal;

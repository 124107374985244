export default {
    admin: 'Chewing',
    write_goal_today: '오늘의 목표를 적어주세요',
    to_my_goal: '내 목표까지',
    period_list: [
        { code: 0, name: '일' },
        { code: 1, name: '주' },
        { code: 2, name: '월' }
    ],
    day: '일',
    week: '주',
    month: '월',
    today_mission: "오늘의 미션",
    today_ranker: "오늘의 랭커",
    today: "오늘",
    best_rank: "베스트 랭킹",
    this_month: "이달의",
    grow_rate: "성장률",
    mission: "미션",
    no_1: "No 1",
    kicked: "강제 탈퇴 되었습니다.",
    _s: "s",
    _min: "m",
    _file: " 파일",
    load_more: "더 불러오기",
    real_time: "리얼 타임",
    study_efficiency: "공부 효율도",
    not_good: "아쉬워요...",
    goal_achievement: "목표 달성 수치",
    study_than_prev: "전일 대비 공부량",
    yesterday: "어제",
    hour: "시간",
    efficiency: "공부 효율도",
    achievement: "목표달성",
    to_do_list: "TO DO List",
    daily: "일상",
    study_cafe_mission: "미션 리스트",
    clinic_mission: "클리닉 퀘스트",
    cabinet_mission: "캐비닛 퀘스트",
    my_study: "마이 스터디",
    my_score: "마이 스코어",
    multi_memo: "메모",
    memo_placeholder: "메모를 남겨보세요",
    no_review: "작성된 후기가 없습니다.",
    all: "전체",
    opened: "개설한 룸",
    entered: "참가한 룸",
    open_room: "룸 개설",
    open: "오픈룸",
    secret: "시크릿 룸",
    today_room: "오늘의 룸",
    study_with_me: "스터디 윗미",
    challenge_room: "챌린지 룸",
    advertise1: "5주간의 클리닉 케어!\n클리닉 받고 성공하자!",
    advertise2: "학습자의 정보에 맞는 AI 컨설팅\n전문 멘토진들의 실시간 질의응답까지\n\n현재 당신의 상태를 알고 싶다면?",
    room_search_placeholder: "사용자, 룸 검색",
    class_search_placeholder: "클래스 검색",
    classInfo_search_placeholder: "학생 검색",
    organization_search_placeholder: "기관을 검색해주세요",
    student_search_placeholder: "학생의 이름이나 이메일을 검색하세요.",
    class_input_placeholder: "반을 입력해주세요",
    sat: "수능",
    official: "공무원",
    hiring: "임용",
    certific: "자격증",
    lang: "어학",
    employ: "취업",
    school: "학교",
    etc: "기타",
    clinic_premium: "클리닉 프리미엄",
    edit_profile: "프로필 수정",
    settings: "설정",
    create_study_room: "스터디 룸 만들기",
    my_room: "마이 룸",
    change_password: "비밀번호 변경",
    old_password: "현재 비밀번호",
    new_password: "새 비밀번호",
    room_title: "룸 제목",
    enter_title: "제목을 입력해주세요",
    room_mode: "룸 모드",
    room_public: "공개 여부",
    room_info_study: "학습관리를 받으며 공부습관 교정이 가능한 스터디룸입니다.",
    room_info_challenge: "학습관리를 받으며 공부습관 교정이 가능한 스터디룸입니다.",
    cover_image: "커버 이미지",
    tag: "태그",
    keyword: "키워드",
    category: "카테고리",
    tag_placeholder: "스터디 룸에 해당하는 키워드를 최대 3가지 입력해주세요",
    tag_placeholder_conference: "컨퍼런스 룸에 해당하는 키워드를 최대 3가지 입력해주세요",
    period: "기간",
    up_to_month: "최대 한달",
    question_add_success: "질문이 등록되었습니다.",
    select_image: "이미지를 추가해주세요.",
    password: "비밀번호",
    room_password_placeholder: "비밀번호 6자리",
    enter_password: "비밀번호를 입력해주세요.",
    enter_password_correctly: "비밀번호를 유효한 형식으로 입력해주세요.",
    please_enter: "입력하세요",
    enter_your_password: "비밀번호를 입력해주세요.",
    password_placeholder: "비밀번호 입력(8~20자 영문, 숫자, 특수문자 조합)",
    re_enter_password: "비밀번호 재입력",
    you_cannot_change_password_sns: "SNS계정으로 가입되어 비밀번호 변경이 불가합니다.",
    sign_out_warning: "chewing을 다시 사용할 일이 없어 계정을 없애고 싶으시면 계정삭제를 도와드리겠습니다.\n삭제된 계정은 다시 복구할 수 없고 계정의 데이터는 모두 삭제된다는 점을 기억해주세요.\n그래도 계정을 삭제하려면 계정삭제를 클릭해주세요.",
    delete_account: "탈퇴하기",
    consult: "상담하기",
    please_confirm_password: "비밀번호 확인을 입력해주세요.",
    confirm_password: "비밀번호 확인을 입력해주세요.",
    password_confirm: "비밀번호 확인",
    i_agree: "에 동의합니다",
    i_agree_optional: "에 동의합니다 (선택)",
    to_collection_use_info: "개인정보 수집 및 이용",
    to_use_marketing_receive_adv: "마케팅 활용 및 광고 수신",
    invalid_email: "유효하지 않은 이메일 입니다. 다른 이메일을 입력해주세요.",
    invalid_password: "8~20자 영문, 숫자, 특수문자를 입력하세요",
    name: "이름",
    enter_your_name: "이름을 입력해주세요",
    next: "다음",
    introduction: "소개글",
    introduction_placeholder: "목적, 내용, 규칙 등 스터디에 대한 상세한 정보를 입력하세요 (선택)",
    password_not_matching: "비밀번호와 비밀번호 확인이 동일하지 않습니다.",
    until: "까지",
    warning: "알림",
    select_period: "기간을 정확히 선택해주세요.",
    study_room: "스터디 룸",
    time: "시간",
    clinic: "CLINICC",
    mentor_question: "멘토질문",
    item_search: "문항검색",
    study_material: "학습자료",
    reaction: "리액션",
    participants: "참가자",
    share: "화면공유",
    split: "스플릿",
    start: "공부시작",
    end: "공부종료",
    upload: "업로드",
    upload_file: "파일 업로드",
    drag_drop_file: "파일을 끌어다 놓으세요",
    cloud_storage: "클라우드 스토리지",
    ranking: "랭킹",
    planner: "플래너",
    chatting: "채팅",
    ai_couch: "Ai Chew 코치",
    couch_word: "쉬는시간 그만~\n얼른 자리로 와주세요!",
    edit: "편집",
    delete: "삭제",
    cancel: "취소",
    available_time: "가용 시간",
    todo_list: "Todo List",
    comment: "COMMENT",
    memo: "MEMO",
    add_tag: "태그 추가",
    todo_add_hint: "오늘의 투두리스트를\n작성해주세요",
    feedback: "Feedback",
    enter_chat: "채팅을 입력해주세요",
    input_feedback: "피드백을 입력해주세요",
    create: "생성하기",
    color_random: "* 컬러는 랜덤적용 됩니다",
    invite: "초대하기",
    leave: "나가기",
    ai_chewing: "Ai 츄잉이",
    couch_word_conference: "다들 불타오르네요!\n각자 의견을 잘 듣고 답변을 준비해요!",
    hot: "아뜨거",
    temperature: "이방의 온도",
    edit_tag: "태그 편집",
    edit_todo: "TODO 수정",
    add_todo: "TODO 추가",
    input_tag_name: "태그명을 입력해주세요",
    input_todo_name: "TODO 내용을 입력해주세요",
    modify: "수정",
    signup: "회원가입",
    class_info: "클래스 정보",
    delete_planner: "플래너 삭제",
    delete_video: "동영상 삭제",
    delete_planner_alert: "정말로 삭제하시겠습니까?\n한번 삭제하시면 복구할 수 없습니다",
    screen_settings: "화면 설정",
    video_on: "비디오 켜기",
    video_off: "비디오 끄기",
    speaker_off: "스피커 끄기",
    speaker_on: "스피커 켜기",
    reverse: "좌우 반전",
    confirm: "확인",
    delete_tag: "태그 삭제",
    delete_cloud: "클라우드에서 제거",
    delete_todo: "TODO 삭제",
    nick_name: "닉네임",
    email: "이메일",
    hope_school: "희망 학교",
    hope_career: "희망 진로",
    career: "경력",
    requirements: "필수 요건 3가지",
    pocari_consulting: "포카리 컨설팅",
    enter_nick: "닉네임을 입력해주세요",
    enter_your_nick: "사용하실 닉네임을 입력해주세요",
    add_more: "파일 추가",
    enter_email: "이메일을 입력해주세요",
    enter_your_email: "이메일을 입력해주세요",
    enter_school: "학교명 입력",
    item_search_placeholder: "강의명 / 교재명 / 문항코드를 입력해주세요",
    reservation_history: "학생내역",
    no_plan: "오늘 등록된 플랜이 없습니다.",
    account_info: "계정정보",
    sign_out: "회원탈퇴",
    qr: "QR",
    date_birth: "생년월일",
    phone: "휴대폰",
    re_send: "재전송",
    done: "완료",
    include_graduation: "(졸업 포함)",
    optional: "(선택)",
    search_school: "재학중인 학교를 검색해주세요",
    desired_school: "희망하는 학교를 적어주세요",
    desired_career: "희망하는 진로를 적어주세요",
    enter_phone: "휴대폰 번호를 입력해주세요 ( ‘-’제외 )",
    enter_code: "인증번호 입력",
    enter_birth: "생년월일 6자리를 입력해주세요",
    recent_view_video: "최근 본 동영상",
    in_progress: "준비중입니다.",
    mentor_chew: "멘토츄",
    mentor_ask: "안녕? 포카리야~ 무엇이 궁금하니?",
    question_list: "질문 리스트",
    enter_want: "모르는 내용을 입력해줘!",
    ask: "물어보기",
    question: "질문",
    subject: "과목",
    grade: "학년",
    no_plan_today: "오늘 등록된 플랜이 없습니다.",
    school_search: "학교검색",
    no_member: "아직 참여한 회원이 없습니다.",
    normal: "Normal",
    file: "파일",
    notifications: "알림",
    notification_empty: "등록된 알림이 없습니다.",
    _owner_open: " 님으로부터 톡이 왔습니다.",
    mark_read: "읽음으로 표시",
    turn_off_noti: "알림 끄기",
    upload_photo: "사진 및 파일\n업로드",
    max: "최대",
    take_photo: "사진 촬영",
    file_photo: "파일/사진",
    camera: "촬영",
    ask_new_question: "새 질문하기",
    ask_a_question: "질문하기",
    read: "읽음",
    save: "저장",
    info: "정보",
    kick_out: "강퇴",
    room_info: "방 정보",
    room_date: "날짜",
    d_: "D-",
    leave_alert: "‘리얼타임’에 저장되지 않을 수 있습니다.\n그래도 종료하시겠습니까?",
    not_read: "안읽음",
    notification: "알림",
    premium_subscription: "PREMIUM 구독",
    terms: "약관",
    notification_from_web: "웹 내에서 알림 받기",
    receive_marketing_info: "마케팅 정보 수신 동의",
    term_service: "서비스 이용약관",
    collect_info: "개인정보 수집 및 이용",
    success: "Success",
    room_create_success: "Room Creating Success",
    no_room: "개설된 방이 존재하지 않습니다.",
    no_item: "등록된 상품이 없습니다.",
    h: "h",
    change_success: "소개글이 수정되었습니다.",
    term_service_detail: "관련 내용관련 내용관련 내용관련 내용관련 내용\n관련 내용관련 내용관련 내용관련 내용관련 내용\n관련 내용관련 내용관련 내용관련 내용관련 내용\n",
    term_info_detail: "관련 내용관련 내용관련 내용관련 내용관련 내용\n관련 내용관련 내용관련 내용관련 내용관련 내용\n관련 내용관련 내용관련 내용관련 내용관련 내용\n",
    success_to_save: "저장되었습니다.",
    consultant_expert: "전문가",
    _consultant: " 컨설턴트",
    consultation_history: "상담 내역",
    consulting_placeholder: "컨설팅 전문가에 대한 정보를 입력해주세요",
    favourite: "즐겨찾기",
    want_know_best_tip: "서울대가는 꿀팁이 알고 싶다면?",
    go: "바로가기",
    expert: "전문가",
    reservation_complete: "예약완료",
    write_review: "후기작성",
    match_complete: "매칭완료",
    review: "후기",
    _reviews: "개",
    max_500: "(최대 500자)",
    max_10: "(최대 10장)",
    photo_files: "사진파일",
    you_cannot_select_more: "더 이상 파일을 추가할 수 없습니다.",
    you_cannot_add_more: "더 이상 추가할 수 없습니다.",
    write: "작성하기",
    book_now: "바로 예약",
    enter_question: "질문을 입력해주세요.",
    select_grade: "학년을 선택해주세요.",
    select_subject: "과목을 선택해주세요.",
    expert_placeholder: "전문가에게 전달할 내용을 입력해주세요",
    student_placeholder: "학생에게 전달할 내용을 입력해주세요",
    send: "전송",
    consulting_tendency: "오늘의 한줄",
    consult_time: "상담가능 시간",
    hours: "시간",
    no_tendency: "오늘도 최선을 다하자.",
    average_response_time: "평균 응답시간",
    age: "나이",
    within: " 이내",
    clinic_duration: "클리닉 진행 기간",
    conversion_value: "(변환값)",
    ratings: "평점",
    rating: "별점",
    view_details: "상세보기",
    introduce: "소개",
    left: " Left ",
    joined: " Joined ",
    enter_school_name: "학교명을 입력해주세요",
    hope_search: "학교검색",
    select_category: "카테고리를 선택해주세요",
    wrong_password: "비밀번호가 동일하지 않습니다.",
    study_effi: "공부 효율도",
    study_time: "공부시간",
    study: "스터디 카페",
    up_top: "상위 TOP 3",
    down_top: "하위 TOP 3",
    mission_accomplished: "미션 달성",
    attendance: "출석",
    _times: "회",
    // comparison: "비교 수치",
    comparison: "어제와 오늘",
    realTime: "리얼타임",
    _h: "H",
    coming_soon: "준비중 입니다",
    _Times: "회",
    total: "Total",
    curriculum: "맞춤형 커리큘럼 ",
    check_out: "확인하기",
    goal_ach: "목표달성",
    predicted_growth: "예측 성장률",
    find_account: "계정 찾기",
    find_id: "아이디 찾기",
    find_password: "비밀번호 찾기",
    you_can_check_id: "휴대폰 인증을 통해\n아이디(이메일)를 확인할 수 있습니다.",
    enter_registered_id: "가입한 아이디(이메일)를 입력해주세요.\n휴대폰 본인인증 또는 이메일 인증을\n통해 비밀번호를 변경합니다.",
    this_is_temporary_password: "임시 비밀번호입니다.\n계정설정에서 변경하실 수 있습니다.",
    authenticate_phone: "휴대폰 인증하기",
    go_login: "로그인 바로가기",
    this_is_your_id: "본인 확인 아이디(이메일)입니다.",
    phone_duplicate: "등록된 휴대폰 번호입니다. 다른 번호를 이용해주세요.",
    nickname_duplicate: "사용중인 닉네임 입니다.",
    authenticate_email: "이메일 인증하기",
    for_successful_admission: "성공적인\n진학을 위한",
    new_consulting: "NEW 컨설팅",
    make_reservation: "예약하기",
    expert_advertise_chewing: "진학의 기본이자 필수인 컨설팅!\n츄잉에서 최고의 전문가와 함께 체계적인 프로그램에 함께하세요",
    _consulting: " 컨설팅",
    consultant: "컨설턴트",
    resume: "이력",
    anywhere_anytime: "원하는 곳, 어디서나",
    consulting_available: "컨설팅 가능",
    hour_Q_A_available: "24시간 자유롭게 질의응답",
    more_24: "24시간 이상",
    g1: "고1",
    g2: "고2",
    g3: "고3/N수",
    g3_ns: "고3/N수",
    _GB: "GB",
    _KB: "KB",
    _MB: "MB",
    free: "여유",
    precaution_refund_policy: "주의사항 및 환불규정",
    more_than_days_after: "환불요청일시 기준 수강시작 후 7일 초과",
    increase_capacity: "용량 늘리기",
    refund_policy: "환불정책",
    type: "종류",
    size: "크기",
    date: "올린 날짜",
    open_file: "파일 열기",
    download_file: "파일 다운로드",
    remove_from_cloud: "클라우드에서 제거",
    _selected: "개 선택",
    order_list: [
        { code: "abc", name: "이름순" },
        { code: "latest", name: "최신순" },
        { code: "oldest", name: "오래된순" },
    ],
    status_list: [
        "아쉬워요",
        "조금만 더 힘내요",
        "잘하고 있어요",
        "멋있어요",
        "이대로만 가주세요"
    ],
    past_list: [
        "어제",
        "지난주",
        "지난달"
    ],
    present_list: [
        "오늘",
        "이번주",
        "이번달"
    ],
    pocari_list: [
        { small: "24시간 자유롭게 질의응답", big: "프리 컨설턴트" },
        { small: "1:1 나만을 위한", big: "맞춤형 컨설팅" },
        { small: "원하는 목표만을 위한", big: "타겟팅 프로그램" },
    ],
    expert_detail_button_list: [
        { code: 0, name: "컨설턴트 소개" },
        { code: 1, name: "커리큘럼" },
        { code: 2, name: "리뷰" },
        { code: 3, name: "환불정책" },
    ],
    benchmark_list: [
        "의 1위",
        "성장률 1위",
        "학습효율도 1위"
    ],
    this_date: "오늘",
    this_month: "이달",
    this_year: "올해",
    first_month: "이달의 1위",
    first_growth: "성장률 1위",
    first_efficiency: "학습효율도 1위",
    addr: "고1",
    net_time: "순공시간",
    growth_rate: "성장률",
    learning_efficiency: "학습 효율도",
    save_id: "아이디 저장",
    are_you_new: "츄잉이 처음이신가요?",
    signup_and: "회원가입하고 ",
    try_for_free: "무료체험 하기",
    create_conference_room: "컨퍼런스 룸 만들기",
    url_copied: "초대링크가 복사되었습니다.",
    invalid_date: "날짜형식이 잘못되었습니다. 다시 입력해주세요.",
    not_available: '상담 불가능한 시간입니다.',
    not_known: '알수 없음',
    write_today: "오늘의 투두리스트를\n작성해주세요.",
    c_shop: "C Shop",
    enter_brand_product: "브랜드 및 상품명을 입력해주세요",
    point: "포인트",
    product_info: "상품정보",
    reward_exchange: "리워드 교환",
    exchange_confirmation: "교환 확인",
    exchange_complete: "교환이 완료되었습니다.",
    get_the: "를",
    for: "에 획득하시겠습니까?",
    get: "GET",
    ID: "아이디",
    _y: "년",
    _m: "월",
    _d: "일",
    shop_tab: [
        {code: "", name: "전체"},
        {code: "GIFTICON", name: "기프티콘"},
        {code: "GIFTCARD", name: "상품권"},
        {code: "MUSIC", name: "뮤직"},
        {code: "OTT", name: "OTT 멤버십"},
    ],
    study_material_button_list: [
        { code: 0, name: "문서" },
        // { code: 1, name: "저장된 영상" },
        // { code: 2, name: "틀린 문항" },
        // { code: 3, name: "오답노트" },
    ],
    before_start_chew: [
        "츄잉안에서 데이터랩으로\n본인의 데이터를 확인할 수 있대요!",
        "AI 컨설턴트를 통해 구체적인 목표를\n세워보는건 어때요?",
        "이제 어질러진 자료들은 그만!\n클라우드에서 깔끔하게 관리해보세요~"
    ],

    studying_chew: [
        {
            chew: "/assets/image/img_li_04.png",
            txt: "얼굴을 책에 너무 가까이 대고\n있진 않나요?\n잠시 멀어져보세요ㅎㅎㅎ"
        },
        {
            chew: "/assets/image/img_li_02.png",
            txt: "기본기가 충실하면 나중에 넘어질일이 없다고 해요 모르겠으면 과감히 첨부터!"
        },
        {
            chew: "/assets/image/img_li_01.png",
            txt: "집중과 선택 그것이 문제로다...\n욕심부리지 말고 차근차근 해봐요!"
        }
    ],

    studying_event: [
        {
            chew: "/assets/image/img_li_03.png",
            txt: "혹시 졸고 있는건 아니죠?\n조금만 힘내서 목표 달성하기!!"
        },
        {
            chew: "/assets/image/img_li_02.png",
            txt: "벌써 공부시작한지 한시간이 지났어요!\n집중 할 수 있을만큼 욕심내지 말고~!"
        },
        {
            chew: "/assets/image/img_li_02.png",
            txt: "벌써 끝났어요?...\n에이 잘못누른거죠? ㅎㅎㅎ"
        },
        {
            chew: "/assets/image/img_li_02.png",
            txt: "오늘 목표의 반을 성공했어요~\n2라운드 시작 :)"
        },
        {
            chew: "/assets/image/img_li_04.png",
            txt: "오늘 목표를 모두 달성하셨어요 (짝짝)\n너무 무리하지말고 내일을 위해서 정리한번 어때요?"
        },
        {
            chew: "/assets/image/img_li_04.png",
            txt: "공부효율도가 한단계 상승했어요!\n그만큼 효율적으로 계획을 세웠다는 뜻이니 꾸준히 지켜봐요~"
        },
        {
            chew: "/assets/image/img_li_02.png",
            txt: "쉬는시간 그만~\n이제 시작해봅시다~"
        },
    ],

    studying_event_red: [
        {
            chew: "/assets/image/img_li_02.png",
            txt: "혹시 자는건 아니죠?...\n제가 지켜보고 있습니다~~!"
        },
        {
            chew: "/assets/image/img_li_03.png",
            txt: "공부효율도가 한단계 떨어졌어요ㅠㅠ\n잠깐 휴식을 가져보는 건 어떨까요?"
        },
    ],

    report_period: [
        { code: 0, name: "일" },
        { code: 1, name: "주" },
        { code: 2, name: "월" },
    ],
    weekList: [
        "1주차",
        "2주차",
        "3주차",
        "4주차"
    ],
    weekList5: [
        "1주차",
        "2주차",
        "3주차",
        "4주차",
        "5주차"
    ],
    table_head: [
        "이름",
        "순공시간",
        "학습효율도",
        "성장률",
        "미션",
        "학년",
        "학교"
    ],
    ranking_period: [
        { code: "YEAR", name: "월" },
        { code: "MONTH", name: "주" },
        { code: "DAY", name: "일" }
    ],
    ranking_period_desc: [
        { code: 0, name: "올해" },
        { code: 1, name: "이달" },
        { code: 2, name: "오늘" }
    ],
    ranking_button: [
        { code: "ALL", name: "종합" },
        { code: "GROWTH", name: "성장률" },
        { code: "STUDY", name: "학습 효율도" },
    ],
    subject_list: [
        "영어",
        "수학",
        "과학",
        "역사",
    ],
    grade_list: [
        "고1",
        "고2",
        "고3/N수",
    ],
    room_category_list: [
        { code: 0, name: '전체' },
        { code: 1, name: '수능' },
        { code: 2, name: '공무원' },
        { code: 3, name: '임용' },
        { code: 4, name: '자격증' },
        { code: 5, name: '어학' },
        { code: 6, name: '취업' },
        { code: 7, name: '학교' },
        { code: 8, name: '기타' },
    ],
    room_new_category_list: [
        { code: 1, name: '인기순' },
        { code: 2, name: '최신순' },
        { code: 3, name: '오래된 순' },
    ],
    expert_list: [
        { code: "abc", name: "전체" },
        { code: "review", name: "후기순" },
        { code: "latest", name: "최신순" },
        { code: "score_high", name: "평점 높은순" },
        { code: "score_low", name: "평점 낮은순" },
    ],
    clinic_list: [
        { code: "all", name: "전체" },
        { code: "latest", name: "시간순" },
        { code: "unread", name: "안읽은 순" },
    ],
    clinic_list_student: [
        {code: "all", name: "전체"},
        {code: "latest", name: "시간순"},
        {code: "unread", name: "안읽은 순"},
        { code: "like", name: "저장목록" },
    ],
    period_list_room: [
        { code: "all", name: "전체" },
        { code: "create", name: "개설한 룸" },
        { code: "join", name: "참가한 룸" }
    ],
    room_status_list: [
        { code: 1, name: "오픈룸" },
        { code: 2, name: "시크릿 룸" }
    ],
    study_room_type_list: [
        { code: 0, name: "전체" },
        { code: 1, name: "스터디 윗미" },
        { code: 2, name: "챌린지룸" },
    ],
    multi_room_type_list: [
        { code: 0, name: "전체" },
        { code: 1, name: "클래스룸" },
        { code: 2, name: "미팅룸" },
    ],     
    // room_type_list: [
    //     { code: 3, name: "오늘의 룸" },
    //     { code: 1, name: "스터디 윗미" },
    //     { code: 2, name: "챌린지 룸" },
    // ],  
    menu: {
        class: '클래스',
        log_out: '로그아웃',
        // lounge: '라운지',
        study_cafe: '스터디 카페',
        study_room: '스터디룸',
        conference_room: '멀티룸',
        clinic: '전문가',
        aitutor: 'AI 튜터',
        cabinet: '캐비닛',
        chewing_shop: '츄잉샵',
        ranking: '랭킹',
        cloud: "클라우드",
        planner: "플래너",
        data_lab: "데이터랩",
        ai_coaching: "AI 튜터",
        chat_coaching: "튜터링",
        road_map: "로드맵",
    },
    modal: {
        alert: '알림',
        confirm: '확인',
    },
    common: {
        male: 'male',
        female: 'female',
        unknown: 'unknown',
        accept: 'accept',
        accept_cancel: 'accept_cancel',
        accepted: 'accepted',
        reject: 'reject',
        reject_cancel: 'reject_cancel',
        rejected: 'rejected',
        m: "월"
    },
    login: {
        id: '아이디',
        password: '비밀번호',
        login: '로그인',
    },
    member: {
        normal: 'normal',
        pending: 'pending',
        favor: 'favor',
        leave: 'leave',
        block: 'block',
        hold: 'hold',
        unblock: 'unblock',
    },
    weekdays: {
        sunday: "S",
        monday: "M",
        tuesday: "T",
        wednesday: "W",
        thursday: "T",
        friday: "F",
        saturday: "S",
    },
    ai_coach_list: [
        { subject: 1, name: "AI 수학" },
        { subject: 2, name: "AI 국어" },
        { subject: 3, name: "AI 영어" },
    ],
    coach_chat_placeholder: "AI 코치에게 전달할 내용을 입력해주세요",
    chat_with_coach: "AI 코치와 대화해보세요",
    test_start: "테스트 시작",
    test_done: "테스트 완료",
    test: "테스트",
    test_desc: "본 테스트는 학습진행을 하기 위한 취약점 분석 솔루션입니다.\n\n문제지를 확인하시고 주어진 문제를 정확히 풀어주시기 바랍니다.\n분석의 정확도 향상을 위해 풀이과정을 적지 못한 경우 답을\n체크하지 말아주세요",
    test_time: "테스트 시간",
    submit_caution: "* 문제의 정답은 따로 필기하여 제출 후 체점해주세요.",
    submit: "제출",
    submit_desc: "제출을 하면 재 제출이 불가능합니다.\n정말로 제출 하시겠습니까?",
    answer_sheet: "답안지",
    correct_answer_check: "정답 체크",
    video_count: "영상 횟수",
    solve_count: "문제풀이 횟수",
    achievement_rate: "학습 성취율",
    roadmap_course: "로드맵 코스",
    view_video: "영상보기",
    solve_problem: "문제 풀기",
    class_year:[
     { code: 0, year: 2023 },
     { code: 1, year: 2022 },
     { code: 2, year: 2021 },
     { code: 3, year: 2020 },
     { code: 4, year: 2019 },
     { code: 5, year: 2018 },
     { code: 6, year: 2017 },
     { code: 7, year: 2016 },
    ],
    class_grade:[
        { code: 0, grade: "1학년" },
        { code: 1, grade: "2학년" },
        { code: 2, grade: "3학년" }
       ]
}

import React from "react";

export default function PlannerChatButton(props) {
    return (
        <button
            className={props.selected ? "planner-chat-button-selected" : "planner-chat-button"}
            onClick={props.onClick}>
            {
                props.isNew &&
                <div className='red-dot' style={{top: 14, right: 0}}/>
            }
            {props.value}</button>
    );
}

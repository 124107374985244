import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';

export default function SubCategoryButton(props) {
    return (
<>
    
        {/* <button
            className={props.selected ? "sub-category-button-selected" : "sub-category-button"}
            onClick={props.onClick}>
            {
                props.selected &&
                <div className="blue-dot"/>
            }
            {
                !props.selected &&
                    <div className="white-dot"/>
            }
            {props.value}</button> */}
                <Dropdown.Item className ={props.selected ? "sub-category-button-selected" : "sub-category-button"} onClick={props.onClick}>{props.value}</Dropdown.Item>
                {/* <Dropdown.Item href="#/action-2">{props.value}</Dropdown.Item> */}
                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
            </>
    );
}

import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";
import StarRatingCustom from "../item/star_rating";

const WriteReview = (props) => {
    const [starCnt, setStarCnt] = useState(0)

    const {show, close, toggle, onNext, expert} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='clinic-review-modal' onOpened={() => {
            setStarCnt(0)
        }}>
            <ModalBody className="p-0">
                <div className='d-flex header align-item-center m-b-20'>
                    <div className="f-s-20">{strings.write_review}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="consultant f-s-16">{expert.name + strings._consultant}</div>
                <div className="f-s-16 m-b-15">{strings.rating}</div>
                <div className="rating-container">
                    <StarRatingCustom
                        starCnt={starCnt}
                        fullStar={"/assets/image/icon_fav_active.png"}
                        halfStar={"/assets/image/icon_fav_active.png"}
                        emptyStar={"/assets/image/icon_fav_inactive.png"}
                        onClick={(idx) => setStarCnt(idx)}
                        disabled={false}
                        padding={true}
                        size={35}
                    />
                    <div className={("m-l-10 ") + (starCnt > 0 ? "white" : "")}>{starCnt}</div>
                    <div className="m-l-10">/</div>
                    <div className="m-l-10">5</div>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="review-next" onClick={() => onNext(starCnt)}>{strings.next}</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default WriteReview;

import React from "react";
import strings from "../../../lang/strings";

export default function StudyStartButton(props) {
    return (
        <button
            className={props.start ? "study-start-button" : "study-start-button study-end-button"}
            onClick={() => props.onClick()}>
            {
                props.start &&
                <img src={"/assets/image/icon_start.png"} alt=""/>
            }
            {
                !props.start &&
                <img src={"/assets/image/icon_circle_check_white.png"} alt=""/>
            }
            <div>{props.start ? strings.start : strings.save}</div>
        </button>
    );
}

import React from "react";
import strings from "../../../lang/strings";

const TimeShow = (props)=>{
    return(
        <div className="enter-time-show">
            <img src={"/assets/image/icon_alarm.png"} alt=""/>
            <div className="flex-grow-1">{strings.today}</div>
            <div className="enter-time">{props.time}</div>
        </div>
    );
}

export default TimeShow;

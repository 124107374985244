import React from "react";

const ProgressBar = (props) => {

    const {percent, color, height, width, margin, backgroundColor} = props;

    return (
        <div className="progress rounded-corner" style={{height: height, width: width, margin: margin, background:backgroundColor?? ""}}>
            <div className="progress-bar" style={{
                width: `${percent}%`,
                backgroundColor: color,
                transition: 'all .2s ease-out',
                minWidth: '0px',
            }}
            />
        </div>
    );
}

export default ProgressBar;
